import { combineReducers } from "redux";
import CommonReducer from "./common";
import SignUpReducer from "./SignUpReducer";
import { reducer as formReducer } from "redux-form";
import FloorbookReducer from "./FloorbookReducer";
import FloorbookPageReducer from "./FloorbookPageReducer";
import LoginReducer from "./LoginReducer";
import UsefulResourcesReducer from "./UsefulResourcesReducer";
import ReportReducer from "./ReportReducer";
import NotificationReducer from "./NotificationReducer";
import ScrollPositionsReducer from "./scrollpositions";
import HomePageReducer from "./HomeReducer";
import HistoryReducer from "./HistoryReducer";

const rootReducer = combineReducers({
  CommonReducer,
  LoginReducer: LoginReducer,
  form: formReducer,
  SignUpReducer: SignUpReducer,
  FloorbookReducer: FloorbookReducer,
  FloorbookPageReducer: FloorbookPageReducer,
  UsefulResourcesReducer: UsefulResourcesReducer,
  ReportReducer: ReportReducer,
  NotificationReducer: NotificationReducer,
  ScrollPositionsReducer: ScrollPositionsReducer,
  HomePageReducer: HomePageReducer,
  HistoryReducer: HistoryReducer,
});

export default rootReducer;
