import { takeLatest, all, put } from "redux-saga/effects";
import {
  ADD_FLOORBOOK,
  GET_FLOORBOOKS,
  getMyFloorbooks,
  SAVE_FLOORBOOK_DATA,
  GET_INQUIRY_LINES,
  saveInquiryLines,
  startLoading,
  stopLoading,
  GET_FLOORBOOK_PAGES,
  saveFloorbookPages,
  GET_OTHER_FLOORBOOKS,
  saveOthersFloorbooks,
  UPDATE_FLOORBOOK,
  saveSubjectArea,
  GET_SUBJECT_AREA,
  GET_ALL_FLOORBOOKS_LIST,
  LIKE_FLOORBOOK,
  GET_FLOORBOOK_COMMENTS,
  SET_FAVOURITE,
  GET_FAVOURITE_FLOORBOOKS,
  setFavouriteFloorbooks,
  logout_user,
  DELETE_FLOORBOOK,
  ADD_COMMENT_LIKE,
  GET_FLOORBOOK_COVER_DETAILS,
  DELETE_COMMENTS_FLOORBOOK,
  saveHomeLatestPosts,
  saveTotalCountofLatestPosts,
  EDIT_FLOORBOOK_COMMENT,
  ADD_COMMENT_REPLY,
  UPDATE_FLOORBOOK_JOURNAL,
  savepageNoOfLatestPosts,
  HIDE_UNHIDE_FLOORBOOK,
  setPrivacyStatusofFloorbook,
} from "../actions";
import { checkOffline } from "../../shared/services/Utility";
const api = require(`../../shared/Apis/api`);
const {
  postRequest,
  getRequest,
  deleteRequest,
} = require(`../../helpers/axios`);
const { STATUS_CODE, MESSAGES, STATUS } = require(`../../shared/constants`);

//request to get all floorbook list
// todo:
// for home page posts use a different saga
function* getAllFloorbooksList({ data = 1, success, callback }) {
  try {
    yield put(startLoading());
    const response = yield getRequest({
      API: `${api.GET_ALL_FLOORBOOKS_PAGE}?page_no=${data}`,
    });

    if (response.status === STATUS_CODE.unAuthorized) {
      yield put(stopLoading());
      callback(response.data.message, STATUS.error);
      yield put(logout_user());
    } else if (response.status === 404) {
      yield put(stopLoading());
      callback(MESSAGES.ERROR_IN_CATCH, STATUS.error);
    } else if (response.status !== STATUS_CODE.successful) {
      yield put(stopLoading());
      callback(response.data.error.message, STATUS.error);
    } else {
      yield put(savepageNoOfLatestPosts(data));
      yield put(saveHomeLatestPosts(response.data.data.data));
      yield put(saveTotalCountofLatestPosts(response.data.data.count_pages));
      callback(response.data.data, STATUS.success);
      yield put(stopLoading());
    }
  } catch (error) {
    yield put(stopLoading());

    if (checkOffline()) {
      callback(MESSAGES.OFFLINE, STATUS.error);
    } else {
      callback(MESSAGES.ERROR_IN_CATCH, STATUS.error);
    }
  }
}
//request to add-floorbook
function* addFloorbook({ data, callback }) {
  try {
    yield put(startLoading());
    const formdata = new FormData();
    formdata.append("name", data.name);
    formdata.append("cover_image", data.cover_image);
    formdata.append("image", data.image);
    formdata.append("description", data.description);
    formdata.append("is_learning_journal", data.is_learning_journal);
    formdata.append("private", data.private);

    const response = yield postRequest({
      API: `${api.ADD_FLOORBOOK}`,
      DATA: formdata,
    });
    if (response.status === STATUS_CODE.unAuthorized) {
      yield put(stopLoading());
      callback(response.data.message, STATUS.error);
      yield put(logout_user());
    } else if (response.status === 413) {
      yield put(stopLoading());
      callback("File size too large. Max size allowed : 20MB", STATUS.error);
    } else if (response.status !== STATUS_CODE.successful) {
      yield put(stopLoading());
      callback(response.data.error.message, STATUS.error);
    } else {
      callback(response.data.data.message, STATUS.success);
      yield put(stopLoading());
    }
  } catch (error) {
    yield put(stopLoading());

    if (checkOffline()) {
      callback(MESSAGES.OFFLINE, STATUS.error);
    } else {
      callback(MESSAGES.ERROR_IN_CATCH, STATUS.error);
    }
  }
}
function* setFloorbookIsJournal({ data,callback}) {
  try {
    yield put(startLoading());
    const formdata = new FormData();
    formdata.append("id", data.id);
    formdata.append("is_learning_journal", data.is_learning_journal);

    const response = yield postRequest({
      API: `${api.UPDATE_FLOORBOOK_IS_JOURNAL}`,
      DATA: formdata,
    });
    if (response.status === STATUS_CODE.unAuthorized) {
      yield put(stopLoading());
      callback(response.data.message, STATUS.error);
      yield put(logout_user());
    } else if (response.status === 413) {
      yield put(stopLoading());
      callback("File size too large. Max size allowed : 20MB", STATUS.error);
    } else if (response.status !== STATUS_CODE.successful) {
      yield put(stopLoading());
      callback(response.data.error.message, STATUS.error);
    } else {
      callback(response.data.data.message, STATUS.success);
      yield put(stopLoading());
    }
  } catch (error) {
    yield put(stopLoading());

    if (checkOffline()) {
      callback(MESSAGES.OFFLINE, STATUS.error);
    } else {
      callback(MESSAGES.ERROR_IN_CATCH, STATUS.error);
    }
  }
}
//request to update floorbook
function* updateFloorbook({ id, data, callback }) {
  try {
    yield put(startLoading());
    const formdata = new FormData();
    formdata.append("name", data.name);
    formdata.append("cover_image", data.cover_image);
    formdata.append("image", data.image);
    formdata.append("description", data.description);
    formdata.append("is_learning_journal", data.is_learning_journal);
    formdata.append("private", data.private);

    const response = yield postRequest({
      API: `${api.UPDATE_FLOORBOOK}/${id}`,
      DATA: formdata,
    });
    if (response.status === STATUS_CODE.unAuthorized) {
      yield put(stopLoading());
      callback(response.data.message, STATUS.error);
      yield put(logout_user());
    } else if (response.status === 413) {
      yield put(stopLoading());
      callback("File size too large. Max size allowed : 20MB", STATUS.error);
    } else if (response.status !== STATUS_CODE.successful) {
      yield put(stopLoading());
      callback(response.data.error.message, STATUS.error);
    } else {
      callback(response.data.data.message, STATUS.success);
      yield put(stopLoading());
    }
  } catch (error) {
    yield put(stopLoading());
    if (checkOffline()) {
      callback(MESSAGES.OFFLINE, STATUS.error);
    } else {
      callback(MESSAGES.ERROR_IN_CATCH, STATUS.error);
    }
  }
}
//request to get all-floorbooks
function* getAllFloorbooks({ callback }) {
  try {
    yield put(startLoading());
    const response = yield postRequest({ API: `${api.GET_ALL_FLOORBOOKS}` });

    if (response.status === STATUS_CODE.unAuthorized) {
      yield put(stopLoading());
      callback(response.data.message, STATUS.error);
      yield put(logout_user());
    } else if (response.status === 404) {
      yield put(stopLoading());
    } else if (response.status !== STATUS_CODE.successful) {
      yield put(stopLoading());
      callback(response.data.error.message, STATUS.error);
    } else {
      console.log("my floorbooks from saga",response.data.data.data)
      callback(response.data.data.message, STATUS.success);
      yield put(stopLoading());
      yield put(getMyFloorbooks(response.data.data.data));
    }
  } catch (error) {
    yield put(stopLoading());
    if (checkOffline()) {
      callback(MESSAGES.OFFLINE, STATUS.error);
    } else {
      callback(MESSAGES.ERROR_IN_CATCH, STATUS.error);
    }
  }
}
//request to get pages/details of a floorbook
function* getAllFloorBookPage({ id, callback }) {
  try {
    yield put(startLoading());
    const response = yield postRequest({
      API: `${api.GET_FLOORBOOK}?id=${id}`,
    });
    if (response.status === STATUS_CODE.unAuthorized) {
      yield put(stopLoading());
      callback(
        response.data.message || response.data.error.message,
        STATUS.error
      );
      yield put(logout_user());
    } else if (response.status === 404) {
      yield put(stopLoading());
      yield put(saveFloorbookPages([]));
      callback(response.data.error.message, STATUS.error, response.status);
    } else if (response.status !== STATUS_CODE.successful) {
      yield put(stopLoading());
      yield put(saveFloorbookPages([]));
      callback(response.data.error.message, STATUS.error);
    } else {
      callback(response.data.data.message, STATUS.success, response.data.data);
      yield put(stopLoading());
      yield put(saveFloorbookPages(response.data.data.data));
      yield put(
        setPrivacyStatusofFloorbook(
          response.data.data.data.floorbook.is_private
        )
      );
    }
  } catch (error) {
    yield put(stopLoading());
    if (checkOffline()) {
      callback(MESSAGES.OFFLINE, STATUS.error);
    } else {
      callback(MESSAGES.ERROR_IN_CATCH, STATUS.error);
    }
  }
}
//request to get inquiry lines(dropdown)
function* getInquiryLines({ callback }) {
  try {
    yield put(startLoading());
    const response = yield getRequest({ API: `${api.GET_INQUIRY_LINES}` });

    if (response.status === STATUS_CODE.unAuthorized) {
      yield put(stopLoading());
      callback(response.data.message, STATUS.error);
      yield put(logout_user());
    }
    if (response.status !== STATUS_CODE.successful) {
      yield put(stopLoading());
      callback(response.data.error.message, STATUS.error);
    } else {
      callback(response.data.message, STATUS.success);
      yield put(stopLoading());
      yield put(saveInquiryLines(response.data.data.data));
    }
  } catch (error) {
    yield put(stopLoading());
    if (checkOffline()) {
      callback(MESSAGES.OFFLINE, STATUS.error);
    } else {
      callback(MESSAGES.ERROR_IN_CATCH, STATUS.error);
    }
  }
}
//request to get subject area(dropdown)
function* getSubjectArea({ callback }) {
  try {
    yield put(startLoading());
    const response = yield getRequest({ API: `${api.GET_SUBJECT_AREA}` });

    if (response.status === STATUS_CODE.unAuthorized) {
      yield put(stopLoading());
      callback(response.data.message, STATUS.error);
      yield put(logout_user());
    } else if (response.status !== STATUS_CODE.successful) {
      yield put(stopLoading());
      callback(response.data.error.message, STATUS.error);
    } else {
      callback(response.data.message, STATUS.success);
      yield put(stopLoading());
      yield put(saveSubjectArea(response.data.data.data));
    }
  } catch (error) {
    yield put(stopLoading());
    if (checkOffline()) {
      callback(MESSAGES.OFFLINE, STATUS.error);
    } else {
      callback(MESSAGES.ERROR_IN_CATCH, STATUS.error);
    }
  }
}
//request to add floorbook-page
function* SaveFloorbook({ data, callback }) {
  console.log("form data after floorbook entry added", data);
  try {
    yield put(startLoading());
    const formdata = new FormData();
    formdata.append("title", data.title);
    formdata.append("activity_from", data.activity_from);
    formdata.append("activity_to", data.activity_to);
    formdata.append("line_inquiry", data.line_inquiry);
    if (data.line_inquiry === 11) {
      formdata.append("other_line_inquiry", data.other_line_inquiry);
    }
    formdata.append("location_activity", data.location);
    formdata.append("description", data.description);
    formdata.append("floorbook_id", data.floorbook_id);
    formdata.append("is_learning_journal", data.is_learning_journal);
    formdata.append("subject", data.subject);
    formdata.append("improvement", data.improvement);

    data.updatedImages.forEach((item, i) => {
      const myFile = new File([item.file],`filenames[${i}]`)
      formdata.append(`filename[${i}]`, item.file.type.includes("video") ? myFile : item.file );
    });
    const response = yield postRequest({
      API: `${api.SAVE_FLOORBOOKS_PAGE}`,
      DATA: formdata,
    });

    if (response.status === STATUS_CODE.unAuthorized) {
      yield put(stopLoading());
      callback(response.data.message, STATUS.error);
      yield put(logout_user());
    } else if (response.status !== STATUS_CODE.successful) {
      yield put(stopLoading());
      callback(response.data.error.message, STATUS.error);
    } else {
      callback(response.data.data.message, STATUS.success);
      yield put(stopLoading());
    }
  } catch (error) {
    yield put(stopLoading());
    if (checkOffline()) {
      callback(MESSAGES.OFFLINE, STATUS.error);
    } else {
      callback(MESSAGES.ERROR_IN_CATCH, STATUS.error);
    }
  }
}
//request to get other users floorbook(public one)
function* getOtherFloorbooks({ callback }) {
  try {
    yield put(startLoading());
    const response = yield postRequest({ API: `${api.GET_OTHERS_FLOORBOOKS}` });

    if (response.status === STATUS_CODE.unAuthorized) {
      yield put(stopLoading());
      callback(response.data.message, STATUS.error);
      yield put(logout_user());
    } else if (response.status === 404) {
      yield put(stopLoading());
      // callback(response.data.error.message, STATUS.error);
    } else if (response.status !== STATUS_CODE.successful) {
      yield put(stopLoading());
      callback(response.data.error.message, STATUS.error);
    } else {
      callback(response.data.data.message, STATUS.success);
      yield put(stopLoading());
      yield put(saveOthersFloorbooks(response.data.data.data));
    }
  } catch (error) {
    yield put(stopLoading());
    if (checkOffline()) {
      callback(MESSAGES.OFFLINE, STATUS.error);
    } else {
      callback(MESSAGES.ERROR_IN_CATCH, STATUS.error);
    }
  }
}

//request to get Favourite floorbooks
function* getFavouriteFloorbooks({ callback }) {
  try {
    yield put(startLoading());
    const response = yield postRequest({
      API: `${api.GET_FAVOURITE_FLOORBOOK}`,
    });

    if (response.status === STATUS_CODE.unAuthorized) {
      yield put(stopLoading());
      callback(response.data.message, STATUS.error);
      yield put(logout_user());
    } else if (response.status === 404) {
      yield put(stopLoading());
      // callback(response.data.error.message, STATUS.error);
    } else if (response.status !== STATUS_CODE.successful) {
      yield put(stopLoading());
      callback(response.data.error.message, STATUS.error);
    } else {
      callback(response.data.data.message, STATUS.success);
      yield put(stopLoading());
      yield put(setFavouriteFloorbooks(response.data.data.data));
    }
  } catch (error) {
    yield put(stopLoading());
    if (checkOffline()) {
      callback(MESSAGES.OFFLINE, STATUS.error);
    } else {
      callback(MESSAGES.ERROR_IN_CATCH, STATUS.error);
    }
  }
}

//request to like floorbook
function* likeFloorbook({ data, status, created_at, callback }) {
  try {
    yield put(startLoading());

    const formdata = new FormData();
    formdata.append("id", data);
    formdata.append("status", status);
    formdata.append("created_at", created_at);

    const response = yield postRequest({
      API: `${api.LIKE_FLOORBOOK}`,
      DATA: formdata,
    });

    if (response.status === STATUS_CODE.unAuthorized) {
      yield put(stopLoading());
      callback(response.data.message, STATUS.error);
      yield put(logout_user());
    } else if (response.status !== STATUS_CODE.successful) {
      yield put(stopLoading());
      //callback(response.data.error.message, STATUS.error);
    } else {
      yield put(stopLoading());
      callback(response.data.data);
    }
  } catch (error) {
    yield put(stopLoading());
    if (checkOffline()) {
      callback(MESSAGES.OFFLINE, STATUS.error);
    } else {
      callback(MESSAGES.ERROR_IN_CATCH, STATUS.error);
    }
  }
}
//rquest to comment on floorbook
function* saveFloorbookComment({ data, callback }) {
  try {
    yield put(startLoading());
    const formdata = new FormData();
    formdata.append("id", data.id);
    formdata.append("comment", data.comment);
    formdata.append("created_at", data.created_at);

    const response = yield postRequest({
      API: `${api.SAVE_FLOORBOOK_COMMENT}`,
      DATA: formdata,
    });

    if (response.status === STATUS_CODE.unAuthorized) {
      yield put(stopLoading());
      callback(response.data.message, STATUS.error);
      yield put(logout_user());
    } else if (response.status === 404) {
      yield put(stopLoading());
      callback(response.data.error.message, STATUS.error);
    } else if (response.status !== STATUS_CODE.successful) {
      yield put(stopLoading());
      callback(response.data.error.message, STATUS.error);
    } else {
      callback(
        response.data.data.message,
        STATUS.success,
        response.data.data.comments
      );
      yield put(stopLoading());
    }
  } catch (error) {
    yield put(stopLoading());
    if (checkOffline()) {
      callback(MESSAGES.OFFLINE, STATUS.error);
    } else {
      callback(MESSAGES.ERROR_IN_CATCH, STATUS.error);
    }
  }
}
function* saveCommentReply({ data, callback }) {
  try {
    yield put(startLoading());
    const formdata = new FormData();
    formdata.append("comment_id", data.comment_id);
    formdata.append("item_id", data.item_id);
    formdata.append("item_type", data.item_type);
    formdata.append("comment_type", data.comment_type);
    formdata.append("comment", data.comment);
    formdata.append("created_at", data.created_at);

    const response = yield postRequest({
      API: `${api.DO_COMMENT_REPLY}`,
      DATA: formdata,
    });

    if (response.status === STATUS_CODE.unAuthorized) {
      yield put(stopLoading());
      callback(response.data.message, STATUS.error);
      yield put(logout_user());
    } else if (response.status === 404) {
      yield put(stopLoading());
      callback(response.data.error.message, STATUS.error);
    } else if (response.status !== STATUS_CODE.successful) {
      yield put(stopLoading());
      callback(response.data.error.message, STATUS.error);
    } else {
      callback(
        response.data.data.message,
        STATUS.success,
        response.data.data.comments
      );
      yield put(stopLoading());
    }
  } catch (error) {
    yield put(stopLoading());
    if (checkOffline()) {
      callback(MESSAGES.OFFLINE, STATUS.error);
    } else {
      callback(MESSAGES.ERROR_IN_CATCH, STATUS.error);
    }
  }
}
function* setEditFloorbookComment({ data, callback }) {
  try {
    yield put(startLoading());

    const formdata = new FormData();
    formdata.append("comment_id", data.comment_id);
    formdata.append("id", data.id);
    formdata.append("comment", data.comment);
    formdata.append("created_at", data.created_at);

    const response = yield postRequest({
      API: `${api.UPDATE_FLOORBOOK_COMMENT}`,
      DATA: formdata,
    });
    if (response.status === STATUS_CODE.unAuthorized) {
      yield put(stopLoading());
      callback(response.data.message, STATUS.error);
      yield put(logout_user());
    } else if (response.status != STATUS_CODE.successful) {
      yield put(stopLoading());
    } else {
      callback(
        response.data.data.message,
        STATUS.success,
        response.data.data.comments
      );
      yield put(stopLoading());
    }
  } catch (error) {
    yield put(stopLoading());
    if (checkOffline()) {
      callback(MESSAGES.OFFLINE, STATUS.error);
    } else {
      callback(MESSAGES.ERROR_IN_CATCH, STATUS.error);
    }
  }
}
function* setCommentLike({ data, callback }) {
  try {
    yield put(startLoading());

    const formdata = new FormData();
    formdata.append("comment_id", data.comment_id);
    formdata.append("item_id", data.item_id);
    formdata.append("item_type", data.item_type);
    formdata.append("status", data.status);
    formdata.append("created_at", data.created_at);

    const response = yield postRequest({
      API: `${api.DO_COMMENT_LIKE}`,
      DATA: formdata,
    });
    if (response.status === STATUS_CODE.unAuthorized) {
      yield put(stopLoading());
      callback(response.data.message, STATUS.error);
      yield put(logout_user());
    } else if (response.status != STATUS_CODE.successful) {
      yield put(stopLoading());
    } else {
      callback(
        response.data.data.message,
        STATUS.success,
        response.data.data.comments
      );
      yield put(stopLoading());
    }
  } catch (error) {
    yield put(stopLoading());
    if (checkOffline()) {
      callback(MESSAGES.OFFLINE, STATUS.error);
    } else {
      callback(MESSAGES.ERROR_IN_CATCH, STATUS.error);
    }
  }
}
/************ set favourite floorbook  *********************/
function* updateFavouriteFloorbook({ data, callback }) {
  try {
    yield put(startLoading());
    const formdata = new FormData();
    formdata.append("id", data.id);
    formdata.append("status", data.status);
    formdata.append("timestamp", data.marked_at);
    formdata.append("is_learning_journal", data.is_learning_journal);

    const response = yield postRequest({
      API: `${api.SET_FAVOURITE_FLOORBOOK}`,
      DATA: formdata,
    });

    if (response.status === STATUS_CODE.unAuthorized) {
      yield put(stopLoading());
      callback(response.data.message, STATUS.error);
      yield put(logout_user());
    } else if (response.status === 404) {
      yield put(stopLoading());
      callback(response.data.error.message, STATUS.error);
    } else if (response.status !== STATUS_CODE.successful) {
      yield put(stopLoading());
      callback(response.data.error.message, STATUS.error);
    } else {
      callback(response.data.data, STATUS.success);
      yield put(stopLoading());
    }
  } catch (error) {
    yield put(stopLoading());
    if (checkOffline()) {
      callback(MESSAGES.OFFLINE, STATUS.error);
    } else {
      callback(MESSAGES.ERROR_IN_CATCH, STATUS.error);
    }
  }
}
function* onDeleteFloorbook({ data, callback }) {
  try {
    yield put(startLoading());

    const response = yield deleteRequest({
      API: `${api.DELETE_FLOORBOOK}/${data}`,
    });

    if (response.status === STATUS_CODE.unAuthorized) {
      yield put(stopLoading());
      callback(response.data.message, STATUS.error);
      yield put(logout_user());
    } else if (response.status === 404) {
      yield put(stopLoading());
      callback(response.data.error.message, STATUS.error);
    } else if (response.status !== STATUS_CODE.successful) {
      yield put(stopLoading());
      callback(response.data.error.message, STATUS.error);
    } else {
      console.log("on floorbook delete response",response.data)
      callback(response.data.data.message, STATUS.success);
      yield put(stopLoading());
    }
  } catch (error) {
    yield put(stopLoading());
    if (checkOffline()) {
      callback(MESSAGES.OFFLINE, STATUS.error);
    } else {
      callback(MESSAGES.ERROR_IN_CATCH, STATUS.error);
    }
  }
}
function* getCoverDetailsOfFloorbook({ data, callback }) {
  try {
    yield put(startLoading());

    const response = yield getRequest({
      API: `${api.GET_COVER_DETAILS}/${data}`,
    });

    if (response.status === STATUS_CODE.unAuthorized) {
      yield put(stopLoading());
      callback(response.data.message, STATUS.error);
      yield put(logout_user());
    } else if (response.status === 404) {
      yield put(stopLoading());
      callback(response.data.error.message, STATUS.error);
    } else if (response.status !== STATUS_CODE.successful) {
      yield put(stopLoading());
      callback(response.data.error.message, STATUS.error);
    } else {
      callback(response.data.data.data, STATUS.success);
      yield put(stopLoading());
    }
  } catch (error) {
    yield put(stopLoading());
    if (checkOffline()) {
      callback(MESSAGES.OFFLINE, STATUS.error);
    } else {
      callback(MESSAGES.ERROR_IN_CATCH, STATUS.error);
    }
  }
}
/*******request to delete floorbook comment ********/
function* deleteFloorbookComment({ data, callback }) {
  try {
    yield put(startLoading());

    const response = yield deleteRequest({
      API: `${api.DELETE_COMMENT_FLOORBOOK}/${data}`,
    });

    if (response.status === STATUS_CODE.unAuthorized) {
      yield put(stopLoading());
      callback(response.data.message, STATUS.error);
      yield put(logout_user());
    } else if (response.status === 404) {
      yield put(stopLoading());
      callback(response.data.error.message, STATUS.error);
    } else if (response.status !== STATUS_CODE.successful) {
      yield put(stopLoading());
      callback(response.data.error.message, STATUS.error);
    } else {
      callback(
        response.data.data.message,
        STATUS.success,
        response.data.data.id
      );
      yield put(stopLoading());
    }
  } catch (error) {
    yield put(stopLoading());

    if (checkOffline()) {
      callback(MESSAGES.OFFLINE, STATUS.error);
    } else {
      callback(MESSAGES.ERROR_IN_CATCH, STATUS.error);
    }
  }
}
/****** request to hide or unhide the floorbook (private or public) *******/
function* setHideorUnhideFloorbookPrivacy({ data, callback }) {
  try {
    yield put(startLoading());

    const response = yield postRequest({
      API: `${api.HIDE_UNHIDE_FLOORBOOK}`,
      DATA: data,
    });

    if (response.status === STATUS_CODE.unAuthorized) {
      yield put(stopLoading());
      callback(response.data.message, STATUS.error);
      yield put(logout_user());
    } else if (response.status === 404) {
      yield put(stopLoading());
      callback(response.data.error.message, STATUS.error);
    } else if (response.status !== STATUS_CODE.successful) {
      yield put(stopLoading());
      callback(response.data.error.message, STATUS.error);
    } else {
      callback(
        response.data.data.message,
        STATUS.success,
        response.data.data.id
      );
      yield put(stopLoading());
      yield put(setPrivacyStatusofFloorbook(data.status == 1 ? 1 : 0));
    }
  } catch (error) {
    yield put(stopLoading());

    if (checkOffline()) {
      callback(MESSAGES.OFFLINE, STATUS.error);
    } else {
      callback(MESSAGES.ERROR_IN_CATCH, STATUS.error);
    }
  }
}

function* FloorbookSaga() {
  yield all([
    takeLatest(ADD_FLOORBOOK, addFloorbook),
    takeLatest(GET_FLOORBOOKS, getAllFloorbooks),
    takeLatest(GET_ALL_FLOORBOOKS_LIST, getAllFloorbooksList),
    takeLatest(SAVE_FLOORBOOK_DATA, SaveFloorbook),
    takeLatest(GET_INQUIRY_LINES, getInquiryLines),
    takeLatest(GET_FLOORBOOK_PAGES, getAllFloorBookPage),
    takeLatest(GET_OTHER_FLOORBOOKS, getOtherFloorbooks),
    takeLatest(UPDATE_FLOORBOOK, updateFloorbook),
    takeLatest(GET_SUBJECT_AREA, getSubjectArea),
    takeLatest(LIKE_FLOORBOOK, likeFloorbook),
    takeLatest(GET_FLOORBOOK_COMMENTS, saveFloorbookComment),
    takeLatest(SET_FAVOURITE, updateFavouriteFloorbook),
    takeLatest(GET_FAVOURITE_FLOORBOOKS, getFavouriteFloorbooks),
    takeLatest(DELETE_FLOORBOOK, onDeleteFloorbook),
    takeLatest(GET_FLOORBOOK_COVER_DETAILS, getCoverDetailsOfFloorbook),
    takeLatest(DELETE_COMMENTS_FLOORBOOK, deleteFloorbookComment),
    takeLatest(HIDE_UNHIDE_FLOORBOOK, setHideorUnhideFloorbookPrivacy),
    takeLatest(EDIT_FLOORBOOK_COMMENT, setEditFloorbookComment),
    takeLatest(ADD_COMMENT_LIKE, setCommentLike),
    takeLatest(ADD_COMMENT_REPLY, saveCommentReply),
    takeLatest(UPDATE_FLOORBOOK_JOURNAL,setFloorbookIsJournal),
  ]);
}

export default FloorbookSaga;


