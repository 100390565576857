import { connect } from "react-redux";
import Screen from "./screen";
import { LoginRequest, goBackToSignUPFirst } from "../../../redux/actions/LoginActions";

const mapStateToProps = (state) => {
  return {
    loading: state.CommonReducer.isLoading,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    dispatchLoginRequest: (data, callback) => dispatch(LoginRequest(data, callback)),
    goBackToSignUPFirst: (data) => dispatch(goBackToSignUPFirst(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Screen);
