import { takeLatest, all, put } from "redux-saga/effects";
import { startLoading, stopLoading, logout_user } from "../actions";
import {
  GET_REPORT_REQUEST,
  GET_REPORT_FLOORBOOK,
  SAVE_COMPLETION_DATA,
  SET_REPORTS_HEADING,
  SET_REPORTS_SUMMARY,
} from "../actions/ActionTypes";
import { checkOffline } from "../../shared/services/Utility";
const api = require(`../../shared/Apis/api`);
const { getRequest, postRequest } = require(`../../helpers/axios`);
const { STATUS_CODE, MESSAGES, STATUS } = require(`../../shared/constants`);

function* getReport({ data, callback }) {
  try {
    yield put(startLoading());
    const { floorbook, reportType } = data;
    const response = yield getRequest({ API: `${api.GET_REPORT}/${floorbook}/${reportType}` });
    if (response.status === STATUS_CODE.unAuthorized) {
      yield put(stopLoading());
      callback(response.data.message, STATUS.error);
      yield put(logout_user());
    } else if (response.status === 404) {
      yield put(stopLoading());
      callback(response.data.error.message, STATUS.error);
    } else if (response.status !== STATUS_CODE.successful) {
      yield put(stopLoading());
      callback(response.data.message, STATUS.error);
    } else {
      callback(response.data.data.message, STATUS.success, response.data.data.data);
      yield put(stopLoading());
    }
  } catch (error) {
    yield put(stopLoading());
    if (checkOffline()) {
      callback(MESSAGES.OFFLINE, STATUS.error);
    } else {
      callback(MESSAGES.ERROR_IN_CATCH, STATUS.error);
    }
  }
}

function* getReportFloorbook({ callback }) {
  try {
    yield put(startLoading());

    const response = yield getRequest({ API: `${api.GET_REPORT_FLOORBOOK}` });
    if (response.status === STATUS_CODE.unAuthorized) {
      yield put(stopLoading());
      callback(response.data.message, STATUS.error);
      yield put(logout_user());
    } else if (response.status === 404) {
      yield put(stopLoading());
      callback(response.data.error.message, STATUS.error);
    } else if (response.status !== STATUS_CODE.successful) {
      yield put(stopLoading());
      callback(response.data.error.message, STATUS.error);
    } else {
      callback(response.data.data.message, STATUS.success, response.data.data);
      yield put(stopLoading());
    }
  } catch (error) {
    yield put(stopLoading());
    if (checkOffline()) {
      callback(MESSAGES.OFFLINE, STATUS.error);
    } else {
      callback(MESSAGES.ERROR_IN_CATCH, STATUS.error);
    }
  }
}
function* saveCompletionDataSaga({ data, callback }) {
  try {
    yield put(startLoading());

    const response = yield postRequest({ API: `${api.UPDATE_FLOORBOOK_COMPLETION_TIME}`, DATA: data });
    if (response.status === STATUS_CODE.unAuthorized) {
      yield put(stopLoading());
      callback(response.data.message, STATUS.error);
      yield put(logout_user());
    } else if (response.status === 404) {
      yield put(stopLoading());
      callback(response.data.error.message, STATUS.error);
    } else if (response.status !== STATUS_CODE.successful) {
      yield put(stopLoading());
      callback(response.data.error.message, STATUS.error);
    } else {
      callback(response.data.data.message, STATUS.success, response.data.data.data);
      yield put(stopLoading());
    }
  } catch (error) {
    yield put(stopLoading());
    if (checkOffline()) {
      callback(MESSAGES.OFFLINE, STATUS.error);
    } else {
      callback(MESSAGES.ERROR_IN_CATCH, STATUS.error);
    }
  }
}

/***** api to set reports heading *****/
function* setReportsHeading({ data, callback }) {
  try {
    yield put(startLoading());
    const formdata = new FormData();
    formdata.append("heading", data.heading);

    const response = yield postRequest({ API: `${api.UPDATE_REPORTS_HEADING}`, DATA: formdata });
    if (response.status === STATUS_CODE.unAuthorized) {
      yield put(stopLoading());
      callback(response.data.message, STATUS.error);
      yield put(logout_user());
    } else if (response.status === 404) {
      yield put(stopLoading());
      callback(response.data.error.message, STATUS.error);
    } else if (response.status !== STATUS_CODE.successful) {
      yield put(stopLoading());
      callback(response.data.error.message, STATUS.error);
    } else {
      callback(response.data.data.message, STATUS.success, response.data.data.data);
      yield put(stopLoading());
    }
  } catch (error) {
    yield put(stopLoading());
    if (checkOffline()) {
      callback(MESSAGES.OFFLINE, STATUS.error);
    } else {
      callback(MESSAGES.ERROR_IN_CATCH, STATUS.error);
    }
  }
}
/*******api to update reports summary*********/
function* setReportsSummary({ data, callback }) {
  try {
    yield put(startLoading());
    const formdata = new FormData();
    formdata.append("report_type", data.type);
    formdata.append("summary", data.summary);
    const response = yield postRequest({ API: `${api.UPDATE_REPORTS_SUMMARY}`, DATA: formdata });
    if (response.status === STATUS_CODE.unAuthorized) {
      yield put(stopLoading());
      callback(response.data.message, STATUS.error);
      yield put(logout_user());
    } else if (response.status === 404) {
      yield put(stopLoading());
      callback(response.data.error.message, STATUS.error);
    } else if (response.status !== STATUS_CODE.successful) {
      yield put(stopLoading());
      callback(response.data.error.message, STATUS.error);
    } else {
      callback(response.data.data.message, STATUS.success, response.data.data.data);
      yield put(stopLoading());
    }
  } catch (error) {
    yield put(stopLoading());
    if (checkOffline()) {
      callback(MESSAGES.OFFLINE, STATUS.error);
    } else {
      callback(MESSAGES.ERROR_IN_CATCH, STATUS.error);
    }
  }
}
function* ReportSaga() {
  yield all([
    takeLatest(GET_REPORT_REQUEST, getReport),
    takeLatest(GET_REPORT_FLOORBOOK, getReportFloorbook),
    takeLatest(SAVE_COMPLETION_DATA, saveCompletionDataSaga),
    takeLatest(SET_REPORTS_HEADING, setReportsHeading),
    takeLatest(SET_REPORTS_SUMMARY, setReportsSummary),
  ]);
}

export default ReportSaga;
