import React, { useState, useEffect, useRef } from "react";
import moment from "moment";
import { withSnackbar } from "notistack";
import { ImageOverlay } from "../../atoms";
import "./style.scss";
import PreviewModal from "./previewModal";
import DeleteModal from "../DeleteModal";
import ProfileModal from "../ProfileModal";
import {
  STATUS,
  SNACKBAR_FILECHECKS,
  BUTTON_LABELS,
  LIKE_GREEN_ICON
} from "../../../shared/constants";
import { USER_ROLES } from "../../../shared/constants";

const CommentBox = ({
  id,
  loading,
  comments = [],
  getComments = () => {},
  enqueueSnackbar,
  updateComments = () => {},
  commentType = 0,
  //0 for pageComments, 1 for floorbook Cover comment
  saveFloorbookComment = () => {},
  profileImage,
  deleteFloorbook_PageComment,
  editFloorbookPageComment,
  addCommentReply = () => {},
  editFloorbookComment,
  deleteFloorbookComment,
  addCommentLike = () => { },
  userRole,
  isJournal = false,
}) => {
  const [commentInput, update_comment] = useState("");
  const [replyInput, update_reply] = useState("");
  const [uploadImage, set_uploadImage] = useState("");
  const [commentId, setCommentId] = useState("");
  const [replies, update_replies] = useState("");
  const [imagePreview, set_imagePreview] = useState("");
  const [showDeleteBtn, set_showDeleteBtn] = useState("");
  const [editable, setEditable] = useState("");
  const [deleteId, set_deleteId] = useState("");
  const [comment, setEditedComment] = useState("");
  const node = useRef();
  const [replyTouch, setReplyTouch] = useState(false)
  const [currentCommentId,setCurrentCommentId]=useState("")
  const [replyType,setReplyType] = useState("")
  const [likeStatus, setLikeStatus] = useState("");
  const [likeCount, setLikeCount] = useState("");
  const [reply, setReply] = useState("");
  const node1 = useRef();
  var objDiv = document.getElementById("scroll_div");
  if (objDiv) {
    objDiv.scrollTop = objDiv.scrollHeight;
  }

  const onSubmitt = (check, commentId) => {
    let data = {
      id:id,
      comment: check ? uploadImage : commentInput,
      created_at: new Date().getTime(),
    };
    let replyData = {
      comment_id: currentCommentId ? currentCommentId : commentId,
      item_id: id,
      item_type: commentType && !isJournal ? 1 : !isJournal ? 2 : 3,
      comment_type: replyType ? replyType : "text",
      comment: check ? uploadImage : commentInput ? commentInput : replyInput,
      created_at: new Date().getTime(),
    };
    //0 for pageComments, 1 for floorbook Cover comment
    if (!commentInput && reply && (replyInput || replyTouch)) {
      addCommentReply(replyData, (message, type, data) => {
        enqueueSnackbar(message, {
          variant: type,
          autoHideDuration: 3000,
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "left",
          },
        });

        if (type === STATUS.success) {
          let id = currentCommentId ? currentCommentId : commentId;
          set_imagePreview(false);
          let newData = { [id]: [...replies[id], data] };
          update_replies({ ...replies, ...newData });
          update_reply("");
        }
      });
    }
    
   else if (commentType && !isJournal) {
      saveFloorbookComment(data, (message, type, data) => {
          enqueueSnackbar(message, {
            variant: type,
            autoHideDuration: 3000,
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "left",
            },
          });

        if (type === STATUS.success) {
          set_imagePreview(false);
          update_comment("");
          let newData = [...comments, data];
          updateComments(comments.length + 1, newData);
        }
      });
    } else {
      !isJournal &&
        getComments(data, (message, type, data) => {
          // api for add-floorbook-page comment
          if (type === STATUS.error) {
            enqueueSnackbar(message, {
              variant: type,
              autoHideDuration: 3000,
              anchorOrigin: {
                vertical: "bottom",
                horizontal: "left",
              },
            });
            return;
          }

          if (type === STATUS.success) {
            set_imagePreview(false);
            update_comment("");
            let newData = [...comments, data];
            updateComments(comments.length + 1, newData);
          }
        });
    }
  };
  const likeOnComments = (comment_id,status) => {
    let data = {
      comment_id: comment_id,
      item_id: id,
      item_type: commentType && !isJournal ? 1 : !isJournal ? 2 : 3,
      status: status,
      created_at: new Date().getTime(),
    };
    addCommentLike(data, (message, type, data) => {
      enqueueSnackbar(message, {
        variant: type,
        autoHideDuration: 3000,
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "left",
        },
      });
      if (type === STATUS.success) {
        setLikeStatus({...likeStatus,[comment_id]:data.status})
        setLikeCount({...likeCount,[comment_id]:data.like})
      }
    })
  }
  const handleCommentChange = (e, comment_id) => {
    setEditedComment(e.target.value);
    setCommentId(comment_id);
  };
  const onDeleteComment = (data) => {
    if (commentType) {
      deleteFloorbookComment(data, (message, type, data) => {
        enqueueSnackbar(message, {
          variant: type,
          autoHideDuration: 3000,
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "left",
          },
        });

        if (type === STATUS.success) {
          let id = Number(data);
          let arr = comments.filter((item) => item.id !== id);
          updateComments(comments.length - 1, arr);
        }
      });
    } else {
      deleteFloorbook_PageComment(data, (message, type, data) => {
        enqueueSnackbar(message, {
          variant: type,
          autoHideDuration: 3000,
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "left",
          },
        });

        if (type === STATUS.success) {
          let id = Number(data);
          let arr = comments.filter((item) => item.id !== id);
          updateComments(comments.length - 1, arr);
        }
      });
    }
  };

  const onEditComment = (ID) => {
    let data = {
      comment_id: commentId,
      id: id,
      comment: comment,
      created_at: new Date().getTime(),
    };
    setEditable(ID);
    set_showDeleteBtn(false);
    if (commentType && commentId) {
      editFloorbookComment(data, (message, type, data) => {
        console.log("after the pai hit", type, data);
        enqueueSnackbar(message, {
          variant: type,
          autoHideDuration: 3000,
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "left",
          },
        });

        if (type === STATUS.success) {
          console.log("");
          let id = Number(data);
         let array = comments.map((item) => {
           if (item.id == commentId) {
             return { ...item, text: comment,created_at:new Date().getTime() };
           } else {
             return item;
           }
         });
          updateComments(comments.length, array);
          setEditedComment("");
        }
      });
    } else if (commentId) {
      editFloorbookPageComment(data, (message, type, data) => {
        console.log("after the pai hit", type, data);
        enqueueSnackbar(message, {
          variant: type,
          autoHideDuration: 3000,
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "left",
          },
        });

        if (type === STATUS.success) {
          let id = Number(data);
          let array = comments.map((item) => {
            if (item.id == commentId)
            { 
              return {
                ...item,
                text: comment,
                created_at: new Date().getTime(),
              };
            }
            else {
              return item
            }
          }
          );
          updateComments(comments.length, array);
          setEditedComment("");
        }
      });
    } else {
      console.log("no comment edited");
    }
  };
  useEffect(() => {
    // add when mounted
    document.addEventListener("mousedown", handleClick);
    // return function to be called when unmounted
    return () => {
      document.removeEventListener("mousedown", handleClick);
    };
  });
  useEffect(() => {
    let status = {};
    let count = {};
    let reply = {};
    if (!isJournal) {
      comments.map((item) => {
        status = { ...status, [item.id]: item.comment_like_status }
        count = { ...count, [item.id]: item.total_likes_on_comment }
        reply = { ...reply, [item.id]: item.reply }
      }
      );
      setLikeStatus({ ...status });
      setLikeCount({ ...count });
      update_replies({ ...reply });
    }
  },[comments])
  const handleClick = (e) => {
    if (
      (node &&
        node.current &&
        node.current.contains &&
        node.current.contains(e.target)) ||
      (node1 &&
        node1.current &&
        node1.current.contains &&
        node1.current.contains(e.target))
    ) {
      // inside click
      return;
    } else {
      // outside click
      set_showDeleteBtn(false);
      //set_deleteId("");
      return;
    }
  };
  const renderCommentBox = (color,placeholder,width,height,imgWidth,imgHeight,commentId) => {
    return (
      <div className="comment_block" style={{ backgroundColor: color }}>
        <figure
          className="cmt_pic"
          style={{ width: width, height: height }}
        >
          <ImageOverlay>
            <img
              src={
                profileImage
                  ? profileImage
                  : require("../../../assets/images/no-user.png")
              }
              alt=""
            />
          </ImageOverlay>
        </figure>

        <div className="d-flex commentbox_groups">
          <input
            style={{ minHeight: height }}
            className="form-control comment-input"
            type="text"
            maxLength={255}
            value={placeholder ? replyInput : commentInput}
            placeholder={placeholder ? placeholder : "Type your comment here"}
            onChange={(e) =>
              placeholder
                ? update_reply(e.target.value)
                : update_comment(e.target.value)
            }
          />
          {commentInput.length || replyInput.length ? null : (
            <input
              id="files"
              multiple={false}
              style={{ display: "none" }}
              type="file"
              accept={
                ".jpg,.png,.mp4,.webm,.MPG,.MP2,.MPEG,.MPE,.MPV,.m4p,.m4v"
              }
              onChange={(e) => {
                if (e.target.files.length) {
                  update_comment("");
                  setCurrentCommentId(commentId)
                  update_reply("");
                  setReplyTouch(true)
                  if (e.target.files[0].type.includes("video")) { 
                    setReplyType("video")
                    if (e.target.files[0].size <= 10000000) {
                      set_uploadImage(e.target.files[0]);
                      e.target.files.length &&
                        set_imagePreview({
                          url: URL.createObjectURL(e.target.files[0]),
                          type: e.target.files[0].type,
                        });
                    } else {
                      enqueueSnackbar(SNACKBAR_FILECHECKS.Video_10Mb, {
                        variant: "error",
                        autoHideDuration: 2000,
                        anchorOrigin: {
                          vertical: "bottom",
                          horizontal: "left",
                        },
                      });
                    }
                  }

                  if (e.target.files[0].type.includes("image")) {
                    setReplyType("image")
                    if (e.target.files[0].size <= 5000000) {
                      set_uploadImage(e.target.files[0]);
                      e.target.files.length &&
                        set_imagePreview({
                          url: URL.createObjectURL(e.target.files[0]),
                          type: e.target.files[0].type,
                        });
                    } else {
                      enqueueSnackbar(SNACKBAR_FILECHECKS.Image_5Mb, {
                        variant: "error",
                        autoHideDuration: 2000,
                        anchorOrigin: {
                          vertical: "bottom",
                          horizontal: "left",
                        },
                      });
                    }
                  }
                }
              }}
            />
          )}
          <div className="send_attach_group">
            {commentInput.length ? null : (
              <label htmlFor="files">
                <img
                  style={{
                    width: imgWidth ? imgWidth - 5 : null,
                    height: imgHeight ? imgHeight - 5 : null,
                  }}
                  src={require("../../../assets/images/icons/attach_icon.svg")}
                  alt=""
                />
              </label>
            )}

            <button
              style={{
                width: imgWidth,
                height: imgHeight,
                justifyContent: "center",
                alignItems: "center",
                display: "flex",
              }}
              disabled={commentInput || replyInput ? false : true}
              className={
                commentInput
                  ? "btn btn-primary comment-btn"
                  : "btn btn-primary comment-btn-disabled"
              }
              type="button"
              onClick={() => onSubmitt(false, commentId)}
            >
              <img
                style={{
                  width: imgWidth ? imgWidth - 15 : null,
                  height: imgHeight ? imgHeight - 15 : null,
                }}
                src={require("../../../assets/images/icons/send_icon.svg")}
                alt=""
              />
            </button>
          </div>
        </div>
      </div>
    );
  };
  return (
    <>
      <div className="comments-container">
        <div id="scroll_div" style={{ maxHeight: 500, overflow: "scroll" }}>
          {comments &&
            comments.map((item, index) => {
              return (
                <div className="comment_item" key={index}>
                  <div className="d-flex justify-content-md-between">
                    <div className="cmt_icon">
                      <ProfileModal
                        user_image={item.user_image}
                        user_id={item.user_id}
                      />
                    </div>
                    <div className="cmt_rht">
                      <div className="d-flex flex-wrap justify-content-between">
                        <span className="comment-username">{item.name}</span>
                        {/* commented text */}
                        <span className="post_time">
                          <i className="mr-1">
                            <img
                              src={require("../../../assets/images/icons/clock_icon.svg")}
                              alt=""
                            />
                          </i>{" "}
                          {moment(item.created_at).format(
                            "DD MMM YYYY, hh:mm A"
                          )}
                        </span>
                      </div>

                      {item.deleted || USER_ROLES.ADMIN == userRole ? (
                        <div className="dropdown_dots">
                          <i>
                            <img
                              src={require("../../../assets/images/icons/dots_icons.svg")}
                              onClick={() => {
                                set_showDeleteBtn(!showDeleteBtn);
                                set_deleteId(item.id);
                              }}
                              alt=""
                            />
                          </i>
                          <ul
                            className={
                              showDeleteBtn
                                ? "show dropdown_menu"
                                : "dropdown_menu"
                            }
                          >
                            {deleteId === item.id ? (
                              <>
                                <li ref={node}>
                                  <DeleteModal
                                    btnLabel="Delete"
                                    type={3}
                                    onDelete={() => onDeleteComment(item.id)}
                                    loading={loading}
                                  />
                                </li>
                                <li ref={node1}>
                                  <button
                                    type="button"
                                    onClick={() => onEditComment(item.id)}
                                  >
                                    {BUTTON_LABELS.EDIT}
                                  </button>
                                </li>
                              </>
                            ) : null}
                          </ul>
                        </div>
                      ) : null}
                      <div style={{ display: "flex", flexDirection: "row" }}>
                        {item.type === "video" ? (
                          <figure className="mt-2 mb-0">
                            <video
                              width={200}
                              controls
                              controlsList="nodownload"
                            >
                              <source src={item.text}></source>
                            </video>
                          </figure>
                        ) : item.type === "image" ? (
                          <ImageOverlay>
                            <figure className="mt-2 mb-0">
                              <img
                                src={item.text}
                                alt=""
                                width={200}
                                className="img-fluid"
                              />
                            </figure>
                          </ImageOverlay>
                        ) : editable == item.id ? (
                          <input
                            style={{
                              width: comment
                                ? comment.length + "ch"
                                : item.text.length + "ch",
                            }}
                            type="text"
                            name="comment"
                            value={comment ? comment : item.text}
                            onChange={(e) => handleCommentChange(e, item.id)}
                            onKeyPress={(e) =>
                              e.key == "Enter" && onEditComment()
                            }
                          ></input>
                        ) : (
                          <p className="user-comment">{item.text}</p>
                        )}
                      </div>
                      <div>
                        <span
                          style={{
                            fontSize: "13px",
                            fontWeight: likeStatus[item.id] ? "600" : null,
                            cursor: "pointer",
                            color: likeStatus[item.id] ? "#0D532D" : null,
                          }}
                          onClick={() =>
                            likeOnComments(item.id, likeStatus[item.id])
                          }
                        >
                          {"Like"}
                        </span>
                        <span>{" . "}</span>
                        <span
                          style={{ fontSize: "13px", cursor: "pointer" }}
                          onClick={() => {
                            setReply({ ...reply, [item.id]: item.id });
                              setCurrentCommentId(item.id)
                          }}
                        >
                          {"Reply"}
                        </span>
                        {likeCount[item.id] ? (
                          <>
                            <span style={{ paddingLeft: 50 }}>
                              {" "}
                              {likeCount[item.id]}
                            </span>
                            <span
                              style={{
                                paddingLeft: 5,
                              }}
                            >
                              <img
                                src={LIKE_GREEN_ICON}
                                style={{ width: 12, height: 12 }}
                                alt="like"
                              ></img>
                            </span>
                          </>
                        ) : null}
                      </div>
                      {replies && replies[item.id] ? replies[item.id].map(items => {
                      return (
                        <div style={{ display: 'flex', flexDirection: 'row', padding:'10px'}}>
                          <div className="cmt_icon">
                            <ProfileModal
                              user_image={items.user_image}
                              user_id={items.user_id}
                            />
                          </div>
                          <div style={{display:'flex',flexDirection:'column'}}>
                          <div className="d-flex flex-wrap justify-content-between" style={{marginLeft:'20px'}}>
                            <span className="comment-username">
                              {items.name}
                            </span>
                            {/* commented text */}
                            <span className="post_time" style={{marginLeft:'50px'}}>
                              <i className="mr-1">
                                <img
                                  src={require("../../../assets/images/icons/clock_icon.svg")}
                                  alt=""
                                />
                              </i>{" "}
                              {moment(items.created_at).format(
                                "DD MMM YYYY, hh:mm A"
                              )}
                            </span>
                          </div>
                          <div
                            style={{ display: "flex", flexDirection: "row",marginLeft:'20px' }}
                          >
                            {items.type === "video" ? (
                              <figure className="mt-2 mb-0">
                                <video
                                  width={200}
                                  controls
                                  controlsList="nodownload"
                                >
                                  <source src={items.text}></source>
                                </video>
                              </figure>
                            ) : items.type === "image" ? (
                              <ImageOverlay>
                                <figure className="mt-2 mb-0">
                                  <img
                                    src={items.text}
                                    alt=""
                                    width={200}
                                    className="img-fluid"
                                  />
                                </figure>
                              </ImageOverlay>
                            ) : (
                              // editable == item.id ? (
                              //   <input
                              //     style={{
                              //       width: comment
                              //         ? comment.length + "ch"
                              //         : item.text.length + "ch",
                              //     }}
                              //     type="text"
                              //     name="comment"
                              //     value={comment ? comment : item.text}
                              //     onChange={(e) =>
                              //       handleCommentChange(e, item.id)
                              //     }
                              //     onKeyPress={(e) =>
                              //       e.key == "Enter" && onEditComment()
                              //     }
                              //   ></input>
                              // ) : (
                              <p className="user-comment">{items.text}</p>
                            )}
                            </div>
                            </div>
                        </div>
                      );
                      }):null}
                      {Object.values(reply).includes(item.id) &&
                        renderCommentBox(
                          "white",
                          "Write a reply...",
                          45,
                          45,
                          25,
                          25,
                          item.id
                        )}
                    </div>
                  </div>
                </div>
              );
            })}
          {imagePreview && (
            <>
              <PreviewModal
                isOpen={true}
                source={imagePreview.url}
                width={200}
                onCancelClick={() => set_imagePreview("")}
                onDoneClick={() => onSubmitt(true)}
                sourceType={imagePreview.type}
              />
            </>
          )}
        </div>
        {renderCommentBox()}
      </div>
    </>
  );
};

export default withSnackbar(CommentBox);
