import React from "react";
import { requestFirebaseNotificationPermission } from "./config/init-fcm";
import "./App.css";
import RootRouter from "./routers";
import { connect } from "react-redux";
import { SnackbarWrapper } from "./components/molecules/snackbar-wrapper";
import { SnackbarProvider } from "notistack";
import { updateInternetStatus, getUnreadNotificationCount } from "./redux/actions";
import firebase from "firebase/app";

class App extends React.Component {
  state = {
    openSnackBar: false,
    snackbarData: {
      variant: "",
      message: "",
    },
  };
  componentDidMount() {
    console.log("heuyyyyyy");
    if (firebase.messaging.isSupported()) {
      requestFirebaseNotificationPermission()
        .then((firebaseToken) => {
          // eslint-disable-next-line no-console
          console.log(firebaseToken);
        })
        .catch((err) => {
          return err;
        });

      if (window.navigator.onLine === false) {
        this.setState({
          openSnackBar: true,
          snackbarData: {
            variant: "error",
            message: "Please connect to internet.",
          },
        });
        this.props.updateInternetStatus(false);
      } else {
        this.props.updateInternetStatus(true);
      }
    }
    getUnreadNotificationCount((message, type) => {
      console.log("measasase in app.js", message);
    });
  }
  render() {
    const {
      openSnackBar,
      snackbarData: { variant, message },
    } = this.state;
    return (
      <SnackbarProvider maxSnack={3}>
        <SnackbarWrapper
          visible={openSnackBar}
          onClose={() => this.setState({ openSnackBar: false })}
          variant={variant}
          message={message}
        />

        <RootRouter />
      </SnackbarProvider>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    state,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateInternetStatus: (status) => dispatch(updateInternetStatus(status)),
    getUnreadNotificationCount: (callback) => dispatch(getUnreadNotificationCount(callback)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
