import React, { Component } from "react";
import OtpInput from "react-otp-input";
import CustomButton from "../CustomButton/index";

class CustomOTP extends Component {
  constructor(props) {
    super(props);
    const num = this.props.numberOfOtpDigits;
    const otpboxIdArr = [];
    const valArr = [];

    for (let index = 0; index < num; index++) {
      otpboxIdArr[index] = index;
      valArr[index] = "";
    }

    let elRefArr = [];
    elRefArr = Array(num)
      .fill()
      .map((_, i) => elRefArr[i] || React.createRef());

    this.state = {
      otp: "",
      otpboxIdArr,
      valArr,
      onFocusOtp: 0,
      length: num,
      elRefArr,
      err: "",
      cleanOtp: false,
    };
  }

  validate = (value, id) => {
    const regex = /[0-9]/;
    let { onFocusOtp, valArr, elRefArr, length } = this.state;
    if (regex.test(value)) {
      valArr[id] = value;
      onFocusOtp = id;
      if (onFocusOtp !== length - 1) {
        onFocusOtp++;
      }

      elRefArr[onFocusOtp].current.focus();
      this.setState({ onFocusOtp, valArr });
    } else if (value === "") {
      valArr[id] = value;
      this.setState({ valArr });
    }
  };

  checkOtp = () => {
    const { valArr } = this.state;
    const result = valArr.filter((el) => el === "");
    if (result.length > 0) {
      this.setState({ err: "Incorrect OTP" });
    }
  };
  checkBackSpace = (e, id) => {
    let { valArr, onFocusOtp, elRefArr } = this.state;
    if (e.keyCode === 8 && onFocusOtp !== 0 && valArr[id] === "") {
      onFocusOtp = id - 1;
      elRefArr[onFocusOtp].current.focus();
      this.setState({ onFocusOtp });
    }
  };

  handleChange = (otp) =>
    this.setState({ otp }, () => {
      this.props.sendOtp(otp);
    });

  sendMail = () => {
    this.props.clickMailOtp();
  };

  render() {
    const { err } = this.state;
    return (
      <>
        <div className="verification_otp" style={styles.otpScreen}>
          <div style={{}}>
            {/* {otpboxIdArr.map((el, idx) => (
              <input
                ref={elRefArr[el]}
                type="text"
                key={el}
                style={styles.input}
                min={0}
                maxLength={4 - idx}
                onKeyDown={(e) => this.checkBackSpace(e, el)}
                autoFocus={el === onFocusOtp}
                onChange={(e) => {
                 

                    if (e.target.value.length > 1) {
                      for (let i = el; i < e.target.value.length; i++) {
                        this.validate(e.target.value[i], i);
                        let otp = valArr.join("");
                        this.props.getOTP(otp);
                      }
                    } else {
                      this.validate(e.target.value, el);
                      let otp = valArr.join("");
                      this.props.getOTP(otp);
                    }

                  
                }}
                value={valArr[el]}
              />
            ))} */}
            <OtpInput
              inputStyle={styles.input}
              value={this.state.otp}
              onChange={this.handleChange}
              numInputs={4}
              isInputNum={true}
              hasErrored={true}
              separator={<span> </span>}
            />
          </div>
          <h2>{err}</h2>
        </div>

        <CustomButton
          label={"Resend OTP"}
          className={
            this.props.showResendBtn
              ? "w-100 btn btn-lg text-capitalize btn-primary my-3"
              : "w-100 btn btn-lg text-capitalize btn-primary my-3 disabled"
          }
          onButtonClick={() => {
            this.setState({
              otp: "",
            });

            this.sendMail();
          }}
        />
      </>
    );
  }
}
const styles = {
  otpScreen: {
    height: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "flex-start",
    flexWrap: "wrap",
  },
  button: {
    padding: "1rem 2rem",
    marginLeft: "1rem",
    background: "red",
    color: "white",
    borderRadius: 15,
    fontSize: "1rem",
  },
  input: {
    height: 60,
    width: 40,
    margin: 10,
    fontSize: 20,
    textAlign: "center",
  },
};

export default CustomOTP;
