import { takeLatest, all, put } from "redux-saga/effects";
import {
  startLoading,
  stopLoading,
  GET_DROPDOWN_DATA,
  saveCountryData,
  saveSettingTypeData,
  saveCityData,
  SIGN_UP_FOR_NEW_USER,
  logout_user,
  GET_SCOTTISH_LOCAL_AUTHORITY_DROPDOWN,
  saveScottishLocalAuthorities,
} from "../actions";
import { checkOffline } from "../../shared/services/Utility";
const api = require(`../../shared/Apis/api`);
const { postRequest, getRequest } = require(`../../helpers/axios`);
const { STATUS_CODE, MESSAGES, STATUS } = require(`../../shared/constants`);

//request to get dropdown data for register screen(countries,settingType)
function* getDropDown({ callback }) {
  try {
    yield put(startLoading());
    const response = yield getRequest({ API: `${api.Get_Dropdown}` });
    if (response.status === STATUS_CODE.unAuthorized) {
      yield put(stopLoading());
      callback(response.data.message, STATUS.error);
      yield put(logout_user());
    } else if (response.status !== STATUS_CODE.successful) {
      yield put(stopLoading());
      callback(MESSAGES.ERROR_IN_CATCH, STATUS.error);
    } else {
      yield put(saveCountryData(response.data.success.country));
      yield put(saveCityData(response.data.success.cities));
      yield put(saveSettingTypeData(response.data.success.setting_type));
      yield put(stopLoading());
    }
  } catch (error) {
    yield put(stopLoading());
    if (checkOffline()) {
      callback(MESSAGES.OFFLINE, STATUS.error);
    } else {
      callback(MESSAGES.ERROR_IN_CATCH, STATUS.error);
    }
  }
}

//request to get dropdown data for scottish local authority
function* getDropdowndataforScottishLocalAuthority({ callback }) {
  try {
    yield put(startLoading());
    const response = yield getRequest({ API: `${api.GET_SCOTTISH_LOCAL_AUTHORITY}` });
    if (response.status === STATUS_CODE.unAuthorized) {
      yield put(stopLoading());
      callback(response.data.message, STATUS.error);
      yield put(logout_user());
    } else if (response.status !== STATUS_CODE.successful) {
      yield put(stopLoading());
      callback(MESSAGES.ERROR_IN_CATCH, STATUS.error);
    } else {
      yield put(saveScottishLocalAuthorities(response.data.data.locals));
      yield put(stopLoading());
    }
  } catch (error) {
    yield put(stopLoading());
    if (checkOffline()) {
      callback(MESSAGES.OFFLINE, STATUS.error);
    } else {
      callback(MESSAGES.ERROR_IN_CATCH, STATUS.error);
    }
  }
}
//request for signup
function* signUp({ data, callback }) {
  try {
    yield put(startLoading());
    const response = yield postRequest({ API: `${api.Register_URL}`, DATA: data });

    if (response.status === STATUS_CODE.unAuthorized) {
      yield put(stopLoading());
      callback(response.data.message, STATUS.error);
      yield put(logout_user());
    } else if (response.status !== STATUS_CODE.successful) {
      yield put(stopLoading());
      callback(response.data.error.message, STATUS.error);
    } else {
      callback(response.data.success.message, STATUS.success);
      yield put(stopLoading());
    }
  } catch (error) {
    yield put(stopLoading());

    if (checkOffline()) {
      callback(MESSAGES.OFFLINE, STATUS.error);
    } else {
      callback(MESSAGES.ERROR_IN_CATCH, STATUS.error);
    }
  }
}
function* SignUpSaga() {
  yield all([
    takeLatest(GET_DROPDOWN_DATA, getDropDown),
    takeLatest(SIGN_UP_FOR_NEW_USER, signUp),
    takeLatest(GET_SCOTTISH_LOCAL_AUTHORITY_DROPDOWN, getDropdowndataforScottishLocalAuthority),
  ]);
}

export default SignUpSaga;
