import * as actionTypes from "../actions/ActionTypes";

const initialCommonState = {
  goBackToSignUpFirst: false,
};
const LoginReducer = (state = { canShow: false, message: "" }, action) => {
  switch (action.type) {
    case actionTypes.GO_BACK:
      return {
        ...state,
        goBackToSignUpFirst: action.data,
      };

    default:
      return state;
  }
};

export default LoginReducer;
