import { takeLatest, all, put } from "redux-saga/effects";
import {
  setFloorbookLike,
  startLoading,
  stopLoading,
  GET_FLOORBOOK_PAGE_DETAIL,
  saveFloorbookPagesDatabyId,
  UPDATE_FLOORBOOK_PAGE,
  GET_COMMENTS,
  DELETE_FLOORBOOK_PAGE,
  GET_FLOORBOOK_PAGE_DATA_BY_ID,
  LIKE_PAGE,
  logout_user,
  DELETE_COMMENTS_FLOORBOOK_PAGE,
  EDIT_FLOORBOOK_PAGE_COMMENT,
  UPDATE_DELETE_LINE_OF_INQUIRY,
} from "../actions";
import { checkOffline } from "../../shared/services/Utility";
const api = require(`../../shared/Apis/api`);
const { postRequest, deleteRequest } = require(`../../helpers/axios`);
const { STATUS_CODE, MESSAGES, STATUS } = require(`../../shared/constants`);

// request for like-page
function* likeFloorbookPage({ data, status, created_at, pageType, callback }) {
  try {
    yield put(startLoading());

    const formdata = new FormData();
    formdata.append("page_id", data);
    formdata.append("status", status);
    formdata.append("created_at", created_at);

    const response = yield postRequest({
      API: `${api.LIKE_FLOORBOOK_PAGE}`,
      DATA: formdata,
    });
    if (response.status === STATUS_CODE.unAuthorized) {
      yield put(stopLoading());
      callback(response.data.message, STATUS.error);
      yield put(logout_user());
    } else if (response.status != STATUS_CODE.successful) {
      yield put(stopLoading());
      //callback(response.data.error.message, "error");
    } else {
      yield put(stopLoading());
      if (pageType === 0) {
        // Home tab
        callback(response.data.data);
      } else if (pageType === 1) {
        //Floorbok
        callback(response.data.data);
      } else if (pageType === 2) {
        // page Detail
        callback(response.data.data);
      }
    }
  } catch (error) {
    yield put(stopLoading());
    if (checkOffline()) {
      callback(MESSAGES.OFFLINE, STATUS.error);
    } else {
      callback(MESSAGES.ERROR_IN_CATCH, STATUS.error);
    }
  }
}
function* setEditFloorbookPageComment({ data, callback }) {
  try {
    console.log("daatatatatta", data);
    yield put(startLoading());

    const formdata = new FormData();
    formdata.append("comment_id", data.comment_id);
    formdata.append("page_id", data.id);
    formdata.append("comment", data.comment);
    formdata.append("created_at", data.created_at);

    const response = yield postRequest({
      API: `${api.UPDATE_FLOORBOOK_PAGE_COMMENT}`,
      DATA: formdata,
    });
    if (response.status === STATUS_CODE.unAuthorized) {
      yield put(stopLoading());
      callback(response.data.message, STATUS.error);
      yield put(logout_user());
    } else if (response.status != STATUS_CODE.successful) {
      yield put(stopLoading());
    } else {
      callback(
        response.data.data.message,
        STATUS.success,
        response.data.data.comments
      );
      yield put(stopLoading());
    }
  } catch (error) {
    yield put(stopLoading());
    if (checkOffline()) {
      callback(MESSAGES.OFFLINE, STATUS.error);
    } else {
      callback(MESSAGES.ERROR_IN_CATCH, STATUS.error);
    }
  }
}
// request to get page details of particular page
function* getFloorbookPageDetails({ data, callback }) {
  try {
    yield put(startLoading());
    const response = yield postRequest({
      API: `${api.GET_FLOORBOOK_PAGE_DETAIL}?page_id=${data}`,
    });
    if (response.status === STATUS_CODE.unAuthorized) {
      yield put(stopLoading());
      callback(
        response.data.message || response.data.error.message,
        STATUS.error
      );
      yield put(logout_user());
    } else if (response.status === 404) {
      yield put(stopLoading());
      callback(response.data.error.message, STATUS.error, response.status);
    } else if (response.status !== STATUS_CODE.successful) {
      yield put(stopLoading());
      callback(response.data.error.message, STATUS.error);
    } else {
      callback(
        response.data.data.message,
        STATUS.success,
        response.data.data.data
      );
      yield put(stopLoading());
    }
  } catch (error) {
    yield put(stopLoading());
    if (checkOffline()) {
      callback(MESSAGES.OFFLINE, STATUS.error);
    } else {
      callback(MESSAGES.ERROR_IN_CATCH, STATUS.error);
    }
  }
}
// request to get floobook
function* getFloorbookPageData({ data, callback }) {
  try {
    yield put(startLoading());
    const response = yield postRequest({
      API: `${api.GET_PAGE_DATA_BY_ID}?page_id=${data}`,
    });

    if (response.status === STATUS_CODE.unAuthorized) {
      yield put(stopLoading());
      callback(
        response.data.message || response.data.error.message,
        STATUS.error
      );
      yield put(logout_user());
    } else if (response.status === 404) {
      yield put(stopLoading());
      callback(response.data.error.message, STATUS.error);
    } else if (response.status !== STATUS_CODE.successful) {
      yield put(stopLoading());
      callback(response.data.error.message, STATUS.error);
    } else {
      callback(
        response.data.data.message,
        STATUS.success,
        response.data.data.data
      );
      yield put(stopLoading());
      yield put(saveFloorbookPagesDatabyId(response.data.data.data));
    }
  } catch (error) {
    yield put(stopLoading());
    if (checkOffline()) {
      callback(MESSAGES.OFFLINE, STATUS.error);
    } else {
      callback(MESSAGES.ERROR_IN_CATCH, STATUS.error);
    }
  }
}
//request to update page
function* updateFloorbookPageSaga({ id, data, callback }) {
  try {
    yield put(startLoading());
    const formdata = new FormData();
    formdata.append("title", data.title);
    formdata.append("activity_from", data.activity_from);
    formdata.append("activity_to", data.activity_to);
    formdata.append("line_inquiry", data.line_inquiry);
    formdata.append("is_learning_journal", data.is_learning_journal);
    if (data.line_inquiry === 11 && data.other_line_inquiry) {
      formdata.append("other_line_inquiry", data.other_line_inquiry);
    }
    formdata.append("location_activity", data.location);
    formdata.append("description", data.description);
    formdata.append("floorbook_id", data.floorbook_id);
    formdata.append("subject", data.subject);
    formdata.append("improvement", data.improvement);

    data.updatedImages.map((item, i) => {
       const myFile = new File([item.file], `filenames[${i}]`);
      formdata.append(`filename[${i}]`, item.file.type && item.file.type.includes("video") ? myFile : item.file);
    });

    const response = yield postRequest({
      API: `${api.UPDATE_FLOORBOOK_PAGE}/${id}`,
      DATA: formdata,
    });

    if (response.status === STATUS_CODE.unAuthorized) {
      yield put(stopLoading());
      callback(response.data.message, STATUS.error);
      yield put(logout_user());
    } else if (response.status === 404) {
      yield put(stopLoading());
      callback(response.data.error.message, STATUS.error);
    } else if (response.status !== STATUS_CODE.successful) {
      yield put(stopLoading());
      callback(response.data.error.message, STATUS.error);
    } else {
      callback(response.data.data.message, STATUS.success);
      yield put(stopLoading());
    }
  } catch (error) {
    yield put(stopLoading());

    if (checkOffline()) {
      callback(MESSAGES.OFFLINE, STATUS.error);
    } else {
      callback(MESSAGES.ERROR_IN_CATCH, STATUS.error);
    }
  }
}
//request to do comment on page
function* saveFloorbookPageComment({ data, callback }) {
  try {
    yield put(startLoading());
    const formdata = new FormData();
    formdata.append("page_id", data.id);
    formdata.append("comment", data.comment);
    formdata.append("created_at", data.created_at);

    const response = yield postRequest({
      API: `${api.SAVE_FLOORBOOK_PAGE_COMMENT}`,
      DATA: formdata,
    });

    if (response.status === STATUS_CODE.unAuthorized) {
      yield put(stopLoading());
      callback(response.data.message, STATUS.error);
      yield put(logout_user());
    } else if (response.status === 404) {
      yield put(stopLoading());
      callback(response.data.error.message, STATUS.error);
    } else if (response.status !== STATUS_CODE.successful) {
      yield put(stopLoading());
      callback(response.data.error.message, STATUS.error);
    } else {
      callback(
        response.data.data.message,
        STATUS.success,
        response.data.data.comments
      );
      yield put(stopLoading());
    }
  } catch (error) {
    yield put(stopLoading());

    if (checkOffline()) {
      callback(MESSAGES.OFFLINE, STATUS.error);
    } else {
      callback(MESSAGES.ERROR_IN_CATCH, STATUS.error);
    }
  }
}
function* deleteCommentFloorbookPage({ data, callback }) {
  try {
    yield put(startLoading());
    const response = yield deleteRequest({
      API: `${api.DELETE_COMMENT_FLOORBOOK_PAGE}/${data}`,
    });

    if (response.status === STATUS_CODE.unAuthorized) {
      yield put(stopLoading());
      callback(response.data.message, STATUS.error);
      yield put(logout_user());
    } else if (response.status === 404) {
      yield put(stopLoading());
      callback(response.data.error.message, STATUS.error);
    } else if (response.status !== STATUS_CODE.successful) {
      yield put(stopLoading());
      callback(response.data.error.message, STATUS.error);
    } else {
      callback(
        response.data.data.message,
        STATUS.success,
        response.data.data.id
      );
      yield put(stopLoading());
    }
  } catch (error) {
    yield put(stopLoading());

    if (checkOffline()) {
      callback(MESSAGES.OFFLINE, STATUS.error);
    } else {
      callback(MESSAGES.ERROR_IN_CATCH, STATUS.error);
    }
  }
}
function* deleteFloorbookPage({ data, callback }) {
  try {
    yield put(startLoading());
    const response = yield deleteRequest({
      API: `${api.DELETE_FLOORBOOK_PAGE}/${data}`,
    });

    if (response.status === STATUS_CODE.unAuthorized) {
      yield put(stopLoading());
      callback(response.data.message, STATUS.error);
      yield put(logout_user());
    } else if (response.status === 404) {
      yield put(stopLoading());
      callback(response.data.error.message, STATUS.error);
    } else if (response.status !== STATUS_CODE.successful) {
      yield put(stopLoading());
      callback(response.data.error.message, STATUS.error);
    } else {
      callback(response.data.data.message, STATUS.success);
      yield put(stopLoading());
    }
  } catch (error) {
    yield put(stopLoading());

    if (checkOffline()) {
      callback(MESSAGES.OFFLINE, STATUS.error);
    } else {
      callback(MESSAGES.ERROR_IN_CATCH, STATUS.error);
    }
  }
}

// api requests to delete/edit the line of inquiries

function* updateDeleteLineOfInquiry({ data, callback }) {
  try {
    yield put(startLoading());
    const response = yield postRequest({ API: `${api.UPDATE_LINE_OF_INQUIRY}`, DATA: data });

    if (response.status === STATUS_CODE.unAuthorized) {
      yield put(stopLoading());
      callback(response.data.message, STATUS.error);
      yield put(logout_user());
    } else if (response.status === 404) {
      yield put(stopLoading());
      callback(response.data.error.message, STATUS.error);
    } else if (response.status !== STATUS_CODE.successful) {
      yield put(stopLoading());
      callback(response.data.error.message, STATUS.error);
    } else {
      callback(response.data.data.message, STATUS.success);
      yield put(stopLoading());
    }
  } catch (error) {
    yield put(stopLoading());

    if (checkOffline()) {
      callback(MESSAGES.OFFLINE, STATUS.error);
    } else {
      callback(MESSAGES.ERROR_IN_CATCH, STATUS.error);
    }
  }
}

function* FloorbookPagesSaga() {
  yield all([
    takeLatest(LIKE_PAGE, likeFloorbookPage),
    takeLatest(GET_FLOORBOOK_PAGE_DETAIL, getFloorbookPageDetails),
    takeLatest(UPDATE_FLOORBOOK_PAGE, updateFloorbookPageSaga),
    takeLatest(GET_COMMENTS, saveFloorbookPageComment),
    takeLatest(GET_FLOORBOOK_PAGE_DATA_BY_ID, getFloorbookPageData),
    takeLatest(DELETE_COMMENTS_FLOORBOOK_PAGE, deleteCommentFloorbookPage),
    takeLatest(DELETE_FLOORBOOK_PAGE, deleteFloorbookPage),
    takeLatest(EDIT_FLOORBOOK_PAGE_COMMENT, setEditFloorbookPageComment),
    takeLatest(UPDATE_DELETE_LINE_OF_INQUIRY, updateDeleteLineOfInquiry),
  ]);
}

export default FloorbookPagesSaga;
