import * as actionTypes from "../actions/ActionTypes";

const initialCommonState = {
  myfloorbookData: "",
  inquiryLines: "",
  allFloorBooksPagesList: [],
  allFloorBooksPagesCount: "",
  floorbookAllPages: [],
  OthersFloorbookData: [],
  subjectAreaData: [],
  favouriteFloorbooksData: [],
  privacyStatusofFloorbook: "",
};

const FloorbookReducer = (state = { ...initialCommonState }, action) => {
  switch (action.type) {
    // case actionTypes.SET_NEW_LIKES:
    //   let selectedIndex = state.floorbookAllPages.pages.findIndex((item) => action.data.page_id === item.id);
    //   let floorbook = state.floorbookAllPages.pages;
    //   let selectedFloorbook = floorbook[selectedIndex];

    //   if (selectedFloorbook) {
    //     selectedFloorbook.like_status = action.data.status;
    //     selectedFloorbook.total_likes = action.data.total_likes;
    //     selectedFloorbook.user_likes = action.data.user_likes;
    //   }
    //   floorbook[selectedIndex] = selectedFloorbook;
    //   return {
    //     ...state,
    //     floorbookAllPages: { ...state.floorbookAllPages, pages: [...floorbook] },
    //   };

    // case actionTypes.SET_NEW_LIKES_HOME:
    //   let selectedListIndex = state.allFloorBooksPagesList.findIndex((item) => action.data.page_id === item.id);

    //   let floorbookList = state.allFloorBooksList;
    //   let selectedListItem = floorbookList[selectedListIndex];

    //   if (selectedListItem) {
    //     selectedListItem.like_status = action.data.status;
    //     selectedListItem.total_count = action.data.total_likes;
    //     selectedListItem.user_likes = action.data.user_likes;
    //   }
    //   floorbookList[selectedListIndex] = selectedListItem;

    //   return {
    //     ...state,
    //     allFloorBooksPagesList: [...floorbookList],
    //   };

    case actionTypes.MY_FLOORBOOK_DATA:
      return {
        ...state,
        myfloorbookData: action.data,
      };
    case actionTypes.SAVE_INQUIRY_LINES:
      return {
        ...state,
        inquiryLines: action.data,
      };
    case actionTypes.SAVE_FLOORBOOK_PAGES:
      return {
        ...state,
        floorbookAllPages: action.data,
      };
    case actionTypes.SAVE_OTHERS_FLOORBOOKS:
      return {
        ...state,
        OthersFloorbookData: action.data,
      };
    case actionTypes.SAVE_SUBJECT_AREA:
      return {
        ...state,
        subjectAreaData: action.data,
      };
    case actionTypes.UPDATE_FLOORBOOK:
      return {
        ...state,
        myfloorbookData: action.data,
      };
    case actionTypes.SET_PRIVACY_STATUS:
      return {
        ...state,
        privacyStatusofFloorbook: action.data,
      };
    /*********** favourite floorbooks state */
    case actionTypes.SET_FAVOURITE_FLOORBOOKS:
      return {
        ...state,
        favouriteFloorbooksData: action.data,
      };
    /********************************/
    case actionTypes.LOGOUT:
      return {
        ...state,
        myfloorbookData: "",
        inquiryLines: "",
        floorbookAllPages: [],
        OthersFloorbookData: [],
        subjectAreaData: [],
      };
    /********************************/

    default:
      return state;
  }
};

export default FloorbookReducer;
