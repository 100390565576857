import { connect } from "react-redux";
import Screen from "./screen";
import { getFlooorbook_PageDetails, likeFloorbookPage, deleteFloorbookPage } from "../../../../../redux/actions";

const mapStateToProps = (state) => {
  return {
    floorbookAllPages: state.FloorbookReducer.floorbookAllPages,
    loading: state.CommonReducer.isLoading,
    userRole: state.CommonReducer.userData.user_role,
    profileImage: state.CommonReducer.userData.profile_pic,
    FetchDetailPageBy: state.CommonReducer.FetchDetailPageBy,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getFlooorbook_PageDetails: (data, callback) => dispatch(getFlooorbook_PageDetails(data, callback)),
    likeFloorbookPage: (data, status, created_at, type, callback) =>
      dispatch(likeFloorbookPage(data, status, created_at, type, callback)),
    deleteFloorbookPage: (data, callback) => dispatch(deleteFloorbookPage(data, callback)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Screen);
