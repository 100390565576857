import {
    SEND_EMAIL_RESET_PASSWORD,
    SUBMIT_OTP,
    UPDATE_PASSWORD
} from './ActionTypes'

export const sendEmailForPasswordReset = (data, callback) => {
    return {
        type: SEND_EMAIL_RESET_PASSWORD,
        data,
        callback
    };
};
export const submitOtpOfUser = (data, callback) => {
    return {
        type: SUBMIT_OTP,
        data,
        callback
    }
}
export const updatePassword = (data, callback) => {
    return {
        type: UPDATE_PASSWORD,
        data,
        callback
    }
}