import { withSnackbar } from "notistack";
import React, { useEffect } from "react";
import { useState } from "react";
import { useHistory } from "react-router-dom";
import { reduxForm } from "redux-form";
import { HEAD_TITLES, ROUTE_CONSTANTS, STATUS } from "../../../../shared/constants";
import { HeadTitle, ImageOverlay, Loader, AddProfilePicModal } from "../../../../components";
import { RenderForm } from "./form";
import { connect } from "react-redux";
import "./style.scss";
import { validator } from "./validator";
import { getBase64Image } from "../../../../shared/services/Utility";

let DEFAULT_VALUES = {};

const EditProfileForm = ({
  loading,
  getProfileData,
  enqueueSnackbar,
  handleSubmit,
  getDropDownData,
  allCountries,
  allSettingTypes,
  updateProfile = () => {},
  setUserData = () => {},
  change = () => {},
  scottishLocalAuthority,
}) => {
  const history = useHistory();
  const [countries, set_countries] = useState([]);
  const [settings, set_settingsTypes] = useState([]);
  const [selectedSettingType, setSelectedSettingType] = useState([]);
  const [profileData, set_profileData] = useState({});
  const [profileImage, set_profileImage] = useState({});
  const [scotishlocalAuthorities, set_scotishlocalAuthorities] = useState([]);
  const [showScottishAuthorityField, set_showScottishAuthorityField] = useState(false);

  useEffect(() => {
    getDropDownData((message, type) => {
      enqueueSnackbar(message, {
        variant: type,
        autoHideDuration: 3000,
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "left",
        },
      });
    });
  }, []);

  useEffect(() => {
    allCountries &&
      allCountries.length &&
      set_countries(() => {
        return allCountries.map((country) => {
          return { value: country.id, label: country.name };
        });
      });
  }, [allCountries]);

  useEffect(() => {
    allSettingTypes &&
      allSettingTypes.length &&
      set_settingsTypes(() => {
        return allSettingTypes.map((settings) => {
          return { value: settings.id, label: settings.name };
        });
      });
  }, [allSettingTypes]);

  useEffect(() => {
    scottishLocalAuthority &&
      scottishLocalAuthority.length &&
      set_scotishlocalAuthorities(() => {
        return scottishLocalAuthority.map((item) => {
          return { value: item.id, label: item.local_authority };
        });
      });
  }, [scottishLocalAuthority]);

  useEffect(() => {
    getProfileData((payload, type) => {
      if (type === STATUS.success) {
        set_profileData(payload.data);
        DEFAULT_VALUES = payload.data;

        //converts the backend url to binary file for re-upload other-wise empty string is set
        getBase64Image(payload.data.profile_pic, "profilePic", (file) => {
          set_profileImage({
            file: file,
            url: payload.data.profile_pic,
          });
        });
      } else {
        enqueueSnackbar(payload, {
          variant: type,
          autoHideDuration: 3000,
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "left",
          },
        });
      }
    });
  }, []);

  // used to change the image in UI
  const handle_imageChange = (file) => {
    let imageUrl = URL.createObjectURL(file);
    if (file.size > 10000000) {
      enqueueSnackbar(`${file.name} file size too large. Max size allowed : 10MB`, {
        variant: "error",
        autoHideDuration: 6000,
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "left",
        },
      });
      return;
    }
    set_profileImage({ file, url: imageUrl });
  };

  const removeProfilePic = () => {
    set_profileImage("");
  };
  const submitForm = ({
    city,
    country,
    description,
    email,
    firstName,
    lastName,
    settingName,
    settingType,
    scottishLocal,
  }) => {
    let data = {
      profile_pic: profileImage.file,
      first_name: firstName,
      last_name: lastName,
      email: email,
      setting_type: settingType.value || settingType,
      setting_name: settingName,
      country_code: country.value || country,
      city: city,
      description: description,
    };
    if (!!scottishLocal) {
      data = { ...data, scottish_authority: scottishLocal.value || scottishLocal };
    }
    console.log("data", data);
    // action for updating profile saga
    updateProfile(data, (message, type, payload) => {
      enqueueSnackbar(message, {
        variant: type,
        autoHideDuration: 3000,
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "left",
        },
      });
      if (type === STATUS.success) {
        // updates the user data in common reducer

        setUserData({
          first_name: payload.first_name,
          last_name: payload.last_name,
          email: payload.email,
          profile_pic: payload.profile_pic,
        });
        history.push(`${ROUTE_CONSTANTS.PROFILE}`);
      }
    });
  };

  return (
    <>
      <div className="card-header d-flex flex-column-reverse flex-xl-row justify-content-center justify-content-xl-between align-items-center">
        {/* <HeadTitle
          title={HEAD_TITLES.PROFILE}
          titleCursor={true}
          titleOnClick={() => history.push(`${ROUTE_CONSTANTS.PROFILE}`)}
          subTitle={[{ label: "Edit Profile" }]}
        /> */}
      </div>
      <div className="card-body">
        <div className="row">
          <div className="col-12 d-flex justify-content-center">
            <div className="user-info profile_pic">
              <figure>
                <span>
                  <ImageOverlay>
                    <img
                      src={profileImage.url || require("../../../../assets/images/no-user.png")}
                      width={"100%"}
                      height={"100%"}
                      style={{ height: 140, objectFit: "cover" }}
                      alt=""
                    />
                  </ImageOverlay>
                </span>
              </figure>

              <AddProfilePicModal handle_imageChange={handle_imageChange} removeProfilePic={removeProfilePic} />
            </div>
          </div>
        </div>
        <RenderForm
          handleSubmit={handleSubmit}
          submitForm={submitForm}
          settings={settings}
          setSelectedSettingType={setSelectedSettingType}
          change={change}
          selectedSettingType={selectedSettingType}
          countries={countries}
          DEFAULT_VALUES={profileData}
          scotishlocalAuthorities={scotishlocalAuthorities}
          set_showScottishAuthorityField={set_showScottishAuthorityField}
          showScottishAuthorityField={showScottishAuthorityField}
        />
      </div>
      {loading && <Loader />}
    </>
  );
};

let Screen = reduxForm({
  form: "editProfileForm",
  validate: validator,
  enableReinitialize: true,
})(EditProfileForm);

Screen = connect((state) => {
  const {
    first_name,
    last_name,
    email,
    city,
    country_id,
    description,
    setting_id,
    setting_name,
    scottish_authority_id,
  } = DEFAULT_VALUES;
  let initialValues = {
    firstName: first_name,
    lastName: last_name,
    email,
    city,
    country: country_id,
    description,
    settingName: setting_name,
    settingType: setting_id,
    scottishLocal: scottish_authority_id,
  };
  return {
    initialValues: initialValues,
  };
})(Screen);

export default withSnackbar(Screen);
