import * as actionTypes from "../actions/ActionTypes";

const initialCommonState = {
  resources: [],
  isAdmin: false,
  resourceDetail: [],
};

const UsefulResourcesReducer = (state = { ...initialCommonState }, action) => {
  switch (action.type) {
    case actionTypes.SET_USEFUL_RESOURCES_DATA:
      return {
        ...state,
        resources: action.data,
      };
    case actionTypes.IS_ADMIN:
      return {
        ...state,
        isAdmin: action.data,
      };
    case actionTypes.SET_USEFUL_RESOURCE_DETAIL:
      return {
        ...state,
        resourceDetail: action.data,
      };
    /********************************/

    default:
      return state;
  }
};

export default UsefulResourcesReducer;
