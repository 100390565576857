import { takeLatest, all, put } from "redux-saga/effects";
import { startLoading, stopLoading, logout_user } from "../actions";
import { Get_HELP_DATA, SET_HELP_DATA } from "../actions/ActionTypes";
import { checkOffline } from "../../shared/services/Utility";
const api = require(`../../shared/Apis/api`);
const { getRequest, postRequest } = require(`../../helpers/axios`);
const { STATUS_CODE, MESSAGES, STATUS } = require(`../../shared/constants`);

function* getHelpData({ callback }) {
  try {
    yield put(startLoading());
    const response = yield getRequest({ API: `${api.GET_HELP_INFO}` });
    if (response.status === STATUS_CODE.unAuthorized) {
      yield put(stopLoading());
      callback(response.data.message, STATUS.error);
      yield put(logout_user());
    } else if (response.status === 404) {
      yield put(stopLoading());
      callback(response.data.error.message, STATUS.error);
    } else if (response.status !== STATUS_CODE.successful) {
      yield put(stopLoading());
      callback(response.data.error.message, STATUS.error);
    } else {
      callback(response.data.data, STATUS.success);
      yield put(stopLoading());
    }
  } catch (error) {
    yield put(stopLoading());
    if (checkOffline()) {
      callback(MESSAGES.OFFLINE, STATUS.error);
    } else {
      callback(MESSAGES.ERROR_IN_CATCH, STATUS.error);
    }
  }
}

function* setHelpData({ data, callback }) {
  try {
    yield put(startLoading());
    const response = yield postRequest({ API: `${api.SET_HELP_INFO}`, DATA: data });
    if (response.status === STATUS_CODE.unAuthorized) {
      yield put(stopLoading());
      callback(response.data.message, STATUS.error);
      yield put(logout_user());
    } else if (response.status === 404) {
      yield put(stopLoading());
      callback(response.data.error.message, STATUS.error);
    } else if (response.status !== STATUS_CODE.successful) {
      yield put(stopLoading());
      callback(response.data.error.message, STATUS.error);
    } else {
      callback(response.data.data.data, STATUS.success);
      yield put(stopLoading());
    }
  } catch (error) {
    yield put(stopLoading());
    if (checkOffline()) {
      callback(MESSAGES.OFFLINE, STATUS.error);
    } else {
      callback(MESSAGES.ERROR_IN_CATCH, STATUS.error);
    }
  }
}

function* HelpPageSaga() {
  yield all([takeLatest(Get_HELP_DATA, getHelpData), takeLatest(SET_HELP_DATA, setHelpData)]);
}

export default HelpPageSaga;
