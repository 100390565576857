import { CITY_REGEX, STRINGS } from "../../../../shared/constants";
import { VALIDATION_MESSAGES } from "../../../../shared/constants";

const validator = (values) => {
  const errors = {};
  if (!values[STRINGS.SETTING_TYPE_INPUT]) {
    errors[STRINGS.SETTING_TYPE_INPUT] = VALIDATION_MESSAGES.SETTING_TYPE_REQUIRED;
  }

  if (!values[STRINGS.SETTING_OTHER_TYPE_INPUT]) {
    errors[STRINGS.SETTING_OTHER_TYPE_INPUT] = VALIDATION_MESSAGES.SETTING_OTHER_TYPE_REQUIRED;
  }

  if (!values[STRINGS.SETTING_NAME_INPUT]) {
    errors[STRINGS.SETTING_NAME_INPUT] = VALIDATION_MESSAGES.SETTING_NAME_REQUIRED;
  } else if (values[STRINGS.SETTING_NAME_INPUT]) {
    if (!values[STRINGS.SETTING_NAME_INPUT].trim())
      errors[STRINGS.SETTING_NAME_INPUT] = VALIDATION_MESSAGES.SETTING_TYPE_REQUIRED;

    // if (values[STRINGS.SETTING_NAME_INPUT].trim() && !NAME_REGX.test(values[STRINGS.SETTING_NAME_INPUT])) {
    //   errors[STRINGS.SETTING_NAME_INPUT] = VALIDATION_MESSAGES.SETTING_TYPE_VALIDATE;
    // }
  }

  if (!values[STRINGS.COUNTRY_INPUT]) {
    errors[STRINGS.COUNTRY_INPUT] = VALIDATION_MESSAGES.COUNTRY_REQUIRED;
  }

  if (!values[STRINGS.CITY_INPUT]) {
    errors[STRINGS.CITY_INPUT] = VALIDATION_MESSAGES.CITY_REQUIRED;
  } else if (values[STRINGS.CITY_INPUT]) {
    if (!values[STRINGS.CITY_INPUT].trim()) errors[STRINGS.CITY_INPUT] = VALIDATION_MESSAGES.CITY_REQUIRED;

    if (values[STRINGS.CITY_INPUT].trim() && !CITY_REGEX.test(values[STRINGS.CITY_INPUT])) {
      errors[STRINGS.CITY_INPUT] = VALIDATION_MESSAGES.CITY_VALIDATE;
    }
  }

  if (!values[STRINGS.DESCRIPTION_OF_CLASS_AND_AGE_INPUT]) {
    errors[STRINGS.DESCRIPTION_OF_CLASS_AND_AGE_INPUT] = VALIDATION_MESSAGES.DESCRIPTION_OF_CLASS_AND_AGE_REQUIRED;
  } else if (values[STRINGS.DESCRIPTION_OF_CLASS_AND_AGE_INPUT]) {
    if (!values[STRINGS.DESCRIPTION_OF_CLASS_AND_AGE_INPUT].trim())
      errors[STRINGS.DESCRIPTION_OF_CLASS_AND_AGE_INPUT] = VALIDATION_MESSAGES.DESCRIPTION_OF_CLASS_AND_AGE_REQUIRED;
  }

  // if (values[STRINGS.SCOTTISH_LOCAL_AUTHORITY_INPUT]) {
  //   if (!values[STRINGS.SCOTTISH_LOCAL_AUTHORITY_INPUT].trim()) {
  //     errors[STRINGS.SCOTTISH_LOCAL_AUTHORITY_INPUT] = "Spaces are not allowed";
  //   }
  // }
  return errors;
};

export default validator;
