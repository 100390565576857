import React from "react";

import { ScreenHOC } from "../components";
import { Route, Switch, Redirect } from "react-router-dom";
import { ROUTE_CONSTANTS } from "../shared/constants";
import NotificationScreen from "../components/atoms/push-notification";
import {
  AddNewFloorbookPage,
  Floorbooks,
  Home,
  FloorbookDetails,
  PageDetails,
  UsefulResources,
  Add_UsefulResource,
  Report,
  CoverDetails,
  UseFulResourceDetail,
  Help,
  UserProfile,
  ChangePassword,
  History,
  Setting,
  JournalEntryDetails,
  EditProfile,
  LearningJournals,
  JournalDetails,
  JournalCoverDetails,
  AddNewJournalEntry,
} from "../Screens";

const MainRouter = () => {
  return (
    <>
      <NotificationScreen />
      {/* <CheckNotification /> */}
      <ScreenHOC>
        <div>
          <Switch>
            <Route exact path={`${ROUTE_CONSTANTS.HOME}`} component={Home} />
            <Route
              exact
              path={`${ROUTE_CONSTANTS.FLOORBOOKS}`}
              component={Floorbooks}
            />
            <Route
              exact
              path={`${ROUTE_CONSTANTS.LEARNING_JOURNAL}`}
              component={LearningJournals}
            />
            <Route
              exact
              path={`${ROUTE_CONSTANTS.ADD_NEW_FLOORBOOK_PAGE}`}
              component={AddNewFloorbookPage}
            />
            <Route
              exact
              path={`${ROUTE_CONSTANTS.FLOORBOOK_DETAILS}`}
              component={FloorbookDetails}
            />
            <Route
              exact
              path={`${ROUTE_CONSTANTS.FLOORBOOK_PAGE_DETAILS}`}
              component={PageDetails}
            />
            <Route
              exact
              path={`${ROUTE_CONSTANTS.JOURNAL_PAGE_DETAILS}`}
              component={JournalEntryDetails}
            />
            <Route
              exact
              path={`${ROUTE_CONSTANTS.EDIT_FLOORBOOK_PAGE}`}
              component={AddNewFloorbookPage}
            />
            <Route
              exact
              path={`${ROUTE_CONSTANTS.JOURNAL_DETAILS}`}
              component={JournalDetails}
            />
            <Route
              exact
              path={`${ROUTE_CONSTANTS.EDIT_JOURNAL_ENTRY}`}
              component={AddNewJournalEntry}
            />
            <Route
              exact
              path={`${ROUTE_CONSTANTS.USEFUL_RESOURCES}`}
              component={UsefulResources}
            />
            <Route
              exact
              path={`${ROUTE_CONSTANTS.ADD_USEFUL_RESOURCE}`}
              component={Add_UsefulResource}
            />
            <Route
              exact
              path={`${ROUTE_CONSTANTS.REPORT}`}
              component={Report}
            />
            <Route
              exact
              path={`${ROUTE_CONSTANTS.FLOORBOOK_COVER_DETAILS}`}
              component={CoverDetails}
            />
            <Route
              exact
              path={`${ROUTE_CONSTANTS.JOURNAL_COVER_DETAILS}`}
              component={JournalCoverDetails}
            />
            <Route
              exact
              path={`${ROUTE_CONSTANTS.USEFUL_RESOURCE_DETAIL}`}
              component={UseFulResourceDetail}
            />
            <Route exact path={`${ROUTE_CONSTANTS.HELP}`} component={Help} />
            <Route
              exact
              path={`${ROUTE_CONSTANTS.PROFILE}`}
              component={UserProfile}
            />
            <Route
              exact
              path={`${ROUTE_CONSTANTS.CHNAGE_PASSOWRD}`}
              component={ChangePassword}
            />
            <Route
              exact
              path={`${ROUTE_CONSTANTS.HISTORY}`}
              component={History}
            />
            <Route
              exact
              path={`${ROUTE_CONSTANTS.SETTINGS}`}
              component={Setting}
            />
            <Route
              exact
              path={`${ROUTE_CONSTANTS.EDIT_PROFILE}`}
              component={EditProfile}
            />
            <Route
              exact
              path={`${ROUTE_CONSTANTS.EDIT_RESOURCE}`}
              component={Add_UsefulResource}
            />
            <Route
              exact
              path={`${ROUTE_CONSTANTS.ADD_NEW_JOURNAL}`}
              component={AddNewJournalEntry}
            />
            <Redirect to={ROUTE_CONSTANTS.HOME} />
          </Switch>
        </div>
      </ScreenHOC>
    </>
  );
};

export default MainRouter;
