import React from "react";
import { Field } from "redux-form";
import { CustomButton, Form, Input, SearchSelect, Select, TextArea } from "../../../../components";
import { BUTTON_LABELS, ROUTE_CONSTANTS, STRINGS } from "../../../../shared/constants";

export const RenderForm = ({
  history,
  handleSubmit,
  submit,
  goBackToSignUpFirst,
  goBackToSignUPFirst,
  settings,
  formData,
  setSelectedSettingType,
  change,
  selectedSettingType,
  showGoBackBtn,
  countries,
  scottishLocalAuthorities,
  showScottishAuthorityField = false,
  set_showScottishAuthorityField = () => {},
}) => {
  return (
    <Form onSubmit={handleSubmit(submit)}>
      <h2 className="text-center  text-capitalize">{STRINGS.SIGN_UP_LABEL}</h2>
      <div className="row">
        <div className="col-12">
          <div className="form-group">
            <label>{STRINGS.SETTING_TYPE_LABEL}</label>
            <Field
              name={STRINGS.SETTING_TYPE_INPUT}
              component={SearchSelect}
              placeholder={STRINGS.SELECT_PLACEHOLDER}
              options={settings}
              defaultValue={goBackToSignUpFirst && formData ? formData.settingType.value : ""}
              onChange={(value) => {
                if (value.label === "Other") {
                  setSelectedSettingType(value);
                } else {
                  setSelectedSettingType([]);
                  change(STRINGS.SETTING_OTHER_TYPE_INPUT, "");
                }
              }}
            />
          </div>
        </div>
      </div>
      {selectedSettingType.label === "Other" ? (
        <div className="row">
          <div className="col-12">
            <div className="form-group">
              <label>{STRINGS.SETTING_OTHER_TYPE_LABEL}</label>
              <Field
                name={STRINGS.SETTING_OTHER_TYPE_INPUT}
                component={Input}
                placeholder={STRINGS.OTHER_TYPE_PLACEHOLDER}
              />
            </div>
          </div>
        </div>
      ) : null}
      <div className="row">
        <div className="col-12">
          <div className="form-group">
            <label>{STRINGS.SETTING_NAME_LABEL}</label>
            <Field name={STRINGS.SETTING_NAME_INPUT} component={Input} placeholder={STRINGS.SETTING_NAME_PLACEHOLDER} />
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-12">
          <div className="form-group">
            <label>{STRINGS.COUNTRY_LABEL}</label>
            <Field
              name={STRINGS.COUNTRY_INPUT}
              component={Select}
              isSearchable={true}
              placeholder={STRINGS.COUNTRY_PLACEHOLDER}
              options={countries}
              defaultValue={goBackToSignUpFirst && formData ? formData.country.value : ""}
              onChange={(value) => {
                if (value && value.value === 253) {
                  //show dropdown for country UK
                  set_showScottishAuthorityField(true);
                }
              }}
            />
          </div>
        </div>
      </div>
      {showScottishAuthorityField || (formData && formData.scottishLocal && formData.scottishLocal.value) ? (
        <div className="row">
          <div className="col-12">
            <div className="form-group">
              <label>{STRINGS.SCOTTISH_LOCAL_AUTHORITY_LABEL}</label>
              <Field
                name={STRINGS.SCOTTISH_LOCAL_AUTHORITY_INPUT}
                component={Select}
                isSearchable={true}
                placeholder={STRINGS.SCOTTISH_LOCAL_AUTHORITY_PLACEHOLDER}
                options={scottishLocalAuthorities}
                defaultValue={goBackToSignUpFirst && formData ? formData.scottishLocal.value : ""}
              />
            </div>
          </div>
        </div>
      ) : null}
      <div className="row">
        <div className="col-12">
          <div className="form-group">
            <label>{STRINGS.CITY_LABEL}</label>
            <Field name={STRINGS.CITY_INPUT} component={Input} placeholder={STRINGS.CITY_PLACEHOLDER} />
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-12">
          <div className="form-group">
            <label>{STRINGS.DESCRIPTION_OF_CLASS_AND_AGE_LABEL}</label>
            <Field
              name={STRINGS.DESCRIPTION_OF_CLASS_AND_AGE_INPUT}
              component={TextArea}
              id={"descriptionSignup"}
              placeholder={STRINGS.DESCRIPTION_OF_CLASS_AND_AGE_LABEL_PLACEHOLDER}
            />
          </div>
        </div>
      </div>
      <div class="mt-3">
        <CustomButton label="Sign Up" className="w-100 btn btn-lg text-capitalize btn-primary" type="submit" />
        <div className="text-center mt-3 gotos">
          <span onClick={() => history.push(ROUTE_CONSTANTS.LOGIN)}>{BUTTON_LABELS.LOGIN_IF_YOU_ALREADY_SIGNED}</span>
        </div>
        {showGoBackBtn ? (
          <div className="text-center mt-3 gotos">
            <span
              onClick={() => {
                history.push(ROUTE_CONSTANTS.SIGNUP_FIRST);
                goBackToSignUPFirst(true);
              }}
            >
              {BUTTON_LABELS.GO_BACK}
            </span>
          </div>
        ) : null}
      </div>
    </Form>
  );
};
