import React, { useEffect, useState } from "react";
import Carousel from "react-material-ui-carousel";
import { useLocation } from "react-router-dom";
import {
  CommentBox,
  CustomButton,
  ImageOverlay,
  LikesListingModal,
  Loader,
} from "../../../../../../components";
import { withSnackbar } from "notistack";
import {
  BUTTON_LABELS,
  ROUTE_CONSTANTS,
  STATUS,
  COMMENTS_GREEN_ICON,
  LIKE_OUTLINE,
  LIKE_GREEN_ICON,
  LIKE_GRAY_ICON,
  COMMENTS_GRAY_ICON,
} from "../../../../../../shared/constants";
import "./style.scss";

const Screen = ({
  history,
  likeFloorbook,
  loading,
  profileImage,
  getFloorbookCoverDetails,
  enqueueSnackbar,
}) => {
  const useQuery = () => {
    return new URLSearchParams(useLocation().search);
  };
  let query = useQuery();
  let id_floorbook = query.get("journal_page");

  const [floorbookPageData, set_floorbookPageData] = useState({});
  const [data, updateData] = useState([]);
  const [likeStatus, set_likeStatus] = useState("");
  const [totalCount, set_totalCount] = useState(0);
  const [userLikes, set_userLikes] = useState([]);
  const [userTotalComments, set_userTotalComments] = useState("");
  const [userComments, set_userComments] = useState("");
  const [floorbookName, setFloorbookName] = useState("");

  useEffect(() => {
    getFloorbookCoverDetails(id_floorbook, (data, type) => {
      if (type === STATUS.error) {
        enqueueSnackbar(data, {
          variant: type,
          autoHideDuration: 3000,
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "left",
          },
        });
      }
      if (type === STATUS.success) {
        set_floorbookPageData(data);
      }
    });
  }, []);

  useEffect(() => {
    updateData([
      floorbookPageData && floorbookPageData.cover_image,
      floorbookPageData && floorbookPageData.image,
    ]);
    set_likeStatus(floorbookPageData && floorbookPageData.like_status);
    set_totalCount(floorbookPageData && floorbookPageData.total_likes);
    set_userLikes(floorbookPageData && floorbookPageData.user_likes);
    set_userTotalComments(
      floorbookPageData && floorbookPageData.total_comments
    );
    set_userComments(floorbookPageData && floorbookPageData.comment);
    setFloorbookName(floorbookPageData && floorbookPageData.name);
  }, [floorbookPageData]);

  const updateLike = (id) => {
    likeFloorbook(id, likeStatus, new Date().getTime(), (data) => {
      set_likeStatus(data.status);
      set_totalCount(data.total_likes);
      set_userLikes([...data.user_likes]);
    });
  };

  const updateComments = (count, comments) => {
    set_userComments(comments);
    set_userTotalComments(count);
  };

  return (
    <div className="post_detail_com">
      <div className="card-header d-flex justify-content-end">
        {/* <HeadTitle
          title={HEAD_TITLES.FLOORBOOK}
          titleCursor={true}
          titleOnClick={() => history.push(ROUTE_CONSTANTS.FLOORBOOKS)}
          subTitle={[
            {
              label: "Detail",
              onClick: () => {
                history.push({
                  pathname: ROUTE_CONSTANTS.FLOORBOOK_DETAILS,
                  search: `floorbook_id=${floorbookPageData.id}`,
                });
              },
              cursor: "pointer",
            },
            { label: "Cover Image" },
          ]}
        /> */}
        <div className="hdr_btns ml-xl-auto">
          <CustomButton
            label={BUTTON_LABELS.REPORT}
            className="btn btn-sm btn-primary text-capitalize"
            onButtonClick={() => {
              history.push({
                pathname: `${ROUTE_CONSTANTS.REPORT}`,
                state: {
                  id: id_floorbook,
                  name: floorbookName,
                },
              });
            }}
          />
        </div>
      </div>

      <div className="cover_sec">
        <Carousel>
          {data.map((item, index) => (
            <figure key={index}>
              <ImageOverlay>
                <img
                  src={item}
                  key={index}
                  height={200}
                  width={200}
                  className="img-fluid"
                  alt="cover"
                />
              </ImageOverlay>
            </figure>
          ))}
        </Carousel>
      </div>
      <div className="post_status">
        <span>
          <i className="mr-2">
            <img src={LIKE_GRAY_ICON} alt="" />
          </i>{" "}
          <span>
            <LikesListingModal
              likes={totalCount || 0}
              Likes_Data={userLikes || []}
            />
          </span>
        </span>
        <span>
          <i className="mr-2">
            <img src={COMMENTS_GRAY_ICON} alt="" />
          </i>{" "}
          {userTotalComments || 0} <span>Comments</span>
        </span>
      </div>
      <div className="d-flex justify-content-around flex-row detial_like_smt">
        <CustomButton
          onButtonClick={() => {
            updateLike(floorbookPageData.id);
          }}
          icon={likeStatus ? LIKE_GREEN_ICON : LIKE_OUTLINE}
          className="btn btn-lg btn-primary"
          label={likeStatus ? BUTTON_LABELS.LIKED : BUTTON_LABELS.LIKE}
        />
        <CustomButton
          icon={COMMENTS_GREEN_ICON}
          className="btn btn-lg btn-primary"
          label={BUTTON_LABELS.COMMENT}
        />
      </div>
      <CommentBox
        id={floorbookPageData.id}
        comments={userComments || []}
        updateComments={updateComments}
        commentType={1}
        profileImage={profileImage}
      />
      {loading && <Loader />}
    </div>
  );
};

export default withSnackbar(Screen);
