import {
  ADD_FLOORBOOK,
  GET_FLOORBOOKS,
  MY_FLOORBOOK_DATA,
  SAVE_FLOORBOOK_DATA,
  GET_INQUIRY_LINES,
  SAVE_INQUIRY_LINES,
  GET_FLOORBOOK_PAGES,
  SAVE_FLOORBOOK_PAGES,
  GET_OTHER_FLOORBOOKS,
  SAVE_OTHERS_FLOORBOOKS,
  GET_SUBJECT_AREA,
  SAVE_SUBJECT_AREA,
  UPDATE_FLOORBOOK,
  GET_ALL_FLOORBOOKS_LIST,
  SAVE_ALL_FLOORBOOKS_LIST,
  LIKE_FLOORBOOK,
  GET_FLOORBOOK_COMMENTS,
  SET_FAVOURITE,
  UPDATE_FLOORBOOK_JOURNAL,
  GET_FAVOURITE_FLOORBOOKS,
  SET_FAVOURITE_FLOORBOOKS,
  DELETE_FLOORBOOK,
  DELETE_COMMENTS_FLOORBOOK,
  GET_FLOORBOOK_COVER_DETAILS,
  HIDE_UNHIDE_FLOORBOOK,
  SET_PRIVACY_STATUS,
  EDIT_FLOORBOOK_COMMENT,
  ADD_COMMENT_REPLY,
  ADD_COMMENT_LIKE
} from "./ActionTypes";

export const deleteFloorbookComment = (data, callback) => {
  return {
    type: DELETE_COMMENTS_FLOORBOOK,
    data,
    callback,
  };
};
export const getAllFloorbooksList = (data, callback) => {
  return {
    type: GET_ALL_FLOORBOOKS_LIST,
    data,
    callback,
  };
};
export const saveAllFloorbooksList = (data) => {
  return {
    type: SAVE_ALL_FLOORBOOKS_LIST,
    data,
  };
};
export const saveFloorbookPages = (data) => {
  return {
    type: SAVE_FLOORBOOK_PAGES,
    data,
  };
};
export const updateFloorbookJournal = (data,callback) => {
  return {
    type: UPDATE_FLOORBOOK_JOURNAL,
    data,
    callback,
  };
};
export const addNewFloorbook = (data, callback) => {
  return {
    type: ADD_FLOORBOOK,
    data,
    callback,
  };
};
export const addCommentLike = (data, callback) => {
  return {
    type: ADD_COMMENT_LIKE,
    data,
    callback,
  };
};
export const addCommentReply = (data, callback) => {
  return {
    type: ADD_COMMENT_REPLY,
    data,
    callback,
  };
};
export const getAllFloorbooks = (callback) => {
  return {
    type: GET_FLOORBOOKS,
    callback,
  };
};
export const getFavouriteFloorbooks = (callback) => {
  return {
    type: GET_FAVOURITE_FLOORBOOKS,
    callback,
  };
};
export const setFavouriteFloorbooks = (data) => {
  return {
    type: SET_FAVOURITE_FLOORBOOKS,
    data,
  };
};
export const getMyFloorbooks = (data) => {
  return {
    type: MY_FLOORBOOK_DATA,
    data,
  };
};

export const getAllInquiryLines = (callback) => {
  return {
    type: GET_INQUIRY_LINES,
    callback,
  };
};
export const dispatchSaveFloorbook = (data, callback) => {
  return {
    type: SAVE_FLOORBOOK_DATA,
    data,
    callback,
  };
};
export const updateFloorbook = (id, data, callback) => {
  return {
    type: UPDATE_FLOORBOOK,
    id,
    data,
    callback,
  };
};

export const saveInquiryLines = (data) => {
  return {
    type: SAVE_INQUIRY_LINES,
    data,
  };
};
export const getFloorbookPages = (id, callback) => {
  return {
    type: GET_FLOORBOOK_PAGES,
    id,
    callback,
  };
};
export const getOtherFloorbooks = (callback) => {
  return {
    type: GET_OTHER_FLOORBOOKS,
    callback,
  };
};
export const saveOthersFloorbooks = (data) => {
  return {
    type: SAVE_OTHERS_FLOORBOOKS,
    data,
  };
};
export const saveSubjectArea = (data) => {
  return {
    type: SAVE_SUBJECT_AREA,
    data,
  };
};
export const getSubjectAreaDropDown = (callback) => {
  return {
    type: GET_SUBJECT_AREA,
    callback,
  };
};
export const likeFloorbook = (data, status, created_at, callback) => {
  return {
    type: LIKE_FLOORBOOK,
    data,
    status,
    created_at,
    callback,
  };
};

export const saveFloorbookComment = (data, callback) => {
  return {
    type: GET_FLOORBOOK_COMMENTS,
    data,
    callback,
  };
};
export const editFloorbookComment = (data, callback) => {
  return {
    type: EDIT_FLOORBOOK_COMMENT,
    data,
    callback,
  };
};

export const setFavourite = (data, callback) => {
  return {
    type: SET_FAVOURITE,
    data,
    callback,
  };
};

export const deleteFloorbook = (data, callback) => {
  return {
    type: DELETE_FLOORBOOK,
    data,
    callback,
  };
};

export const getFloorbookCoverDetails = (data, callback) => {
  return {
    type: GET_FLOORBOOK_COVER_DETAILS,
    data,
    callback,
  };
};
export const setHideorUnHideFloorbook = (data, callback) => {
  return {
    type: HIDE_UNHIDE_FLOORBOOK,
    data,
    callback,
  };
};
export const setPrivacyStatusofFloorbook = (data) => {
  return {
    type: SET_PRIVACY_STATUS,
    data,
  };
};
