import React from "react";

const Form = ({ children, onSubmit, justifyContent}) => {
  return (
    <form
      noValidate
      onSubmit={(value) => {
        onSubmit(value);
      }}
      style={{ justifyContent: justifyContent}}
    >
      {children}
    </form>
  );
};
export default Form;
