export const ROUTE_CONSTANTS = {
  DASHBOARD: "/dashboard",
  LOGIN: "/login",
  FORGOT_PASSWORD: "/forgot-password",
  // ROOT: "/",
  SIGNUP_FIRST:"/16cN-xujOBZPW8fC16cN-xujOBZPW8fCsgUydF7dB10-dQr4LKFTirpg7QXIsgUydF7dB10-dQr4LKFTirpg7QXI",
  SIGNUP_SECOND: "/signup_page2",
  HOME: "/",
  FLOORBOOKS: "/floorbooks",
  LEARNING_JOURNAL: "/learning_journal",
  USEFUL_RESOURCES: "/useful_resources",
  ADD_USEFUL_RESOURCE: "/useful_resources/add-resource",
  HISTORY: "/history",
  SETTINGS: "/settings",
  REPORT: "/report",
  LOGOUT: "/logout",
  ADD_NEW_FLOORBOOK_PAGE: "/add_new_floorbook_page",
  FLOORBOOK_DETAILS: "/floorbooks/floorbook_details",
  FLOORBOOK_PAGE_DETAILS: "/floorbooks/floorbook_details/page_details",
  JOURNAL_PAGE_DETAILS:"/learning_journal/journal-details/journal_page_details",
  EDIT_FLOORBOOK_PAGE: "/edit-floorbook-page",
  FLOORBOOK_COVER_DETAILS: "/floorbooks/floorbook-cover-details",
  JOURNAL_COVER_DETAILS: "/learning_journal/journal-cover-details",
  USEFUL_RESOURCE_DETAIL: "/useful_resources/detail",
  HELP: "/help",
  PROFILE: "/profile",
  CHNAGE_PASSOWRD: "/profile/change-Password",
  EDIT_PROFILE: "/profile/edit-profile",
  JOURNAL_DETAILS: "/learning_journal/journal-details",
  EDIT_JOURNAL_ENTRY: "/edit-journal-entry",
  ADD_NEW_JOURNAL: "/add_new_learning_journal",
  EDIT_RESOURCE: "/useful_resources/edit-resource",
};
