import { connect } from "react-redux";
import { getProfileData, setUserData } from "../../../../redux/actions";
import Screen from "./screen";

const mapStateToProps = (state) => {
  return {
    loading: state.CommonReducer.isLoading,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getProfileData: (callback) => dispatch(getProfileData(callback)),
    setUserData: (data) => dispatch(setUserData(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Screen);
