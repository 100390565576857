import React from "react";
import { Field } from "redux-form";
import { CustomButton, Form, Input, SearchSelect, TextArea } from "../../../../components";
import { BUTTON_LABELS, STRINGS } from "../../../../shared/constants";

export const RenderForm = ({
  handleSubmit,
  submitForm,
  settings,
  setSelectedSettingType,
  change,
  selectedSettingType,
  countries,
  DEFAULT_VALUES,
  scotishlocalAuthorities,
  set_showScottishAuthorityField = () => {},
  showScottishAuthorityField = false,
}) => {
  console.log("DEFAULT_VALUES.scottish_authority_id", DEFAULT_VALUES.country_id);
  return (
    <Form onSubmit={handleSubmit(submitForm)}>
      <div className="row">
        <div className="col-6">
          <div className="form-group">
            <label>{STRINGS.FIRST_NAME_LABEL}</label>
            <Field name={STRINGS.FIRST_NAME_INPUT} component={Input} placeholder={STRINGS.FIRST_NAME_PLACEHOLDER} />
          </div>
        </div>
        <div className="col-6">
          <div className="form-group">
            <label>{STRINGS.LAST_NAME_LABEL}</label>
            <Field name={STRINGS.LAST_NAME_INPUT} component={Input} placeholder={STRINGS.LAST_NAME_PLACEHOLDER} />
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-12">
          <div className="form-group">
            <label>{STRINGS.SETTING_TYPE_LABEL}</label>
            <Field
              name={STRINGS.SETTING_TYPE_INPUT}
              component={SearchSelect}
              placeholder={STRINGS.SETTING_TYPE_PLACEHOLDER}
              options={settings}
              onChange={(value) => {
                if (value.label == "Other") {
                  setSelectedSettingType(value);
                } else {
                  setSelectedSettingType([]);
                  change(STRINGS.SETTING_OTHER_TYPE_INPUT, "");
                }
              }}
              defaultValue={DEFAULT_VALUES.setting_id}
            />
          </div>
        </div>
      </div>
      {selectedSettingType.label == "Other" ? (
        <div className="row">
          <div className="col-12">
            <div className="form-group">
              <label>{STRINGS.SETTING_OTHER_TYPE_LABEL}</label>
              <Field name={STRINGS.SETTING_OTHER_TYPE_INPUT} component={Input} placeholder={"Enter your type."} />
            </div>
          </div>
        </div>
      ) : null}
      <div className="row">
        <div className="col-12">
          <div className="form-group">
            <label>{STRINGS.SETTING_NAME_LABEL}</label>
            <Field name={STRINGS.SETTING_NAME_INPUT} component={Input} placeholder={STRINGS.SETTING_NAME_PLACEHOLDER} />
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-6">
          <div className="form-group">
            <label>{STRINGS.COUNTRY_LABEL}</label>
            <Field
              name={STRINGS.COUNTRY_INPUT}
              component={SearchSelect}
              placeholder={STRINGS.COUNTRY_LABEL}
              options={countries}
              defaultValue={DEFAULT_VALUES.country_id}
              onChange={(value) => {
                if (value && value.value === 253) {
                  //show dropdown for country UK
                  set_showScottishAuthorityField(true);
                }
              }}
            />
          </div>
        </div>
        <div className="col-6">
          <div className="form-group">
            <label>{STRINGS.CITY_LABEL}</label>
            <Field name={STRINGS.CITY_INPUT} component={Input} placeholder={STRINGS.CITY_LABEL} />
          </div>
        </div>
      </div>
      {(DEFAULT_VALUES && DEFAULT_VALUES.scottish_authority_id) || showScottishAuthorityField ? (
        <div className="row">
          <div className="col-12">
            <div className="form-group">
              <label>{STRINGS.SCOTTISH_LOCAL_AUTHORITY_LABEL}</label>
              <Field
                name={STRINGS.SCOTTISH_LOCAL_AUTHORITY_INPUT}
                placeholder={STRINGS.SCOTTISH_LOCAL_AUTHORITY_PLACEHOLDER}
                component={SearchSelect}
                options={scotishlocalAuthorities}
                defaultValue={DEFAULT_VALUES ? DEFAULT_VALUES.scottish_authority_id : ""}
              />
            </div>
          </div>
        </div>
      ) : null}
      <div className="row">
        <div className="col-12">
          <div className="form-group">
            <label>{STRINGS.DESCRIPTION_OF_CLASS_AND_AGE_LABEL}</label>
            <Field
              name={STRINGS.DESCRIPTION_OF_CLASS_AND_AGE_INPUT}
              placeholder={STRINGS.DESCRIPTION_OF_CLASS_AND_AGE_LABEL_PLACEHOLDER}
              component={TextArea}
              id={"descriptionProfile"}
              defaultData={DEFAULT_VALUES ? DEFAULT_VALUES.description : ""}
            />
          </div>
        </div>
      </div>
      <div className="d-flex justify-content-center">
        <CustomButton
          label={BUTTON_LABELS.SAVE}
          type="submit"
          className=" btn btn-lg text-capitalize btn-primary mt-4"
        />
      </div>
    </Form>
  );
};
