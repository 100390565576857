import React, { useState, useEffect, useRef } from "react";
import ReactSelect, { components } from "react-select";
import "./style.scss";
const Select = ({
  defaultValue,
  label,
  config = {},
  meta: { touched, error },
  options,
  widthStyle,
  input,
  onValueChange = () => {},
  isSearchable = false,
  updateDeleteLineOfInquiries = () => {},
  showIconsforEditDelete = false,
}) => {
  let inputRef = useRef();
  widthStyle = widthStyle ? widthStyle : "";
  const validationSpan = touched && error ? <span className="error_msg text-danger">{error}</span> : null;

  const [selectValue, setSelectvalue] = useState("");
  const [editMode, set_editMode] = useState(false);
  const [newValue, set_newValue] = useState("");
  const [editModeId, set_editModeId] = useState("");

  useEffect(() => {
    if (options) {
      let obj = options && options.filter((option) => option.value == defaultValue);
      setSelectvalue(obj);
    }
  }, [defaultValue && options]);

  const { Option } = components;
  const IconOption = (props) => {
    const [editValue, setEditValue] = useState(props.label);
    return (
      <Option
        key={props.label}
        {...props}
        innerProps={{
          ...props.innerProps,
          // onClick: (e) => {
          //   //     console.log("edit mode in select", editMode)
          //   // // //   //   e.stopPropagation(); // doesn't do anything, sadly
          //   // // //   //   e.preventDefault(); // doesn't do anything, sadly
          //   // // //   //   // still unsure how to preven the menu from opening
          //   // // //   // },
          // },
        }}
      >
        {" "}
        {props.label}
        {/* <img src={require("../../../assets/images/logo_green.png")} style={{ width: 36 }} alt={props.label} /> */}
        {/* {showIconsforEditDelete && editMode && editModeId == props.value ? (
          <input
            //autoFocus
            ref={inputRef}
            type="text"
            value={editValue}
            contentEditable={true}
            onChange={(e) => {
              e.preventDefault();
              setEditValue(e.target.value)
              //set_newValue(e.target.value);
             // e.stopPropagation(); // doesn't do anything, sadly
              // doesn't do anything, sadly
            }}
          ></input>
        ) : (
          props.label
        )} */}
        {"  "}
        {/* {showIconsforEditDelete ? (
          editMode && editModeId == props.value ? (
            <label
              style={{ cursor: "pointer" }}
              onClick={() => {
                set_editMode(false);
                set_editModeId("");
              }}
            >
              Save
            </label>
          ) : (
            <label
              onClick={(e) => {
                set_editMode(true);
                set_newValue(props.label);
                console.log("label click", inputRef);
                set_editModeId(props.value);
                e.stopPropagation(); // doesn't do anything, sadly
                e.preventDefault(); // doesn't do anything, sadly
                // still unsure how to preven the menu from opening
              }}
              style={{ fontWeight: "500", cursor: "pointer" }}
            >
              Edit{"    "}
            </label>
          )
        ) : null}{" "} */}
        {/* {showIconsforEditDelete ? (
          <label
            style={{ fontWeight: "500" }}
            onClick={() => updateDeleteLineOfInquiries(props.value, props.label, "delete")}
          >
            Delete
          </label>
        ) : null} */}
      </Option>
    );
  };

  return (
    <>
      {label && <label>{label}</label>}
      <div className="form-group">
        <ReactSelect
          {...input}
          {...config}
          options={options}
          defaultInputValue={newValue}
          width={widthStyle}
          isSearchable={isSearchable}
          value={selectValue}
          onChange={(value) => {
            console.log("value", value);
            // e.stopPropagation(); // doesn't do anything, sadly
            // e.preventDefault(); // doesn't do anything, sadly
            input.onChange(value);
            onValueChange(value);
            setSelectvalue(value);
          }}
          className={"form-select"}
          onBlur={(event) => event.preventDefault()}
          components={{ Option: IconOption }}
        />
        {validationSpan}
      </div>
    </>
  );
};
export default Select;
