import {
  SAVE_HOME_LATEST_POSTS,
  PAGENO_OF_LATEST_POSTS,
  TOTAL_LATEST_POSTS_COUNT,
  SAVE_PAGENO,
  EMPTY_POSTS,
} from "./ActionTypes";
export const saveHomeLatestPosts = (data) => {
  return {
    type: SAVE_HOME_LATEST_POSTS,
    data,
  };
};
export const savepageNoOfLatestPosts = (data) => {
  return {
    type: PAGENO_OF_LATEST_POSTS,
    data,
  };
};
export const saveTotalCountofLatestPosts = (data) => {
  return {
    type: TOTAL_LATEST_POSTS_COUNT,
    data,
  };
};
export const savePageNo = (data) => {
  return {
    type: SAVE_PAGENO,
    data,
  };
};
export const setEmptyDataForPosts = (data) => {
  return {
    type: EMPTY_POSTS,
    data,
  };
};
