import { takeLatest, all, put } from "redux-saga/effects";
import { startLoading, stopLoading, logout_user, setAllHistory, setLikesHistory, setCommentsHistory } from "../actions";
import { GET_LIKES_COMMENT_HISTORY, GET_LIKES, GET_COMMENTS_HISTORY } from "../actions/ActionTypes";
import { checkOffline } from "../../shared/services/Utility";
const api = require(`../../shared/Apis/api`);
const { getRequest } = require(`../../helpers/axios`);
const { STATUS_CODE, MESSAGES, STATUS } = require(`../../shared/constants`);

//Request to get all data of likes and comment in history tab
function* getAllLikesAndComment({ data = 1, callback }) {
  try {
    yield put(startLoading());
    const response = yield getRequest({ API: `${api.GET_USER_HISTORY}?page_no=${data}` });
    if (response.status === STATUS_CODE.unAuthorized) {
      yield put(stopLoading());
      callback(response.data.message, STATUS.error);
      yield put(logout_user());
    } else if (response.status === 404) {
      yield put(stopLoading());
      callback(response.data.error.message, STATUS.error);
    } else if (response.status !== STATUS_CODE.successful) {
      yield put(stopLoading());
      callback(response.data.error.message, STATUS.error);
    } else {
      callback(response.data.data, STATUS.success);
      yield put(setAllHistory(response.data.data.data));
      yield put(stopLoading());
    }
  } catch (error) {
    yield put(stopLoading());
    if (checkOffline()) {
      callback(MESSAGES.OFFLINE, STATUS.error);
    } else {
      callback(MESSAGES.ERROR_IN_CATCH, STATUS.error);
    }
  }
}
//request to get all-likes
function* getLikes({ data = 1, callback }) {
  try {
    yield put(startLoading());
    const response = yield getRequest({ API: `${api.LIKE_HISTORY}?page_no=${data}` });
    if (response.status === STATUS_CODE.unAuthorized) {
      yield put(stopLoading());
      callback(response.data.message, STATUS.error);
      yield put(logout_user());
    } else if (response.status === 404) {
      yield put(stopLoading());
      callback(response.data.error.message, STATUS.error);
    } else if (response.status !== STATUS_CODE.successful) {
      yield put(stopLoading());
      callback(response.data.error.message, STATUS.error);
    } else {
      callback(response.data.data, STATUS.success);
      yield put(setLikesHistory(response.data.data.data));
      yield put(stopLoading());
    }
  } catch (error) {
    yield put(stopLoading());
    if (checkOffline()) {
      callback(MESSAGES.OFFLINE, STATUS.error);
    } else {
      callback(MESSAGES.ERROR_IN_CATCH, STATUS.error);
    }
  }
}
//request to get all-comments
function* getComments({ data = 1, callback }) {
  try {
    yield put(startLoading());
    const response = yield getRequest({ API: `${api.COMMENTS_HISTORY}?page_no=${data}` });
    if (response.status === STATUS_CODE.unAuthorized) {
      yield put(stopLoading());
      callback(response.data.message, STATUS.error);
      yield put(logout_user());
    } else if (response.status === 404) {
      yield put(stopLoading());
      callback(response.data.error.message, STATUS.error);
    } else if (response.status !== STATUS_CODE.successful) {
      yield put(stopLoading());
      callback(response.data.error.message, STATUS.error);
    } else {
      callback(response.data.data, STATUS.success);
      yield put(setCommentsHistory(response.data.data.data));
      yield put(stopLoading());
    }
  } catch (error) {
    yield put(stopLoading());
    if (checkOffline()) {
      callback(MESSAGES.OFFLINE, STATUS.error);
    } else {
      callback(MESSAGES.ERROR_IN_CATCH, STATUS.error);
    }
  }
}

function* ReportSaga() {
  yield all([
    takeLatest(GET_LIKES_COMMENT_HISTORY, getAllLikesAndComment),
    takeLatest(GET_LIKES, getLikes),
    takeLatest(GET_COMMENTS_HISTORY, getComments),
  ]);
}

export default ReportSaga;
