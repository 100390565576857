import { HOME_PAGE_POSITION, FLOORBOOK_PAGE_POSITION, FLOORBOOK_DETAIL_PAGE_POSITION } from "./ActionTypes";

export const homePagePosition = (data) => {
  return {
    type: HOME_PAGE_POSITION,
    data,
  };
};

export const floorbookDetailsPagePosition = (data) => {
  return {
    type: FLOORBOOK_PAGE_POSITION,
    data,
  };
};
export const floorbookDetailPagePosition = (data) => {
  return {
    type: FLOORBOOK_DETAIL_PAGE_POSITION,
    data,
  };
};
