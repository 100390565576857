import React, { useCallback, useEffect } from "react";
import { UploadImageName } from "../../atoms";
import update from "immutability-helper";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { withSnackbar } from "notistack";

const Uploads = ({ file, fileChange, enqueueSnackbar, editMode }) => {
  const onFileChange = (file) => {
    //File size and type checks

    if (file.type.includes("image") && file.size > 50000000) {
      enqueueSnackbar(`Image size not to exceed 50 Mb.`, {
        variant: "error",
        autoHideDuration: 2000,
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "left",
        },
      });
    } else if (file.type.includes("video") && file.size > 50000000) {
      enqueueSnackbar(`Video size not to exceed 50 Mb.`, {
        variant: "error",
        autoHideDuration: 2000,
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "left",
        },
      });
    } else {
      let uploadedFile = file && URL.createObjectURL && URL.createObjectURL(file);
      if (file.type === "video/mp4") {
        const data = {
          file: file,
          url: uploadedFile,
        };
        fileChange([data]);
      } else {
        const data = {
          file: file,
          url: uploadedFile,
        };
        fileChange([data]);
      }
    }
  };

  const moveCard = useCallback(
    (dragIndex, hoverIndex) => {
      const dragCard = file[dragIndex];
      fileChange(
        update(file, {
          $splice: [
            [dragIndex, 1],
            [hoverIndex, 0, dragCard],
          ],
        })
      );
    },
    [file]
  );
  return (
    <div>
      <div className="file_upload">
        {/* To make image draggable */}
        <DndProvider backend={HTML5Backend}>
          <label style={{ cursor: "pointer" }}>
            <input
              type="file"
              id="fileInput"
              name="files"
              accept=".jpg,.png,.mp4"
              style={{ visibility: "hidden" }}
              onChange={(e) => {
                e.target.files.length && onFileChange(e.target.files[0]);
              }}
            />
            Upload File
          </label>
          {file.length ? (
            <UploadImageName
              moveCard={moveCard}
              id={file[0].file.name}
              image={file[0].file.name}
              onClick={() => {
                fileChange([]);
                var name = document.getElementById("fileInput");
                name.value = "";
              }}
            />
          ) : null}
        </DndProvider>
      </div>
      <label>Max Size: 50MB</label>
    </div>
  );
};

export default withSnackbar(Uploads);
