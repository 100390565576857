import * as actionTypes from "../actions/ActionTypes";

import {
  startLoading,
  stopLoading,
  set_Notification_Status,
  set_Notification_Sound,
  start_Notification_Loader,
  stop_Notification_Loader,
  set_Notification_List,
  logout_user,
  notificationCount,
} from "../actions";

import { put, all, takeLatest } from "redux-saga/effects";
import { checkOffline } from "../../shared/services/Utility";
const { postRequest, getRequest } = require(`../../helpers/axios`);
const { STATUS_CODE, MESSAGES, STATUS } = require(`../../shared/constants`);

const api = require(`../../shared/Apis/api`);

//request to logout (logged user)
function* changeNotificationSession({ data, callback }) {
  try {
    const formdata = new FormData();
    formdata.append("device_token", data.fcm_token);
    formdata.append("notification_status", Number(data.status));

    //yield put(startLoading());
    const response = yield postRequest({ API: `${api.NOTIFICATION_SETTING}`, DATA: formdata });

    if (response.status === STATUS_CODE.unAuthorized) {
      // yield put(stopLoading());
      callback(response.data.message, STATUS.error);
      yield put(logout_user());
    } else if (response.status !== STATUS_CODE.successful) {
      //yield put(stopLoading());
      callback(response.data.error.message, STATUS.error);
    } else {
      // yield put(stopLoading());
    }
  } catch (error) {
    // yield put(stopLoading());
    if (checkOffline()) {
      callback(MESSAGES.OFFLINE, STATUS.error);
    } else {
      callback(MESSAGES.ERROR_IN_CATCH, STATUS.error);
    }
  }
}

function* notificationSound({ data, callback }) {
  try {
    const formdata = new FormData();
    formdata.append("sound_status", Number(data.sound));
    formdata.append("notification_status", Number(data.status));

    yield put(startLoading());
    const response = yield postRequest({ API: `${api.NOTIFICATION_SETTING}`, DATA: formdata });

    if (response.status === STATUS_CODE.unAuthorized) {
      yield put(stopLoading());
      callback(response.data.message, STATUS.error);
      yield put(logout_user());
    } else if (response.status !== STATUS_CODE.successful) {
      yield put(stopLoading());
      callback(response.data.error.message, STATUS.error);
    } else {
      yield put(set_Notification_Sound(data.sound));

      yield put(stopLoading());
    }
  } catch (error) {
    yield put(stopLoading());
    if (checkOffline()) {
      callback(MESSAGES.OFFLINE, STATUS.error);
    } else {
      callback(MESSAGES.ERROR_IN_CATCH, STATUS.error);
    }
  }
}

function* notificationStatus({ data, callback }) {
  try {
    const formdata = new FormData();

    //formdata.append("sound_status", data.sound);
    formdata.append("notification_status", Number(data.status));

    yield put(startLoading());
    const response = yield postRequest({ API: `${api.NOTIFICATION_SETTING}`, DATA: formdata });

    if (response.status === STATUS_CODE.unAuthorized) {
      yield put(stopLoading());
      callback(response.data.message, STATUS.error);
      yield put(logout_user());
    } else if (response.status !== STATUS_CODE.successful) {
      yield put(stopLoading());
      callback(response.data.error.message, STATUS.error);
    } else {
      yield put(set_Notification_Status(data.status));
      yield put(stopLoading());
    }
  } catch (error) {
    yield put(stopLoading());
    if (checkOffline()) {
      callback(MESSAGES.OFFLINE, STATUS.error);
    } else {
      callback(MESSAGES.ERROR_IN_CATCH, STATUS.error);
    }
  }
}

function* clearNotifications({ callback }) {
  try {
    //clear notification api call
    yield put(start_Notification_Loader());

    const response = yield getRequest({ API: `${api.NOTIFICATION_CLEAR}` });

    if (response.status === STATUS_CODE.unAuthorized) {
      yield put(stop_Notification_Loader());
      yield put(logout_user());
      callback(response.data.message, STATUS.error);
    } else if (response.status !== STATUS_CODE.successful) {
      yield put(stop_Notification_Loader());

      callback(response.data.error.message, STATUS.error);
    } else {
      yield put(set_Notification_List([]));

      yield put(stop_Notification_Loader());
    }
  } catch (error) {
    yield put(stop_Notification_Loader());
    if (checkOffline()) {
      callback(MESSAGES.OFFLINE, STATUS.error);
    } else {
      callback(MESSAGES.ERROR_IN_CATCH, STATUS.error);
    }
  }
}

function* getNotificationsList({ callback }) {
  try {
    // notification list api call
    yield put(start_Notification_Loader());
    const response = yield getRequest({ API: `${api.NOTIFICATION_LIST}` });

    if (response.status === STATUS_CODE.unAuthorized) {
      yield put(stop_Notification_Loader());
      callback(MESSAGES.ERROR_IN_CATCH, STATUS.error);
    } else if (response.status !== STATUS_CODE.successful) {
      yield put(stop_Notification_Loader());
      callback(response.data.error.message, STATUS.error);
      yield put(set_Notification_List([]));
    } else {
      yield put(set_Notification_List(response.data.data));
      yield put(stop_Notification_Loader());
      callback("Notification list fetched successfully", STATUS.success);
    }
  } catch (error) {
    yield put(stop_Notification_Loader());
    if (checkOffline()) {
      callback(MESSAGES.OFFLINE, STATUS.error);
    } else {
      callback(MESSAGES.ERROR_IN_CATCH, STATUS.error);
    }
  }
}
function* updateNotificationReadStatus({ callback }) {
  try {
    // notification list api call
    yield put(start_Notification_Loader());
    const response = yield postRequest({ API: `${api.UPDATE_NOTIFICATION_READ_STATUS}` });

    if (response.status === STATUS_CODE.unAuthorized) {
      yield put(stop_Notification_Loader());
      callback(MESSAGES.ERROR_IN_CATCH, STATUS.error);
    } else if (response.status !== STATUS_CODE.successful) {
      yield put(stop_Notification_Loader());
      callback(response.data.error.message, STATUS.error);
    } else {
      yield put(stop_Notification_Loader());
      callback("messasasge", STATUS.success);
    }
  } catch (error) {
    yield put(stop_Notification_Loader());
    if (checkOffline()) {
      callback(MESSAGES.OFFLINE, STATUS.error);
    } else {
      callback(MESSAGES.ERROR_IN_CATCH, STATUS.error);
    }
  }
}
function* getUnreadNotificationCount({ callback }) {
  try {
    // notification list api call
    yield put(start_Notification_Loader());
    const response = yield getRequest({ API: `${api.GET_UNREAD_NOTIFICATION_COUNT}` });

    if (response.status === STATUS_CODE.unAuthorized) {
      yield put(stop_Notification_Loader());
      callback(MESSAGES.ERROR_IN_CATCH, STATUS.error);
    } else if (response.status !== STATUS_CODE.successful) {
      yield put(stop_Notification_Loader());
      callback(response.data.error.message, STATUS.error);
    } else {
      yield put(stop_Notification_Loader());
      yield put(notificationCount(response.data.data.unread));
      callback("messasasge", STATUS.success);
    }
  } catch (error) {
    yield put(stop_Notification_Loader());
    if (checkOffline()) {
      callback(MESSAGES.OFFLINE, STATUS.error);
    } else {
      callback(MESSAGES.ERROR_IN_CATCH, STATUS.error);
    }
  }
}

//get notification setting

function* getNotificationSetting({ callback }) {
  try {
    // notification list api call
    yield put(start_Notification_Loader());
    const response = yield getRequest({ API: `${api.GET_NOTIFICATION_SETTING}` });

    if (response.status === STATUS_CODE.unAuthorized) {
      yield put(stop_Notification_Loader());
      callback(MESSAGES.ERROR_IN_CATCH, STATUS.error);
    } else if (response.status !== STATUS_CODE.successful) {
      yield put(stop_Notification_Loader());
      callback(response.data.error.message, STATUS.error);
    } else {
      yield put(stop_Notification_Loader());
      yield put(set_Notification_Status(response.data.data.settings.notification));
      yield put(set_Notification_Sound(response.data.data.settings.sound));
      callback(response.data.data.settings, STATUS.success);
    }
  } catch (error) {
    yield put(stop_Notification_Loader());
    if (checkOffline()) {
      callback(MESSAGES.OFFLINE, STATUS.error);
    } else {
      callback(MESSAGES.ERROR_IN_CATCH, STATUS.error);
    }
  }
}
function* notificationSaga() {
  yield all([
    takeLatest(actionTypes.NOTICATION_STATUS, notificationStatus),
    takeLatest(actionTypes.NOTICATION_SOUND, notificationSound),
    takeLatest(actionTypes.CHANGE_SESSION, changeNotificationSession),
    takeLatest(actionTypes.CLEAR_NOTIFICATIONS, clearNotifications),
    takeLatest(actionTypes.GET_NOTIFICATION_LIST, getNotificationsList),
    takeLatest(actionTypes.UPDATE_NOTIFICATION_READ_STATUS, updateNotificationReadStatus),
    takeLatest(actionTypes.GET_UNREAD_NOTIFICATION_COUNT, getUnreadNotificationCount),
    takeLatest(actionTypes.GET_NOTIFICATION_SETTING, getNotificationSetting),
  ]);
}

export default notificationSaga;
