import { takeLatest, all, put } from "redux-saga/effects";
import {
  startLoading,
  stopLoading,
  SEND_EMAIL_RESET_PASSWORD,
  SUBMIT_OTP,
  UPDATE_PASSWORD,
  logout_user,
} from "../actions";
import { checkOffline } from "../../shared/services/Utility";
const api = require(`../../shared/Apis/api`);
const { postRequest } = require(`../../helpers/axios`);
const { STATUS_CODE, MESSAGES, STATUS } = require(`../../shared/constants`);

//request to send email for forget password functionality(send otp)
function* sendEmailForResetPassword({ data, callback }) {
  try {
    yield put(startLoading());
    const response = yield postRequest({ API: `${api.SEND_EMAIL_FOR_PASSWORD_RESET}`, DATA: data });

    if (response.status === STATUS_CODE.unAuthorized) {
      yield put(stopLoading());
      callback(response.data.message, STATUS.error);
      yield put(logout_user());
    } else if (response.status !== STATUS_CODE.successful) {
      yield put(stopLoading());
      callback(response.data.error.message, STATUS.error);
    } else {
      yield put(stopLoading());
      callback(response.data.success.message, STATUS.success);
    }
  } catch (error) {
    yield put(stopLoading());
    if (checkOffline()) {
      callback(MESSAGES.OFFLINE, STATUS.error);
    } else {
      callback(MESSAGES.ERROR_IN_CATCH, STATUS.error);
    }
  }
}
//request to submit otp(which user recieve on email)
function* submitOTP({ data, callback }) {
  try {
    yield put(startLoading());
    const response = yield postRequest({ API: `${api.MATCH_OTP}`, DATA: data });

    if (response.status === STATUS_CODE.unAuthorized) {
      yield put(stopLoading());
      callback(response.data.message, STATUS.error);
      yield put(logout_user());
    } else if (response.status !== STATUS_CODE.successful) {
      yield put(stopLoading());
      callback(response.data.error.message, STATUS.error);
    } else {
      yield put(stopLoading());
      callback(response.data.success.message, STATUS.success);
    }
  } catch (error) {
    yield put(stopLoading());

    if (checkOffline()) {
      callback(MESSAGES.OFFLINE, STATUS.error);
    } else {
      callback(MESSAGES.ERROR_IN_CATCH, STATUS.error);
    }
  }
}
//request to update user password
function* updateUserPassword({ data, callback }) {
  try {
    yield put(startLoading());
    const response = yield postRequest({ API: `${api.UPDATE_PASSWORD}`, DATA: data });

    if (response.status === STATUS_CODE.unAuthorized) {
      yield put(stopLoading());
      callback(response.data.message, STATUS.error);
      yield put(logout_user());
    } else if (response.status !== STATUS_CODE.successful) {
      yield put(stopLoading());
      callback(response.data.error.message, STATUS.error);
    } else {
      callback(response.data.success.message, STATUS.success);
      yield put(stopLoading());
    }
  } catch (error) {
    yield put(stopLoading());

    if (checkOffline()) {
      callback(MESSAGES.OFFLINE, STATUS.error);
    } else {
      callback(MESSAGES.ERROR_IN_CATCH, STATUS.error);
    }
  }
}
function* SignUpSaga() {
  yield all([
    takeLatest(SEND_EMAIL_RESET_PASSWORD, sendEmailForResetPassword),
    takeLatest(SUBMIT_OTP, submitOTP),
    takeLatest(UPDATE_PASSWORD, updateUserPassword),
  ]);
}

export default SignUpSaga;
