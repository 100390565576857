import { connect } from "react-redux";
import Screen from "./screen";
import {
  getAllFloorbooks,
  getFavouriteFloorbooks,
  getOtherFloorbooks,
  setFavourite,
  floorbookDetailsPagePosition,
  setHideorUnHideFloorbook,
  updateFloorbookJournal
} from "../../../../../redux/actions";

const mapStateToProps = (state) => {
  return {
    loading: state.CommonReducer.isLoading,
    myfloorbookData: state.FloorbookReducer.myfloorbookData,
    OthersFloorbookData: state.FloorbookReducer.OthersFloorbookData,
    favouriteFloorbooksData: state.FloorbookReducer.favouriteFloorbooksData,
    Floorbookpage_Position: state.ScrollPositionsReducer.Floorbookpage_Position,
    userRole: state.CommonReducer.userData.user_role,
    privacyStatusofFloorbook: state.FloorbookReducer.privacyStatusofFloorbook,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getOtherFloorbooks: (callback) => dispatch(getOtherFloorbooks(callback)),
    getAllFloorbooks: (callback) => dispatch(getAllFloorbooks(callback)),
    getFavouriteFloorbooks: (callback) => dispatch(getFavouriteFloorbooks(callback)),
    setFavourite: (data, callback) => dispatch(setFavourite(data, callback)),
    floorbookDetailsPagePosition: (data) => dispatch(floorbookDetailsPagePosition(data)),
    setHideorUnHideFloorbook: (data, callback) => dispatch(setHideorUnHideFloorbook(data, callback)),
    updateFloorbookJournal:(data,callback)=>dispatch(updateFloorbookJournal(data,callback))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Screen);
