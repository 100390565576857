import { connect } from "react-redux";
import Screen from "./screen";
import { sendEmailForPasswordReset, submitOtpOfUser, updatePassword } from "../../../redux/actions";

//map the reducer states
const mapStateToProps = (state) => {
  return {
    state,
    loading: state.CommonReducer.isLoading,
  };
};

//used to call redux action functions
const mapDispatchToProps = (dispatch) => {
  return {
    sendEmailForPasswordReset: (data, callback) => dispatch(sendEmailForPasswordReset(data, callback)),
    submitOtpOfUser: (data, callback) => dispatch(submitOtpOfUser(data, callback)),
    updatePassword: (data, callback) => dispatch(updatePassword(data, callback)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Screen);
