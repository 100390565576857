import React, { useState, useEffect } from "react";
import { Loader } from "../../../../components";
import { withSnackbar } from "notistack";
import Switch from "@material-ui/core/Switch";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import "./style.scss";
import { STATUS, NOTIFICATION } from "../../../../shared/constants";

const Screen = ({
  enqueueSnackbar,
  loading,
  status,
  sound,
  notificationSoundSet = () => {},
  notificationStatus = () => {},
  getNotificationSetting = () => {},
}) => {
  const [notification, set_notification] = useState(false);
  const [notificationSound, set_notificationSound] = useState(false);

  useEffect(() => {
    getNotificationSetting((data, type) => {});
  }, []);

  useEffect(() => {
    set_notification(status);
  }, [status]);

  useEffect(() => {
    set_notificationSound(sound);
  }, [sound]);

  const notificationChange = () => {
    let data = {
      status: !notification,
      sound: notificationSound,
    };

    notificationStatus(data, (message, type) => {
      if (type === STATUS.error) {
        enqueueSnackbar(message, {
          variant: type,
          autoHideDuration: 3000,
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "left",
          },
        });
      }
    });
  };

  const notificationSoundChange = () => {
    let data = {
      status: notification,
      sound: !notificationSound,
    };

    notificationSoundSet(data, (message, type) => {
      if (type === STATUS.error) {
        enqueueSnackbar(message, {
          variant: type,
          autoHideDuration: 3000,
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "left",
          },
        });
      }
    });
  };

  return (
    <div>
      <div className="card-header">{/* <HeadTitle title={HEAD_TITLES.SETTINGS} /> */}</div>
      <div className="card-body">
        <div className="notification_fields">
          <div className="d-flex justify-content-between align-item-center notification_item">
            <span>{NOTIFICATION.NOTIFICATION_LABEL}</span>
            <FormGroup>
              <FormControlLabel
                control={
                  <Switch
                    size="small"
                    checked={notification}
                    onChange={() => {
                      notificationChange();
                    }}
                  />
                }
              />
            </FormGroup>
          </div>

          <div className="d-flex justify-content-between notification_item">
            <span>{NOTIFICATION.NOTIFICATION_SOUND_LABEL}</span>
            <FormGroup>
              <FormControlLabel
                control={<Switch size="small" checked={notificationSound} onChange={() => notificationSoundChange()} />}
              />
            </FormGroup>
          </div>
        </div>
      </div>
      {loading && <Loader />}
    </div>
  );
};

export default withSnackbar(Screen);
