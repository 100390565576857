import { takeLatest, all, put } from "redux-saga/effects";
import {
  setUsefulResources,
  startLoading,
  stopLoading,
  isAdmin,
  setUsefulResourceDetail,
  logout_user,
} from "../actions";
import {
  ADD_USEFUL_RESOURCE,
  GET_USEFUL_RESOURCES_DATA,
  GET_USEFUL_RESOURCE_DETAIL,
  DELETE_USEFUL_RESOURCE,
  GET_RESOURCE_BY_ID,
  EDIT_USEFUL_RESOURCE,
} from "../actions/ActionTypes";
import { checkOffline } from "../../shared/services/Utility";
const api = require(`../../shared/Apis/api`);
const { postRequest, getRequest } = require(`../../helpers/axios`);
const { STATUS_CODE, MESSAGES, STATUS } = require(`../../shared/constants`);

//request to get all previous added resources
function* getUsefulResourcesData({ callback }) {
  try {
    yield put(startLoading());
    const response = yield getRequest({ API: `${api.GET_USEFULRESOURCES}` });
    if (response.status === STATUS_CODE.unAuthorized) {
      yield put(stopLoading());
      callback(response.data.message, STATUS.error);
      yield put(logout_user());
    } else if (response.status !== STATUS_CODE.successful) {
      yield put(stopLoading());
      callback(response.data.error.message, STATUS.error);
    } else {
      yield put(stopLoading());
      yield put(setUsefulResources(response.data.data.data));
      yield put(isAdmin(response.data.data.is_admin));
    }
  } catch (error) {
    yield put(stopLoading());
    if (checkOffline()) {
      callback(MESSAGES.OFFLINE, STATUS.error);
    } else {
      callback(MESSAGES.ERROR_IN_CATCH, STATUS.error);
    }
  }
}
//request to add resource
function* addUsefulResources({ data, callback }) {
  try {
    const formdata = new FormData();
    formdata.append("title", data.name);
    formdata.append("description", data.description);
    formdata.append("resourceFile", data.resourceFile);
    yield put(startLoading());
    const response = yield postRequest({ API: `${api.ADD_USEFULRESOURCES}`, DATA: formdata });
    if (response.status === STATUS_CODE.unAuthorized) {
      yield put(stopLoading());
      callback(response.data.message, STATUS.error);
      yield put(logout_user());
    } else if (response.status !== STATUS_CODE.successful) {
      yield put(stopLoading());
      callback(response.data.error.message, STATUS.error);
    } else {
      yield put(stopLoading());
      callback(response.data, STATUS.success);
    }
  } catch (error) {
    yield put(stopLoading());
    if (checkOffline()) {
      callback(MESSAGES.OFFLINE, STATUS.error);
    } else {
      callback(MESSAGES.ERROR_IN_CATCH, STATUS.error);
    }
  }
}
//request to fetch detail of resource
function* getUseFulResourcesDetail({ data, callback }) {
  try {
    yield put(startLoading());
    const response = yield postRequest({ API: `${api.GET_USEFUL_RESOURCES_DETAIL}?id=${data}` });
    if (response.status === STATUS_CODE.unAuthorized) {
      yield put(stopLoading());
      callback(response.data.message, STATUS.error);
      yield put(logout_user());
    } else if (response.status !== STATUS_CODE.successful) {
      yield put(stopLoading());
      callback(response.data.error.message, STATUS.error);
    } else {
      yield put(stopLoading());
      yield put(setUsefulResourceDetail(response.data.data.data));
      callback(response.data.message, STATUS.success, response.data.data.data);
    }
  } catch (error) {
    yield put(stopLoading());
    if (checkOffline()) {
      callback(MESSAGES.OFFLINE, STATUS.error);
    } else {
      callback(MESSAGES.ERROR_IN_CATCH, STATUS.error);
    }
  }
}
//request to delete resource
function* deleteResourceSaga({ data, callback }) {
  try {
    yield put(startLoading());
    const response = yield getRequest({ API: `${api.DELETE_RESOURCE}?id=${data}` });
    if (response.status === STATUS_CODE.unAuthorized) {
      yield put(stopLoading());
      callback(response.data.message, STATUS.error);
      yield put(logout_user());
    } else if (response.status !== STATUS_CODE.successful) {
      yield put(stopLoading());
      callback(response.data.error.message, STATUS.error);
    } else {
      yield put(stopLoading());
      callback(response.data.data.message, STATUS.success);
    }
  } catch (error) {
    yield put(stopLoading());
    if (checkOffline()) {
      callback(MESSAGES.OFFLINE, STATUS.error);
    } else {
      callback(MESSAGES.ERROR_IN_CATCH, STATUS.error);
    }
  }
}
function* getResourceByIdSaga({ data, callback }) {
  try {
    yield put(startLoading());
    const response = yield getRequest({ API: `${api.GET_RESOURCE_BY_ID}?id=${data}` });
    if (response.status === STATUS_CODE.unAuthorized) {
      yield put(stopLoading());
      callback(response.data.message, STATUS.error);
      yield put(logout_user());
    } else if (response.status !== STATUS_CODE.successful) {
      yield put(stopLoading());
      callback(response.data.error.message, STATUS.error);
    } else {
      yield put(stopLoading());
      callback(response.data.data.message, STATUS.success, response.data.data.data);
    }
  } catch (error) {
    yield put(stopLoading());
    if (checkOffline()) {
      callback(MESSAGES.OFFLINE, STATUS.error);
    } else {
      callback(MESSAGES.ERROR_IN_CATCH, STATUS.error);
    }
  }
}
function* editResourceSaga({ data, callback }) {
  try {
    const formdata = new FormData();
    formdata.append("title", data.name);
    formdata.append("description", data.description);
    formdata.append("resourceFile", data.resourceFile);
    formdata.append("id", data.id);
    yield put(startLoading());
    const response = yield postRequest({ API: `${api.EDIT_RESOURCE}`, DATA: formdata });
    if (response.status === STATUS_CODE.unAuthorized) {
      yield put(stopLoading());
      callback(response.data.message, STATUS.error);
      yield put(logout_user());
    } else if (response.status !== STATUS_CODE.successful) {
      yield put(stopLoading());
      callback(response.data.error.message, STATUS.error);
    } else {
      yield put(stopLoading());
      callback(response.data.data.message, STATUS.success);
    }
  } catch (error) {
    yield put(stopLoading());
    if (checkOffline()) {
      callback(MESSAGES.OFFLINE, STATUS.error);
    } else {
      callback(MESSAGES.ERROR_IN_CATCH, STATUS.error);
    }
  }
}
function* UsefulResourcesSaga() {
  yield all([
    takeLatest(GET_USEFUL_RESOURCES_DATA, getUsefulResourcesData),
    takeLatest(ADD_USEFUL_RESOURCE, addUsefulResources),
    takeLatest(GET_USEFUL_RESOURCE_DETAIL, getUseFulResourcesDetail),
    takeLatest(DELETE_USEFUL_RESOURCE, deleteResourceSaga),
    takeLatest(GET_RESOURCE_BY_ID, getResourceByIdSaga),
    takeLatest(EDIT_USEFUL_RESOURCE, editResourceSaga),
  ]);
}

export default UsefulResourcesSaga;
