export const validEmail = (text) => {
  // eslint-disable-next-line no-useless-escape
  let reg = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return reg.test(text);
};

export const validNumber = (text) => {
  let reg = /^[0-9]+$/g;
  return reg.test(text);
};

export const checkOffline = () => {
  return !window.navigator.onLine;
};

export function getBase64Image(url, filename, callback) {
  var canvas = document.createElement("CANVAS");
  var ctx = canvas.getContext("2d");
  var img = new Image();
  img.crossOrigin = "Anonymous";
  img.onload = function () {
    canvas.height = img.height;
    canvas.width = img.width;
    ctx.drawImage(img, 0, 0);
    var dataURL = canvas.toDataURL("image/jpeg", 0.6);
    let baseUrl = dataURL;
    dataURL = dataURLtoFile(dataURL, filename);
    callback(dataURL, baseUrl);
    // Clean up
    canvas = null;
  };
  img.src = url;
}

export function dataURLtoFile(dataurl, filename) {
  var arr = dataurl.split(","),
    mime = arr[0].match(/:(.*?);/)[1],
    bstr = atob(arr[1]),
    n = bstr.length,
    u8arr = new Uint8Array(n);
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  return new File([u8arr], filename, { type: mime });
}
export function videoURLtoFile(dataurl, filename) {
  var arr = dataurl.split("/"),
    mime = "video/mp4",
    bstr = atob(arr[1]),
    n = bstr.length,
    u8arr = new Uint8Array(n);
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  var arr1= arr[9] ? arr[9].split("_") :null
  return new File([u8arr], filename , { type: mime });
}

export const parseToHtml = (data) => {
  if (data) {
    var lines = data.split(/\r?\n/);
    var html = "";
    for (var i = 0; i < lines.length; i++) {
      html += "<p>" + lines[i] + "</p>";
    }
    html = JSON.parse(JSON.stringify(html));
    return html;
  }
};

export const timeConverter = (time) => {
  let dateNow = new Date().getTime();
  let diffInMilliSeconds = Math.abs(parseInt(time) - dateNow) / 1000;

  // calculate days
  const days = Math.floor(diffInMilliSeconds / 86400);
  diffInMilliSeconds -= days * 86400;

  // calculate hours
  const hours = Math.floor(diffInMilliSeconds / 3600) % 24;
  diffInMilliSeconds -= hours * 3600;

  // calculate minutes
  const minutes = Math.floor(diffInMilliSeconds / 60) % 60;
  diffInMilliSeconds -= minutes * 60;

  if (minutes <= 0 && hours === 0 && days === 0) {
    return `just now`;
  } else if (minutes < 60 && hours === 0 && days === 0) {
    return `${minutes} ${minutes === 1 ? "min" : "mins"} ago`;
  } else if (hours > 0 && hours < 24 && days === 0) {
    return `${hours} ${hours === 1 ? "hour" : "hrs"} ago`;
  } else {
    return `${days} ${days === 1 ? "day" : "days"} ago`;
  }
};
