import React, { useRef, useEffect } from "react";
import {
  HEAD_TITLES,
  ROUTE_CONSTANTS,
  HOME_TAB_ICON,
  FLOORBOOK_TAB_ICON,
  RESOURCE_TAB_ICON,
  REPORTS_ICON,
  HISTORY_TAB_ICON,
  SETTINGS_TAB_ICON,
  HELP_TAB_ICON,
} from "../../../shared/constants";
import { connect } from "react-redux";
import ProfileImageContainer from "../../atoms/ProfileImageContainer";
import { useLocation } from "react-router-dom";
import "./style.scss";
import DeleteModal from "../DeleteModal";
import { logout } from "../../../redux/actions/CommonActions";
import { ImageOverlay } from "../../atoms";
import { withSnackbar } from "notistack";

// side bar Navigation List
const Home = [
  {
    title: HEAD_TITLES.HOME,
    path: ROUTE_CONSTANTS.HOME,
    active: HOME_TAB_ICON,
  },
];

const Floorbook = [
  {
    title: HEAD_TITLES.FLOORBOOK,
    path: ROUTE_CONSTANTS.FLOORBOOKS,
    active: FLOORBOOK_TAB_ICON,
  },
  {
    title: HEAD_TITLES.LEARNING_JOURNALS,
    path: ROUTE_CONSTANTS.LEARNING_JOURNAL,
    active: require("../../../assets/images/icons/learning.svg"),
  },
];
const reports = [
  {
    title: HEAD_TITLES.REPORT,
    path: ROUTE_CONSTANTS.REPORT,
    active: REPORTS_ICON,
  },
];

const DATA = [
  {
    title: HEAD_TITLES.USEFUL_RESOURCES,
    path: ROUTE_CONSTANTS.USEFUL_RESOURCES,
    active: RESOURCE_TAB_ICON,
  },
  {
    title: HEAD_TITLES.HISTORY,
    path: ROUTE_CONSTANTS.HISTORY,
    active: HISTORY_TAB_ICON,
  },
  {
    title: HEAD_TITLES.SETTINGS,
    path: ROUTE_CONSTANTS.SETTINGS,
    active: SETTINGS_TAB_ICON,
  },
  {
    title: HEAD_TITLES.HELP,
    path: ROUTE_CONSTANTS.HELP,
    active: HELP_TAB_ICON,
  },
];
const SideBar = ({ toggle, onClick, userData, logout = () => {}, enqueueSnackbar, onToggle, loading }) => {
  let { pathname } = useLocation();
  const node = useRef();

  useEffect(() => {
    // add when mounted
    document.addEventListener("mousedown", handleClick);
    // return function to be called when unmounted
    return () => {
      document.removeEventListener("mousedown", handleClick);
    };
  });

  const handleClick = (e) => {
    if (node && node.current && node.current.contains(e.target)) {
      // inside click
      return;
    } else {
      // outside click
      toggle && onToggle();
      return;
    }
  };
  return (
    <div ref={node}>
      <button className="close-btn d-lg-none" onClick={() => onToggle()}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="27.001"
          height="27"
          viewBox="0 0 27.001 27"
        >
          <path
            id="close"
            d="M15.974,13.636,26.487,3.122A1.749,1.749,0,0,0,24.014.649L13.5,11.162,2.987.649A1.749,1.749,0,0,0,.513,3.122L11.027,13.636.513,24.15a1.749,1.749,0,1,0,2.473,2.473L13.5,16.109,24.014,26.623a1.749,1.749,0,0,0,2.473-2.473Zm0,0"
            transform="translate(0 -0.136)"
            fill="#0D532D"
          />
        </svg>
      </button>
      <ProfileImageContainer
        email={userData.email}
        name={`${userData.firstName} ${userData.lastName} `}
        profile_pic={userData.profile_pic}
        onToggle={() => onToggle()}
      />
      <div className="sidebar_menu">
        {Home.map((item, index) => {
          let { title, active, path } = item;
          //highlight the active route
          let sameRoute =
            path && pathname ? (pathname === path ? true : false) : false;
          return (
            <div
              className={sameRoute ? "nav-item active" : "nav-item"}
              key={index}
              onClick={() => {
                onClick(path);
                onToggle();
              }}
            >
              <ImageOverlay>
                <i className="mr-3">
                  <img src={active} width="20" alt="" />
                </i>
                <span>{title}</span>
              </ImageOverlay>
            </div>
          );
        })}
      </div>
      <div className="group_nav d-flex align-items-center justify-content-center sidebar_menu">
        {Floorbook.map((item, index) => {
          let { title, active, path } = item;
          let sameRoute =
            path && pathname ? (pathname.includes(path) ? true : false) : false;
          return (
            <div
              className={sameRoute ? "nav-item active" : "nav-item"}
              key={index}
              onClick={() => {
                onClick(path);
                onToggle();
              }}
            >
              <ImageOverlay>
                <i className="mr-3">
                  <img src={active} width="20" alt="" />
                </i>
                <span>{title}</span>
              </ImageOverlay>
            </div>
          );
        })}
      </div>
      <div className="sidebar_menu">
        {reports.map((item, index) => {
          let { title, active, path } = item;
          let sameRoute =
            path && pathname ? (pathname === path ? true : false) : false;
          return (
            <div
              className={sameRoute ? "nav-item active" : "nav-item"}
              key={index}
              onClick={() => {
                onClick(path);
                onToggle();
              }}
            >
              <ImageOverlay>
                <i className="mr-3">
                  <img src={active} width="20" alt="" />
                </i>
                <span>{title}</span>
              </ImageOverlay>
            </div>
          );
        })}
      </div>
      <div className="menubar">
        {DATA.map((item, index) => {
          let { title, active, path } = item;
          let sameRoute =
            path && pathname ? (pathname === path ? true : false) : false;
          return (
            <div
              className={sameRoute ? "nav-item active" : "nav-item"}
              key={index}
              onClick={() => {
                onClick(path);
                onToggle();
              }}
            >
              <ImageOverlay>
                {" "}
                <i className="mr-3">
                  <img src={active} width="20" alt="" />
                </i>
                <span>{title}</span>
              </ImageOverlay>
            </div>
          );
        })}

        <DeleteModal
          btnLabel={HEAD_TITLES.LOGOUT}
          type={5}
          loading={loading}
          onDelete={() =>
            logout((message, type) => {
              enqueueSnackbar(message, {
                variant: type,
                autoHideDuration: 3000,
                anchorOrigin: {
                  vertical: "bottom",
                  horizontal: "left",
                },
              });
            })
          }
        />
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    state,
    userData: state.CommonReducer.userData,
    loading: state.CommonReducer.isLoading,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    logout: (callback) => dispatch(logout(callback)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withSnackbar(SideBar));
