import { connect } from "react-redux";
import { getDropDownData, getProfileData, setUserData, updateProfile } from "../../../../redux/actions";
import Screen from "./screen";

const mapStateToProps = (state) => {
  return {
    loading: state.CommonReducer.isLoading,
    allCountries: state.SignUpReducer.allCountries,
    allSettingTypes: state.SignUpReducer.allSettingTypes,
    scottishLocalAuthority: state.SignUpReducer.scottishLocalAuthority,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getProfileData: (callback) => dispatch(getProfileData(callback)),
    getDropDownData: (callback) => dispatch(getDropDownData(callback)),
    updateProfile: (data, callback) => dispatch(updateProfile(data, callback)),
    setUserData: (data) => dispatch(setUserData(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Screen);
