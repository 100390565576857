//common actions
export const STOP_LOADING = "stop_loading";
export const START_LOADING = "start_loading";
export const UPDATE_INTERNET_STATUS = "update_internet";
export const SAVE_CAPTCHA = "SAVE_CAPTCHA";
export const FCM_TOKEN = "FCM_TOKEN";
export const CHANGE_SESSION = "CHANGE_SESSION";

//Login Action Types
export const LOGIN = "login";
export const LOGOUT = "logout"; // remove token local storage
export const LOGOUT_SERVER = "LOGOUT_SERVER";
export const LOGIN_REQUEST = "login_request";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const SET_USER_DATA = "SET_USER_DATA";
//home actions
export const SAVE_HOME_LATEST_POSTS = "SAVE_HOME_LATEST_POSTS";
export const PAGENO_OF_LATEST_POSTS = "TOTAL_LATEST_POSTS";
export const TOTAL_LATEST_POSTS_COUNT = "TOTAL_LATEST_POSTS_COUNT";
export const SAVE_PAGENO = "SAVE_PAGENO";
export const EMPTY_POSTS = "EMPTY_POSTS";
//Sign up Action Types
export const SIGNUP_REQUEST = "signUp_request";
export const GET_DROPDOWN_DATA = "getDropdown_data";
export const SAVE_SIGNUP_FORM_ONE = "SAVE_SIGNUP_FORM_ONE";
export const SAVE_SIGNUP_FORM_TWO = "SAVE_SIGNUP_FORM_TWO";
export const SAVE_COUNTRY_DATA = "SAVE_DROPDOWN_DATA";
export const SAVE_CITY_DATA = "SAVE_CITY_DATA";
export const GET_SCOTTISH_LOCAL_AUTHORITY_DROPDOWN = "GET_SCOTTISH_LOCAL_AUTHORITY_DROPDOWN";
export const SAVE_SCOTTISH_LOCAL_AUTHORITY_DROPDOWN = "SAVE_SCOTTISH_LOCAL_AUTHORITY_DROPDOWN";
export const SAVE_SETTING_TYPE_DATA = "SAVE_SETTING_TYPE_DATA";
export const SIGN_UP_FOR_NEW_USER = "SIGN_UP_FOR_NEW_USER";
export const GO_BACK = "GO_BACK";
//foregt password actions
export const SEND_EMAIL_RESET_PASSWORD = "SEND_EMAIL_RESET_PASSWORD";
export const SUBMIT_OTP = "SUBMIT_OTP";
export const UPDATE_PASSWORD = "UPDATE_PASSWORD";
//floorbook actions
export const GET_ALL_FLOORBOOKS_LIST = "GET_ALL_FLOORBOOKS_LIST";
export const SAVE_ALL_FLOORBOOKS_LIST = "SAVE_ALL_FLOORBOOKS_LIST";
export const ADD_FLOORBOOK = "ADD_FLOORBOOK";
export const GET_FLOORBOOKS = "GET_FLOORBOOKS";
export const MY_FLOORBOOK_DATA = "MY_FLOORBOOK_DATA";
export const SAVE_FLOORBOOK_DATA = "SAVE_FLOORBOOK_DATA";
export const GET_INQUIRY_LINES = "GET_INQUIRY_LINES";
export const SAVE_INQUIRY_LINES = "SAVE_INQUIRY_LINES";
export const GET_FLOORBOOK_PAGES = "GET_FLOORBOOK_PAGES";
export const SAVE_FLOORBOOK_PAGES = "SAVE_FLOORBOOK_PAGES";
export const GET_OTHER_FLOORBOOKS = "GET_OTHER_FLOORBOOKS";
export const SAVE_OTHERS_FLOORBOOKS = "SAVE_OTHERS_FLOORBOOKS";
export const UPDATE_FLOORBOOK = "UPDATE_FLOORBOOK";
export const LIKE_FLOORBOOK = "LIKE_FLOORBOOK";
export const GET_FLOORBOOK_COMMENTS = "GET_FLOORBOOK_COMMENTS";
export const SET_FAVOURITE = "SET_FAVOURITE";
export const GET_FAVOURITE_FLOORBOOKS = "GET_FAVOURITE_FLOORBOOKS";
export const SET_FAVOURITE_FLOORBOOKS = "SET_FAVOURITE_FLOORBOOKS";
export const DELETE_FLOORBOOK = "DELETE_FLOORBOOK";
export const GET_FLOORBOOK_COVER_DETAILS = "GET_FLOORBOOK_COVER_DETAILS";
export const DELETE_COMMENTS_FLOORBOOK = "DELETE_COMMENTS_FLOORBOOK";
export const SET_PRIVACY_STATUS = "SET_PRIVACY_STATUS";
export const ADD_COMMENT_LIKE = "ADD_COMMENT_LIKE";
export const ADD_COMMENT_REPLY = "ADD_COMMENT_REPLY";
//floorbook page actions
export const LIKE_PAGE = "LIKE_PAGE";
export const SET_NEW_LIKES = "SET_NEW_LIKES";
export const SET_NEW_LIKES_HOME = "SET_NEW_LIKES_HOME";
export const GET_FLOORBOOK_PAGE_DETAIL = "GET_FLOORBOOK_PAGE_DETAIL";

export const GET_SUBJECT_AREA = "GET_SUBJECT_AREA";
export const SAVE_SUBJECT_AREA = "SAVE_SUBJECT_AREA";
export const SAVE_FLOORBOOK_PAGE_DATA = "SAVE_FLOORBOOK_PAGE_DATA";
export const UPDATE_FLOORBOOK_PAGE = "UPDATE_FLOORBOOK_PAGE";
export const GET_COMMENTS = "GET_COMMENTS";
export const EDIT_FLOORBOOK_COMMENT = "EDIT_FLOORBOOK_COMMENT";
export const EDIT_FLOORBOOK_PAGE_COMMENT = "EDIT_FLOORBOOK_PAGE_COMMENT";
export const GET_FLOORBOOK_PAGE_DATA_BY_ID = "GET_FLOORBOOK_PAGE_DATA_BY_ID";
export const DELETE_COMMENTS_FLOORBOOK_PAGE = "DELETE_COMMENTS_FLOORBOOK_PAGE";
export const DELETE_FLOORBOOK_PAGE = "DELETE_FLOORBOOK_PAGE";
export const UPDATE_FLOORBOOK_JOURNAL = "UPDATE_FLOORBOOK_JOURNAL";
export const HIDE_UNHIDE_FLOORBOOK = "HIDE_UNHIDE_FLOORBOOK"; // action to make private , public floorbook
// useful resources actions
export const GET_USEFUL_RESOURCES_DATA = "GET_USEFUL_RESOURCES_DATA";
export const SET_USEFUL_RESOURCES_DATA = "SET_USEFUL_RESOURCES_DATA";
export const ADD_USEFUL_RESOURCE = "ADD_USEFUL_RESOURCE";
export const IS_ADMIN = "IS_ADMIN";
export const GET_USEFUL_RESOURCE_DETAIL = "GET_USEFUL_RESOURCE_DETAIL";
export const SET_USEFUL_RESOURCE_DETAIL = "SET_USEFUL_RESOURCE_DETAIL";
export const DELETE_USEFUL_RESOURCE = "DELETE_USEFUL_RESOURCE";
export const EDIT_USEFUL_RESOURCE = "EDIT_USEFUL_RESOURCE";
export const GET_RESOURCE_BY_ID = "GET_RESOURCE_BY_ID";
// Report actions
export const GET_REPORT_REQUEST = "GET_REPORT_REQUEST";
export const GET_REPORT_FLOORBOOK = "GET_REPORT_FLOORBOOK";
export const SAVE_COMPLETION_DATA = "SAVE_COMPLETION_DATA";
export const SET_REPORTS_SUMMARY = "SET_REPORTS_SUMMARY";
export const SET_REPORTS_HEADING = "SET_REPORTS_HEADING";
export const GET_REPORTS_HEADING = "GET_REPORTS_HEADING";
//History actions
export const GET_LIKES_COMMENT_HISTORY = "GET_LIKES_COMMENT_HISTORY";
export const GET_LIKES = "GET_LIKES";
export const GET_COMMENTS_HISTORY = "GET_COMMENTS_HISTORY";
export const SET_ALL_HISTORY = "SET_ALL_HISTORY";
export const SET_LIKES_HISTORY = "SET_LIKES_HISTORY";
export const SET_COMMENTS_HISTORY = "SET_COMMENTS_HISTORY";
export const LAST_OPENED_TAB = "LAST_OPENED_TAB";
// Profile actions
export const GET_USER_PROFILE = "GET_USER_PROFILE";
export const UPDATE_USER_PROFILE = "UPDATE_USER_PROFILE";
export const CHANGE_PASSWORD = "CHANGE_PASSWORD";

//Notification Actions
export const NOTICATION_STATUS = "NOTICATION_STATUS";
export const NOTICATION_SOUND = "NOTICATION_SOUND";
export const GET_NOTIFICATION_LIST = "GET_NOTIFICATION_LIST";

export const SET_NOTIFICATION_LIST = "SET_NOTIFICATION_LIST";

export const STOP_NOTIFICATION_LOADER = "STOP_NOTIFICATION_LOADER";
export const START_NOTIFICATION_LOADER = "START_NOTIFICATION_LOADER";

export const SET_NOTIFICATION_STATUS = "SET_NOTIFICATION_STATUS";
export const SET_NOTIFICATION_SOUND = "SET_NOTIFICATION_SOUND";
export const SET_NOTIFICATION_COUNT = "SET_NOTIFICATION_COUNT";
export const CLEAR_NOTIFICATIONS = "CLEAR_NOTIFICATIONS";

export const UPDATE_NOTIFICATION_READ_STATUS = "UPDATE_NOTIFICATION_READ_STATUS";
export const GET_UNREAD_NOTIFICATION_COUNT = "GET_UNREAD_NOTIFICATION_COUNT";

export const GET_NOTIFICATION_SETTING = "GET_NOTIFICATION_SETTING";
export const SET_NOTIFICATION_UNREAD_COUNT = "SET_NOTIFICATION_UNREAD_COUNT";
//Help Page Actions
export const Get_HELP_DATA = "Get_HELP_DATA";
export const SET_HELP_DATA = "SET_HELP_DATA";

//Journal Actions
export const ADD_NEW_JOURNAL_ENTRY = "ADD_NEW_JOURNAL_ENTRY";
export const UPDATE_JOURNAL = "UPDATE_JOURNAL";
export const GET_MY_JOURNALS = "GET_MY_JOURNALS";
export const GET_ALL_JOURNALS = "GET_ALL_JOURNALS";
export const GET_JOURNAL_BY_JOURNAL_ID = "GET_JOURNAL_BY_JOURNAL_ID";
export const DELETE_JOURNAL_ENTRY = "DELETE_JOURNAL_ENTRY";
export const EDIT_JOURNAL_ENTRY = "EDIT_JOURNAL_ENTRY";
export const SET_FAVOURITE_JOURNAL = "SET_FAVOURITE_JOURNAL";
export const GET_FAVOURITE_JOURNALS = "GET_FAVOURITE_JOURNALS";
export const LIKE_JOURNAL = "LIKE_JOURNAL";

//line of inquiry actions

export const UPDATE_DELETE_LINE_OF_INQUIRY = "UPDATE_DELETE_LINE_OF_INQUIRY";

//get profile details
export const GET_USER_PROFILE_DETAILS = "GET_USER_PROFILE_DETAILS";
//scroll positions
export const HOME_PAGE_POSITION = "HOME_PAGE_POSITION";
export const FLOORBOOK_PAGE_POSITION = "FLOORBOOK_PAGE_POSITION";
export const FLOORBOOK_DETAIL_PAGE_POSITION = "FLOORBOOK_DETAIL_PAGE_POSITION";
//prev next posts
export const FETCH_FLOORBOOK_BY = "FETCH_FLOORBOOK_BY";

// search actions
