import { connect } from "react-redux";
import Screen from "./screen";
import {
  getDropDownData,
  signUpForNewUser,
  goBackToSignUPFirst,
  saveSignUpFormTwo,
  getScottishLocalAuthorities,
} from "../../../../redux/actions";

const mapStateToProps = (state) => {
  return {
    state,
    loading: state.CommonReducer.isLoading,
    allCountries: state.SignUpReducer.allCountries,
    allCities: state.SignUpReducer.allCities,
    allSettingTypes: state.SignUpReducer.allSettingTypes,
    SignUpFormOneData: state.SignUpReducer.Form1,
    goBackToSignUpFirst: state.LoginReducer.goBackToSignUpFirst,
    scottishLocalAuthority: state.SignUpReducer.scottishLocalAuthority,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getDropDownData: (callback) => dispatch(getDropDownData(callback)),
    signUpForNewUser: (data, callback) => dispatch(signUpForNewUser(data, callback)),
    goBackToSignUPFirst: (data) => dispatch(goBackToSignUPFirst(data)),
    saveSignUpFormTwo: (data) => dispatch(saveSignUpFormTwo(data)),
    getScottishLocalAuthorities: (callback) => dispatch(getScottishLocalAuthorities(callback)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Screen);
