import React, { useEffect, useState } from "react";
import "./styles.scss";
import { DisplayPost, Loader ,ImageOverlay,ProfileModal} from "../../../../components";
import { withSnackbar } from "notistack";
import {timeConverter} from '../../../../shared/services/Utility'
import { HEAD_TITLES, STATUS } from "../../../../shared/constants";
import InfiniteScroll from "react-infinite-scroll-component";
import ReactLoading from "react-loading";
import moment from "moment";

const Screen = ({
  getAllFloorbooksList,
  loading,
  enqueueSnackbar,
  pagePosition,
  Page_Position,
  fetchFloorboookOrder,
  LatestPosts,
  pageNoOfLatestPost,
  savepageNoOfLatestPosts,
  totalLatestPosts,
  savePageNo,
  pageNoHome,
  setEmptyDataForPosts,
  notificationList,
  notificationLoader,
  getUserProfileDetails = () => {},
  get_Notification = () => {},
  getUnreadNotificationCount,
}) => {
  const [pageNo, set_pageNo] = useState(1);
  const [totalPages, set_totalPages] = useState(1);
  const [showLatestPosts, set_showLatestPosts] = useState(true);
  const [showFloorbook, set_showFloorbook] = useState(false);
  const [showJournals, set_showJournals] = useState(false);
  const [allFloorbooks, set_allFloorbooks] = useState([]);
  const [showLatestComments, set_showLatestComments] = useState(false);
  const [previousPositionOfPage, set_previousPositionOfPage] = useState("");
  const [loader, setLoader] = useState(false);
  const [list, setList] = useState([]);
  const [userData, setData] = useState('');
  const [postDays, setPostDays] = useState('');
  const fetchData = (pageNo) => {
    if (pageNo > totalPages || pageNo < 1) {
      return;
    }
    getAllFloorbooksList(pageNo, (data, type) => {
      if (type === STATUS.error) {
        enqueueSnackbar(data, {
          variant: type,
          autoHideDuration: 3000,
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "left",
          },
        });
      }
    });
  };
  useEffect(() => {
    let today = 0;
    let yesterday = 0;
    let earlierDay = 0;
    list.map(item => {
      today = moment(Number(item.created_at)).format("DD MMM YYYY") == moment(new Date()).format("DD MMM YYYY") ? today + 1 : today
      yesterday = moment(Number(item.created_at)).add(1, "day").format("DD MMM YYYY") == moment(new Date()).format("DD MMM YYYY") ? yesterday + 1 : yesterday
      earlierDay = moment(Number(item.created_at)).format("DD MMM YYYY") == moment(new Date()).format("DD MMM YYYY") || moment(Number(item.created_at)).add(1, "day").format("DD MMM YYYY") == moment(new Date()).format("DD MMM YYYY") ? earlierDay : earlierDay + 1
    })
    setPostDays({today:today,yesterday:yesterday,earlierDay:earlierDay})
  },[list.length])
  //hit the api when pageNo update
  useEffect(() => {
    if (pageNoHome == pageNoOfLatestPost) {
      // if pageNo of the page and the last pageNo for which the api hits match than return
      return;
    } else if (pageNo && allFloorbooks.length !== totalPages && pageNoHome != pageNoOfLatestPost) {
      fetchData(pageNo);
    }
  }, [pageNo]);
//   const fetchUserProfile = (id) => {
//       getUserProfileDetails(id, (data, type) => {
//    if (type === STATUS.success) {
//      setData({...userData,[id]:data});
//    }
//    if (type === STATUS.error) {
//      enqueueSnackbar(data, {
//        variant: type,
//        autoHideDuration: 3000,
//        anchorOrigin: {
//          vertical: "bottom",
//          horizontal: "left",
//        },
//      });
//    }
//  });
//   }
  const fetchLatestActivites = () => {
    get_Notification((message, type) => {
      enqueueSnackbar(message, {
        variant: type,
        autoHideDuration: 3000,
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "left",
        },
      });
    });
  };
  useEffect(() => {
    getUnreadNotificationCount((message, type) => {
      console.log("measasase in app.js", message);
    });
  }, []);
  useEffect(() => {
    if (showLatestComments) {
      fetchLatestActivites();
    }
  }, [showLatestComments]);

  useEffect(() => {
    set_totalPages(totalLatestPosts);
    set_allFloorbooks([...LatestPosts]);
    set_pageNo(pageNoHome);
  }, [LatestPosts, pageNoHome, totalLatestPosts]);

  //if hasMore true than update the pageNo
  const updatePageNo = () => {
    if (allFloorbooks.length !== totalPages) {
      savePageNo(pageNoHome + 1);
    }
  };
  // save the page position , so that when user came back on this page , same post showed
  const savePagePosition = (data) => {
    pagePosition(data);
  };
  //scroll to the post from which user had redirected to detail page
  var rowToScrollTo = Page_Position && document.getElementById(Page_Position);

  useEffect(() => {
    if (Page_Position && rowToScrollTo) {
      window.onload = init();
    }
  }, [rowToScrollTo]);

  const init = () => {
    var d = document.getElementById("content-wrapper");
    rowToScrollTo.scrollIntoView();
  };
  const styless = {
    cancelBtnWrapper: {
      position: "absolute",
      right: 15,
      top: 15,
      cursor: "pointer",
    },
    cancelIcon: {
      color: "#fff",
      fontSize: 30,
    },
    linkStyle: {
      // cursor: "pointer",
      // margin: 15,
      // padding: 5,
    },
  };

  const refresh = () => {
    setEmptyDataForPosts([]);
    set_allFloorbooks([]);
    savePageNo(1);
    savepageNoOfLatestPosts(1);
    fetchData(1);
  };

  useEffect(() => {
    setList((state) => {
      if (notificationList && notificationList.data && notificationList.data.length) {
        notificationList.data.sort((a, b) => b.created_at.localeCompare(a.created_at));
        return notificationList.data;
      }
      return [];
    });
  }, [notificationList]);

  useEffect(() => {
    setLoader(notificationLoader);
  }, [notificationLoader]);
  console.log("USERS DATA", postDays)
  console.log(
    "today date",moment(new Date()).format("DD MMM YYYY")
  );
  const renderData = () => {
    return (
      <>
        <div className="card-body">
          {showLatestPosts ? (
            allFloorbooks.length ? (
              allFloorbooks.map((item, index) => (
                <DisplayPost
                  key={index}
                  id={item.id}
                  title={item.title}
                  name={item.floorbook_name}
                  post_id={item.floorbook_id}
                  images={item.image}
                  location_activity={item.location_activity}
                  description={item.description}
                  improvement={item.improvement}
                  inquiry_line={item.inquiry_line}
                  activity_from={item.activity_from}
                  activity_to={item.activity_to}
                  floorbookPagePost={true}
                  likes={item.total_count}
                  likeStatus={item.like_status}
                  likedUsers={item.user_likes}
                  username={item.username}
                  pageType={0}
                  city={item.city}
                  country={item.country}
                  setting_name={item.setting_name}
                  createdAt={item.created}
                  comments={item.total_comments}
                  user_image={item.user_image}
                  subject={item.subject}
                  user_id={item.user_id}
                  savePagePosition={savePagePosition}
                  fetchFloorboookOrder={fetchFloorboookOrder}
                />
              ))
            ) : (
              <h6 className="text-center p-5">Loading ....</h6>
            )
          ) : null}
          {showLatestComments ? (
            <>
              {notificationLoader ? (
                <div className="notification-loader">
                  <ReactLoading
                    type={"spinningBubbles"}
                    color={"#0d532d"}
                    height={"50px"}
                    width={"50px"}
                  />
                </div>
              ) : (
                <>
                  <>
                    <div>
                      {" "}
                      <h6>Today</h6>
                      {list.map((item, index) => {
                        return (
                          <>
                            <div key={index}
                              className="today"
                              style={{
                                display:
                                  moment(Number(item.created_at)).format(
                                    "DD MMM YYYY"
                                  ) ==
                                  moment(new Date()).format(
                                    "DD MMM YYYY"
                                  )
                                    ? "flex"
                                    : "none",
                                padding: "10px",
                                flexDirection: "row",
                                //   alignItems: "center",
                              }}
                            >
                              <div
                                style={{
                                  marginRight: "10px",
                                  marginTop: "3px",
                                }}
                              >
                                <img
                                  src={
                                    item.profile_pic
                                      ? item.profile_pic
                                      : require("../../../../assets/images/no-user.png")
                                  }
                                  alt="img"
                                  style={{
                                    borderRadius: "50%",
                                    width: "40px",
                                    height: "40px",
                                  }}
                                ></img>
                              </div>
                              <div>
                                <span>
                                  <span>{item.username}</span>
                                </span>
                                <span> {item.title}</span>
                                <span>( {item.name} )</span>
                                <p>
                                  {moment(Number(item.created_at)).format(
                                    "DD MMM YYYY, hh:mm A"
                                  )}
                                </p>
                              </div>
                            </div>
                          </>
                        );
                      })}
                    </div>
                    {postDays.today == 0 ? (
                      <div>
                        <p>No comments and likes for today</p>
                      </div>
                    ) : null}
                  </>
                  <>
                    <div>
                      {" "}
                      <h6>Yesterday</h6>
                      {list.map((item,index) => {
                        return (
                          <>
                            <div key={index}
                              className="yesterday"
                              style={{
                                display:
                                  moment(Number(item.created_at))
                                    .add(1, "day")
                                    .format("DD MMM YYYY") ==
                                  moment(new Date()).format(
                                    "DD MMM YYYY"
                                  )
                                    ? "flex"
                                    : "none",
                                padding: "10px",
                                flexDirection: "row",
                                //   alignItems: "center",
                              }}
                            >
                              <div
                                style={{
                                  marginRight: "10px",
                                  marginTop: "3px",
                                }}
                              >
                                <img
                                  src={
                                    item.profile_pic
                                      ? item.profile_pic
                                      : require("../../../../assets/images/no-user.png")
                                  }
                                  alt="img"
                                  style={{
                                    borderRadius: "50%",
                                    width: "40px",
                                    height: "40px",
                                  }}
                                ></img>
                              </div>
                              <div>
                                <span>
                                  <span>{item.username}</span>
                                </span>
                                <span> {item.title}</span>
                                <span>( {item.name} )</span>
                                <p>
                                  {moment(Number(item.created_at)).format(
                                    "DD MMM YYYY, hh:mm A"
                                  )}
                                </p>
                              </div>
                            </div>
                          </>
                        );
                      })}
                    </div>
                    {postDays.yesterday == 0 ? (
                      <div style={{paddingBottom:'10px'}}>No comments and likes for yesterday</div>
                    ) : null}
                  </>
                  <>
                    <div>
                      {" "}
                      <h6>Earlier days</h6>
                      {list.map((item,index) => {
                        return (
                          <>
                            <div key={index}
                              className="earlier days"
                              style={{
                                display:
                                  moment(Number(item.created_at)).format(
                                    "DD MMM YYYY"
                                  ) ==
                                    moment(new Date()).format(
                                      "DD MMM YYYY"
                                    ) ||
                                  moment(Number(item.created_at))
                                    .add(1, "day")
                                    .format("DD MMM YYYY") ==
                                    moment(new Date()).format(
                                      "DD MMM YYYY"
                                    )
                                    ? "none"
                                    : "flex",
                                padding: "10px",
                                flexDirection: "row",
                                //   alignItems: "center",
                              }}
                            >
                              <div
                                style={{
                                  marginRight: "10px",
                                  marginTop: "3px",
                                }}
                              >
                                <img
                                  src={
                                    item.profile_pic
                                      ? item.profile_pic
                                      : require("../../../../assets/images/no-user.png")
                                  }
                                  alt="img"
                                  style={{
                                    borderRadius: "50%",
                                    width: "40px",
                                    height: "40px",
                                  }}
                                ></img>
                              </div>
                              <div>
                                <span>
                                  <span>{item.username}</span>
                                </span>
                                <span> {item.title}</span>
                                <span>( {item.name} )</span>
                                <p>
                                  {moment(Number(item.created_at)).format(
                                    "DD MMM YYYY, hh:mm A"
                                  )}
                                </p>
                              </div>
                            </div>
                          </>
                        );
                      })}
                    </div>
                    {postDays.earlierDay == 0 ? (
                      <div>No comments and likes for earlier Day</div>
                    ) : null}
                  </>
                </>
              )}
            </>
          ) : null}
        </div>
      </>
    );
  };

  return (
    <>
      <div className="card-header d-flex flex-column-reverse flex-xl-row justify-content-center justify-content-xl-between align-items-center">
        <div className="breadcrumbs d-flex">{/* <h4 className="mb-0">{HEAD_TITLES.HOME}</h4> */}</div>
      </div>
      <ul className="list-unstyled d-flex flex-wrap card_tab">
        <li
          style={{
            ...styless.linkStyle,
            ...(showLatestPosts ? { backgroundColor: "#0D532D", color: "white", cursor: "default" } : null),
          }}
          onClick={() => {
            set_showLatestPosts(true);
            set_showLatestComments(false);
          }}
        >
          {HEAD_TITLES.LATEST_POSTS}
        </li>
        {/* <li
          style={{
            ...styless.linkStyle,
            ...(showFloorbook ? { backgroundColor: "#0D532D", color: "white" } : null),
          }}
          onClick={() => {
          
            set_showFloorbook(true);
            set_showJournals(false);
          }}
        >
          {HEAD_TITLES.FLOORBOOK}
        </li> */}
        <li
          style={{
            ...styless.linkStyle,
            ...(showLatestComments ? { backgroundColor: "#0D532D", color: "white" } : null),
          }}
          onClick={() => {
            set_showLatestPosts(false);
            set_showLatestComments(true);
          }}
        >
          {HEAD_TITLES.LATEST_COMMENTS+"/Likes"}
        </li>
      </ul>
      <InfiniteScroll
        dataLength={allFloorbooks.length} //This is important field to render the next data
        next={updatePageNo}
        hasMore={pageNo < totalPages ? true : false} //if totalPages is more than the pageNo it returns true and next function called
        pullDownToRefresh
        refreshFunction={refresh}
        pullDownToRefreshThreshold={10}
        pullDownToRefreshContent={<p style={{ textAlign: "center" }}>Pull down to refresh</p>}
        releaseToRefreshContent={<p style={{ textAlign: "center" }}>Release to refresh</p>}
      >
        {renderData()}
      </InfiniteScroll>

      {loading && <Loader />}
    </>
  );
};
export default withSnackbar(Screen);
