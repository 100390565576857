import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { connect } from 'react-redux'
import AuthRoutes from './auth-routes';
import AuthenticatedRoutes from './authenticated-routes'


const RootRouter = ({
    access_token
}) => {
    return (
        <BrowserRouter>
            {access_token ?
                <AuthenticatedRoutes /> : <AuthRoutes />}
        </BrowserRouter>


    )
}

const mapStateToProps = state => {
    return {
        access_token: state.CommonReducer.access_token
    };
};

export default connect(mapStateToProps)(RootRouter);


