import { connect } from "react-redux";
import Screen from "./screen";
import { saveSignUPFormOne } from "../../../../redux/actions";

const mapStateToProps = (state) => {
  return {
    state,
    loading: state.CommonReducer.isLoading,
    captcha: state.CommonReducer.captcha,
    goBackToSignUpFirst: state.LoginReducer.goBackToSignUpFirst,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    saveSignUPFormOne: (data) => dispatch(saveSignUPFormOne(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Screen);
