import { DialogActions, DialogContent } from "@material-ui/core";
import React from "react";
import { Field } from "redux-form";
import { STRINGS, PRIVACY_STATUS_LABELS, BUTTON_LABELS } from "../../../shared/constants";
import { CustomButton, Form, Input, RadioButtons, TextArea } from "../../atoms";
import UploadImage from "../UploadImage";
import ReactLoading from "react-loading";

export const NewJournalForm = ({
  handleSubmit,
  journalCoverImage,
  set_journalCoverImage,
  journalSignInImage,
  set_journalSignInImage,
  onDeleteCoverImage,
  onDeleteSignInImage,
  formType,
  submit,
  setErrors,
  errors,
  showError,
  details,
  loader,
}) => {
  return (
    <Form onSubmit={handleSubmit(submit)}>
      <DialogContent>
        <div className="row">
          <div className="col-12">
            <div className="form-group">
              <label>{STRINGS.JOURNAL_NAME_LABEL}</label>
              <Field
                name={STRINGS.JOURNAL_NAME_INPUT}
                component={Input}
                placeholder={STRINGS.JOURNAL_NAME_PLACEHOLDER}
              />
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-12">
            <label>{STRINGS.JOURNAL_COVER}</label>
            <div className="form-group">
              <UploadImage
                carasol={false}
                imageData={formType ? journalCoverImage : null}
                accept=".jpg, .jpeg, .png"
                onFileChange={(filedata, value) => {
                  set_journalCoverImage(filedata);
                  setErrors({ ...errors, coverImage: "" });
                }}
                multilple={false}
                onDelete={onDeleteCoverImage}
              />
              {showError && <span className="error_msg text-danger">{errors.coverImage}</span>}
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <label>{STRINGS.JOURNAL_SIGN_IN_IMAGE}</label>
            <div className="form-group">
              <UploadImage
                carasol={false}
                imageData={formType ? journalSignInImage : null}
                accept=".jpg, .jpeg, .png"
                onFileChange={(filedata, value) => {
                  set_journalSignInImage(filedata);
                  setErrors({ ...errors, signInImage: "" });
                }}
                multilple={false}
                onDelete={onDeleteSignInImage}
              />
              {showError && <span className="error_msg text-danger">{errors.signInImage}</span>}
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <div className="form-group">
              <label>{STRINGS.JOURNAL_DESCRIPTION_LABEL}</label>
              <Field
                name={STRINGS.JOURNAL_DESCRIPTION_INPUT}
                id="descriptionJournal"
                component={TextArea}
                placeholder={STRINGS.JOURNAL_DESCRIPTION_PLACEHOLDER}
              />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <div className="form-group">
              <label>{STRINGS.JOURNAL_PRIVACY_STATUS_LABEL}</label>
              <Field
                name={STRINGS.JOURNAL_PRIVACY_STATUS_INPUT}
                component={RadioButtons}
                radioGroupItems={PRIVACY_STATUS_LABELS}
                defaultValue={details ? details.privacyStatus : null}
              />
            </div>
          </div>
        </div>
      </DialogContent>
      <DialogActions className="justify-content-center">
        {loader ? (
          <ReactLoading
            type={"spinningBubbles"}
            color={"#0d532d"}
            height={"50px"}
            width={"50px"}
          />
        ) : (
          <CustomButton
            label={formType ? BUTTON_LABELS.SAVE : BUTTON_LABELS.CREATE}
            type="submit"
            className="btn btn-lg btn-primary text-capitalize"
          />
        )}
      </DialogActions>
    </Form>
  );
};
