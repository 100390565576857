import { CHANGE_PASSWORD, GET_USER_PROFILE, UPDATE_USER_PROFILE, GET_USER_PROFILE_DETAILS } from "./ActionTypes";

export const getProfileData = (callback) => {
  return {
    type: GET_USER_PROFILE,
    callback,
  };
};

export const updateProfile = (data, callback) => {
  return {
    type: UPDATE_USER_PROFILE,
    data,
    callback,
  };
};

export const changePassword = (data, callback) => {
  return {
    type: CHANGE_PASSWORD,
    data,
    callback,
  };
};

export const getUserProfileDetails = (data, callback) => {
  return {
    type: GET_USER_PROFILE_DETAILS,
    data,
    callback,
  };
};
