import * as actionTypes from "../actions/ActionTypes";

const initialCommonState = {
  floorbookPageData: [],
};

const FloorbookPageReducer = (state = { ...initialCommonState }, action) => {
  switch (action.type) {
    case actionTypes.SAVE_FLOORBOOK_PAGE_DATA:
      return {
        ...state,
        floorbookPageData: action.data,
      };
    /********************************/

    default:
      return state;
  }
};

export default FloorbookPageReducer;
