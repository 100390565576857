import React from "react";
import { ImageOverlay } from "../../../../components";
import { reduxForm } from "redux-form";
import validator from "./validator";
import { connect } from "react-redux";
import { ROUTE_CONSTANTS } from "../../../../shared/constants";
import { onSubmitFail } from "../../../../helpers/onSubmitFail";
import { LOGO } from "../../../../shared/constants";

import { RenderForm } from "./form";

const SingUpScreen = ({ handleSubmit = () => {}, history, saveCaptcha = () => {}, saveSignUPFormOne }) => {
  const submit = (values) => {
    saveSignUPFormOne(values);
    history.replace(ROUTE_CONSTANTS.SIGNUP_SECOND);
  };

  return (
    <div className="front-forms">
      <div className="container-fluid">
        <div className="login_box">
          <div className="front-logo text-center mb-4">
            <ImageOverlay>
              <img src={LOGO} alt="Inquiry Tracker" />
            </ImageOverlay>
          </div>

          <div className="form-box">
            <RenderForm history={history} handleSubmit={handleSubmit} submit={submit} saveCaptcha={saveCaptcha} />
          </div>
        </div>
      </div>
    </div>
  );
};

const showForm = reduxForm({
  form: "SignUpForm1",
  validate: validator,
  onSubmitFail,
  enableReinitialize: true,
})(SingUpScreen);

const Screen = connect((state) => {
  let values = state.LoginReducer.goBackToSignUpFirst ? state.SignUpReducer.Form1 : null;
  return {
    initialValues: values,
  };
})(showForm);

export default Screen;
