import { connect } from "react-redux";

import Setting from "./screen";
import { notificationStatus, notificationSound, getNotificationSetting } from "../../../../redux/actions";

const mapStateToProps = (state) => {
  return {
    state,
    loading: state.CommonReducer.isLoading,
    status: state.CommonReducer.status,
    sound: state.CommonReducer.sound,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    notificationStatus: (data, callback) => dispatch(notificationStatus(data, callback)),
    notificationSoundSet: (data, callback) => dispatch(notificationSound(data, callback)),
    getNotificationSetting: (callback) => dispatch(getNotificationSetting(callback)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Setting);
