import { connect } from "react-redux";
import CheckNotification from "./screen";

const { ChangeFcmSession } = require(`../../../redux/actions/CommonActions`);

const mapStateToProps = (state) => {
  return {
    state,
    status: state.NotificationReducer.status,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    ChangeFcmSession: (data, callback) => dispatch(ChangeFcmSession(data, callback)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(CheckNotification);
