import { REHYDRATE } from "redux-persist";
import * as actionTypes from "../actions/ActionTypes";

const initialCommonState = {
  access_token: null,
  captcha: "",
  isLoading: false,
  FCMToken: "",
  userData: {
    firstName: "",
    lastName: "",
    email: "",
    user_role: "",
    profile_pic: "",
  },
  goBackToSignUpFirst: false,
  status: true,
  sound: true,
  count: 0,
  Page_Position: "",
  FetchDetailPageBy: "",
};

const { updateAuthToken } = require(`../../helpers/axios`);
const CommonReducer = (state = { ...initialCommonState }, action) => {
  switch (action.type) {
    case actionTypes.SET_USER_DATA:
      return {
        ...state,
        userData: {
          ...state.userData,
          firstName: action.data.first_name,
          lastName: action.data.last_name,
          email: action.data.email,
          user_role: action.data.user_role,
          profile_pic: action.data.profile_pic,
        },
      };

    case actionTypes.FCM_TOKEN:
      return {
        ...state,
        FCMToken: action.data,
      };

    case actionTypes.LOGOUT:
      return {
        ...state,
        access_token: null,
        FCMToken: "",
      };

    case actionTypes.START_LOADING:
      return {
        ...state,
        isLoading: true,
      };
    case actionTypes.STOP_LOADING:
      return {
        ...state,
        isLoading: false,
      };
    case actionTypes.UPDATE_INTERNET_STATUS:
      return {
        ...state,
        isNetConnected: action.isNetConnected,
      };

    case actionTypes.SAVE_CAPTCHA:
      return {
        ...state,
        captcha: action.text,
      };
    case actionTypes.LOGIN_SUCCESS:
      return {
        ...state,
        access_token: action.data,
      };
    case actionTypes.SET_NOTIFICATION_SOUND:
      return {
        ...state,
        sound: action.data,
      };
    case actionTypes.SET_NOTIFICATION_STATUS:
      return {
        ...state,
        status: action.data,
      };

    case actionTypes.SET_NOTIFICATION_COUNT:
      console.log("countt called 1", action.data);
      return {
        ...state,
        count: action.data,
      };
    case actionTypes.SET_NOTIFICATION_UNREAD_COUNT:
      console.log("countt called2", action.data);
      return {
        ...state,
        count: state.count + action.data,
      };
    case actionTypes.FETCH_FLOORBOOK_BY:
      return {
        ...state,
        FetchDetailPageBy: action.data,
      };
    /********************************/
    case REHYDRATE:
      let common = ((action || {}).payload || {}).CommonReducer || initialCommonState;
      updateAuthToken(common.access_token || "");
      return {
        ...state,
        access_token: common.access_token,
        ...(action.payload || {}).CommonReducer,
      };
    default:
      return state;
  }
};

export default CommonReducer;
