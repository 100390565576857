import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";

import React, { useState, useEffect } from "react";

import "date-fns";
import Grid from "@material-ui/core/Grid";
import DateFnsUtils from "@date-io/date-fns";
import "./style.scss";

export const KeyboardDateTimePickerr = ({
  input,
  label,
  meta: { touched, error },
  widthStyle,
  disabled = false,
  defaultValue,
  placeholder,
  openTo,
  onValueChange = () => {},
}) => {
  const [openCalendar, setOpenCalendar] = useState(false);
  const [err, setErr] = useState("");
  widthStyle = widthStyle ? widthStyle : "";
  const validationSpan = touched && error ? <span className="error_msg text-danger">{error}</span> : null;
  const [dateVal, setDateVal] = useState(null);

  useEffect(() => {
    defaultValue && setDateVal(defaultValue); // set the default value in date-picker
  }, [defaultValue]);

  return (
    <>
      {label && <label>{label}</label>}
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <Grid>
          <KeyboardDatePicker
            className="form-control"
            {...input}
            helperText={""}
            error={false}
            margin="normal"
            autoComplete="off"
            mask={"__/__/____"}
            id="date-picker-dialog"
            format="yyyy-MM-dd"
            minDate={new Date("12/31/1920").toString()}
            maxDate={new Date().toString()}
            disabled={disabled}
            value={dateVal}
            openTo={openTo}
            allowKeyboardControl={false}
            placeholder={placeholder}
            KeyboardButtonProps={{
              "aria-label": "change date",
            }}
            InputProps={{
              disabled: true,
              style: { color: "black" },
              onClick: () => {
                !disabled && setOpenCalendar(true);
              },
            }}
            onChange={(value) => {
              input.onChange(value);
              setDateVal(value);
              setErr("");
              setOpenCalendar(false);
              onValueChange(value);
            }}
            onOpen={() => {
              setOpenCalendar(true);
            }}
            onClose={() => {
              setOpenCalendar(false);
            }}
            open={openCalendar}
          />
          {validationSpan}
        </Grid>
      </MuiPickersUtilsProvider>
    </>
  );
};

export default KeyboardDateTimePickerr;
