import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import CustomButton from "../../atoms/CustomButton";
import { useHistory } from "react-router-dom";
import AddNewFloorBookModal from "../AddNewFloorBookModal";
import AddNewJournalModal from "../AddNewJournalModal";
import { ROUTE_CONSTANTS, BUTTON_LABELS, ADD_FLOORBOOK_TYPE, ADD_JORNAL_TYPE } from "../../../shared/constants";

import "./style.scss";

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon style={{ color: "#0D532D" }} />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const AddModal = ({ btnLabel }) => {
  const [open, setOpen] = React.useState(false);
  let history = useHistory();
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <CustomButton
        className="btn btn-md text-capitalize btn-primary"
        label={btnLabel}
        onButtonClick={handleClickOpen}
      />
      <Dialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <DialogTitle id="customized-dialog-title" onClose={handleClose} />
        <MuiDialogContent className="add_floor_box d-flex align-items-center justify-content-center flex-column">
          <CustomButton
            className="w-100 btn btn-lg btn-primary text-capitalize mb-4"
            label={BUTTON_LABELS.ADD_NEW_FLOORBOOK_PAGE}
            onButtonClick={() => {
              handleClose();
              history.push(ROUTE_CONSTANTS.ADD_NEW_FLOORBOOK_PAGE);
            }}
          />
          <AddNewFloorBookModal
            btnLabel={"Add New Floorbook"}
            formType={ADD_FLOORBOOK_TYPE.create}
            onClose={handleClose}
          />{" "}
          <CustomButton
            className="w-100 m-4 btn btn-lg btn-primary text-capitalize"
            label={BUTTON_LABELS.ADD_NEW_LEARNING_JOURNAL_ENTRY}
            onButtonClick={() => {
              history.push(ROUTE_CONSTANTS.ADD_NEW_JOURNAL);
              handleClose();
            }}
          />
          <AddNewJournalModal
            btnLabel={"Add New Group Learning Journal"}
            formType={ADD_JORNAL_TYPE.create}
            onClose={handleClose}
          />{" "}
        </MuiDialogContent>
      </Dialog>
    </>
  );
};
export default AddModal;
