import React, { useEffect } from "react";
import { withStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import ImageOverlay from "../../atoms/ImageOverlay";
import { STRINGS, STATUS } from "../../../shared/constants";
import "./style.scss";
import { getUserProfileDetails } from "../../../redux/actions";
import { connect } from "react-redux";
import { parseToHtml } from "../../../shared/services/Utility";
import ReactLoading from "react-loading";
import { withSnackbar } from "notistack";

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon style={{ color: "#0D532D" }} />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const ProfileModal = ({ user_image, user_id, getUserProfileDetails, loading, enqueueSnackbar }) => {
  const [open, setOpen] = React.useState(false);
  const [profileData, set_profileData] = React.useState({});

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  useEffect(() => {
    if (user_id && open) {
      //hit the api to get profile data  only when modal gets open
      getUserProfileDetails(user_id, (data, type) => {
        if (type === STATUS.success) {
          set_profileData(data);
        }
        if (type === STATUS.error) {
          enqueueSnackbar(data, {
            variant: type,
            autoHideDuration: 3000,
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "left",
            },
          });
        }
      });
    }
  }, [user_id, open]);

  return (
    <>
      <ImageOverlay handleClick={handleClickOpen} cusror={true}>
        <img alt="no-user"
          style={{ cursor: "pointer" }}
          src={user_image ? user_image : require("../../../assets/images/no-user.png")}
          height={40}
          width={40}
          className="img-fluid"
        />
      </ImageOverlay>

      <Dialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={open}>
        <DialogTitle id="customized-dialog-title" onClose={handleClose} />
        <MuiDialogContent className="add_floor_box d-flex align-items-center justify-content-center flex-column">
          <div className="profile_modal">
            <div className="row">
              <div className="col-12 popup_pic text-center">
                <div className="post_pic">
                  <ImageOverlay>
                    <img alt="no-user"
                      src={user_image ? user_image : require("../../../assets/images/no-user.png")}
                      height={40}
                      width={40}
                      className="img-fluid"
                    />
                  </ImageOverlay>
                </div>
                <h6>{profileData.user_name}</h6>
              </div>
            </div>
          </div>
          {profileData && (
            <div className="card-body pt-0">
              <div className="row align-items-center">
                <div className="col-md-3"></div>
              </div>

              <div className="profile_summary">
                <div className="table_grid text-center">
                  <div className="row">
                    <div className="col-sm-6 col-12">
                      <div class="bio_label">
                        <label>Type of Setting</label>
                        <span>{profileData.setting_type}</span>
                      </div>
                    </div>
                    <div className="col-sm-6 col-12">
                      <div class="bio_label">
                        <label>Name of Setting</label>
                        <span>{profileData.setting_name}</span>
                      </div>
                    </div>
                    <div className="col-sm-6 col-12">
                      <div class="bio_label">
                        <label>Country</label>
                        <span>{profileData.country}</span>
                      </div>
                    </div>
                    <div className="col-sm-6 col-12">
                      <div class="bio_label">
                        <label>City</label>
                        <span>{profileData.city}</span>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-12 text-left">
                    <label>{STRINGS.DESCRIPTION_OF_CLASS_AND_AGE_LABEL}</label>
                    <p dangerouslySetInnerHTML={{ __html: parseToHtml(profileData.description) }}></p>
                  </div>
                </div>
              </div>
            </div>
          )}
          {loading ? <ReactLoading type={"spinningBubbles"} color={"#0d532d"} height={"50px"} width={"50px"} /> : null}
        </MuiDialogContent>
      </Dialog>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    state,
    loading: state.CommonReducer.isLoading,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getUserProfileDetails: (data, callback) => dispatch(getUserProfileDetails(data, callback)),
  };
};
const Modal = connect(mapStateToProps, mapDispatchToProps)(ProfileModal);
export default withSnackbar(Modal);
