import firebase from "firebase/app";
import "firebase/messaging";
import { firebaseConfig } from "./firebaseConfig";
let safariAgent = navigator.userAgent.indexOf("Safari") > -1;
let chromeAgent = navigator.userAgent.indexOf("Chrome") > -1;

if (chromeAgent && safariAgent) {
  safariAgent = false;
}

let messaging = "";
if (!safariAgent) {
  const initializedFirebaseApp = firebase.initializeApp({
    // Project Settings => Add Firebase to your web app
    apiKey: firebaseConfig.apiKey,
    authDomain: firebaseConfig.authDomain,
    databaseURL: firebaseConfig.databaseURL,
    projectId: firebaseConfig.projectId,
    storageBucket: firebaseConfig.storageBucket,
    messagingSenderId: firebaseConfig.messagingSenderId,
    appId: firebaseConfig.appId,
    measurementId: firebaseConfig.measurementId,
  });
  messaging = initializedFirebaseApp.messaging();
  messaging.usePublicVapidKey(
    //     // Project Settings => Cloud Messaging => Web Push certificates
    firebaseConfig.usePublicVapidKey
  );
  // messaging.onMessage((payload) => console.log('Message received. ', payload));
}
export { messaging };

export const requestFirebaseNotificationPermission = () =>
  new Promise((resolve, reject) => {
    messaging
      .requestPermission()
      .then(() => messaging.getToken())
      .then((firebaseToken) => {
        console.log("firebase Token", firebaseToken);
        resolve(firebaseToken);
      })
      .catch((err) => {
        reject(err);
      });
  });
