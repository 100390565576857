import React, { useEffect, useState } from "react";
import { createCaptcha } from "./capctha-generator";
import "./style.scss";
import TextField from "@material-ui/core/TextField";

//component used to generate captcha on signup Form
const CaptchaInput = ({
  input,
  label,
  meta: { touched, error },
  config,
  placeholder,
  widthStyle,
  style,
  data,
  REGENERATE_CAPCTHA,
  savedCaptcha,
  saveCaptcha = () => {},
  ...props
}) => {
  useEffect(() => {
    createCaptcha();
  }, []);
  const validationSpan = touched && error ? <span className="error_msg text-danger">{error}</span> : null;
  const result = (text) => {
    saveCaptcha(text);
  };
  const [captcha, updateCaptcha] = useState("");

  return (
    <>
      <div className="row">
        <div className="col-md-6 col-6 input_fields">
          {label && <label>{label}</label>}
          <TextField
            className="form-control"
            label={placeholder}
            id={placeholder}
            {...input}
            {...config}
            maxLength={60}
            onChange={(e) => {
              input.onChange(e.target.value);
              updateCaptcha(e.target.value);
              if (captcha.length == 5) {
                if (savedCaptcha == captcha) console.log("matchedddd=>>>>");
              }
            }}
            autoComplete="off"
          />
          {validationSpan}
        </div>
        <div className="col-md-6 col-6">
          <div className="captcha_field" style={{ overflow: "visible" }}>
            <div className="btn bg_green d-flex align-items-center flex-row" type="button">
              <span id={"captcha"} className="captcha_code"></span>
              <i>
                <img alt="captcha"
                  src={REGENERATE_CAPCTHA}
                  height="20px"
                  onClick={() => {
                    createCaptcha(); // creates captcha
                  }}
                />
              </i>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CaptchaInput;
