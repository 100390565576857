import React from "react";
import { Route, Redirect, Switch } from "react-router-dom";

const { LoginScreen, SignUpFirstScreen, Home, SignUpSecondScreen, ForgotPasswordScreen } = require(`../Screens`);
const { ROUTE_CONSTANTS } = require(`../shared/constants`);
const AuthRouter = (props) => {
  return (
    <Switch>
      <Route path={ROUTE_CONSTANTS.LOGIN} component={LoginScreen} />
      <Route path={ROUTE_CONSTANTS.SIGNUP_FIRST} component={SignUpFirstScreen} />
      <Route path={ROUTE_CONSTANTS.SIGNUP_SECOND} component={SignUpSecondScreen} />
      <Route path={ROUTE_CONSTANTS.FORGOT_PASSWORD} component={ForgotPasswordScreen} />
      <Route path={ROUTE_CONSTANTS.DASHBOARD} component={Home} />
      <Redirect to={ROUTE_CONSTANTS.LOGIN} />
    </Switch>
  );
};

export default AuthRouter;
