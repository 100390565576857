import {
  GET_REPORT_REQUEST,
  GET_REPORT_FLOORBOOK,
  SAVE_COMPLETION_DATA,
  SET_REPORTS_SUMMARY,
  SET_REPORTS_HEADING,
  GET_REPORTS_HEADING,
} from "./ActionTypes";

export const reportGet = (data, callback) => {
  return {
    type: GET_REPORT_REQUEST,
    data,
    callback,
  };
};

export const reportFloorbook = (callback) => {
  return {
    type: GET_REPORT_FLOORBOOK,
    callback,
  };
};
export const saveCompletionData = (data, callback) => {
  return {
    type: SAVE_COMPLETION_DATA,
    data,
    callback,
  };
};

export const setReportsSummary = (data, callback) => {
  return {
    type: SET_REPORTS_SUMMARY,
    data,
    callback,
  };
};

export const setReportsHeading = (data, callback) => {
  return {
    type: SET_REPORTS_HEADING,
    data,
    callback,
  };
};

export const getReportsHeading = (data, callback) => {
  return {
    type: GET_REPORTS_HEADING,
    data,
    callback,
  };
};
