import { ADD_FLOORBOOK_PAGE, VALIDATION_MESSAGES } from "../../../../../shared/constants";

const validator = (values) => {
  const errors = {};

  if (values[ADD_FLOORBOOK_PAGE.FLOORBOOK_INPUT]) {
    if (!values[ADD_FLOORBOOK_PAGE.FLOORBOOK_INPUT].value) {
      errors[ADD_FLOORBOOK_PAGE.FLOORBOOK_INPUT] = VALIDATION_MESSAGES.FLOORBOOK_REQUIRED;
    }
  }
  if (!values[ADD_FLOORBOOK_PAGE.TITLE_INPUT]) {
    errors[ADD_FLOORBOOK_PAGE.TITLE_INPUT] = VALIDATION_MESSAGES.PAGE_TITLE_REQUIRED;
  }

  if (values[ADD_FLOORBOOK_PAGE.SUBJECT_INPUT]) {
    if (!values[ADD_FLOORBOOK_PAGE.SUBJECT_INPUT].value) {
      errors[ADD_FLOORBOOK_PAGE.SUBJECT_INPUT] = VALIDATION_MESSAGES.LINE_OF_INQUIRY_REQUIRED;
    }
  }

  if (values[ADD_FLOORBOOK_PAGE.LINE_OF_INQUIRY_INPUT]) {
    if (!values[ADD_FLOORBOOK_PAGE.LINE_OF_INQUIRY_INPUT].value) {
      errors[ADD_FLOORBOOK_PAGE.LINE_OF_INQUIRY_INPUT] = VALIDATION_MESSAGES.LINE_OF_INQUIRY_REQUIRED;
    }
  }
  if (!values[ADD_FLOORBOOK_PAGE.OTHER_TYPE_INPUT]) {
    errors[ADD_FLOORBOOK_PAGE.OTHER_TYPE_INPUT] = VALIDATION_MESSAGES.OTHER_LINE_OF_INQUIRY_REQUIRED;
  }

  if (!values[ADD_FLOORBOOK_PAGE.START_DATE_INPUT]) {
    errors[ADD_FLOORBOOK_PAGE.START_DATE_INPUT] = VALIDATION_MESSAGES.FROM_DATE_REQUIRED;
  }
  if (!values[ADD_FLOORBOOK_PAGE.END_DATE_INPUT]) {
    errors[ADD_FLOORBOOK_PAGE.END_DATE_INPUT] = VALIDATION_MESSAGES.END_DATE_REQUIRED;
  }
  if (!values[ADD_FLOORBOOK_PAGE.DESCRIPTION_INPUT]) {
    errors[ADD_FLOORBOOK_PAGE.DESCRIPTION_INPUT] = VALIDATION_MESSAGES.DESCRIPTION_REQUIRED;
  }
  if (!values[ADD_FLOORBOOK_PAGE.LOCATION_INPUT]) {
    errors[ADD_FLOORBOOK_PAGE.LOCATION_INPUT] = VALIDATION_MESSAGES.LOCATION_REQUIRED;
  }
  if (!values[ADD_FLOORBOOK_PAGE.SUBJECT_INPUT]) {
    errors[ADD_FLOORBOOK_PAGE.SUBJECT_INPUT] = VALIDATION_MESSAGES.SUBJECT_REQUIRED;
  }
  if (!values[ADD_FLOORBOOK_PAGE.REFLECTIONS_INPUT]) {
    errors[ADD_FLOORBOOK_PAGE.REFLECTIONS_INPUT] = VALIDATION_MESSAGES.REFLECTIONS_REQUIRED;
  }
  if (!values[ADD_FLOORBOOK_PAGE.START_DATE_INPUT] && values[ADD_FLOORBOOK_PAGE.END_DATE_INPUT]) {
    errors[ADD_FLOORBOOK_PAGE.END_DATE_INPUT] = VALIDATION_MESSAGES.SELECT_START_TIME_FIRST;
  }
  if (values[ADD_FLOORBOOK_PAGE.START_DATE_INPUT] && values[ADD_FLOORBOOK_PAGE.END_DATE_INPUT]) {
    if (new Date(values[ADD_FLOORBOOK_PAGE.END_DATE_INPUT]) < new Date(values[ADD_FLOORBOOK_PAGE.START_DATE_INPUT])) {
      errors[ADD_FLOORBOOK_PAGE.START_DATE_INPUT] = VALIDATION_MESSAGES.START_TIME_SHOULD_BE_AHEAD;
    }
  }

  return errors;
};

export default validator;
