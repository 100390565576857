import React, { useState, useEffect } from "react";
import { Loader, DisplayHistoryPost } from "../../../../components";
import { withSnackbar } from "notistack";
import { HEAD_TITLES, STATUS } from "../../../../shared/constants";
import InfiniteScroll from "react-infinite-scroll-component";
import { set } from "date-fns";

const Screen = ({
  loading,
  getAllHistory,
  getLikesHistory,
  getCommentsHistory,
  enqueueSnackbar,
  fetchFloorboookOrder,
  allCommentsHistory = [],
  allLikesHistory = [],
  allLikeCommentsHistory = [],
  setLastOpenedTab = () => {},
  lastOpenedTab = "",
}) => {
  const [showAll, set_showAll] = useState(true);
  const [showLikes, set_showLikes] = useState(false);
  const [showComments, set_showComments] = useState(false);
  const [allLikeComments, set_allLikeComments] = useState([]);
  const [allLikes, set_allLikes] = useState([]);
  const [allComments, set_allComments] = useState([]);
  const [pageNo, set_pageNo] = useState({ all: 1, likes: 1, comments: 1 });
  const [totalPages, set_totalPages] = useState({ all: 1, likes: 1, comments: 1 });

  //calls api to fetch both likes and comments
  const fetchAllLikesAndComments = (pageNo) => {
    getAllHistory(pageNo, (data, type) => {
      if (type === STATUS.error) {
        enqueueSnackbar(data, {
          variant: type,
          autoHideDuration: 3000,
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "left",
          },
        });
      } else {
        set_totalPages((state) => {
          return {
            ...state,
            all: Math.ceil(data.count / 10),
          };
        });
        // set_allLikeComments([...allLikeComments, ...allLikeCommentsHistory]);
      }
    });
  };

  //calls api to fetch likes
  const fetchAllLikes = (pageNo) => {
    getLikesHistory(pageNo, (data, type) => {
      if (type === STATUS.error) {
        enqueueSnackbar(data, {
          variant: type,
          autoHideDuration: 3000,
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "left",
          },
        });
      } else {
        set_totalPages((state) => {
          return {
            ...state,
            likes: Math.ceil(data.count / 10),
          };
        });
        // set_allLikes([...allLikes, ...allLikesHistory]);
      }
    });
  };
  /**** save the last history tab user had opened, and check the saved tab when user again come on the page *****/
  useEffect(() => {
    if (lastOpenedTab === "showAll") set_showAll(true);
    if (lastOpenedTab === "showLikes") {
      set_showLikes(true);
      set_showAll(false);
    }
    if (lastOpenedTab === "showComments") {
      set_showAll(false);
      set_showComments(true);
    }
  }, [lastOpenedTab]);

  const fetchAllComments = (pageNo) => {
    getCommentsHistory(pageNo, (data, type) => {
      if (type === STATUS.error) {
        enqueueSnackbar(data, {
          variant: type,
          autoHideDuration: 3000,
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "left",
          },
        });
      } else {
        set_totalPages((state) => {
          return {
            ...state,
            comments: Math.ceil(data.count / 10),
          };
        });
        // set_allComments([...allComments, ...allCommentsHistory]);
      }
    });
  };

  useEffect(() => {
    if (allLikeComments.length || allComments.length || allLikes.length) {
      set_pageNo({
        all: 1,
        likes: 1,
        comments: 1,
      });
      // resets the state of likes and comments on tab change
      set_allLikes([]);
      set_allLikeComments([]);
      set_allComments([]);
    }
  }, [showAll, showLikes, showComments]);

  useEffect(() => {
    if (showAll) {
      fetchAllLikesAndComments(pageNo.all);
    }
    if (showLikes) {
      fetchAllLikes(pageNo.likes);
    }
    if (showComments) {
      fetchAllComments(pageNo.comments);
    }
  }, [pageNo, showAll, showLikes, showComments]);

  /********** we have to save the history data in redux so that when user come back to history page, same post will be shown ***************/
  useEffect(() => {
    if (showAll) {
      set_allLikeComments([...allLikeCommentsHistory]);
    }
    if (showLikes) {
      set_allLikes([...allLikesHistory]);
    }
    if (showComments) {
      set_allComments([...allCommentsHistory]);
    }
  }, [showAll, allLikeCommentsHistory, allLikesHistory, allCommentsHistory, showComments, showLikes]);

  const handleScroll = (e) => {
    let page =
      showAll && pageNo.all < totalPages.all
        ? { ...pageNo, all: pageNo.all + 1 }
        : showLikes && pageNo.likes < totalPages.likes
        ? { ...pageNo, likes: pageNo.likes + 1 }
        : showComments && pageNo.comments < totalPages.comments
        ? { ...pageNo, comments: pageNo.comments + 1 }
        : pageNo;
    set_pageNo(page);
  };

  const renderData = () => {
    return (
      <>
        <div className="card-body">
          <DisplayHistoryPost
            data={showAll ? allLikeComments : showLikes ? allLikes : showComments ? allComments : []}
            loading={loading}
            fetchFloorboookOrder={fetchFloorboookOrder}
          />
        </div>
      </>
    );
  };
  const refresh = () => {
    set_allComments([]);
    set_allLikeComments([]);
    set_allLikes([]);

    set_pageNo({ all: 1, likes: 1, comments: 1 });
  };
  return (
    <>
      <div className="card-header d-flex flex-column-reverse flex-xl-row justify-content-center justify-content-xl-between align-items-center">
        <div className="breadcrumbs d-flex">{/* <HeadTitle title={HEAD_TITLES.HISTORY} /> */}</div>
      </div>
      <ul className="list-unstyled d-flex align-items-center flex-wrap card_tab">
        <li
          style={showAll ? { backgroundColor: "#0D532D", color: "#fff" } : null}
          onClick={() => {
            set_showAll(true);
            set_showLikes(false);
            set_showComments(false);
            setLastOpenedTab("showAll");
          }}
        >
          {HEAD_TITLES.ALL}
        </li>
        <li
          style={showLikes ? { backgroundColor: "#0D532D", color: "#fff" } : null}
          onClick={() => {
            set_showAll(false);
            set_showLikes(true);
            set_showComments(false);
            setLastOpenedTab("showLikes");
          }}
        >
          {HEAD_TITLES.LIKES}
        </li>
        <li
          style={showComments ? { backgroundColor: "#0D532D", color: "#fff" } : null}
          onClick={() => {
            set_showAll(false);
            set_showLikes(false);
            set_showComments(true);
            setLastOpenedTab("showComments");
          }}
        >
          {HEAD_TITLES.COMMENTS}
        </li>
      </ul>
      <InfiniteScroll
        dataLength={
          showAll ? allLikeComments.length : showLikes ? allLikes.length : showComments ? allComments.length : ""
        } //This is important field to render the next data
        next={handleScroll}
        pullDownToRefresh
        refreshFunction={refresh}
        pullDownToRefreshThreshold={10}
        pullDownToRefreshContent={<p style={{ textAlign: "center" }}>Pull down to refresh</p>}
        releaseToRefreshContent={<p style={{ textAlign: "center" }}>Release to refresh</p>}
        hasMore={
          (showAll && pageNo.all < totalPages.all) ||
          (showLikes && pageNo.likes < totalPages.likes) ||
          (showComments && pageNo.comments < totalPages.comments)
            ? true
            : false
        }
      >
        {renderData()}
      </InfiniteScroll>

      {loading && <Loader />}
    </>
  );
};

export default withSnackbar(Screen);
