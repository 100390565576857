import {
  ADD_NEW_JOURNAL_ENTRY,
  GET_MY_JOURNALS,
  UPDATE_JOURNAL,
  GET_ALL_JOURNALS,
  GET_JOURNAL_BY_JOURNAL_ID,
  DELETE_JOURNAL_ENTRY,
  EDIT_JOURNAL_ENTRY,
  SET_FAVOURITE_JOURNAL,
  GET_FAVOURITE_JOURNALS,
  LIKE_JOURNAL,
} from "./ActionTypes";

export const addNewJournalEntry = (data, callback) => {
  return {
    type: ADD_NEW_JOURNAL_ENTRY,
    data,
    callback,
  };
};
export const updateJournal = (id, data, callback) => {
  return {
    type: UPDATE_JOURNAL,
    data,
    callback,
  };
};
export const getMyJournals = (callback) => {
  return {
    type: GET_MY_JOURNALS,
    callback,
  };
};
export const getAllJournals = (callback) => {
  return {
    type: GET_ALL_JOURNALS,
    callback,
  };
};
export const getJournalById = (data, callback) => {
  return {
    type: GET_JOURNAL_BY_JOURNAL_ID,
    data,
    callback,
  };
};
export const deleteJournal = (data, callback) => {
  return {
    type: DELETE_JOURNAL_ENTRY,
    data,
    callback,
  };
};
export const editJournalEntry = (data, callback) => {
  return {
    type: EDIT_JOURNAL_ENTRY,
    data,
    callback,
  };
};
export const setFavouriteJournal = (data, callback) => {
  return {
    type: SET_FAVOURITE_JOURNAL,
    data,
    callback,
  };
};
export const getFavouriteJournals = (callback) => {
  return {
    type: GET_FAVOURITE_JOURNALS,
    callback,
  };
};
export const likeJournal = (data, callback) => {
  return {
    type: LIKE_JOURNAL,
    data,
    callback,
  };
};
