import * as actionTypes from "../actions/ActionTypes";

const initialCommonState = {
  allLikesComments: [],
  allLikes: [],
  allComments: [],
  lastOpenedTab: "showAll",
};
const HistoryReducer = (state = { ...initialCommonState }, action) => {
  switch (action.type) {
    case actionTypes.SET_ALL_HISTORY:
      return {
        ...state,
        allLikesComments: [...state.allLikesComments, ...action.data],
      };
    case actionTypes.SET_LIKES_HISTORY:
      return {
        ...state,
        allLikes: [...state.allLikes, ...action.data],
      };
    case actionTypes.SET_COMMENTS_HISTORY:
      return {
        ...state,
        allComments: [...state.allComments, ...action.data],
      };
    case actionTypes.LAST_OPENED_TAB:
      return {
        ...state,
        lastOpenedTab: action.data,
      };

    default:
      return state;
  }
};

export default HistoryReducer;
