import { connect } from "react-redux";
import Screen from "./screen";
import {
  dispatchSaveFloorbook,
  getAllInquiryLines,
  getAllFloorbooks,
  getSubjectAreaDropDown,
  getPageById,
  updateFloorbookPage,
  startLoading,
  stopLoading,
  updateDeleteLineofInquiry,
} from "../../../../../redux/actions";

const mapStateToProps = (state) => {
  return {
    loading: state.CommonReducer.isLoading,
    inquiryLines: state.FloorbookReducer.inquiryLines,
    myfloorbookData: state.FloorbookReducer.myfloorbookData,
    subjectAreaData: state.FloorbookReducer.subjectAreaData,
    floorbookPageData: state.FloorbookPageReducer.floorbookPageData,
    userRole: state.CommonReducer.userData.user_role,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getAllInquiryLines: (callback) => dispatch(getAllInquiryLines(callback)),
    dispatchSaveFloorbook: (data, callback) =>
      dispatch(dispatchSaveFloorbook(data, callback)),
    getAllFloorbooks: (callback) => dispatch(getAllFloorbooks(callback)),
    getSubjectAreaDropDown: (callback) =>
      dispatch(getSubjectAreaDropDown(callback)),
    getPageById: (data, callback) => dispatch(getPageById(data, callback)),
    updateFloorbookPage: (id, data, callback) =>
      dispatch(updateFloorbookPage(id, data, callback)),
    startLoading: () => dispatch(startLoading()),
    stopLoading: () => dispatch(stopLoading()),
    updateDeleteLineofInquiry: (data, callback) =>
      dispatch(updateDeleteLineofInquiry(data, callback)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Screen);
