import {
  GET_LIKES_COMMENT_HISTORY,
  GET_LIKES,
  GET_COMMENTS_HISTORY,
  SET_LIKES_HISTORY,
  SET_ALL_HISTORY,
  SET_COMMENTS_HISTORY,
  LAST_OPENED_TAB,
} from "./ActionTypes";

export const getAllHistory = (data, callback) => {
  return {
    type: GET_LIKES_COMMENT_HISTORY,
    data,
    callback,
  };
};
export const getLikesHistory = (data, callback) => {
  return {
    type: GET_LIKES,
    data,
    callback,
  };
};
export const getCommentsHistory = (data, callback) => {
  return {
    type: GET_COMMENTS_HISTORY,
    data,
    callback,
  };
};

export const setAllHistory = (data) => {
  return {
    type: SET_ALL_HISTORY,
    data,
  };
};

export const setLikesHistory = (data) => {
  return {
    type: SET_LIKES_HISTORY,
    data,
  };
};
export const setCommentsHistory = (data) => {
  return {
    type: SET_COMMENTS_HISTORY,
    data,
  };
};

export const setLastOpenedTab = (data) => {
  return {
    type: LAST_OPENED_TAB,
    data,
  };
};
