export const LOGO = require("../../../src/assets/images/logo.svg");
export const LOGO_GREEN = require("../../assets/images/logo_green.png");
// Top bar icons
export const NOTIFICATION_ICON = require("../../assets/images/icons/notification.svg");
export const HAMBURGER_ICON = require("../../assets/images/icons/hamburger_icon.svg");
// display post and resource icons
export const CLOCK_ICON = require("../../assets/images/icons/clock_icon.svg");
export const LIKE_GRAY_ICON = require("../../assets/images/icons/like_grey.svg");
export const LIKE_GREEN_ICON = require("../../assets/images/icons/like_green.svg");
export const LIKE_OUTLINE = require("../../assets/images/icons/like-outline.png");
export const LIKED_ICON = require("../../assets/images/icons/liked.png");
export const LIKE_ICON = require("../../assets/images/icons/like.png");
export const COMMENTS_GRAY_ICON = require("../../assets/images/icons/comments_grey.svg");
export const COMMENTS_WHITE_ICON = require("../../assets/images/icons/comments_icon.svg");
export const COMMENTS_GREEN_ICON = require("../../assets/images/icons/comments_green.svg");
export const HEART_FILL = require("../../assets/images/icons/heart_fill.svg");
export const HEART_OUTLINE = require("../../assets/images/icons/heart_outline.svg");
export const VIDEO_PLAY_ICON = require("../../assets/images/icons/play_icon.svg");
// side bar tab icons
export const HOME_TAB_ICON = require("../../assets/images/icons/home.svg");
export const FLOORBOOK_TAB_ICON = require("../../assets/images/icons/book.svg");
export const REPORTS_ICON = require("../../assets/images/icons/reports_icon.svg");
export const RESOURCE_TAB_ICON = require("../../assets/images/icons/resource.svg");
export const HISTORY_TAB_ICON = require("../../assets/images/icons/history_icon.svg");
export const SETTINGS_TAB_ICON = require("../../assets/images/icons/settings.svg");
export const HELP_TAB_ICON = require("../../assets/images/icons/help_icon.svg");
export const LEARNING_TAB_ICON = require("../../assets/images/icons/learning.svg");
export const LOGOUT_TAB_ICON = require("../../assets/images/icons/logout.svg");
export const SEARCH_ICON = require("../../assets/images/icons/search.svg");
