import {
  LIKE_PAGE,
  GET_FLOORBOOK_PAGE_DETAIL,
  SET_NEW_LIKES,
  SAVE_FLOORBOOK_PAGE_DATA,
  UPDATE_FLOORBOOK_PAGE,
  GET_COMMENTS,
  SET_NEW_LIKES_HOME,
  GET_FLOORBOOK_PAGE_DATA_BY_ID,
  DELETE_COMMENTS_FLOORBOOK_PAGE,
  DELETE_FLOORBOOK_PAGE,
  EDIT_FLOORBOOK_PAGE_COMMENT,
  UPDATE_DELETE_LINE_OF_INQUIRY,
} from "./ActionTypes";

export const deleteFloorbook_PageComment = (data, callback) => {
  return {
    type: DELETE_COMMENTS_FLOORBOOK_PAGE,
    data,
    callback,
  };
};

export const likeFloorbookPage = (
  data,
  status,
  created_at,
  pageType,
  callback
) => {
  return {
    type: LIKE_PAGE,
    data,
    status,
    created_at,
    pageType,
    callback,
  };
};

export const setFloorbookLike = (data) => {
  return {
    type: SET_NEW_LIKES,
    data,
  };
};
export const setFloorbookLikeHome = (data) => {
  return {
    type: SET_NEW_LIKES_HOME,
    data,
  };
};

export const getFlooorbook_PageDetails = (data, callback) => {
  return {
    type: GET_FLOORBOOK_PAGE_DETAIL,
    data,
    callback,
  };
};
export const saveFloorbookPagesDatabyId = (data) => {
  return {
    type: SAVE_FLOORBOOK_PAGE_DATA,
    data,
  };
};
export const updateFloorbookPage = (id, data, callback) => {
  return {
    type: UPDATE_FLOORBOOK_PAGE,
    id,
    data,
    callback,
  };
};
export const getPageById = (data, callback) => {
  return {
    type: GET_FLOORBOOK_PAGE_DATA_BY_ID,
    data,
    callback,
  };
};
export const editFloorbookPageComment = (data, callback) => {
  return {
    type: EDIT_FLOORBOOK_PAGE_COMMENT,
    data,
    callback,
  };
};
export const getComments = (data, callback) => {
  return {
    type: GET_COMMENTS,
    data,
    callback,
  };
};
export const deleteFloorbookPage = (data, callback) => {
  return {
    type: DELETE_FLOORBOOK_PAGE,
    data,
    callback,
  };
};

//line of inquiries update

export const updateDeleteLineofInquiry = (data, callback) => {
  return {
    type: UPDATE_DELETE_LINE_OF_INQUIRY,
    data,
    callback,
  };
};
