import { connect } from "react-redux";
import { call } from "redux-saga/effects";
import { addUsefulResource, getResourceById, editUsefulResource } from "../../../../../redux/actions";
import AddUsefulResource from "./screen";

const mapStateToProps = (state) => {
  return {
    loading: state.CommonReducer.isLoading,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    addUsefulResource: (data, callback) => dispatch(addUsefulResource(data, callback)),
    getResourceById: (data, callback) => dispatch(getResourceById(data, callback)),
    editUsefulResource: (data, callback) => dispatch(editUsefulResource(data, callback)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AddUsefulResource);
