import React, { useEffect, useState } from "react";
import { Loader, ImageOverlay } from "../../../../components";
import { messaging } from "../../../../config/init-fcm";
import { STATUS, STRINGS } from "../../../../shared/constants";
import { reduxForm } from "redux-form";
import { ROUTE_CONSTANTS } from "../../../../shared/constants";
import { withSnackbar } from "notistack";
import validator from "./validator";
import { Prompt } from "react-router-dom";
import { connect } from "react-redux";
import { RenderForm } from "./form";

const SingUpScreen = ({
  handleSubmit = () => {},
  change = () => {},
  loading,
  history,
  allCountries,
  allSettingTypes,
  scottishLocalAuthority,
  getDropDownData = () => {},
  SignUpFormOneData,
  signUpForNewUser = () => {},
  enqueueSnackbar,
  goBackToSignUPFirst,
  saveSignUpFormTwo,
  goBackToSignUpFirst,
  formData,
  getScottishLocalAuthorities = () => {},
}) => {
  const [loader, set_loader] = useState(false);
  const [selectedSettingType, setSelectedSettingType] = useState([]);
  const [countries, setCountries] = useState([]);
  const [settings, setSettingsTypes] = useState([]);
  const [scottishLocalAuthorities, set_scottishLocalAuthorities] = useState(false);
  const [preventUser, set_preventUser] = useState(true);
  const [isSignUp, set_isSignUp] = useState(false);
  const [showGoBackBtn, set_showGoBackBtn] = useState(false);
  const [showScottishAuthorityField, set_showScottishAuthorityField] = useState(false);
  const [FcmToken, setFcmToken] = useState("");

  useEffect(() => {
    console.log("to notify.");
    messaging
      .requestPermission()
      .then(async function () {
        const token = await messaging.getToken();
        setFcmToken(token);
      })
      .catch(function (err) {
        console.log("Unable to get permission to notify.", err);
        setFcmToken("");
      });
  }, []);
  useEffect(() => {
    if (preventUser) {
      window.onbeforeunload = () => true;
    } else {
      window.onbeforeunload = undefined;
    }
    return () => {
      set_preventUser(false);
      window.onbeforeunload = undefined;
    };
  }, [preventUser]);

  useEffect(() => {
    set_loader(loading);
  }, [loading]);

  const submit = (values) => {
    set_isSignUp(true);
    if (SignUpFormOneData) {
      let postData = {
        first_name: SignUpFormOneData && SignUpFormOneData.firstName && SignUpFormOneData.firstName.trim(),
        last_name: SignUpFormOneData && SignUpFormOneData.lastName && SignUpFormOneData.lastName.trim(),
        email: SignUpFormOneData && SignUpFormOneData.email && SignUpFormOneData.email.trim(),
        device_token: FcmToken,
        city: values.city.trim(),
        country_code: values.country.value,
        setting_name: values.settingName.trim(),
        setting_type: values.settingType.value,
        password: SignUpFormOneData.password,
        description: values.description,
      };
      /** if user select Other setting Type , append that type in drop-down list **/
      if (selectedSettingType.label == "Other") {
        postData = { ...postData, setting_other_type: values.otherType };
      }
      if (!!values.scottishLocal) {
        postData = { ...postData, scottish_authority: values.scottishLocal.value };
      }
      signUpForNewUser(postData, (message, type) => {
        enqueueSnackbar(message, {
          variant: type,
          autoHideDuration: 3000,
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "left",
          },
        });

        if (type === STATUS.success) {
          history.push(ROUTE_CONSTANTS.LOGIN);
        }
        if (type === STATUS.error || message === STRINGS.USER_ALREADY_EXIST) {
          set_showGoBackBtn(true);
          saveSignUpFormTwo(values);
        }
      });
    }
  };

  useEffect(() => {
    getDropDownData((message, type) => {
      enqueueSnackbar(message, {
        variant: type,
        autoHideDuration: 3000,
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "left",
        },
      });
    });
    getScottishLocalAuthorities((message, type) => {
      enqueueSnackbar(message, {
        variant: type,
        autoHideDuration: 3000,
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "left",
        },
      });
    });
  }, []);

  useEffect(() => {
    let arr = [];

    allCountries &&
      allCountries.map((country) => {
        let obj = { value: country.id, label: country.name };
        arr.push(obj);
      });
    setCountries(arr);
  }, [allCountries]);

  useEffect(() => {
    let arr = [];
    allSettingTypes &&
      allSettingTypes.map((setting) => {
        let obj = { value: setting.id, label: setting.name };
        arr.push(obj);
      });
    setSettingsTypes(arr);
  }, [allSettingTypes]);

  useEffect(() => {
    let arr = [];
    scottishLocalAuthority &&
      scottishLocalAuthority.map((item) => {
        let obj = { value: item.id, label: item.local_authority };
        arr.push(obj);
      });
    set_scottishLocalAuthorities(arr);
  }, [scottishLocalAuthority]);

  return (
    <>
      {isSignUp ? null : <Prompt when={preventUser} message={() => STRINGS.DISCARDED_FORM_WARNING} />}
      <div className="front-forms">
        <div className="container-fluid">
          <div className="login_box">
            <div className="front-logo text-center mb-4">
              <ImageOverlay>
                <img src={require("../../../../assets/images/logo.svg")} alt="Inquery Tracker" />
              </ImageOverlay>
            </div>

            <div className="form-box">
              <RenderForm
                history={history}
                handleSubmit={handleSubmit}
                submit={submit}
                goBackToSignUpFirst={goBackToSignUpFirst}
                goBackToSignUPFirst={goBackToSignUPFirst}
                settings={settings}
                formData={formData}
                setSelectedSettingType={setSelectedSettingType}
                change={change}
                selectedSettingType={selectedSettingType}
                showGoBackBtn={showGoBackBtn}
                countries={countries}
                scottishLocalAuthorities={scottishLocalAuthorities}
                set_showScottishAuthorityField={set_showScottishAuthorityField}
                showScottishAuthorityField={showScottishAuthorityField}
              />
            </div>
          </div>
        </div>
        {loader && <Loader />}
      </div>
    </>
  );
};

const showForm = reduxForm({
  form: "SignUpForm2",
  validate: validator,
  enableReinitialize: true,
})(SingUpScreen);

const Screen = connect((state) => {
  let values = state.LoginReducer.goBackToSignUpFirst ? state.SignUpReducer.Form2 : null;
  return {
    initialValues: values,
    formData: state.SignUpReducer.Form2,
  };
})(showForm);

export default withSnackbar(Screen);
