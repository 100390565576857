import React, { useEffect, useState } from "react";
import "./style.scss";
import ReactLoading from "react-loading";
import { timeConverter } from "../../../shared/services/Utility";
import { useHistory } from "react-router-dom";
import { ROUTE_CONSTANTS } from "../../../shared/constants";
import { ProfileModal } from "../../molecules";

const NotificationComponent = ({ notificationList, notificationLoader, onClose = () => {} }) => {
  const [loader, setLoader] = useState(false);
  const [list, setList] = useState([]);
  let history = useHistory();

  useEffect(() => {
    setList((state) => {
      if (notificationList && notificationList.data && notificationList.data.length) {
        notificationList.data.sort((a, b) => b.created_at.localeCompare(a.created_at));
        return notificationList.data;
      }
      return [];
    });
  }, [notificationList]);

  useEffect(() => {
    setLoader(notificationLoader);
  }, [notificationLoader]);

  return (
    <>
      {notificationLoader ? (
        <div className="notification-loader">
          <ReactLoading type={"spinningBubbles"} color={"#0d532d"} height={"50px"} width={"50px"} />
        </div>
      ) : (
        <>
          {list.length > 0 ? (
            <ul>
              {list.map((item, idx) => {
                return (
                  <li className="form-row user_list" key={idx}>
                    <div className="col-sm-2 col-3">
                      <figure>
                        {/* <img src={item.profile_pic} width={80} className="img-fluid" /> */}
                        <ProfileModal user_image={item.profile_pic} user_id={item.user_id} />
                      </figure>
                    </div>
                    <div
                      className="col-sm-10 col-9"
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        if (item.event == "floorbook") {
                          history.push({
                            pathname: `${ROUTE_CONSTANTS.FLOORBOOK_COVER_DETAILS}`,
                            search: `floorbook_page=${item.id}`,
                          });
                          onClose();
                        }
                        if (item.event == "page") {
                          history.push({
                            pathname: `${ROUTE_CONSTANTS.FLOORBOOK_PAGE_DETAILS}`,
                            search: `page_id=${item.id}`,
                          });
                          onClose();
                        }
                      }}
                    >
                      <h6>
                        {item.username}
                        <div className="notifi-date">{timeConverter(item.created_at)}</div>
                      </h6>
                      <span>{item.title}</span>
                      {/* 
                      {item.type == "like" && item.event == "page" ? (
                        <span>Like on your Page</span>
                      ) : item.type == "like" && item.event == "floorbook" ? (
                        <span>Like on your Floorbook</span>
                      ) : item.type == "comment" && item.event == "floorbook" ? (
                        <span>Comment on your Floorbook</span>
                      ) : item.type == "comment" && item.event == "page" ? (
                        <span>Comment on your Page</span>
                      ) : null} */}
                      <p style={{ fontWeight: "500" }}>{item.name}</p>
                    </div>
                  </li>
                );
              })}
            </ul>
          ) : (
            <p style={{ alignItems: "center", justifyContent: "center" }}>{"No data found."}</p>
          )}
        </>
      )}
    </>
  );
};
export default NotificationComponent;
