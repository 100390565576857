import React, { useEffect, useState } from "react";
import ReactNotification from "react-notifications-component";
import "react-notifications-component/dist/theme.css";
import { store } from "react-notifications-component";
import { messaging } from "../../../config/init-fcm";

const NotificationScreen = ({ addNotificationReadCount = () => {}, status, soundStatus, count }) => {
  let audio = new Audio(require("../../../assets/images/notification.mp3"));
  console.log("count=>>>>>>>>>>>", count);
  useEffect(() => {
    let safariAgent = navigator.userAgent.indexOf("Safari") > -1;
    let chromeAgent = navigator.userAgent.indexOf("Chrome") > -1;

    if (chromeAgent && safariAgent) {
      safariAgent = false;
    }
    if (!safariAgent) {
      messaging.onMessage((payload) => console.log("Message received. ", payload));
    }
    navigator.serviceWorker.addEventListener("message", (message) => {
      console.log("message inside push", message, status);
      console.log("coubnt in bnotiofication", status, soundStatus);
      let c = count + 1;
      let title =
        message &&
        message.data &&
        message.data["firebase-messaging-msg-data"] &&
        message.data["firebase-messaging-msg-data"].notification &&
        message.data["firebase-messaging-msg-data"].notification.title
          ? message.data["firebase-messaging-msg-data"].notification.title
          : "";
      let body =
        message &&
        message.data &&
        message.data["firebase-messaging-msg-data"] &&
        message.data["firebase-messaging-msg-data"].notification &&
        message.data["firebase-messaging-msg-data"].notification.body
          ? message.data["firebase-messaging-msg-data"].notification.body
          : "";

      console.log("nottttt notify");
      store.addNotification({
        title: title,
        message: body,
        type: "info",
        insert: "top",
        container: "top-right",
        animationIn: ["animated", "fadeIn"],
        animationOut: ["animated", "fadeOut"],
        showIcon: true,
        dismiss: {
          duration: 5000,
          onScreen: true,
        },
        // slidingExit: {
        //   duration: 0,
        //   timingFunction: "ease-out",
        //   delay: 0,
        // },
      });

      addNotificationReadCount(1);
      if (!!soundStatus) {
        audio.play();
      }
    });
  }, []);

  return (
    <>
      <ReactNotification />
    </>
  );
};

export default NotificationScreen;
