import { STRINGS } from "../../../../shared/constants";
import { VALIDATION_MESSAGES, NAME_REGX, EMAIL_REGX } from "../../../../shared/constants";
import { store } from "../../../../redux/store";
const validator = (values) => {
  const errors = {};

  if (!values[STRINGS.FIRST_NAME_INPUT]) {
    errors[STRINGS.FIRST_NAME_INPUT] = VALIDATION_MESSAGES.FIRST_NAME_REQUIRED;
  } else if (values) {
    if (!values[STRINGS.FIRST_NAME_INPUT].trim())
      errors[STRINGS.FIRST_NAME_INPUT] = VALIDATION_MESSAGES.FIRST_NAME_REQUIRED;

    if (values[STRINGS.FIRST_NAME_INPUT].trim() && !NAME_REGX.test(values[STRINGS.FIRST_NAME_INPUT])) {
      errors[STRINGS.FIRST_NAME_INPUT] = VALIDATION_MESSAGES.FIRST_NAME_VALIDATE;
    }
  }
  if (!values[STRINGS.LAST_NAME_INPUT]) {
    errors[STRINGS.LAST_NAME_INPUT] = VALIDATION_MESSAGES.LAST_NAME_REQUIRED;
  } else if (values) {
    if (!values[STRINGS.LAST_NAME_INPUT].trim())
      errors[STRINGS.LAST_NAME_INPUT] = VALIDATION_MESSAGES.LAST_NAME_REQUIRED;

    if (values[STRINGS.LAST_NAME_INPUT].trim() && !NAME_REGX.test(values[STRINGS.LAST_NAME_INPUT])) {
      errors[STRINGS.LAST_NAME_INPUT] = VALIDATION_MESSAGES.LAST_NAME_VALIDATE;
    }
  }
  if (!values[STRINGS.EMAIL_INPUT_NAME]) {
    errors[STRINGS.EMAIL_INPUT_NAME] = VALIDATION_MESSAGES.EMAIL_REQUIRED;
  } else if (values) {
    if (!values[STRINGS.EMAIL_INPUT_NAME].trim()) errors[STRINGS.EMAIL_INPUT_NAME] = VALIDATION_MESSAGES.EMAIL_REQUIRED;

    if (values[STRINGS.EMAIL_INPUT_NAME].trim() && !EMAIL_REGX.test(values[STRINGS.EMAIL_INPUT_NAME].trim())) {
      errors[STRINGS.EMAIL_INPUT_NAME] = VALIDATION_MESSAGES.EMAIL_INVALID;
    }
  }

  if (!values[STRINGS.PASSWORD_INPUT_NAME]) {
    errors[STRINGS.PASSWORD_INPUT_NAME] = VALIDATION_MESSAGES.PASSWORD_REQUIRED;
  } else if (values) {
    if (!values[STRINGS.PASSWORD_INPUT_NAME].trim())
      errors[STRINGS.PASSWORD_INPUT_NAME] = VALIDATION_MESSAGES.PASSWORD_REQUIRED;

    if (
      values[STRINGS.PASSWORD_INPUT_NAME].trim() &&
      (values[STRINGS.PASSWORD_INPUT_NAME].length == 3 || values[STRINGS.PASSWORD_INPUT_NAME].length < 4)
    ) {
      errors[STRINGS.PASSWORD_INPUT_NAME] = VALIDATION_MESSAGES.PASSWORD_VALIDATE;
    }
  }

  if (!values[STRINGS.CAPTCHA_INPUT]) {
    errors[STRINGS.CAPTCHA_INPUT] = VALIDATION_MESSAGES.CAPTCHA_REQUIRED;
  } else if (values[STRINGS.CAPTCHA_INPUT] !== store.getState()?.CommonReducer?.captcha) {
    errors[STRINGS.CAPTCHA_INPUT] = VALIDATION_MESSAGES.CAPTCHA_VALIDATE;
  }

  return errors;
};

export default validator;
