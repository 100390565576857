import { connect } from "react-redux";
import UsefulResourceDetail from "./screen";
import { getUsefulResourceDetail } from "../../../../../redux/actions";

const mapStateToProps = (state) => {
  return {
    loading: state.CommonReducer.isLoading,
    resourceDetail: state.UsefulResourcesReducer.resourceDetail,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getUsefulResourceDetail: (data, callback) => dispatch(getUsefulResourceDetail(data, callback)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(UsefulResourceDetail);
