import { connect } from "react-redux";
import NotificationComponent from "./screen";

const mapStateToProps = (state) => {
  return {
    state,
    notificationList: state.NotificationReducer.notificationList,
    notificationLoader: state.NotificationReducer.notificationLoader,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {

  };
};
export default connect(mapStateToProps, mapDispatchToProps)(NotificationComponent);
