import React from "react";

const Input = ({
  input,
  label,
  meta: { touched, error },
  config,
  placeholder,
  widthStyle,
  style,
  maxLength,
  data,
  value,
  onChange = () => {},
  ...props
}) => {
  widthStyle = widthStyle ? widthStyle : "";

  const validationSpan = touched && error ? <span className="error_msg text-danger">{error}</span> : null;

  return (
    <div className="form-group" style={style}>
      {label && <label>{label}</label>}
      <input
        className="form-control"
        placeholder={placeholder}
        {...input}
        {...config}
        onChange={(value) => {
          input.onChange(value);
          onChange(value);
        }}
        maxLength={50}
        autoComplete="off"
      />
      {validationSpan}
    </div>
  );
};
export default Input;
