import { takeLatest, all, put } from "redux-saga/effects";
import {
  ADD_NEW_JOURNAL_ENTRY,
  GET_MY_JOURNALS,
  startLoading,
  stopLoading,
  GET_ALL_JOURNALS,
  GET_JOURNAL_BY_JOURNAL_ID,
  logout_user,
  DELETE_JOURNAL_ENTRY,
  EDIT_JOURNAL_ENTRY,
  SET_FAVOURITE_JOURNAL,
  GET_FAVOURITE_JOURNALS,
  LIKE_JOURNAL,
} from "../actions";
import { checkOffline } from "../../shared/services/Utility";
const api = require(`../../shared/Apis/api`);
const { postRequest, getRequest } = require(`../../helpers/axios`);
const { STATUS_CODE, MESSAGES, STATUS } = require(`../../shared/constants`);

//request to add-journal
function* addJournalEntry({ data, callback }) {
  try {
    yield put(startLoading());
    const formdata = new FormData();
    formdata.append("title", data.title);
    formdata.append("activity_from", data.activity_from);
    formdata.append("activity_to", data.activity_to);
    formdata.append("line_inquiry", data.line_inquiry);
    if (data.line_inquiry === 11 && data.other_line_inquiry) {
      formdata.append("other_line_inquiry", data.other_line_inquiry);
    }
    formdata.append("location_activity", data.location);
    formdata.append("description", data.description);
    formdata.append("subject", data.subject);
    formdata.append("improvement", data.improvement);
    formdata.append("journal_id", data.journal_id);

    data.updatedImages.map((item, i) => {
      console.log("item in journal images", item.file);
      formdata.append(`filenames[${i}]`, item.file);
    });

    const response = yield postRequest({ API: `${api.ADD_JOURNAL}`, DATA: formdata });
    if (response.status === STATUS_CODE.unAuthorized) {
      yield put(stopLoading());
      callback(response.data.message, STATUS.error);
      yield put(logout_user());
    } else if (response.status === 413) {
      yield put(stopLoading());
      callback("File size too large. Max size allowed : 20MB", STATUS.error);
    } else if (response.status !== STATUS_CODE.successful) {
      yield put(stopLoading());
      callback(response.data.error.message, STATUS.error);
    } else {
      callback(response.data.data.message, STATUS.success);
      yield put(stopLoading());
    }
  } catch (error) {
    yield put(stopLoading());

    if (checkOffline()) {
      callback(MESSAGES.OFFLINE, STATUS.error);
    } else {
      callback(MESSAGES.ERROR_IN_CATCH, STATUS.error);
    }
  }
}

//request to get my Journal's list
function* getMyJournalList({ callback }) {
  try {
    yield put(startLoading());
    const response = yield getRequest({ API: `${api.GET_MY_JOURNALS}` });

    if (response.status === STATUS_CODE.unAuthorized) {
      yield put(stopLoading());
      callback(response.data.message, STATUS.error);
      yield put(logout_user());
    } else if (response.status === 404) {
      yield put(stopLoading());
      callback(MESSAGES.ERROR_IN_CATCH, STATUS.error);
    } else if (response.status !== STATUS_CODE.successful) {
      yield put(stopLoading());
      callback(response.data.error.message, STATUS.error);
    } else {
      console.log("data", response.data.data.data);
      callback(response.data.data.data, STATUS.success);
      yield put(stopLoading());
    }
  } catch (error) {
    yield put(stopLoading());

    if (checkOffline()) {
      callback(MESSAGES.OFFLINE, STATUS.error);
    } else {
      callback(MESSAGES.ERROR_IN_CATCH, STATUS.error);
    }
  }
}
//request to get other's journal(All)
function* getAllJournalList({ data = 1, callback }) {
  try {
    yield put(startLoading());
    const response = yield getRequest({ API: `${api.GET_OTHERS_JOURNAL}` });

    if (response.status === STATUS_CODE.unAuthorized) {
      yield put(stopLoading());
      callback(response.data.message, STATUS.error);
      yield put(logout_user());
    } else if (response.status === 404) {
      yield put(stopLoading());
      callback(MESSAGES.ERROR_IN_CATCH, STATUS.error);
    } else if (response.status !== STATUS_CODE.successful) {
      yield put(stopLoading());
      callback(response.data.error.message, STATUS.error);
    } else {
      callback(response.data.data.data, STATUS.success);
      yield put(stopLoading());
    }
  } catch (error) {
    yield put(stopLoading());

    if (checkOffline()) {
      callback(MESSAGES.OFFLINE, STATUS.error);
    } else {
      callback(MESSAGES.ERROR_IN_CATCH, STATUS.error);
    }
  }
}
//request to get journal data by their id , for update journal
function* getJournalByJournalId({ data = 1, callback }) {
  try {
    yield put(startLoading());
    const response = yield postRequest({ API: `${api.GET_JOURNAL_BY_ID}`, DATA: data });

    if (response.status === STATUS_CODE.unAuthorized) {
      yield put(stopLoading());
      callback(response.data.message, STATUS.error);
      yield put(logout_user());
    } else if (response.status === 404) {
      yield put(stopLoading());
      callback(MESSAGES.ERROR_IN_CATCH, STATUS.error);
    } else if (response.status !== STATUS_CODE.successful) {
      yield put(stopLoading());
      callback(response.data.error.message, STATUS.error);
    } else {
      callback(response.data.data.data, STATUS.success);
      yield put(stopLoading());
    }
  } catch (error) {
    yield put(stopLoading());

    if (checkOffline()) {
      callback(MESSAGES.OFFLINE, STATUS.error);
    } else {
      callback(MESSAGES.ERROR_IN_CATCH, STATUS.error);
    }
  }
}
//request to delete journal by id
function* onDeleteJournal({ data = 1, callback }) {
  try {
    yield put(startLoading());
    const response = yield postRequest({ API: `${api.DELETE_JOURNAL}`, DATA: data });

    if (response.status === STATUS_CODE.unAuthorized) {
      yield put(stopLoading());
      callback(response.data.message, STATUS.error);
      yield put(logout_user());
    } else if (response.status === 404) {
      yield put(stopLoading());
      callback(MESSAGES.ERROR_IN_CATCH, STATUS.error);
    } else if (response.status !== STATUS_CODE.successful) {
      yield put(stopLoading());
      callback(response.data.error.message, STATUS.error);
    } else {
      callback(response.data.data.message, STATUS.success);
      yield put(stopLoading());
    }
  } catch (error) {
    yield put(stopLoading());

    if (checkOffline()) {
      callback(MESSAGES.OFFLINE, STATUS.error);
    } else {
      callback(MESSAGES.ERROR_IN_CATCH, STATUS.error);
    }
  }
}
//request to edit journal entry data
function* editJournalEntry({ data = 1, callback }) {
  try {
    yield put(startLoading());
    const formdata = new FormData();
    formdata.append("title", data.title);
    formdata.append("activity_from", data.activity_from);
    formdata.append("activity_to", data.activity_to);
    formdata.append("line_inquiry", data.line_inquiry);
    if (data.line_inquiry === 11 && data.other_line_inquiry) {
      formdata.append("other_line_inquiry", data.other_line_inquiry);
    }
    formdata.append("location_activity", data.location);
    formdata.append("description", data.description);
    formdata.append("subject", data.subject);
    formdata.append("improvement", data.improvement);
    formdata.append("id", data.id);
    data.updatedImages.map((item, i) => {
      console.log("item in journal images", item.file);
      formdata.append(`filenames[${i}]`, item.file);
    });
    const response = yield postRequest({ API: `${api.EDIT_JOURNAL_ENTRY}`, DATA: formdata });

    if (response.status === STATUS_CODE.unAuthorized) {
      yield put(stopLoading());
      callback(response.data.message, STATUS.error);
      yield put(logout_user());
    } else if (response.status === 404) {
      yield put(stopLoading());
      callback(MESSAGES.ERROR_IN_CATCH, STATUS.error);
    } else if (response.status !== STATUS_CODE.successful) {
      yield put(stopLoading());
      callback(response.data.error.message, STATUS.error);
    } else {
      callback(response.data.data.message, STATUS.success);
      yield put(stopLoading());
    }
  } catch (error) {
    yield put(stopLoading());

    if (checkOffline()) {
      callback(MESSAGES.OFFLINE, STATUS.error);
    } else {
      callback(MESSAGES.ERROR_IN_CATCH, STATUS.error);
    }
  }
}
//request to set favourite journals
function* setFavouriteJournal({ data = 1, callback }) {
  try {
    yield put(startLoading());
    const response = yield postRequest({ API: `${api.SET_FAVOURITE_JOURNAL}`, DATA: data });

    if (response.status === STATUS_CODE.unAuthorized) {
      yield put(stopLoading());
      callback(response.data.message, STATUS.error);
      yield put(logout_user());
    } else if (response.status === 404) {
      yield put(stopLoading());
      callback(MESSAGES.ERROR_IN_CATCH, STATUS.error);
    } else if (response.status !== STATUS_CODE.successful) {
      yield put(stopLoading());
      callback(response.data.error.message, STATUS.error);
    } else {
      callback(response.data.data, STATUS.success);
      yield put(stopLoading());
    }
  } catch (error) {
    yield put(stopLoading());

    if (checkOffline()) {
      callback(MESSAGES.OFFLINE, STATUS.error);
    } else {
      callback(MESSAGES.ERROR_IN_CATCH, STATUS.error);
    }
  }
}
//request to get all favourite journal entries
function* getFavouriteJournals({ callback }) {
  try {
    yield put(startLoading());
    const response = yield getRequest({ API: `${api.GET_FAVOURITE_JOURNALS}` });

    if (response.status === STATUS_CODE.unAuthorized) {
      yield put(stopLoading());
      callback(response.data.message, STATUS.error);
      yield put(logout_user());
    } else if (response.status === 404) {
      yield put(stopLoading());
      callback(MESSAGES.ERROR_IN_CATCH, STATUS.error);
    } else if (response.status !== STATUS_CODE.successful) {
      yield put(stopLoading());
      callback(response.data.error.message, STATUS.error);
    } else {
      callback(response.data.data.data, STATUS.success);
      yield put(stopLoading());
    }
  } catch (error) {
    yield put(stopLoading());

    if (checkOffline()) {
      callback(MESSAGES.OFFLINE, STATUS.error);
    } else {
      callback(MESSAGES.ERROR_IN_CATCH, STATUS.error);
    }
  }
}
// request to like/ unlike journal entry

function* setlikeUnlikeJournal({ data, callback }) {
  try {
    yield put(startLoading());
    const response = yield postRequest({ API: `${api.LIKE_JOURNAL}`, DATA: data });

    if (response.status === STATUS_CODE.unAuthorized) {
      yield put(stopLoading());
      callback(response.data.message, STATUS.error);
      yield put(logout_user());
    } else if (response.status === 404) {
      yield put(stopLoading());
      callback(MESSAGES.ERROR_IN_CATCH, STATUS.error);
    } else if (response.status !== STATUS_CODE.successful) {
      yield put(stopLoading());
      callback(response.data.error.message, STATUS.error);
    } else {
      callback(response.data.data, STATUS.success);
      yield put(stopLoading());
    }
  } catch (error) {
    yield put(stopLoading());

    if (checkOffline()) {
      callback(MESSAGES.OFFLINE, STATUS.error);
    } else {
      callback(MESSAGES.ERROR_IN_CATCH, STATUS.error);
    }
  }
}
function* FloorbookSaga() {
  yield all([
    takeLatest(ADD_NEW_JOURNAL_ENTRY, addJournalEntry),
    takeLatest(GET_MY_JOURNALS, getMyJournalList),
    takeLatest(GET_ALL_JOURNALS, getAllJournalList),
    takeLatest(GET_JOURNAL_BY_JOURNAL_ID, getJournalByJournalId),
    takeLatest(DELETE_JOURNAL_ENTRY, onDeleteJournal),
    takeLatest(EDIT_JOURNAL_ENTRY, editJournalEntry),
    takeLatest(SET_FAVOURITE_JOURNAL, setFavouriteJournal),
    takeLatest(GET_FAVOURITE_JOURNALS, getFavouriteJournals),
    takeLatest(LIKE_JOURNAL, setlikeUnlikeJournal),
  ]);
}

export default FloorbookSaga;
