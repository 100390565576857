import React, { useEffect, useState } from "react";
import { withSnackbar } from "notistack";
import { useLocation } from "react-router-dom";
import {
  CustomButton,
  LikesListingModal,
  Loader,
  CommentBox,
  ProfileModal,
} from "../../../../../components";
import moment from "moment";
import FbImageLibrary from "../../../../../components/cells/FbGridImages";
import { parseToHtml } from "../../../../../shared/services/Utility";
import {
  ACTIVITY_LOCATIONS,
  BUTTON_LABELS,
  ROUTE_CONSTANTS,
  STATUS,
  NO_DATA_FOUND_MESSAGES,
  LIKE_GRAY_ICON,
  LIKED_ICON,
  LIKE_ICON,
  COMMENTS_WHITE_ICON,
  COMMENTS_GRAY_ICON,
  CLOCK_ICON,
} from "../../../../../shared/constants";
import "./style.scss";

const Screen = ({
  getFlooorbook_PageDetails,
  history,
  enqueueSnackbar,
  likeFloorbookPage,
  loading,
  userRole,
  profileImage,
  deleteFloorbookPage,
  FetchDetailPageBy,
}) => {
  const [pageDetail, updatePageDetail] = useState({});
  const [likeStatus, set_likeStatus] = useState("");
  const [totalCount, set_totalCount] = useState(0);
  const [userLikes, set_userLikes] = useState([]);
  const [userTotalComments, set_userTotalComments] = useState("");
  const [userComments, set_userComments] = useState([]);
  const useQuery = () => {
    return new URLSearchParams(useLocation().search);
  };

  let query = useQuery();
  let id = query.get("page_id");

  useEffect(() => {
    let id = query.get("page_id");
    fetchData(id);
  }, []);

  const fetchData = (id) => {
    getFlooorbook_PageDetails(id, (message, type, data) => {
      if (type === STATUS.error) {
        enqueueSnackbar(message, {
          variant: type,
          autoHideDuration: 3000,
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "left",
          },
        });
        if (data === 404) {
          history.push(ROUTE_CONSTANTS.HOME);
        }
      }
      if (data) {
        console.log("page detail data",data)
        updatePageDetail(data);
        set_likeStatus(data.like_status);
        set_totalCount(data.total_count);
        set_userLikes(data.user_likes);
        set_userTotalComments(data.total_comments);
        set_userComments(data.comment);
      }
    });
  };

  let startTime;
  let endTime;
  if (pageDetail && pageDetail.activity_from && pageDetail.activity_to) {
    startTime = moment(pageDetail.activity_from).format("DD MMMM YYYY");
    endTime = moment(pageDetail.activity_to).format("DD MMMM YYYY");
  }

  const updateLike = (id) => {
    likeFloorbookPage(id, likeStatus, new Date().getTime(), 2, (data) => {
      set_likeStatus(data.status);
      set_totalCount(data.total_likes);
      set_userLikes([...data.user_likes]);
    });
  };

  const updateComments = (count, comments) => {
    set_userTotalComments(count);
    set_userComments(comments);
  };
  const onDeleteFloorbookPage = (id) => {
    deleteFloorbookPage(id, (message, type) => {
      enqueueSnackbar(message, {
        variant: type,
        autoHideDuration: 3000,
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "left",
        },
      });
      if (type === STATUS.success) {
        history.push({
          pathname: ROUTE_CONSTANTS.FLOORBOOK_DETAILS,
          search: `floorbook_id=${pageDetail.floorbook_id}`,
        });
      }
    });
  };
  let prevPath = history && history.location && history.location.state && history.location.state.from;

  return pageDetail ? (
    <>
      <div className="card-header d-flex justify-content-center justify-content-md-between align-items-center">
        {/* <HeadTitle
          title={
            prevPath === ROUTE_CONSTANTS.HOME
              ? HEAD_TITLES.HOME
              : prevPath === ROUTE_CONSTANTS.REPORT
              ? HEAD_TITLES.REPORT
              : prevPath === ROUTE_CONSTANTS.HISTORY
              ? HEAD_TITLES.HISTORY
              : FetchDetailPageBy === "HOME" // to maintain the same title while prev and next posts
              ? HEAD_TITLES.HOME
              : FetchDetailPageBy === "FLOORBOOK"
              ? HEAD_TITLES.FLOORBOOK
              : HEAD_TITLES.FLOORBOOK
          }
          titleCursor={true}
          titleOnClick={
            prevPath === ROUTE_CONSTANTS.HOME
              ? () => {
                  history.push(`${ROUTE_CONSTANTS.HOME}`);
                }
              : prevPath === ROUTE_CONSTANTS.REPORT
              ? () =>
                  history.push({
                    pathname: ROUTE_CONSTANTS.REPORT,
                    state: {
                      ...history.location.state,
                      id: pageDetail.floorbook_id,
                      name: pageDetail.floorbook_name,
                    },
                  })
              : prevPath === ROUTE_CONSTANTS.HISTORY
              ? () => history.push(`${ROUTE_CONSTANTS.HISTORY}`)
              : prevPath === ROUTE_CONSTANTS.FLOORBOOK_DETAILS
              ? () => {
                  history.push({
                    pathname: ROUTE_CONSTANTS.FLOORBOOK_DETAILS,
                    search: `floorbook_id=${pageDetail.floorbook_id}`,
                  });
                }
              : FetchDetailPageBy === "HOME"
              ? () => history.push(ROUTE_CONSTANTS.HOME)
              : () => {
                  history.push({
                    pathname: ROUTE_CONSTANTS.FLOORBOOK_DETAILS,
                    search: `floorbook_id=${pageDetail.floorbook_id}`,
                  });
                }
          }
          subTitle={[{ label: "Detail" }]}
        /> */}
        {/* <div className="hdr_btns ml-xl-auto">
          {pageDetail.is_edit && (userRole === USER_ROLES.ADMIN || userRole === USER_ROLES.EDITOR) ? (
            <>
              <CustomButton
                className="btn btn-md btn-primary text-capitalize"
                label={BUTTON_LABELS.EDIT}
                onButtonClick={() => history.push(`${ROUTE_CONSTANTS.EDIT_FLOORBOOK_PAGE}?page_id=${id}`)}
              />
              <DeleteModal
                btnLabel={BUTTON_LABELS.DELETE}
                type={2}
                id={pageDetail.id}
                onDelete={() => onDeleteFloorbookPage(pageDetail.id)}
                loading={loading}
              />
            </>
          ) : null}
        </div> */}
      </div>
      <div className="card-body pt-0">
        <div className="post_block">
          <div className="pre_nxt_btn">
            {(FetchDetailPageBy === "HOME" && pageDetail.prev_page_id
              ? true
              : FetchDetailPageBy === "FLOORBOOK" && pageDetail.prev_id
              ? true
              : false) && (
              <CustomButton
                className="btn btn-md text-capitalize btn-primary "
                label="Previous"
                onButtonClick={() => {
                  FetchDetailPageBy === "HOME" && fetchData(pageDetail.prev_page_id);
                  FetchDetailPageBy === "FLOORBOOK" && fetchData(pageDetail.prev_id);
                  history.push({
                    pathname: `${ROUTE_CONSTANTS.FLOORBOOK_PAGE_DETAILS}`,
                    search: `page_id=${FetchDetailPageBy === "HOME" ? pageDetail.prev_page_id : pageDetail.prev_id}`,
                    state: {
                      from: history.location.pathname,
                    },
                  });
                  set_userComments("");
                }}
              />
            )}
            {(FetchDetailPageBy === "HOME" && pageDetail.next_page_id
              ? true
              : FetchDetailPageBy === "FLOORBOOK" && pageDetail.next_id
              ? true
              : false) && (
              <CustomButton
                className="btn btn-md text-capitalize btn-primary "
                label="Next"
                onButtonClick={() => {
                  FetchDetailPageBy === "HOME" && fetchData(pageDetail.next_page_id);
                  FetchDetailPageBy === "FLOORBOOK" && fetchData(pageDetail.next_id);
                  history.push({
                    pathname: `${ROUTE_CONSTANTS.FLOORBOOK_PAGE_DETAILS}`,
                    search: `page_id=${FetchDetailPageBy === "HOME" ? pageDetail.next_page_id : pageDetail.next_id}`,
                    state: {
                      from: history.location.pathname,
                    },
                  });
                  set_userComments("");
                }}
              />
            )}
          </div>
          <div className="post_header">
            <div className="post_pic">
              <ProfileModal user_image={pageDetail.user_image} user_id={pageDetail.user_id} />
            </div>

            <div className="post_titles">
              <h4>{pageDetail.username}</h4>
              <span className="post_time">
                <i>
                  <img src={CLOCK_ICON} alt="" />
                </i>
                {startTime}-{endTime}
              </span>
              <span className="post_by">
                Floorbook Name:{" "}
                <strong
                  onClick={() =>
                    history.push({
                      pathname: ROUTE_CONSTANTS.FLOORBOOK_DETAILS,
                      search: `floorbook_id=${pageDetail.floorbook_id}`,
                      state: {
                        from: history.location.pathname,
                      },
                    })
                  }
                  style={{ color: "#000", cursor: "pointer" }}
                >
                  {pageDetail.floorbook_name}
                </strong>
              </span>
            </div>
          </div>
          <div className="px-3 my-3 my-md-4">
            <h4 className="p-0 mb-2">{pageDetail.title}</h4>
            <div className="row">
              <span className="col-md-4">
                Main Subject Area: <strong>{pageDetail.subject && pageDetail.subject.subject_title}</strong>
              </span>
              <span className="col-md-4">
                Location of Activity: <strong>{ACTIVITY_LOCATIONS[pageDetail.location_activity]}</strong>
              </span>
            </div>
            <div
              className="p_detail_sumary my-3"
              dangerouslySetInnerHTML={{ __html: parseToHtml(pageDetail.description) }}
            ></div>
          </div>
          <div className="post_body pt-0">
            <div className="carousal_slider">
              {pageDetail && pageDetail.image && (
                <FbImageLibrary images={pageDetail.image} renderOverlay={() => {}} lightbox={true} hideOverlay={true} />
              )}
            </div>
            <div className="post_status">
              <span>
                <i className="mr-2">
                  <img src={LIKE_GRAY_ICON} alt="" />
                </i>{" "}
                <LikesListingModal likes={totalCount} Likes_Data={userLikes || []} />
              </span>
              <span>
                <i className="mr-2">
                  <img src={COMMENTS_GRAY_ICON} alt="" />
                </i>{" "}
                {userTotalComments} Comments
              </span>
            </div>
          </div>
          <div className="d-flex justify-content-around flex-row post_footer">
            <CustomButton
              onButtonClick={() => {
                updateLike(pageDetail.id);
              }}
              icon={likeStatus ? LIKED_ICON : LIKE_ICON}
              className="btn btn-lg"
              label={likeStatus ? BUTTON_LABELS.LIKED : BUTTON_LABELS.LIKE}
            />
            <CustomButton icon={COMMENTS_WHITE_ICON} className="btn btn-lg" label={BUTTON_LABELS.COMMENT} />
          </div>
        </div>
      </div>
      <CommentBox
        id={pageDetail.id}
        comments={userComments || []}
        updateComments={updateComments}
        profileImage={profileImage}
      />
      {loading && <Loader />}
    </>
  ) : (
    <p className="d-flex justify-content-center">{NO_DATA_FOUND_MESSAGES.SOMETHING_WENT_WRONG}</p>
  );
};

export default withSnackbar(Screen);
