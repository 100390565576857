import React from "react";
import { Snackbar } from "@material-ui/core";
import { CustomizedSnackbars } from "../../atoms/custom-snackbar";

export const SnackbarWrapper = ({
  visible = false,
  variant,
  message,
  onClose = () => { },
}) => {
  return (
    <Snackbar
      open={visible}
      onClose={() => {
        onClose();
      }}
    >
      <CustomizedSnackbars
        onClose={() => {
          onClose();
        }}
        variant={variant}
        message={message}
      />
    </Snackbar>
  );
};
