import React, { useState, useEffect } from "react";
import { withStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogContent from "@material-ui/core/DialogContent";
import "./style.scss";

const DialogContent = withStyles((theme) => ({
  root: {
    // padding: theme.spacing(13),
  },
}))(MuiDialogContent);

const PreviewModal = ({ isOpen, source, width, onCancelClick, onDoneClick, sourceType }) => {
  const [open, setOpen] = useState(false);

  useEffect(() => {
    setOpen(isOpen);
  }, [isOpen]);

  const handleClose = () => {
    setOpen(false);
    onCancelClick();
  };

  return (
    <>
      <Dialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={open}>
        <DialogContent className="comment_preview p-0 d-flex align-items-center justify-content-center flex-column">
          <figure>
            {sourceType === "video/mp4" ? (
              <video width={"100%"} controls>
                <source src={source}></source>
              </video>
            ) : (
              <img src={source} alt=" " width={width} />
            )}
          </figure>
          <div className="preview_btns">
            <span className="prev_btn close_prev" onClick={() => onCancelClick()}>
              <img src={require("../../../assets/images/icons/close_icon.svg")} alt="" />
            </span>
            <span
              onClick={() => {
                onDoneClick();
                handleClose();
              }}
              className="prev_btn accept_prev"
            >
              <img src={require("../../../assets/images/icons/check-mark.svg")} alt="" />
            </span>
          </div>
        </DialogContent>
      </Dialog>
    </>
  );
};
export default PreviewModal;
