import { Get_HELP_DATA, SET_HELP_DATA } from "./ActionTypes";

export const getHelpInfo = (callback) => {
  return {
    type: Get_HELP_DATA,
    callback,
  };
};
export const setHelpInfo = (data, callback) => {
  return {
    type: SET_HELP_DATA,
    data,
    callback,
  };
};
