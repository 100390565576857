import React, { useEffect, useState } from "react";
import {
  Loader,
  AddNewFloorBookModal,
  ImageCarousel,
  UploadImage,
} from "../../../../../components";
import { reduxForm } from "redux-form";
import {
  ADD_FLOORBOOK_TYPE,
  HEAD_TITLES,
  STATUS,
  USER_ROLES,
} from "../../../../../shared/constants";
import validator from "./validator";
import { withSnackbar } from "notistack";
import moment from "moment";
import {
  ROUTE_CONSTANTS,
  BUTTON_LABELS,
} from "../../../../../shared/constants";
import { useLocation } from "react-router-dom";
import { connect } from "react-redux";
import {
  getBase64Image,
  dataURLtoFile,
  videoURLtoFile
} from "../../../../../shared/services/Utility";
import "./style.scss";
import Resizer from "react-image-file-resizer";
import { RenderForm } from "./form";

let DETAILS = {};
const AddForm = ({
  change = () => {},
  getAllInquiryLines,
  inquiryLines,
  myfloorbookData,
  getAllFloorbooks,
  getSubjectAreaDropDown,
  enqueueSnackbar,
  history,
  loading,
  handleSubmit = () => {},
  dispatchSaveFloorbook = () => {},
  subjectAreaData,
  getPageById,
  floorbookPageData,
  updateFloorbookPage,
  startLoading,
  stopLoading,
  userRole,
  updateDeleteLineofInquiry = () => {},
}) => {
  const [selectedSettingType, setSelectedSettingType] = useState([]);
  const [showStepOne, set_showStepOne] = useState(true);
  const [showStepTwo, set_showStepTwo] = useState(false);
  const [allInquiryLines, set_allInquiryLines] = useState([]);
  const [allfloorbooks, updateAllFloorbooks] = useState([]);
  const [updatedImages, set_updatedImages] = useState();
  const [allSubjects, set_allSubjects] = useState([]);
  const [images, set_images] = useState([]);
  const [editMode, update_editMode] = useState(false);
  const [imagesData, update_imagesData] = useState({});
  const [defaultImages_Edit, set_defaultImages_Edit] = useState([]);
  const [floorbookPageDataForEdit, set_floorbookPageDataForEdit] = useState({});

  const useQuery = () => {
    return new URLSearchParams(useLocation().search);
  };
  let query = useQuery();
console.log('default edit image in floorbook,images',defaultImages_Edit,images)
  //user role check
  useEffect(() => {
    if (USER_ROLES.ADMIN == userRole || USER_ROLES.EDITOR == userRole) {
      return;
    } else {
      history.push(ROUTE_CONSTANTS.HOME);
    }
  }, [userRole]);

  useEffect(() => {
    let path = history.location.pathname;
    let id = query.get("page_id");
    if (path == ROUTE_CONSTANTS.EDIT_FLOORBOOK_PAGE && id) {
      getPageById(id, (message, type, data) => {
        data.is_edit || USER_ROLES.ADMIN == userRole
          ? set_floorbookPageDataForEdit(data)
          : history.push(ROUTE_CONSTANTS.HOME);
      });
      update_editMode(true);
    }
  }, [history && history.location && history.location.pathname]);

  useEffect(() => {
    let path = history.location.pathname;
    if (path == ROUTE_CONSTANTS.ADD_NEW_FLOORBOOK_PAGE) {
      DETAILS = {};
    }
  }, [history && history.location && history.location.pathname]);

  useEffect(() => {
    console.log("floorbook page data for edit",floorbookPageDataForEdit)
    let path = history.location.pathname;
    let id = query.get("page_id");
    if (
      path == ROUTE_CONSTANTS.EDIT_FLOORBOOK_PAGE &&
      id &&
      editMode &&
      floorbookPageDataForEdit
    ) {
      DETAILS = floorbookPageDataForEdit;
      set_images(floorbookPageDataForEdit.image);
    }
  }, [floorbookPageDataForEdit]);

  useEffect(() => {
    // if (images.length) {
    //   let array = [];
    //  // let extensionType;
    //   images &&
    //     images.map((item, index) =>
    //       getBase64Image(item, `image${index + 1}`, async (value, url) => {
    //         const img = new Image();
    //         //check url for future
    //         const imageP = await resizeFile(value);
    //         img.src = imageP;
    //         let width;
    //         let height;
    //         img.onload = () => {
    //           width = img.width;
    //           height = img.height;
    //           let dataArray = {
    //             data: {
    //               imageP: imageP,
    //               width: width,
    //               height: height,
    //               size: value.size,
    //             },
    //             // name: multilple ? imageName : file.name,
    //             name: value.name,
    //             localUrl: "",
    //             faces: [],
    //             blurs: [],
    //             canvasDim: {},
    //             newURl: "",
    //           };
    //           startLoading();
    //           set_defaultImages_Edit((state) => [...state, dataArray]);
    //           setTimeout(() => {
    //             stopLoading();
    //           }, images.length * 1000);
    //         };
    //       }))
    //     //  }
    //     //  
    // }
    if (images && images.length) {
       console.log("images in default",images)
       let array = [];
       let extensionType;
       images &&
         images.map((item, index) => {
           let result = item.split("/");
           let result2 = result[result.length - 1];
           let type = result2.split(".");
           extensionType = type[type.length - 1];
           let imageTypes = ["jpg", "jpeg", "png"];
           let videoTypes = ["webm", "MP2", "MPEG", "MPE", "MPV", "mp4"];
           if (imageTypes.includes(extensionType)) {
             console.log("with extension")
             getBase64Image(item, `image${index + 1}`, async (value, url) => {
               const img = new Image();
               //check url for future
               const imageP = await resizeFile(value);
               img.src = imageP;
               let width;
               let height;
               img.onload = () => {
                 width = img.width;
                 height = img.height;
                 let dataArray = {
                   data: {
                     imageP: imageP,
                     width: width,
                     height: height,
                     size: value.size,
                     video:""
                   },
                   // name: multilple ? imageName : file.name,
                   name: value.name,
                   localUrl: "",
                   faces: [],
                   blurs: [],
                   canvasDim: {},
                   newURl: imageP,
                 };
                 startLoading();
                 set_defaultImages_Edit((state) => [...state, dataArray]);
                 setTimeout(() => {
                   stopLoading();
                 }, images.length * 1000);
               };
             });
           } else {
              console.log("item no extension", item);
            //  let binaryVideo = getBase64Image(item);
//console.log("binary vdieo", binaryVideo);
           //  const videoData = dataURLtoFile(item, `filename[${index}]`);
         //    console.log("video data",videoData)
            //  getBase64Image(item, `image${index + 1}`, async (value, url) => {
            //      const img = new Image();
            //      //check url for future
            //      const imageP = await resizeFile(value);
            //    img.src = imageP;
            //    img.onload = () => {
                 let dataArray = {
                   data: {
                     video: "",
                     imageP: "",
                     width: "",
                     height: "",
                     size: "",
                   },

                   name: `filename${index + 1}`,
                   localUrl: "",
                   faces: [],
                   blurs: [],
                   canvasDim: {},
                   newURl: "",
                   videoUrl: item,
                 };
                 set_defaultImages_Edit((state) => [...state, dataArray]);
                 setTimeout(() => {
                   stopLoading();
                 }, images.length * 1000);
               }
             // });
          // }
         });
     }

  }, [images]);
  // put resizeFile in utility
  const resizeFile = (file) =>
    new Promise((resolve) => {
      Resizer.imageFileResizer(
        file,
        1050,
        720,
        "JPEG",
        100,
        0,
        (uri) => {
          resolve(uri);
        },
        "base64"
      );
    });

  useEffect(() => {
    getAllInquiryLines((message, type) => {});
    getAllFloorbooks((message, type) => {});
    getSubjectAreaDropDown((message, type) => {});
  }, []);

  useEffect(() => {
    let arr = [];
    inquiryLines &&
      inquiryLines.map((item) => {
        let obj = { value: item.id, label: item.name };
        arr.push(obj);
      });
    set_allInquiryLines(arr);
  }, [inquiryLines]);

  useEffect(() => {
    let arr = [];
    if (
      (myfloorbookData && myfloorbookData.length && !editMode) ||
      (editMode && floorbookPageDataForEdit && floorbookPageDataForEdit.is_edit)
    ) {
      myfloorbookData.map((item) => {
        if (!item.is_learning_journal) {
          let obj = { value: item.id, label: item.name };
          arr.push(obj);
        }
      });
    } else if (floorbookPageDataForEdit && floorbookPageDataForEdit.floorbook) {
      let obj = {
        value: floorbookPageDataForEdit.floorbook_id,
        label: floorbookPageDataForEdit.floorbook,
      };
      arr.push(obj);
    }

    updateAllFloorbooks(arr);
  }, [myfloorbookData]);

  useEffect(() => {
    let arr = [];
    subjectAreaData &&
      subjectAreaData.map((item) => {
        let obj = { value: item.id, label: item.name };
        arr.push(obj);
      });
    set_allSubjects(arr);
  }, [subjectAreaData]);
console.log("floorbook page data for edit",floorbookPageDataForEdit)
  const renderData = () => {
    return (
      <div className="upload_pics">
        {showStepOne && (
          <>
            <UploadImage
              accept=".jpg, .jpeg, .png .webm,.MP2,.MPEG,.MPE,.MPV,.mp4"
              multilple={true}
              onlyImages={true}
              defaultImages={
                //sort the images alphabetically
                defaultImages_Edit.length === images.length
                  ? defaultImages_Edit.sort((a, b) =>
                      a.name.localeCompare(b.name)
                    )
                  : ""
              }
              carasol={true} //prop for show carasol or not ,as in add floorbook we dont need carasol
              callback={callback} //callback to show images when user click go back to see images
              DataForEdit={imagesData.length ? imagesData : ""}
            />
          </>
        )}
      </div>
    );
  };

  // const callback = (values) => {
  //   //for update the values on go back, and edit'
  //   update_imagesData(values);
  //   let arr = [];
  //   values &&
  //     values.map((item) => {
  //       let obj = { imageP: item.newURl };
  //       arr.push(obj);
  //     });

  //   set_updatedImages(arr);
  //   set_showStepOne(false);
  //   set_showStepTwo(true);
  // };
  const callback = (values) => {
    //for update the values on go back, and edit
    update_imagesData(values);
    console.log("valuess in callback", values);
    let arr = [];
    let binaryImage;
    let binaryVideo;
    values &&
      values.map((item, i) => {
        console.log("items in callback", item);
       if (item.data.imageP) {
          binaryImage = dataURLtoFile(item.newURl, `filename[${i}]`);
       }
      //  else {
      //    binaryVideo = videoURLtoFile(item.videoUrl, `filename[${i}]`);
      //  }
      //  console.log("binary video in conversion",binaryVideo)
        // console.log("video url", item.videoUrl && item.videoUrl)
        // const ur = item.data.video
        //   ? item.data.video.name.split(".")
        //   : item.videoUrl.split(".");
        // let url = item.data.video ? item.data.video.name.split(' ')[0] : item.videoUrl.split(' ')[0];
        // url = url ? url+`.${ur[ur.length - 1]}` : null
        // console.log("urlls after modif", url)
      //  item.data.video.name = `filename[${i}]`
      // let videourlToFile = item.data.video ? null : videoURLtoFile(url,`filename[${i}]`)
        let obj = {
          imageP: item.newURl,
          file: item.videoUrl
            ? item.data.video || item.videoUrl
            : binaryImage
            ? binaryImage
            : "",
          video: item.data.video ? item.data.video : binaryVideo,
          videoUrl: item.videoUrl,
        };
        arr.push(obj);
      });
console.log("array after conversion",arr)
    set_updatedImages(arr); // to show the images/files in carasol on second step
    set_showStepOne(false);
    set_showStepTwo(true);
  };
  // update the floorbook page
  const upadatePage = (data, id) => {
    console.log("floorbook update entry data",data)
    updateFloorbookPage(id, data, (message, type) => {
      enqueueSnackbar(message, {
        variant: type,
        autoHideDuration: 3000,
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "left",
        },
      });
      if (type === STATUS.success) {
        //if success(floorbook get updated) than redirect to floorbook details page
        history.push({
          pathname: ROUTE_CONSTANTS.FLOORBOOK_DETAILS,
          search: `floorbook_id=${data.floorbook_id}`,
        });
      }
    });
  };

  const updateDeleteLineOfInquiries = (id, name, action) => {
    let data = {
      id: id,
      name: name,
      action: action,
    };
    updateDeleteLineofInquiry(data, (message, type) => {
      enqueueSnackbar(message, {
        variant: type,
        autoHideDuration: 3000,
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "left",
        },
      });
      if (type === STATUS.success) {
        getAllInquiryLines((message, type) => {});
        //if success(floorbook get updated) than redirect to floorbook details page
      }
    });
  };
  const submit = (values) => {
    let startTime = moment(values.startDate).format("YYYY-MM-DD");
    let endTime = moment(values.endDate).format("YYYY-MM-DD");

    let postData = {
      floorbook_id: values.floorbookName.value,
      title: values.title,
      line_inquiry: values.lineOfInquiry.value,
      activity_from: startTime,
      activity_to: endTime,
      is_learning_journal:0,
      description: values.description,
      location: values.location,
      subject: values.subject.value,
      improvement: values.reflections,
      updatedImages: updatedImages,
    };
    if (selectedSettingType.label == "Other") {
      postData = {
        ...postData,
        other_line_inquiry: values.other_lineOfInquiry,
      };
    }

    if (editMode == true) {
      upadatePage(postData, values.id);
    } else {
      dispatchSaveFloorbook(postData, (message, type) => {
        console.log("post data in floorbook", postData);
        enqueueSnackbar(message, {
          variant: type,
          autoHideDuration: 3000,
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "left",
          },
        });
        if (type === STATUS.success) {
          //if success(floorbook saved) than redirect to floorbook details page
          history.push({
            pathname: ROUTE_CONSTANTS.FLOORBOOK_DETAILS,
            search: `floorbook_id=${values.floorbookName.value}`,
          });
        }
      });
    }
  };
  return (
    <>
      <div
        className="card-header d-flex justify-content-start
         justify-content-md-between align-items-start align-items-md-center"
      >
        <h4>
          {editMode
            ? HEAD_TITLES.EDIT_FLOORBOOK_PAGE
            : HEAD_TITLES.ADD_NEW_FLOORBOOK_PAGE}
        </h4>
      </div>
      {loading ? (
        <Loader />
      ) : myfloorbookData.length ||
        (USER_ROLES.ADMIN == userRole && editMode) ? (
        <>
          <ul className="list-unstyled d-flex align-items-center justify-content-between steps_list">
            <li className={showStepOne ? "active" : "active completed"}>
              {showStepTwo ? (
                <span>
                  <img
                    src={require("../../../../../assets/images/icons/complete_icon.svg")}
                    width={20}
                  />
                </span>
              ) : (
                <span>1</span>
              )}
              Step 1
            </li>
            <li className={showStepTwo ? "active" : "completed"}>
              <span>2</span>
              Step 2
            </li>
          </ul>

          {renderData()}
          <div className="card-body pt-0">
            {showStepTwo && (
              <>
                {updatedImages.length ? (
                  <div className="pixelate_slider">
                    <ImageCarousel images={updatedImages} />
                  </div>
                ) : (
                  " "
                )}
                <div className="floorbook_form">
                  {/* Render the form component */}
                  <RenderForm
                    handleSubmit={handleSubmit}
                    submit={submit}
                    allfloorbooks={allfloorbooks}
                    floorbookPageDataForEdit={floorbookPageDataForEdit}
                    allSubjects={allSubjects}
                    allInquiryLines={allInquiryLines}
                    setSelectedSettingType={setSelectedSettingType}
                    change={change}
                    selectedSettingType={selectedSettingType}
                    set_showStepOne={set_showStepOne}
                    set_showStepTwo={set_showStepTwo}
                    updateDeleteLineOfInquiries={updateDeleteLineOfInquiries}
                  />
                </div>
              </>
            )}
          </div>
        </>
      ) : (
        <div className="p-4 p-md-5 add_floor_first">
          <p>
            There is no floorbook added yet. First add a floorbook to create its
            page. You can add one by clicking the below button.
          </p>
          <AddNewFloorBookModal
            btnLabel={BUTTON_LABELS.ADD_NEW_FLOORBOOK}
            formType={ADD_FLOORBOOK_TYPE.create}
            className="btn btn-lg btn-primary text-capitalize"
          />
        </div>
      )}
    </>
  );
};

let showForm = reduxForm({
  form: "addFloorbookPage",
  validate: validator,
  enableReinitialize: true,
})(AddForm);

showForm = connect((state) => {
  return {
    initialValues: DETAILS
      ? {
          id: DETAILS.id,
          floorbookName: {
            value: DETAILS.floorbook_id,
          },
          title: DETAILS.title,
          lineOfInquiry: {
            value: DETAILS.inquiry_line,
          },
          startDate: DETAILS.activity_from,
          endDate: DETAILS.activity_to,
          description: DETAILS.description,
          location: DETAILS.location_activity,
          subject: {
            value: DETAILS.subject,
          },
          reflections: DETAILS.improvement,
        }
      : {},
    loading: state.CommonReducer.isLoading,
  };
})(showForm);

export default Screen = withSnackbar(showForm);
