import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import CustomButton from "../../atoms/CustomButton";
import ImageOverlay from "../../atoms/ImageOverlay";
import { BUTTON_LABELS, LOGOUT_TAB_ICON } from "../../../shared/constants";
import ReactLoading from "react-loading";
import "./style.scss";
const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon style={{ color: "#0D532D" }} />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DeleteModal = ({ btnLabel, type, id, onDelete = () => {}, loading }) => {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      {type === 5 ? (
        <div className="nav-item">
          <ImageOverlay handleClick={handleClickOpen}>
            <i className="mr-3">
              <img src={LOGOUT_TAB_ICON} alt="logout" width="20" />
            </i>
            {btnLabel}
          </ImageOverlay>
        </div>
      ) : (
        <CustomButton
          className={
            type === 3 ? "" : type === 1 ? "btn btn-sm text-capitalize btn-primary" : "btn text-capitalize btn-primary"
          }
          label={btnLabel}
          onButtonClick={handleClickOpen}
        />
      )}
      <Dialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={open}>
        <DialogTitle id="customized-dialog-title" onClose={handleClose} />
        <MuiDialogContent className="add_floor_box d-flex align-items-center justify-content-center flex-column">
          <h6 className="text-center mb-4">
            Are you sure <br />
            you want to{" "}
            {type === 1
              ? "delete this Floorbook"
              : type === 2
              ? " delete this Floorbook Page "
              : type == 6
              ? " delete this Journal entry"
              : type == 4
              ? "delete this Resource "
              : type === 5
              ? "Logout"
              : "delete this Comment "}{" "}
            ?
          </h6>
          <div className="btn-contain">
            <CustomButton
              className="btn btn-primary text-capitalize"
              label={BUTTON_LABELS.YES}
              onButtonClick={() => {
                onDelete(id);
                handleClose();
              }}
            />

            <CustomButton
              className="btn btn-cancel text-capitalize"
              label={BUTTON_LABELS.CANCEL}
              onButtonClick={() => handleClose()}
            />
          </div>
          {loading ? <ReactLoading type={"spinningBubbles"} color={"#0d532d"} height={"50px"} width={"50px"} /> : null}
        </MuiDialogContent>
      </Dialog>
    </>
  );
};
export default DeleteModal;
