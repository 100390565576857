import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { CustomButton } from "../../atoms";

import {
  BUTTON_LABELS,
  MAX_DESCRIPTION_SHOW_LENGTH,
  USER_ROLES,
  ROUTE_CONSTANTS,
  CLOCK_ICON,
  LIKE_GRAY_ICON,
  LIKE_ICON,
  LIKED_ICON,
  COMMENTS_GRAY_ICON,
  COMMENTS_WHITE_ICON,
  HEART_OUTLINE,
  HEART_FILL,
  STATUS,
} from "../../../shared/constants";
import { useHistory } from "react-router-dom";
import LikesListingModal from "../LikesListingModal";
import DeleteModal from "../DeleteModal";
import ProfileModal from "../ProfileModal";
import moment from "moment";
import "./style.scss";
import { parseToHtml } from "../../../shared/services/Utility";
import FbImageLibrary from "../../cells/FbGridImages";
import { likeJournal, likeFloorbookPage } from "../../../redux/actions";

const Display = ({
  idx,
  loading,
  likeFloorbookPage = () => {},
  id,
  title,
  name,
  post_id,
  isJournal = false,
  images,
  description,
  activity_from,
  activity_to,
  username,
  likes,
  comments,
  floorbookPagePost,
  likeStatus,
  likedUsers = [],
  is_edit,
  city,
  country,
  is_learning_journal,
  setting_name,
  pageType,
  createdAt,
  userData,
  user_image,
  user_id,
  profileData,
  isMyJournal = false,
  favourite = false,
  is_fav = "",
  onDelete = () => {},
  savePagePosition = () => {},
  saveFloorbookdetailPagePosition = () => {},
  fetchFloorboookOrder = () => {},
  updateFav = () => { },
  fetchDataFxn = () => {},
  journalType,
  likeJournal = () => {},
}) => {
  let history = useHistory();
  let startTime = moment(activity_from).format("DD MMMM");
  let endTime = moment(activity_to).format("DD MMMM");

  const [totalLikes, set_totalLikes] = useState(likes || likedUsers.length);
  const [userLikes, set_userLikes] = useState(likedUsers);
  const [statusLike, set_statusLike] = useState(likeStatus);

  const [descriptionIn_Html, upadte_descriptionIn_Html] = useState("");

  useEffect(() => {
    upadte_descriptionIn_Html(parseToHtml(description));
  }, [description]);
  const [showFull, set_showFull] = useState(false);

  return (
    <>
      <div className="post_block" id={`post${id}`}>
        {floorbookPagePost && (
          <h4>
            {username} added new page in{" "}
            <span
              style={{ textDecoration: "underline" }}
              onClick={() => {
                if (isJournal) {
                  history.push({
                    pathname: ROUTE_CONSTANTS.JOURNAL_DETAILS,
                    // search: `floorbook_id=${post_id}`,
                    // state: {
                    //   from: history.location.pathname,
                    // },
                  });
                } else {
                  savePagePosition(`post${id}`);
                  history.push({
                    pathname: ROUTE_CONSTANTS.FLOORBOOK_DETAILS,
                    search: `floorbook_id=${post_id}`,
                    state: {
                      from: history.location.pathname,
                    },
                  });
                }
              }}
            >
              {name}
            </span>
          </h4>
        )}

        <div className="post_header">
          <div className="post_pic">
            <ProfileModal user_image={user_image} user_id={user_id} />
          </div>
          <div className="post_titles">
            <h4
              style={{ cursor: "pointer" }}
              onClick={() => {
                if (!isJournal) {
                  if (floorbookPagePost) {
                    savePagePosition(`post${id}`);
                    fetchFloorboookOrder("HOME");
                  } else {
                    fetchFloorboookOrder("FLOORBOOK");
                    saveFloorbookdetailPagePosition(`post${id}`);
                  }

                  history.push({
                    pathname: `${ROUTE_CONSTANTS.FLOORBOOK_PAGE_DETAILS}`,
                    search: `page_id=${id}`,
                    state: {
                      from: history.location.pathname,
                    },
                  });
                } else {
                  history.push({ pathname: ROUTE_CONSTANTS.JOURNAL_PAGE_DETAILS, search: `page_id=${id}` });
                }
              }}
            >
              {title}
            </h4>
            {floorbookPagePost ? (
              <>
                <span className="post_by">By: {username}{" "}  |{" "}{setting_name}{" "}|{" "}{city}{" "}|{" "}{country}</span>
                </>
            ) : (
              <span className="post_detail_time">
                <i>
                  <img src={CLOCK_ICON} alt="" />
                </i>{" "}
                {startTime}-{endTime}
              </span>
            )}

            {floorbookPagePost ? (
              <span className="post_time">
                <i>
                  <img src={CLOCK_ICON} alt="" />
                </i>{" "}
                {moment(activity_from).format("DD MMM YYYY")}
              </span>
            ) : is_edit || userData.user_role === USER_ROLES.ADMIN || isMyJournal ? (
              <>
                <div className="ed_btn">
                  <CustomButton
                    className="btn text-capitalize btn-primary"
                    label={BUTTON_LABELS.EDIT}
                    onButtonClick={() => {
                      isJournal
                        ? history.push(`${ROUTE_CONSTANTS.EDIT_JOURNAL_ENTRY}?journal_id=${id}`)
                        : history.push(`${ROUTE_CONSTANTS.EDIT_FLOORBOOK_PAGE}?page_id=${id}`);
                    }}
                  />
                  <DeleteModal
                    btnLabel={BUTTON_LABELS.DELETE}
                    type={isMyJournal || isJournal ? 6 : 2}
                    id={id}
                    onDelete={(id) => onDelete(id)}
                    loading={loading}
                  />
                  {favourite ? (
                    <button
                      className="btn-favourite"
                      onClick={() => {
                        //set favourit api call
                        updateFav(id, Number(is_fav) || journalType, journalType, idx);
                      }}
                    >
                      {is_fav || journalType ? (
                        <i>
                          <img src={HEART_FILL} alt="" />
                        </i>
                      ) : (
                        <i>
                          <img src={HEART_OUTLINE} alt="" />
                        </i>
                      )}
                    </button>
                  ) : null}
                </div>
              </>
            ) : null}
          </div>
        </div>
        <div className="post_body">
          <div className="carousal_slider">
            <FbImageLibrary //component to show images in grid
              images={images}
              renderOverlay={() => {}}
              hideOverlay={true}
              lightbox={false}
              onClickEach={() => {
                if (!is_learning_journal) {
                  if (floorbookPagePost) {
                    savePagePosition(`post${id}`);
                    fetchFloorboookOrder("HOME");
                  } else {
                    fetchFloorboookOrder("FLOORBOOK");
                    saveFloorbookdetailPagePosition(`post${id}`);
                  }

                  history.push({
                    pathname: `${ROUTE_CONSTANTS.FLOORBOOK_PAGE_DETAILS}`,
                    search: `page_id=${id}`,
                    state: {
                      from: history.location.pathname,
                    },
                  });
                } else {
                  history.push({
                    //route for detail page
                    pathname: `${ROUTE_CONSTANTS.JOURNAL_DETAILS}`,
                    search: `id=${id}`,
                    state: {
                      from: history.location.pathname,
                    },
                  });
                }
              }}
            />
          </div>
          <div className="post_description">
            {description && (
              <>
                <div
                  className="w-100 text-justify"
                  dangerouslySetInnerHTML={
                    showFull
                      ? { __html: descriptionIn_Html }
                      : { __html: descriptionIn_Html.slice(0, MAX_DESCRIPTION_SHOW_LENGTH) }
                  }
                ></div>
                {showFull ? (
                  <a
                    style={{ cursor: "pointer", fontWeight: "500", color: "#0D532D" }}
                    onClick={() => set_showFull(false)}
                  >
                    {BUTTON_LABELS.SHOW_LESS}
                  </a>
                ) : description.length >= MAX_DESCRIPTION_SHOW_LENGTH ? (
                  <a
                    style={{ cursor: "pointer", fontWeight: "500", color: "#0D532D" }}
                    onClick={() => set_showFull(true)}
                  >
                    {BUTTON_LABELS.SHOW_MORE}
                  </a>
                ) : null}
              </>
            )}
          </div>
          <div className="post_status">
            <span>
              <i className="mr-2">
                <img src={LIKE_GRAY_ICON} alt="" />
              </i>
              {/* listing of likes */}
              <LikesListingModal likes={totalLikes} Likes_Data={userLikes || []} />
            </span>
            <span
              // redirect to page details
              onClick={() => {
                if (floorbookPagePost) {
                  savePagePosition(`post${id}`);
                  fetchFloorboookOrder("HOME");
                } else {
                  fetchFloorboookOrder("FLOORBOOK");
                  saveFloorbookdetailPagePosition(`post${id}`);
                }
                history.push({
                  pathname: `${ROUTE_CONSTANTS.FLOORBOOK_PAGE_DETAILS}`,
                  search: `page_id=${id}`,
                  state: {
                    from: history.location.pathname,
                  },
                });
              }}
            >
              <i className="mr-2">
                <img src={COMMENTS_GRAY_ICON} alt="" />
              </i>{" "}
              {comments} {BUTTON_LABELS.COMMENTS}
            </span>
          </div>
        </div>
        <div className="d-flex justify-content-around flex-row post_footer">
          <CustomButton
            onButtonClick={() => {
              if (isJournal) {
                let postdata = {
                  id: id,
                  status: statusLike,
                };
                likeJournal(postdata, (data, type) => {
                  if (type == STATUS.success) {
                    set_statusLike(data.status);
                    set_userLikes(data.user_likes);
                    set_totalLikes(data.total_likes);
                  }
                });
              } else {
                likeFloorbookPage(id, statusLike, new Date().getTime(), pageType, (data) => {
                  set_totalLikes(data.total_likes);
                  set_userLikes(data.user_likes);
                  set_statusLike(data.status);
                });
              }
            }}
            icon={statusLike ? LIKED_ICON : LIKE_ICON}
            className="btn btn-lg"
            label={statusLike ? BUTTON_LABELS.LIKED : BUTTON_LABELS.LIKE}
          />
          <CustomButton
            icon={COMMENTS_WHITE_ICON}
            className="btn btn-lg"
            label={BUTTON_LABELS.COMMENT}
            onButtonClick={() => {
              if (floorbookPagePost) {
                savePagePosition(`post${id}`);
                fetchFloorboookOrder("HOME");
              } else {
                fetchFloorboookOrder("FLOORBOOK");
                saveFloorbookdetailPagePosition(`post${id}`);
              }
              history.push({
                pathname: `${ROUTE_CONSTANTS.FLOORBOOK_PAGE_DETAILS}`,
                search: `page_id=${id}`,
                state: {
                  from: history.location.pathname,
                },
              });
            }}
          />
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    state,
    loading: state.CommonReducer.isLoading,
    userData: state.CommonReducer.userData,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    likeFloorbookPage: (data, status, created_at, pageType, callback) =>
      dispatch(likeFloorbookPage(data, status, created_at, pageType, callback)),
    likeJournal: (data, callback) => dispatch(likeJournal(data, callback)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Display);
