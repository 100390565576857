import React from "react";
import { useHistory } from "react-router-dom";
import { ROUTE_CONSTANTS } from "../../../shared/constants/Route";
import ImageOverlay from "../ImageOverlay";
import "./style.scss";

const Image = ({ email, name, onToggle, profile_pic }) => {
  const history = useHistory();
  return (
    <>
      <div
        className="user-info"
        onClick={() => {
          onToggle();
          history.push(ROUTE_CONSTANTS.PROFILE);
        }}
      >
        <figure>
          <span style={{ cursor: "pointer" }}>
            <ImageOverlay>
              <img src={profile_pic || require("../../../assets/images/no-user.png")} alt="" width={60} />
            </ImageOverlay>
          </span>
        </figure>
        <h6
          className="mb-0 nav-user-name"
          style={{ cursor: "pointer" }}
          onClick={() => {
            onToggle();
            history.push(ROUTE_CONSTANTS.PROFILE);
          }}
        >
          {name}
        </h6>
        <span className="user_email">{email}</span>
      </div>
    </>
  );
};

export default Image;
