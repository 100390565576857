import React, { Component } from "react";
import Lightbox from "react-image-lightbox";
import ReactImageVideoLightbox from "./VideoImageLightBox"; // This only needs to be imported once in your app
import "react-image-lightbox/style.css";

class ModalComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      images: props.images || [],
      currentImageIndex: props.index,
      ImagesData: props.ImagesData,
    };

    this.onMovePrevRequest = this.onMovePrevRequest.bind(this);
    this.onMoveNextRequest = this.onMoveNextRequest.bind(this);
  }

  onMovePrevRequest() {
    const { currentImageIndex, images } = this.state;

    this.setState({
      currentImageIndex: (currentImageIndex + images.length - 1) % images.length,
    });
  }

  onMoveNextRequest() {
    const { currentImageIndex, images } = this.state;

    this.setState({
      currentImageIndex: (currentImageIndex + 1) % images.length,
    });
  }

  render() {
    const { images, currentImageIndex,ImagesData } = this.state;
    const { onClose, index } = this.props;
   if(ImagesData && ImagesData[index] && ImagesData[index].type == 'photo') return (
    <Lightbox
        mainSrc={images[currentImageIndex]}
       nextSrc={images[currentImageIndex + 1] ? images[(currentImageIndex + 1) % images.length] : null} // show the next btn only when there is image
       prevSrc={images[currentImageIndex - 1] ? images[(currentImageIndex + images.length - 1) % images.length] : null} // show the prev btn only when there is image
        onCloseRequest={() => {
          this.setState({ isOpen: false });
          onClose();
        }}
        onMovePrevRequest={() =>
          this.setState({
            currentImageIndex: (currentImageIndex + images.length - 1) % images.length,
          })
        }
        onMoveNextRequest={() =>
          this.setState({
            currentImageIndex: (currentImageIndex + 1) % images.length,
          })
        }
        ImagesData={this.state.ImagesData}
      />
    );
   else {
     return (
        <ReactImageVideoLightbox
        data={this.state.ImagesData}
        showResourceCount={true}
        onCloseCallback={() => {
          onClose();
        }}
        startIndex={currentImageIndex}
      />
     )
}
    // ) : (
    //   <ReactImageVideoLightbox
    //     data={this.state.ImagesData}
    //     showResourceCount={true}
    //     onCloseCallback={() => {
    //       onClose();
    //     }}
    //     startIndex={currentImageIndex}
    //   />
    // );
  }
}

export default ModalComponent;
