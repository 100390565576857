import React, { useEffect, useState, useRef } from "react";
import "./styles.scss";
import { Input, CustomButton, CustomOTP, Form, Loader } from "../../../components";
import { BUTTON_LABELS, INPUT_TYPE, STATUS, STRINGS } from "../../../shared/constants";
import { ROUTE_CONSTANTS } from "../../../shared/constants";
import { Field, reduxForm } from "redux-form";
import { VALIDATION_MESSAGES, EMAIL_REGX } from "../../../shared/constants";
import { withSnackbar } from "notistack";
import { LOGO } from "../../../shared/constants";

const validator = (values) => {
  const errors = {};

  if (!values[STRINGS.EMAIL_INPUT_NAME]) {
    errors[STRINGS.EMAIL_INPUT_NAME] = VALIDATION_MESSAGES.EMAIL_REQUIRED;
  } else if (values) {
    if (!values[STRINGS.EMAIL_INPUT_NAME].trim()) errors[STRINGS.EMAIL_INPUT_NAME] = VALIDATION_MESSAGES.EMAIL_REQUIRED;

    if (values[STRINGS.EMAIL_INPUT_NAME].trim() && !EMAIL_REGX.test(values[STRINGS.EMAIL_INPUT_NAME])) {
      errors[STRINGS.EMAIL_INPUT_NAME] = VALIDATION_MESSAGES.EMAIL_INVALID;
    }
  }

  if (!values[STRINGS.NEW_PASSWORD_INPUT]) {
    errors[STRINGS.NEW_PASSWORD_INPUT] = VALIDATION_MESSAGES.PASSWORD_REQUIRED;
  } else if (values) {
    if (!values[STRINGS.NEW_PASSWORD_INPUT].trim())
      errors[STRINGS.NEW_PASSWORD_INPUT] = VALIDATION_MESSAGES.PASSWORD_REQUIRED;

    if (
      values[STRINGS.NEW_PASSWORD_INPUT].trim() &&
      (values[STRINGS.NEW_PASSWORD_INPUT].length == 3 || values[STRINGS.NEW_PASSWORD_INPUT].length < 4)
    ) {
      errors[STRINGS.NEW_PASSWORD_INPUT] = VALIDATION_MESSAGES.PASSWORD_VALIDATE;
    }
  }
  if (!values[STRINGS.CONFIRM_PASSWORD_INPUT]) {
    errors[STRINGS.CONFIRM_PASSWORD_INPUT] = VALIDATION_MESSAGES.PASSWORD_REQUIRED;
  }
  if (values[STRINGS.CONFIRM_PASSWORD_INPUT] != values[STRINGS.NEW_PASSWORD_INPUT])
    errors[STRINGS.CONFIRM_PASSWORD_INPUT] = VALIDATION_MESSAGES.CONFIRM_PASSWORD_VALIDATE;

  return errors;
};

const FogetPasswordScreen = ({
  history,
  loading,
  handleSubmit = () => {},
  sendEmailForPasswordReset,
  enqueueSnackbar,
  submitOtpOfUser,
  updatePassword,
}) => {
  const [loader, set_loader] = useState(false);
  const [step, updateStep] = useState(1);
  const [otp, updateOTP] = useState("");
  const [email, updateEmail] = useState("");
  const [error, updateError] = useState("");
  const [showResendBtn, set_showResendBtn] = useState(false);

  const [startTimer, set_startTimer] = useState(false);

  const [timer, setTimer] = useState(15);
  const id = useRef(null);
  const clear = () => {
    window.clearInterval(id.current);
  };

  useEffect(() => {
    id.current = window.setInterval(() => {
      if (timer >= 0) setTimer((time) => time - 1);
    }, 60000);
    return () => clear();
  }, [startTimer, timer]);

  useEffect(() => {
    if (timer === 0) {
      clear();
      set_showResendBtn(true);
    }
  }, [timer]);

  useEffect(() => {
    set_loader(loading);
  }, [loading]);

  const submit = (values) => {
    updateEmail(values.email);
    // updateStep(2)
    sendMailForOTP(values.email);
  };

  const sendMailForOTP = (email) => {
    let data = {
      email: email,
    };

    sendEmailForPasswordReset(data, (message, type) => {
      enqueueSnackbar(message, {
        variant: type,
        autoHideDuration: 3000,
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "left",
        },
      });

      if (type === STATUS.success) {
        updateStep(2);
        setTimer(15);
        set_startTimer(true);
        set_showResendBtn(false);
      }
      getOTP_By_User("");
    });
  };

  const getOTP_By_User = (value) => {
    updateOTP(value);
  };

  const onSubmitOTP = () => {
    if (otp.length === 4) {
      let data = {
        email: email,
        otp: otp,
      };
      updateError("");
      submitOtpOfUser(data, (message, type) => {
        enqueueSnackbar(message, {
          variant: type,
          autoHideDuration: 3000,
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "left",
          },
        });
        if (type === STATUS.success) {
          updateStep(3);
        }
      });
    } else {
      updateError(STRINGS.OTP_REQIURED);
    }
  };
  const submitPassword = (values) => {
    let data = {
      email: values.email,
      password: values.newPassword,
      password_confirmation: values.confirmPassword,
    };
    updatePassword(data, (message, type) => {
      enqueueSnackbar(message, {
        variant: type,
        autoHideDuration: 3000,
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "left",
        },
      });
      if (type === STATUS.success) {
        history.push(ROUTE_CONSTANTS.LOGIN);
      }
    });
  };

  /********* user email input form *************/
  const step1 = () => {
    return (
      <div className="text-center">
        <h3> {STRINGS.FORGOT_PASSWORD_LABEL} </h3>
        <p className="mt-4 mb-5 sub_info">
          Enter the email address <br />
          associated with your account
        </p>
        <Form onSubmit={handleSubmit(submit)}>
          <div className="row">
            <div className="col-12">
              <Field name={STRINGS.EMAIL_INPUT_NAME} component={Input} placeholder={STRINGS.EMAIL_PLACEHOLDER} />
            </div>
          </div>

          <CustomButton
            label={BUTTON_LABELS.SEND}
            classList={["password"]}
            className="w-100 btn btn-lg text-capitalize btn-primary mt-3"
            type="submit"
          />
        </Form>
        <div className="text-center mt-3 gotos">
          <span onClick={() => history.push(ROUTE_CONSTANTS.LOGIN)}>{BUTTON_LABELS.LOGIN}</span>
        </div>
      </div>
    );
  };

  /********* OTP input form *************/
  const step2 = () => {
    return (
      <div className="text-center">
        <h3>{STRINGS.VERIFICATION_LABEL}</h3>
        <p className="mt-4 mb-5 sub_info">
          Enter the verification code <br />
          we just sent on your email address.
        </p>
        <div className="text-left">
          <div className="row">
            <div className="col-12">
              <div className="form-group text-center">
                <label>{"Code"}</label>
                <div className="mb-5">
                  <CustomOTP
                    value={true}
                    showResendBtn={showResendBtn}
                    sendOtp={(otp) => {
                      updateOTP(otp);
                    }}
                    clickMailOtp={() => {
                      sendMailForOTP(email);
                    }}
                    numberOfOtpDigits={4}
                    getOTP={getOTP_By_User}
                  />
                  <div> {timer ? `Time left : ${timer} minutes ` : null}</div>
                  <p className="error_msg text-danger text-center">{error}</p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <CustomButton
          label={BUTTON_LABELS.SUBMIT}
          className="w-100 btn btn-lg text-capitalize btn-primary"
          onButtonClick={() => onSubmitOTP()}
        />
        <div className="text-center mt-3 gotos">
          <span onClick={() => updateStep(1)}>{BUTTON_LABELS.CHANGE_EMAIL}</span>
        </div>
      </div>
    );
  };

  /********* password change form *************/
  const step3 = () => {
    return (
      <Form onSubmit={handleSubmit(submitPassword)}>
        <div className="row">
          <div className="col-12">
            <div className="form-group">
              <label>{STRINGS.NEW_PASSWORD}</label>
              <Field
                name={STRINGS.NEW_PASSWORD_INPUT}
                component={Input}
                placeholder={STRINGS.NEW_PASSWORD_PLACEHOLDER}
                config={{ type: INPUT_TYPE.password }}
              />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <div className="form-group">
              <label>{STRINGS.CONFIRM_PASSWORD_LABEL}</label>
              <Field
                name={STRINGS.CONFIRM_PASSWORD_INPUT}
                component={Input}
                placeholder={STRINGS.CONFIRM_PASSWORD_PLACEHOLDER}
                config={{ type: INPUT_TYPE.password }}
              />
            </div>
          </div>
        </div>
        <CustomButton
          label={BUTTON_LABELS.SUBMIT}
          type="submit"
          className="w-100 btn btn-lg text-capitalize btn-primary mt-4"
        />
      </Form>
    );
  };

  return (
    <div className="front-forms">
      <div className="container-fluid">
        <div className="login_box">
          <div className="front-logo text-center mb-4">
            <img src={LOGO} alt="Inquiry Tracker" />
          </div>
          <div className="form-box">
            {step === 1 ? (
              step1()
            ) : step === 2 ? (
              step2()
            ) : step === 3 ? (
              <>
                <div className="text-center">
                  <h3>{STRINGS.RESET_PASSWORD_LABEL}</h3>
                </div>
                {step3()}
              </>
            ) : null}
          </div>
        </div>
      </div>
      {loader && <Loader />}
    </div>
  );
};

const Screen = reduxForm({
  form: "resetForm",
  validate: validator,
})(FogetPasswordScreen);

export default withSnackbar(Screen);
