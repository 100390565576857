import React, { useEffect, useState } from "react";
import { withSnackbar } from "notistack";
import { Field, reduxForm } from "redux-form";
import { Select, Loader, CustomButton,CommentBox } from "../../../../components";
import {
  REPORT,
  Graph_Types,
  STRINGS,
  STATUS,
  NO_DATA_FOUND_MESSAGES,
  USER_ROLES,
  BUTTON_LABELS,
  ROUTE_CONSTANTS,
} from "../../../../shared/constants";
import moment from "moment";
import { ActivityLocationReport, ReflectiveReport, drawGraph, printDocument } from "./reports";
import { MindMap } from "./trial";
import "./style.scss";

const Screen = ({
  currentReportData = {},
  change,
  reportFloorbook = () => {},
  reportGet = () => {},
  loading,
  enqueueSnackbar,
  history,
  saveCompletionData,
  user_role,
  setReportsHeading = () => {}, //api for update reports heading
  setReportsSummary = () => {}, //api for update reports summary
}) => {
  const [userFloorbook, set_userFloorbook] = useState([]);
  const [floorbooksOptions, set_floorbooksOptions] = useState([]);
  /************  fetching floorbook id and name from report button of floorbook *********************/
  let [floorbookData, set_floorbookData] = useState(history && history.location && history.location.state && history.location.state);
  const [selectedFloorbook, set_selectedFloorbook] = useState(floorbookData ? floorbookData.id : "");
  const [selectedReport, set_selectedReport] = useState(floorbookData ? floorbookData.reportType : "");
  const [showReport, set_showReport] = useState(false);
  const [allPages, set_allPages] = useState([]);
  const [floorbookName,setFloorbookName]=useState(floorbookData ? floorbookData.name : "")
  const [floorbookDetails, set_floorbookDetails] = useState({});
  const [noDataFound, set_noDataFound] = useState(false);
  const [mapData, set_mapData] = useState([]);
  const [floorbookCategory, set_floorbookCategory] = useState(floorbookData ? floorbookData.floorbookType : "");
  const [editModeForReportHeading, set_editModeForReportHeading] = useState(false);
  const [reportHeading, set_reportHeading] = useState("");
  const [editModeForReportsSummary, set_editModeForReportsSummary] = useState(false);
  const [reportsSummary, set_reportsSummary] = useState("");
  console.log(
    "floorbookdata",
    floorbookData,
    history &&
      history.location &&
      history.location.state &&
      history.location.state
  );
  useEffect(() => {
    // if (floorbookCategory) {
    reportFloorbook((message, type, data) => {
      if (type === STATUS.error) {
        enqueueSnackbar(message, {
          variant: type,
          autoHideDuration: 3000,
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "left",
          },
        });
        return;
      }

      set_floorbooksOptions(data.data);
      set_reportHeading(data.heading);
    });
  }, []);

  // api request to update the reports heading , only admin can update the reports heading
  const updateReportsHeading = () => {
    let data = {
      heading: reportHeading ? reportHeading : " ",
    };
    setReportsHeading(data, (message, type) => {
      enqueueSnackbar(message, {
        variant: type,
        autoHideDuration: 3000,
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "left",
        },
      });
      if (type === STATUS.success) {
        set_editModeForReportHeading(false);
      }
    });
  };

  //api request to update the reports summary of indvidual report type
  const updateReportsSummary = () => {
    let postdata = {
      type: selectedReport,
      summary: reportsSummary ? reportsSummary : " ",
    };
    setReportsSummary(postdata, (message, type) => {
      enqueueSnackbar(message, {
        variant: type,
        autoHideDuration: 3000,
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "left",
        },
      });
      if (type === STATUS.success) {
        set_editModeForReportsSummary(false);
      }
    });
  };

  /********Redirect from location report to the selcted report**********/
  const redirectToFloorbookDetailPage = () => {
    history.push({
      pathname: ROUTE_CONSTANTS.FLOORBOOK_DETAILS,
      search: `floorbook_id=${selectedFloorbook}`,
    });
  };
  //  const updateComments = (count, comments) => {
  //    set_userComments(comments);
  //    set_userTotalComments(count);
  //  };
  useEffect(() => {
    let arr = [];
    floorbooksOptions &&
      floorbooksOptions.map((item) => {
        let obj = {
          value: item.value,
          label: item.label + " | " + item.first_name + " " + item.last_name + " | " + item.setting_name,
        };
        arr.push(obj);
      });
    set_userFloorbook(arr);
  }, [floorbooksOptions]);

  useEffect(() => {
    if (selectedReport && (selectedFloorbook || currentReportData)) {
      let data = {
        floorbook: selectedFloorbook || (currentReportData.id),
        reportType: selectedReport,
      };
      reportGet(data, (message, type, data) => {
        if (type === STATUS.error) {
          enqueueSnackbar(message, {
            variant: type,
            autoHideDuration: 3000,
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "left",
            },
          });
          return;
        }

        if (data.length) {
          set_reportsSummary("");
          set_noDataFound(false);
          set_reportsSummary(data[0].summary);
          set_floorbookDetails({
            floorbook_name: data[0].floorbook_name,
            user_name: data[0].user_name,
          });
          let Pages = [];
          if (selectedReport === 1 || selectedReport === 4) {
            Pages = SortPageByDate(data);
            set_allPages([...Pages]);
          }
          set_showReport(true);
          var ctx = selectedReport === 1 ? document.getElementById("LocationGraph") : null;

          if (ctx) {
            drawGraph(ctx, Pages, redirectToFloorbookDetailPage);
          }
          if (selectedReport === 2 || selectedReport === 3) {
            let uniqueId = new Date().getTime();
            let mData = [
              {
                id: data[0].floorbook_id,
                Label: data[0].floorbook_name,
                fill: "green",
                branch: "Root",
              },
            ];
            data.map((item, idx) => {
              let obj = {
                id: item.id + uniqueId,
                Label: item.name,
                parentId: data[0].floorbook_id,
                branch: (item.id + uniqueId) % 2 === 0 ? "Right" : "Left",
                fill: (item.id + uniqueId) % 2 === 0 ? "red" : "blue",
              };
              mData.push(obj);
            });
            data.map((book) =>
              book.pages.map((page, idx) => {
                let obj = {
                  id: page.id + uniqueId + uniqueId,
                  Label: `${page.name} ${moment(page.activity_from).format("DD-MMM-YYYY")}`,
                  parentId: book.id + uniqueId,
                  branch: (book.id + uniqueId) % 2 === 0 ? "subRight" : "subLeft",
                  fill: (book.id + uniqueId) % 2 === 0 ? "red" : "blue",
                };
                mData.push(obj);
              })
            );
            set_mapData([...mData]);
          }
        } else {
          set_allPages([]);
          set_showReport(false);
          set_noDataFound(true);
        }
      });
    }
  }, [selectedReport, selectedFloorbook || currentReportData.id]);
  // useEffect(() => {
  //   set_selectedFloorbook(data.id)
  // })
  console.log("userfloorbook",selectedFloorbook)
  return (
    <>
      <div>
        {/* <div className="card-header d-flex flex-column-reverse flex-xl-row justify-content-center justify-content-xl-between align-items-center">
          <HeadTitle title={HEAD_TITLES.REPORT} /> 
        </div> */}
        <div className="card-body">
          {/* <div className="row">    //Dropdown to choose floorbook category
            <div className="col-12">
              <div className="form-group">
                <label>{REPORT.FLOORBOOK_CATEGORY}</label>
                <Field
                  name={STRINGS.FLOORBOOK_PRIVACY_STATUS_INPUT}
                  component={RadioButtons}
                  radioGroupItems={FLOORBOOK_CATEGORY_FOR_REPORTS}
                  //defaultValue={details ? details.privacyStatus : null}
                  handleValueChange={(value) => {
                    set_floorbookCategory(value);
                    set_selectedFloorbook("");
                    change(REPORT.FLOORBOOK_INPUT, "");
                    set_showReport(false);
                    set_floorbookData({});
                  }}
                  defaultValue={floorbookCategory}
                />
              </div>
            </div>
          </div> */}
          <div className="row">
            {editModeForReportHeading ? (
              <>
                <div className="col-12">
                  <div className="report_fields d-flex flex-column flex-sm-row align-items-sm-center mb-4">
                    <input
                      placeholder="Reports' Heading"
                      value={reportHeading}
                      onChange={(e) => set_reportHeading(e.target.value)}
                      className="form-control"
                    />
                    <CustomButton
                      className="btn btn-primary"
                      onButtonClick={() => {
                        updateReportsHeading(reportHeading);
                      }}
                      label={BUTTON_LABELS.SAVE}
                    />
                  </div>
                </div>
              </>
            ) : (
              <>
                <div className="col-12">
                  <div className="report_fields d-flex flex-column flex-sm-row align-items-sm-center mb-4">
                    <h5>{reportHeading}</h5>
                    {USER_ROLES.ADMIN == user_role ? (
                      <CustomButton
                        className="btn btn-primary"
                        onButtonClick={() => set_editModeForReportHeading(true)}
                        label={BUTTON_LABELS.EDIT}
                      />
                    ) : null}
                  </div>
                </div>
              </>
            )}
          </div>

          <div className="row">
            <div className="col-12">
              <div
                // className={
                //   floorbookCategory || (floorbookData && floorbookData.name && floorbookData.id)
                //     ? "form-group"
                //     : "disable form-group"
                // }
                className="form-group"
              >
                <label>{REPORT.FLOORBOOK_N_JOURNAL_LABEL}</label>
                <Field
                  name={REPORT.FLOORBOOK_INPUT}
                  component={Select}
                  isSearchable={true}
                  /**************** add new option if user redirects from report button else show data from backend **********/
                  options={
                    floorbookData && floorbookData.name && floorbookData.id
                      ? userFloorbook.findIndex((item) => item.value === floorbookData.id) >= 0
                        ? userFloorbook
                        : [
                            ...userFloorbook,
                            { label: `${floorbookData.name}username settingName`, value: floorbookData.id },
                          ]
                      : userFloorbook
                  }
                  placeholder={STRINGS.SELECT_PLACEHOLDER}
                  onValueChange={(value) => {
                    set_selectedFloorbook(value.value);
                    setFloorbookName(value.label)
                  }}
                  defaultValue={selectedFloorbook ? selectedFloorbook : currentReportData.id}
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <div className="form-group">
                <label>{REPORT.CATEGORY_LABEL}</label>
                <Field
                  name={REPORT.CATEGORY_INPUT}
                  component={Select}
                  isSearchable={true}
                  options={Graph_Types}
                  placeholder={STRINGS.SELECT_PLACEHOLDER}
                  onValueChange={(value) => {
                    set_selectedReport(value.value);
                  }}
                  defaultValue={selectedReport}
                />
              </div>
            </div>
          </div>
          {/*************************  Render Reports ********************/}
          {noDataFound ? (
            <p className="text-center p-5">{NO_DATA_FOUND_MESSAGES.NO_PAGE_FOUND_IN_FLOORBOOK}</p>
          ) : showReport ? (
            <>
              <div className="row">
                <div className="col-12 mt-1">
                  <CustomButton
                    className="btn btn-primary"
                    onButtonClick={() =>
                      printDocument(Graph_Types[selectedReport - 1].label, floorbookDetails.floorbook_name)
                    }
                    label={"export to PDF"}
                  />
                </div>
                {editModeForReportsSummary ? (
                  <div className="col-12">
                    <div className="report_fields d-flex flex-column flex-sm-row align-items-sm-center mt-4 mt-4">
                      <input
                        placeholder="Report Summary"
                        value={reportsSummary}
                        onChange={(e) => set_reportsSummary(e.target.value)}
                        className="form-control"
                      />
                      <CustomButton
                        className="btn btn-primary"
                        onButtonClick={() => {
                          updateReportsSummary();
                        }}
                        label={BUTTON_LABELS.SAVE}
                      />
                    </div>
                  </div>
                ) : (
                  <div className="col-12">
                    <div className="report_fields d-flex flex-column flex-sm-row align-items-sm-center mt-4">
                      <p style={{fontSize:'18px'}}>{reportsSummary}</p>
                      {USER_ROLES.ADMIN == user_role ? (
                        <CustomButton
                          className="btn btn-primary"
                          onButtonClick={() => set_editModeForReportsSummary(true)}
                          label={BUTTON_LABELS.EDIT}
                        />
                      ) : null}
                    </div>
                  </div>
                )}
              </div>
              {selectedReport === 1 ? (
                <>
                    <ActivityLocationReport Pages={allPages} name={floorbookName ? floorbookName : currentReportData ? currentReportData.name : ''}/>
                  {/* <CommentBox
                    id={selectedFloorbook}
                    comments={userComments || []}
                    updateComments={updateComments}
                    commentType={1}
                    // profileImage={profileImage}
                  /> */}
                </>
              ) : selectedReport === 4 && !loading ? (
                <>
                  <ReflectiveReport
                    allPages={allPages}
                    floorbookDetails={floorbookDetails}
                    saveCompletionData={saveCompletionData}
                    floorbookType={floorbookCategory}
                    redirectToFloorbookDetailPage={
                      redirectToFloorbookDetailPage
                    }
                  />
                  {/* <CommentBox
                    id={selectedFloorbook}
                    comments={userComments || []}
                    updateComments={updateComments}
                    commentType={1}
                    // profileImage={profileImage}
                  /> */}
                </>
              ) : selectedReport === 2 || selectedReport === 3 ? (
                <>
                  {" "}
                        <MindMap type={selectedReport === 2 ? Graph_Types[1].label : Graph_Types[2].label}
                          name={floorbookName ? floorbookName : currentReportData ? currentReportData.name : ''}
                    mindData={mapData}
                    redirectToFloorbookDetailPage={
                      redirectToFloorbookDetailPage
                    }
                  />
                  {/* <CommentBox
                    id={selectedFloorbook}
                    comments={userComments || []}
                    updateComments={updateComments}
                    commentType={1}
                    // profileImage={profileImage}
                  /> */}
                </>
              ) : null}
            </>
          ) : null}
        </div>
        {loading && <Loader />}
      </div>
    </>
  );
};
const Screens = reduxForm({
  form: "report",
  enableReinitialize: true,
})(Screen);

export default withSnackbar(Screens);

const SortPageByDate = (data) => {
  let Pages = [];
  data.length && data.map((item) => item.pages.forEach((page) => Pages.push(page)));
  Pages.sort((a, b) => a.activity_from.localeCompare(b.activity_from));
  return Pages;
};
