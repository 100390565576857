import React, { useState, useEffect } from "react";
import { DisplayFloorbooks, HeadTitle, Loader,Select } from "../../../../../components";
import {
  HEAD_TITLES,
  NO_DATA_MESSAGES,
  STATUS,
  HEART_FILL,
  USER_ROLES,
  REPORT,
  STRINGS,
} from "../../../../../shared/constants";
import { withSnackbar } from "notistack";
import './style.scss';
import { Field, reduxForm } from "redux-form";
import { parseToHtml } from "../../../../../shared/services/Utility";
const Screen = ({
  getAllFloorbooks,
  myfloorbookData,
  loading,
  favouriteFloorbooksData,
  getFavouriteFloorbooks,
  getOtherFloorbooks,
  OthersFloorbookData,
  enqueueSnackbar,
  setFavourite,
  updateFloorbookJournal,
  floorbookDetailsPagePosition,
  Floorbookpage_Position,
  userRole,
  setHideorUnHideFloorbook = () => {},
}) => {
  const [showMyFloorbook, set_showMyFloorbook] = useState(false);
  const [showFavouriteFloorbook, set_showFavouriteFloorbook] = useState(false);
  const [showOthersFloorbook, set_showOthersFloorbook] = useState(true);
  const [myfloorbooks, updateSetMyFloorbooks] = useState([]);
  const [favouriteFloorbooks, update_favouriteFloorbooks] = useState([]);
  const [othersFloorbooks, update_othersFloorbooks] = useState([]);
  const [checked, setChecked] = React.useState(false);
  const [searchValue, setSearchValue] = useState("");
  const toggleChecked = () => {
    setChecked((prev) => !prev);
  };
console.log("all floorbooks",OthersFloorbookData)
  useEffect(() => {
    getOtherFloorbooks((message, type) => {
      if (type === STATUS.error) {
        enqueueSnackbar(message, {
          variant: type,
          autoHideDuration: 3000,
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "left",
          },
        });
      }
    });
  }, [showOthersFloorbook]);

  useEffect(() => {
    getAllFloorbooks((message, type) => {
      if (type === STATUS.error) {
        enqueueSnackbar(message, {
          variant: type,
          autoHideDuration: 3000,
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "left",
          },
        });
      }
    });
  }, [showMyFloorbook]);

  useEffect(() => {
    showFavouriteFloorbook &&
      getFavouriteFloorbooks((message, type) => {
        if (type === STATUS.error) {
          enqueueSnackbar(message, {
            variant: type,
            autoHideDuration: 3000,
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "left",
            },
          });
        }
      });
  }, [showFavouriteFloorbook]);
  const updateFloorbookIsJournal = (
    floorbookId,
    isJournal,
    idx,
    floorbookType
  ) => {
    let postdata = {
      id: floorbookId,
      is_learning_journal: isJournal
    };
     updateFloorbookJournal(postdata, (message, type) => {
       enqueueSnackbar(message, {
         variant: type,
         autoHideDuration: 3000,
         anchorOrigin: {
           vertical: "bottom",
           horizontal: "left",
         },
       });
       if (type === "success") {
         // check floorbook Type 1: is for favourite floorbooks , 2: for my floorbooks , and 0 is for other floorbooks
         // change the is_private key acc to the index and type of floorbook
         console.log("is journal updated successfully");
          if (floorbookType == 1) {
            favouriteFloorbooks.splice(idx, 1, {
              ...favouriteFloorbooks[idx],
              is_learning_journal: isJournal,
            });
            update_favouriteFloorbooks(favouriteFloorbooks);
          }
          if (floorbookType == 2) {
            myfloorbooks.splice(idx, 1, {
              ...myfloorbooks[idx],
              is_learning_journal: isJournal,
            });
            updateSetMyFloorbooks(myfloorbooks);
          } else {
            othersFloorbooks.splice(idx, 1, {
              ...othersFloorbooks[idx],
              is_learning_journal: isJournal,
            });
            update_othersFloorbooks(othersFloorbooks);
          }
       }
     });
}
  const updateFloorbookPrivacyData = (
    floorbookId,
    status,
    idx,
    floorbookType,
    is_learning_journal
  ) => {
    console.log("status", floorbookId, status, idx);
    let postdata = {
      id: floorbookId,
      status: status,
      is_learning_journal:is_learning_journal
    };
    setHideorUnHideFloorbook(postdata, (message, type) => {
      enqueueSnackbar(message, {
        variant: type,
        autoHideDuration: 3000,
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "left",
        },
      });
      if (type === "success") {
        // check floorbook Type 1: is for favourite floorbooks , 2: for my floorbooks , and 0 is for other floorbooks
        // change the is_private key acc to the index and type of floorbook
        if (floorbookType == 1) {
          favouriteFloorbooks.splice(idx, 1, {
            ...favouriteFloorbooks[idx],
            is_private: status,
          });
          update_favouriteFloorbooks(favouriteFloorbooks);
        }
        if (floorbookType == 2) {
          myfloorbooks.splice(idx, 1, {
            ...myfloorbooks[idx],
            is_private: status,
          });
          updateSetMyFloorbooks(myfloorbooks);
        } else {
          othersFloorbooks.splice(idx, 1, {
            ...othersFloorbooks[idx],
            is_private: status,
          });
          update_othersFloorbooks(othersFloorbooks);
        }

        toggleChecked();
      }
    });
  };

  useEffect(() => {
     // updateSetMyFloorbooks(myfloorbookData);
     let data = myfloorbookData && myfloorbookData.filter((item) => item.is_learning_journal == 0
     );
     if (userRole === USER_ROLES.ADMIN) {
       updateSetMyFloorbooks(myfloorbookData);
     } else {
       updateSetMyFloorbooks(data);
     }
  }, [myfloorbookData]);
console.log("my floorbooks",OthersFloorbookData)
  useEffect(() => {
    //update_favouriteFloorbooks(favouriteFloorbooksData);
     let data = favouriteFloorbooksData.filter(
       (item) => item.is_learning_journal == 0
     );
     if (userRole === USER_ROLES.ADMIN) {
       update_favouriteFloorbooks(favouriteFloorbooksData);
     } else {
       update_favouriteFloorbooks(data);
     }
  }, [favouriteFloorbooksData]);

  useEffect(() => {
    //update_othersFloorbooks(OthersFloorbookData);
    let data = OthersFloorbookData.filter(item => item.is_learning_journal == 0
    )
    if (userRole === USER_ROLES.ADMIN)
    {
      update_othersFloorbooks(OthersFloorbookData);
    }
    else {
      update_othersFloorbooks(data);
    }
  }, [OthersFloorbookData]);

  const saveFloorbookDetailsPagePosition = (data) => {
    //save the last post position
    floorbookDetailsPagePosition(data);
  };

  var rowToScrollTo = document.getElementById(Floorbookpage_Position);
  useEffect(() => {
    if (Floorbookpage_Position && rowToScrollTo) {
      window.onload = init();
    }
  }, [rowToScrollTo]);
console.log("othersfloorbooks",othersFloorbooks)
  const init = () => {
    var d = document.getElementById("content-wrapper");
    rowToScrollTo.scrollIntoView();
  };
  const renderData = () => {
    const displayData =
      showMyFloorbook && myfloorbooks.length
        ? myfloorbooks
        : showFavouriteFloorbook && favouriteFloorbooks.length
        ? favouriteFloorbooks
        : showOthersFloorbook && othersFloorbooks.length
        ? othersFloorbooks
        : [];

    // function to update favourite status of floorbooks on UI and Api
    const updateFav = (id, status, bookType, idx,is_learning_journal) => {
      setFavourite(
        { id, status, marked_at: new Date().getTime(),is_learning_journal:is_learning_journal },
        (payload, type) => {
          if (type === STATUS.error) {
            enqueueSnackbar(payload, {
              variant: type,
              autoHideDuration: 3000,
              anchorOrigin: {
                vertical: "bottom",
                horizontal: "left",
              },
            });
            return;
          }
          // check if showFavouriteFloorbook or other floorbook
          if (bookType) {
            favouriteFloorbooks.splice(idx, 1);
            update_favouriteFloorbooks(favouriteFloorbooks);
            let otherIdx = othersFloorbooks.findIndex((item) => item.id === id);
            if (otherIdx !== -1) {
              othersFloorbooks[otherIdx] = {
                ...othersFloorbooks[otherIdx],
                user_favourite: payload.status,
              };
              update_othersFloorbooks(othersFloorbooks);
            }
          } else {
            othersFloorbooks.splice(idx, 1, {
              ...othersFloorbooks[idx],
              user_favourite: payload.status,
            });
            update_othersFloorbooks(othersFloorbooks);
          }
        }
      );
    };
console.log("display data",displayData)
    return (
      <div className="card-body" id="floorbook">
        <div className="floorbook_list">
          {displayData.length ? (
            displayData.map((item, index) =>
              !searchValue ||
              item.name.toLowerCase().includes(searchValue.toLowerCase()) ? (
                <DisplayFloorbooks
                  key={index}
                  idx={index}
                  favourite={
                    showOthersFloorbook || showFavouriteFloorbook ? true : false
                  }
                  id={item.id}
                  cover_image={item.cover_image}
                  name={item.name}
                  is_learning_journal={item.is_learning_journal}
                  description={parseToHtml(item.description)}
                  image={item.image}
                  is_fav={item.user_favourite}
                  type={item.type}
                  is_private={item.is_private}
                  settingName={item.setting_name}
                  country={item.country}
                  city={item.city}
                  checked={checked}
                  setChecked={setChecked}
                  firstname={item.first_name}
                    lastname={item.last_name}
                    isAdmin={userRole == USER_ROLES.ADMIN ? true : false}
                    updateFloorbookIsJournal={updateFloorbookIsJournal}
                  updateFloorbookPrivacyData={updateFloorbookPrivacyData} // api to hide/unhide(change privacy status) of floorbook
                  showChangePrivacyOptions={
                    (USER_ROLES.ADMIN === userRole && !item.is_private) ||
                    showMyFloorbook
                      ? true
                      : false
                  }
                  // show hide/unhide switch options or not
                  showFloorbookPrivacyStatus={
                    (showOthersFloorbook || showFavouriteFloorbook) &&
                    userRole == USER_ROLES.ADMIN
                      ? true
                      : showMyFloorbook
                      ? true
                      : false
                  } // to show the (public / private) status label in the floorbook listing(all)
                  updateFav={updateFav} // function to call update favourite api
                  floorbookType={
                    showFavouriteFloorbook ? 1 : showMyFloorbook ? 2 : 0
                  } // 1 : FavouriteFloorbook 0 : otherfloorbooks //2 : my floorbook
                  saveFloorbookDetailsPagePosition={
                    saveFloorbookDetailsPagePosition
                  }
                />
              ) : null
            )
          ) : (
            <div className="d-flex align-items-center no-data-found">
              <div>
                {showFavouriteFloorbook
                  ? NO_DATA_MESSAGES.NO_SAVED_FLOORBOOK
                  : showOthersFloorbook
                  ? NO_DATA_MESSAGES.NO_FLOORBOOK
                  : NO_DATA_MESSAGES.NO_MY_FLOORBOOK}
                <br />{" "}
                {showFavouriteFloorbook && (
                  <>
                    <p>
                      Click on the{" "}
                      <i>
                        <img src={HEART_FILL} alt="" width="30px" />
                      </i>{" "}
                      icon of your Favourite Floorbooks to see them here.
                    </p>
                  </>
                )}{" "}
                {showFavouriteFloorbook
                  ? ""
                  : NO_DATA_MESSAGES.YOU_CAN_ADD_FAV_TO_VIEW_HERE}
              </div>
            </div>
          )}
        </div>
      </div>
    );
  };

  return (
    <>
      {/* <div className="card-header d-flex flex-column-reverse flex-xl-row justify-content-center justify-content-xl-between align-items-center">
       <HeadTitle title={HEAD_TITLES.FLOORBOOK} /> 
      </div> */}
      <input
        type="text"
        className="input_field"
        placeholder="Search floorbook..."
        maxLength="1000px"
        value={searchValue}
        onChange={(e) => setSearchValue(e.target.value)}
      ></input>
      <ul className="list-unstyled d-flex flex-wrap card_tab">
        <li
          style={{
            ...(showOthersFloorbook
              ? { backgroundColor: "#0D532D", color: "white" }
              : null),
          }}
          onClick={() => {
            set_showMyFloorbook(false);
            set_showOthersFloorbook(true);
            set_showFavouriteFloorbook(false);
          }}
        >
          {HEAD_TITLES.ALL_FLOORBOOKS}
        </li>
        <li
          style={{
            ...(showFavouriteFloorbook
              ? { backgroundColor: "#0D532D", color: "white" }
              : null),
          }}
          onClick={() => {
            set_showMyFloorbook(false);
            set_showOthersFloorbook(false);
            set_showFavouriteFloorbook(true);
          }}
        >
          {HEAD_TITLES.SAVED_FLOORBOOKS}
        </li>
        {myfloorbooks && myfloorbooks.length ? (
          <li
            style={{
              ...(showMyFloorbook
                ? { backgroundColor: "#0D532D", color: "white" }
                : null),
            }}
            onClick={() => {
              set_showMyFloorbook(true);
              set_showOthersFloorbook(false);
              set_showFavouriteFloorbook(false);
            }}
          >
            {HEAD_TITLES.MY_FLOORBOOKS}
          </li>
        ) : null}
      </ul>

      {renderData()}

      {loading && <Loader />}
    </>
  );
};
export default withSnackbar(Screen);
