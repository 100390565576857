import {
  SAVE_CAPTCHA,
  STOP_LOADING,
  START_LOADING,
  UPDATE_INTERNET_STATUS,
  LOGIN_SUCCESS,
  SET_USER_DATA,
  LOGOUT,
  LOGOUT_SERVER,
  FCM_TOKEN,
  CHANGE_SESSION,
  FETCH_FLOORBOOK_BY,
} from "./ActionTypes";

export const updateInternetStatus = (status) => {
  return {
    type: UPDATE_INTERNET_STATUS,
    isNetConnected: status,
  };
};

export const logout = (callback) => {
  return {
    type: LOGOUT_SERVER,
    callback,
  };
};

export const logout_user = () => {
  return {
    type: LOGOUT,
  };
};

export const setUserData = (data) => {
  return {
    type: SET_USER_DATA,
    data,
  };
};

export const onLoginSuccess = (data) => {
  return {
    type: LOGIN_SUCCESS,
    data,
  };
};
export const saveCaptcha = (text) => {
  return {
    type: SAVE_CAPTCHA,
    text,
  };
};

export const startLoading = () => {
  return {
    type: START_LOADING,
  };
};

export const stopLoading = () => {
  return {
    type: STOP_LOADING,
  };
};

export const saveFCMToken = (data) => {
  return {
    type: FCM_TOKEN,
    data,
  };
};

export const ChangeFcmSession = (data, callback) => {
  return {
    type: CHANGE_SESSION,
    data,
    callback,
  };
};
export const fetchFloorboookOrder = (data) => {
  return {
    type: FETCH_FLOORBOOK_BY,
    data,
  };
};
