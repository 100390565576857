import React from "react";
import {
  BUTTON_LABELS,
  MAX_DESCRIPTION_SHOW_LENGTH,
  ROUTE_CONSTANTS,
  VIDEO_PLAY_ICON,
  HEART_OUTLINE,
  HEART_FILL,
  STRINGS
} from "../../../shared/constants";
import { useHistory } from "react-router-dom";
import "./style.scss";
import { CustomButton, ImageOverlay } from "../../atoms";
import DeleteModal from "../DeleteModal";
import { makeStyles, Switch } from "@material-ui/core";
import FormGroup from "@material-ui/core/FormGroup";

import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { updateFloorbookJournal } from "../../../redux/actions";

const DisplayFloorbooks = ({
  idx,
  dataType,
  favourite,
  id,
  cover_image,
  name,
  description,
  is_fav,
  type,
  resourceFile,
  title,
  updateFav = () => {}, // function to update favourite icon
  floorbookType, // others : 0  &&  FavouriteFloorbook : 1
  onDeleteResource,
  isAdmin,
  saveFloorbookDetailsPagePosition = () => {},
  loading,
  showFloorbookPrivacyStatus,
  settingName = "",
  is_learning_journal = 0,
  country = "",
  city = "",
  firstname = "",
  lastname = "",
  is_private,
  showChangePrivacyOptions,
  updateFloorbookIsJournal = () => {},
  updateFloorbookPrivacyData = () => {}, //api request to change the privacy status of floorbook
}) => {
  const history = useHistory();

  const useStyles = makeStyles((theme) => ({
    toggle: {
      "& .Mui-checked": {
        color: "#1f532d",
        transform: "translateX(25px) !important",
      },
      "& .MuiSwitch-track": {
        backgroundColor: "#7e9481 !important",
      },
    },
  }));

  // api request to hide or unhide the floorbook (privacy status of floorbook)

  const classes = useStyles();
  return (
    <>
      <div className="floorbook_block" id={`floorbook${id}`}>
        <div className="pbh_div">
          {dataType != "resources" && showChangePrivacyOptions ? (
            <FormGroup>
              <Typography component="div">
                <Grid component="label" container alignItems="center">
                  <Grid item>{BUTTON_LABELS.HIDE}</Grid>
                  <Grid item>
                    <Switch
                      checked={is_private ? false : true}
                      onChange={() =>
                        updateFloorbookPrivacyData(
                          id,
                          is_private ? 0 : 1,
                          idx,
                          floorbookType,
                          is_learning_journal
                        )
                      }
                      name="checkedC"
                      className={classes.toggle}
                    />
                  </Grid>
                  <Grid item>{BUTTON_LABELS.UNHIDE}</Grid>
                </Grid>
              </Typography>
            </FormGroup>
          ) : null}

          {favourite ? (
            <button
              className="btn-favourite"
              onClick={() => {
                //set favourit api call
                updateFav(
                  id,
                  Number(is_fav) || floorbookType,
                  floorbookType,
                  idx,
                  is_learning_journal
                );
              }}
            >
              {is_fav || floorbookType ? (
                <i>
                  <img src={HEART_FILL} alt="" />
                </i>
              ) : (
                <i>
                  <img src={HEART_OUTLINE} alt="" />
                </i>
              )}
            </button>
          ) : null}
          {showChangePrivacyOptions ? (
            <label className="post_type">
              {is_private ? BUTTON_LABELS.PRIVATE : BUTTON_LABELS.PUBLIC}
            </label>
          ) : null}
        </div>
        <div className="row">
          <div
            className="col-xl-3 col-md-4 col-sm-4 col-12"
            style={{ cursor: "pointer" }}
            onClick={() =>
              dataType === "resources"
                ? history.push({
                    pathname: ROUTE_CONSTANTS.USEFUL_RESOURCE_DETAIL,
                    search: `resource_id=${id}`,
                  })
                : dataType === "journals"
                ? history.push({
                    pathname: ROUTE_CONSTANTS.JOURNAL_DETAILS,
                    search: `journal_id=${id}`,
                  })
                : history.push({
                    pathname: ROUTE_CONSTANTS.FLOORBOOK_DETAILS,
                    search: `floorbook_id=${id}`,
                  })
            }
          >
            <figure className="post_thumb">
              {type === "video" ? (
                <ImageOverlay>
                  <video width={230}>
                    <source src={resourceFile}></source>
                  </video>
                  <a className="play_btn" href="javascript:void(0);">
                    <img src={VIDEO_PLAY_ICON} width="50" alt="" />
                  </a>
                </ImageOverlay>
              ) : (
                <ImageOverlay>
                  <img
                    src={cover_image || resourceFile}
                    width={140}
                    className="img-fluid"
                    alt=""
                  />
                </ImageOverlay>
              )}
            </figure>
          </div>

          <div className="col-xl-9 col-md-8 col-sm-8 summary_txt">
            {dataType === "resources" && isAdmin ? (
              <>
                <div className="edit_del_group text-right">
                  <CustomButton
                    label={BUTTON_LABELS.EDIT}
                    className="btn btn-primary text-capitalize"
                    onButtonClick={() => {
                      saveFloorbookDetailsPagePosition(`floorbook${id}`);
                      history.push({
                        pathname: ROUTE_CONSTANTS.EDIT_RESOURCE,
                        search: `id=${id}`,
                        state: {
                          Details: {
                            id: id,
                            title: title,
                            description: description,
                            resourceFile: resourceFile,
                          },
                        },
                      });
                    }}
                  />{" "}
                  <DeleteModal
                    btnLabel={BUTTON_LABELS.DELETE}
                    type={4}
                    id={id}
                    onDelete={(id) => onDeleteResource(id)}
                    loading={loading}
                  />
                </div>
              </>
            ) : null}

            <h3>
              <span
                style={{ cursor: "pointer" }}
                onClick={() => {
                  dataType === "resources" // if dataType is of type resources than redirect the post to the Resource detail page else redirect to floorbook detail page
                    ? history.push({
                        pathname: ROUTE_CONSTANTS.USEFUL_RESOURCE_DETAIL,
                        search: `resource_id=${id}`,
                      })
                    : is_learning_journal
                    ? history.push({
                        pathname: ROUTE_CONSTANTS.JOURNAL_DETAILS,
                        search: `journal_id=${id}`,
                      })
                    : history.push({
                        pathname: ROUTE_CONSTANTS.FLOORBOOK_DETAILS,
                        search: `floorbook_id=${id}`,
                      });
                  saveFloorbookDetailsPagePosition(`floorbook${id}`);
                }}
              >
                {name || title}
              </span>
            </h3>
            {description && (
              <>
                <div
                  className="w-100 text-justify"
                  dangerouslySetInnerHTML={{
                    __html:
                      description &&
                      description.length > MAX_DESCRIPTION_SHOW_LENGTH
                        ? description.slice(0, MAX_DESCRIPTION_SHOW_LENGTH) +
                          "..."
                        : description,
                  }}
                ></div>
              </>
            )}
            {dataType === "resources" ? null : (
              <div className="user_data" style={{ display:'flex',flexDirection:'row'}}>
                <span>{settingName}</span>{" "}
                <span>
                  {firstname} {lastname}
                </span>{" "}
                <span>{city}</span> <span>{country}</span>
                {isAdmin ? <div className="form-group text-left custom_checkbox curser_point" style={{ marginLeft: '20px' }}>
                  <input
                    type="checkbox"
                    checked={is_learning_journal ? true : false}
                    id="isJournalCheck"
                    style={{ width: 22 }}

                    onChange={()=>updateFloorbookIsJournal(id,is_learning_journal ? 0 : 1,idx,floorbookType)}
                  />
                  <label htmlFor="isJournal" className="mb-0">
                    {"Is Group Learning Journal"}
                  </label>
                </div> :null}
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default DisplayFloorbooks;
