import React, { Component, useEffect, useState } from "react";
import "react-notifications-component/dist/theme.css";
import { messaging } from "../../../config/init-fcm";
import { withSnackbar } from "notistack";

const CheckNotification = ({ enqueueSnackbar, status, ChangeFcmSession = () => {} }) => {
  const [notification, set_notification] = useState(true);

  useEffect(() => {
    set_notification(status);
  }, [status]);

  useEffect(() => {
    let safariAgent = navigator.userAgent.indexOf("Safari") > -1;
    let chromeAgent = navigator.userAgent.indexOf("Chrome") > -1;

    if (chromeAgent && safariAgent) {
      safariAgent = false;
    }
    if (!safariAgent) {
      navigator.permissions
        .query({ name: "notifications" })
        .then((notificationPerm) => {
          notificationPerm.onchange = async function () {
            if (notificationPerm && notificationPerm.state === "granted") {
              console.log("changedS in notification", notificationPerm.state);
              const token = await messaging.getToken();
              console.log("token in notificxatiobsbsnsnsnsn", token);
              let data = {
                fcm_token: token,
                status: notification,
              };
              ChangeFcmSession(data, (message, type) => {
                console.log("datata in change fcm session", data);
                enqueueSnackbar(message, {
                  variant: type,
                  autoHideDuration: 3000,
                  anchorOrigin: {
                    vertical: "bottom",
                    horizontal: "left",
                  },
                });
              });
            }
          };
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, []);

  return <></>;
};

export default withSnackbar(CheckNotification);
