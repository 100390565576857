import React from "react";
import "./style.scss";
import moment from "moment";
import {
  ROUTE_CONSTANTS,
  NO_DATA_FOUND_MESSAGES,
  CLOCK_ICON,
  LIKE_GRAY_ICON,
  COMMENTS_GRAY_ICON,
} from "../../../shared/constants";
import { useHistory } from "react-router-dom";

const DisplayHistory = ({
  data = [],
  loading,
  fetchFloorboookOrder = () => {},
}) => {
  let history = useHistory();
  return (
    <div className="history_listing">
      {data.length
        ? data.map((item, index) => {
            return (
              <div
                className="history_items"
                key={index}
                style={{ minHeight: "150px", maxHeight: "auto" }}
              >
                <div className="row">
                  <div className="col-md-8">
                    <h4
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        fetchFloorboookOrder("");
                        item.is_learning_journal
                          ? item.is_page
                            ? history.push({
                                pathname: `${ROUTE_CONSTANTS.JOURNAL_DETAILS}`,
                                search: `journal_id=${item.id}`,
                                state: {
                                  from: history.location.pathname,
                                },
                              })
                            : history.push({
                                pathname: `${ROUTE_CONSTANTS.JOURNAL_PAGE_DETAILS}`,
                                search: `page_id=${item.id}`,
                                state: {
                                  from: history.location.pathname,
                                },
                              })
                          : item.is_page
                          ? history.push({
                              pathname: `${ROUTE_CONSTANTS.FLOORBOOK_DETAILS}`,
                              search: `floorbook_id=${item.id}`,
                              state: {
                                from: history.location.pathname,
                              },
                            })
                          : history.push({
                              pathname: `${ROUTE_CONSTANTS.FLOORBOOK_PAGE_DETAILS}`,
                              search: `page_id=${item.id}`,
                              state: {
                                from: history.location.pathname,
                              },
                            })
                      }}
                    >
                      You {item.comment_text ? "commented on" : "liked"} {" "}
                      {item.title}
                    </h4>

                    <div>
                      {/* <div className="post_pic">
                        <ProfileModal user_image={item.user_image} user_id={item.user_id} />
                        modal on profile image
                      </div> */}
                      <div>
                        {/* <h6
                          style={{ cursor: "pointer",padding:'0px' }}
                          onClick={() => {
                            fetchFloorboookOrder("");
                            item.is_page // check if floorbook or floorbook page
                              ? history.push({
                                  pathname: `${ROUTE_CONSTANTS.FLOORBOOK_DETAILS}`,
                                  search: `floorbook_id=${item.id}`,
                                  state: {
                                    from: history.location.pathname,
                                  },
                                })
                              : history.push({
                                  pathname: `${ROUTE_CONSTANTS.FLOORBOOK_PAGE_DETAILS}`,
                                  search: `page_id=${item.id}`,
                                  state: {
                                    from: history.location.pathname,
                                  },
                                });
                          }}
                        > */}
                        {/* {item.title} */}
                        {/* </h6>  */}
                        {/* <span>By: {item.user_name}</span> */}
                        {item.comment_text && (
                          <p>
                            Comment:{" "}
                            {
                              item.type && item.type == "text" ? (
                                item.comment_text
                              ) : (
                                <a
                                  href={item.comment_text}
                                  alt={item.type}
                                  style={{ color: "#0D532D" }}
                                >
                                  {item.type}
                                </a>
                              )
                              // <img
                              //   src={item.comment_text}
                              //   alt=""
                              //   style={{
                              //     maxWidth: "150px",
                              //     minWidth: "150px",
                              //     maxHeight: "100px",
                              //     minHeight: "70px",
                              //     borderRadius: "5px",
                              //   }}
                              // />
                            }
                          </p>
                        )}
                      </div>
                    </div>

                    <div className="post_ltl">
                      <span>
                      {item.is_learning_journal ? "Group Learning Journal:" : "Floorbook:"} <strong>{item.floorbook_name}</strong>
                      </span>
                    </div>

                    <div className="row">
                      <div className="col-md-7">
                        <div className="post_status p-0">
                          <span style={{ cursor: "default" }}>
                            <i className="mr-2">
                              <img src={LIKE_GRAY_ICON} alt="" />
                            </i>
                            {item.total_count} Likes
                          </span>
                          <span style={{ cursor: "default" }}>
                            <i className="mr-2">
                              <img src={COMMENTS_GRAY_ICON} alt="" />
                            </i>
                            {item.total_comments} Comments
                          </span>
                        </div>
                      </div>
                      <div className="col-md-5 text-md-right text-left">
                        <span className="create_time">
                          <i className="mr-2">
                            <img src={CLOCK_ICON} width="18" alt="" />
                          </i>
                          {moment(item.created_at).format(
                            "DD MMM YYYY, hh:mm A"
                          )}
                        </span>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-4 col-12">
                    <div className="img_post">
                      <img
                        style={{
                          cursor: "pointer",
                          minHeight: "120px",
                          maxHeight: "120px",
                        }}
                        onClick={() => {
                          fetchFloorboookOrder("");
                           item.is_learning_journal
                             ? item.is_page
                               ? history.push({                                pathname: `${ROUTE_CONSTANTS.JOURNAL_DETAILS}`,
                                search: `journal_id=${item.id}`,
                                state: {
                                  from: history.location.pathname,
                                },
                                 })
                               : history.push({
                                pathname: `${ROUTE_CONSTANTS.JOURNAL_PAGE_DETAILS}`,
                                search: `page_id=${item.id}`,
                                state: {
                                  from: history.location.pathname,
                                },
                                 })
                             :item.is_page
                            ? history.push({
                                pathname: `${ROUTE_CONSTANTS.FLOORBOOK_DETAILS}`,
                                search: `floorbook_id=${item.id}`,
                                state: {
                                  from: history.location.pathname,
                                },
                              })
                             : history.push({
                                 pathname: `${ROUTE_CONSTANTS.FLOORBOOK_PAGE_DETAILS}`,
                                 search: `page_id=${item.id}`,
                                state: {
                                  from: history.location.pathname,
                                },
                              });
                        }}
                        src={
                          item && item.is_page
                            ? item.images
                            : item && item.images && item.images[0]
                        }
                        alt=""
                        className="img-fluid"
                      />
                    </div>
                  </div>
                </div>
              </div>
            );
          })
        : !loading && (
            <p className="text-center p-5">
              {NO_DATA_FOUND_MESSAGES.NO_HISTORY_FOUND}
            </p>
          )}
    </div>
  );
};
export default DisplayHistory;
