import * as actionTypes from "../actions/ActionTypes";

const initialCommonState = {
  Page_Position: "",
  Floorbookpage_Position: "",
  FloorbookDetailPage_Position: "",
};

const ScrollPositionsReducer = (state = { ...initialCommonState }, action) => {
  switch (action.type) {
    /********************************/
    case actionTypes.HOME_PAGE_POSITION:
      return {
        ...state,
        Page_Position: action.data,
      };
    case actionTypes.FLOORBOOK_PAGE_POSITION:
      return {
        ...state,
        Floorbookpage_Position: action.data,
      };
    case actionTypes.FLOORBOOK_DETAIL_PAGE_POSITION:
      return {
        ...state,
        FloorbookDetailPage_Position: action.data,
      };

    default:
      return state;
  }
};

export default ScrollPositionsReducer;
