import React, { useEffect } from "react";
import { Field } from "redux-form";
import { CustomButton, Form, Input, TextArea, VideoImageUpload } from "../../../../../components";
import { BUTTON_LABELS, USEFUL_RESOURCES } from "../../../../../shared/constants";

export const RenderForm = ({
  handleSubmit,
  submit,
  handleFileChange,
  file,
  showError,
  errors,
  editMode,
  defaultdata,
}) => {
  const [disableBtn, set_disableBtn] = React.useState(false);

  useEffect(() => {
    if (file.length) {
      set_disableBtn(true);
    } else {
      set_disableBtn(false);
    }
  }, [file]);

  return (
    <Form onSubmit={handleSubmit(submit)}>
      <div className="row">
        <div className="col-12">
          <div className="form-group">
            <label>{USEFUL_RESOURCES.UPLOAD_LABEL}</label>
            <VideoImageUpload file={file} fileChange={handleFileChange} editMode={editMode} />
            {showError && <span className="error_msg text-danger">{errors.file}</span>}
          </div>
        </div>
      </div>
      {file.length ? (
        file[0].file.type === "video/mp4" ? (
          <div className="row">
            <div className="col-12 d-flex justify-content-center">
              <div className="add_assest form-group">
                <video width={600} controls className="img-fluid">
                  <source src={file[0].url} type="video/mp4" />
                </video>
              </div>
            </div>
          </div>
        ) : (
          <div className="row">
            <div className="col-12 d-flex justify-content-center">
              <div className="add_assest form-group">
                <img src={file[0].url} alt="" width={600} className="img-fluid" />
              </div>
            </div>
          </div>
        )
      ) : null}
      <div className="row">
        <div className="col-12">
          <div className="form-group">
            <label>{USEFUL_RESOURCES.NAME_LABEL}</label>
            <Field
              name={USEFUL_RESOURCES.NAME_INPUT}
              component={Input}
              placeholder={USEFUL_RESOURCES.NAME_PLACEHOLDER}
            />
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-12">
          <div className="form-group">
            <label>{USEFUL_RESOURCES.DESCRIPTIONS_LABEL}</label>
            <Field
              name={USEFUL_RESOURCES.DESCRIPTION_INPUT}
              component={TextArea}
              id="resource"
              placeholder={USEFUL_RESOURCES.DESCRIPTIONS_PLACEHOLDER}
              defaultData={defaultdata ? defaultdata : ""}
            />
          </div>
        </div>
      </div>
      <div className="text-center">
        <CustomButton
          label={BUTTON_LABELS.SUBMIT}
          className={
            disableBtn ? "text-capitalize btn btn-lg btn-primary" : "disable text-capitalize btn  btn-lg btn-primary"
          }
        />
      </div>
    </Form>
  );
};
