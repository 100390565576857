import React from "react";
import { Field } from "redux-form";
import {
  CustomButton,
  Form,
  Input,
  KeyboardDateTimePickerr,
  RadioButtons,
  Select,
  TextArea,
} from "../../../../../components";
import {
  ACTIVITY_LOCATIONS,
  ADD_FLOORBOOK_PAGE,
  BUTTON_LABELS,
  STRINGS,
} from "../../../../../shared/constants";

export const RenderForm = ({
  handleSubmit,
  submit,
  allfloorbooks,
  floorbookPageDataForEdit,
  allSubjects,
  allInquiryLines,
  setSelectedSettingType,
  change,
  selectedSettingType,
  set_showStepOne,
  set_showStepTwo,
  updateDeleteLineOfInquiries = () => {},
}) => {
  console.log("floorbook data for edit in form", floorbookPageDataForEdit);
  return (
    <Form onSubmit={handleSubmit(submit)}>
      <div className="row">
        <div className="col-12">
          <div className="form-group">
            <label>{"Select Group Learning Journal"}</label>
            <Field
              name={ADD_FLOORBOOK_PAGE.FLOORBOOK_INPUT}
              isSearchable={true}
              component={Select}
              placeholder={STRINGS.SELECT_PLACEHOLDER}
              options={allfloorbooks}
              defaultValue={
                floorbookPageDataForEdit
                  ? floorbookPageDataForEdit.floorbook_id
                  : ""
              }
            />
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-12">
          <div className="form-group">
            <label>{ADD_FLOORBOOK_PAGE.TITLE_LABEL}</label>
            <Field
              name={ADD_FLOORBOOK_PAGE.TITLE_INPUT}
              component={Input}
              placeholder={ADD_FLOORBOOK_PAGE.TITLE_PLACEHOLDER}
            />
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-12">
          <div className="form-group">
            <label>{ADD_FLOORBOOK_PAGE.SUBJECT_LABEL}</label>
            <Field
              name={ADD_FLOORBOOK_PAGE.SUBJECT_INPUT}
              component={Select}
              isSearchable={true}
              placeholder={ADD_FLOORBOOK_PAGE.SUBJECT_PLACEHOLDER}
              options={allSubjects}
              defaultValue={
                floorbookPageDataForEdit ? floorbookPageDataForEdit.subject : ""
              }
            />
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-12">
          <div className="form-group">
            <label>{ADD_FLOORBOOK_PAGE.LINE_OF_INQUIRY_LABEL}</label>
            <Field
              name={ADD_FLOORBOOK_PAGE.LINE_OF_INQUIRY_INPUT}
              component={Select}
              isSearchable={true}
              placeholder={"Select one"}
              options={allInquiryLines}
              showIconsforEditDelete={true}
              updateDeleteLineOfInquiries={updateDeleteLineOfInquiries}
              defaultValue={
                floorbookPageDataForEdit
                  ? floorbookPageDataForEdit.inquiry_line
                  : ""
              }
              onChange={(value) => {
                if (value.label == "Other") {
                  setSelectedSettingType(value);
                } else {
                  setSelectedSettingType([]);
                  change(ADD_FLOORBOOK_PAGE.OTHER_TYPE_INPUT, "");
                }
              }}
            />
          </div>
        </div>
      </div>

      {selectedSettingType.label == "Other" ? (
        <div className="row">
          <div className="col-12">
            <div className="form-group">
              <label>{ADD_FLOORBOOK_PAGE.OTHER_TYPE_LABEL}</label>
              <Field
                name={ADD_FLOORBOOK_PAGE.OTHER_TYPE_INPUT}
                component={Input}
                placeholder={"Enter your inquiry."}
              />
            </div>
          </div>
        </div>
      ) : null}

      <label>{ADD_FLOORBOOK_PAGE.DATE_LABEL}</label>
      <div className="row">
        <div className="col-md-6">
          <div className="form-group">
            <div className="w-100 d-flex align-items-center group_input">
              <label>{ADD_FLOORBOOK_PAGE.FROM_DATE_LABEL}</label>
              <Field
                name={ADD_FLOORBOOK_PAGE.START_DATE_INPUT}
                component={KeyboardDateTimePickerr}
                placeholder={ADD_FLOORBOOK_PAGE.FROM_PLACEHOLDER}
                defaultValue={
                  floorbookPageDataForEdit
                    ? floorbookPageDataForEdit.activity_from
                    : ""
                }
              />
            </div>
          </div>
        </div>

        <div className="col-md-6">
          <div className="form-group">
            <div className="w-100 d-flex align-items-center group_input">
              <label>{ADD_FLOORBOOK_PAGE.TO_DATE_LABEL}</label>
              <Field
                name={ADD_FLOORBOOK_PAGE.END_DATE_INPUT}
                component={KeyboardDateTimePickerr}
                placeholder={ADD_FLOORBOOK_PAGE.TO_PLACEHOLDER}
                defaultValue={
                  floorbookPageDataForEdit
                    ? floorbookPageDataForEdit.activity_to
                    : ""
                }
              />
            </div>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-12">
          <div className="form-group">
            <label>{ADD_FLOORBOOK_PAGE.DESCRIPTION_LABEL}</label>
            <Field
              name={ADD_FLOORBOOK_PAGE.DESCRIPTION_INPUT}
              component={TextArea}
              id={"description"}
              placeholder={ADD_FLOORBOOK_PAGE.DESCRIPTION_PLACEHOLDER}
              defaultData={
                floorbookPageDataForEdit
                  ? floorbookPageDataForEdit.description
                  : ""
              }
            />
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-12">
          <div className="form-group">
            <label>{ADD_FLOORBOOK_PAGE.LOCATION_LABEL}</label>
            <Field
              name={ADD_FLOORBOOK_PAGE.LOCATION_INPUT}
              component={RadioButtons}
              radioGroupItems={[
                { label: ACTIVITY_LOCATIONS[1], value: "1" },
                { label: ACTIVITY_LOCATIONS[2], value: "2" },
                { label: ACTIVITY_LOCATIONS[3], value: "3" },
              ]}
              defaultValue={
                floorbookPageDataForEdit
                  ? String(floorbookPageDataForEdit.location_activity)
                  : ""
              }
            />
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-12">
          <div className="form-group">
            <label>{ADD_FLOORBOOK_PAGE.REFLECTIONS_LABEL}</label>
            <Field
              name={ADD_FLOORBOOK_PAGE.REFLECTIONS_INPUT}
              component={TextArea}
              id={"reflection"}
              placeholder={ADD_FLOORBOOK_PAGE.REFLECTIONS_PLACEHOLDER}
              defaultData={
                floorbookPageDataForEdit
                  ? floorbookPageDataForEdit.improvement
                  : ""
              }
            />
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-6">
          <div className="text-right mt-md-4 mt-3">
            <CustomButton
              label={BUTTON_LABELS.GO_BACK}
              className="btn btn-lg btn-primary text-capitalize"
              onButtonClick={() => {
                set_showStepOne(true);
                set_showStepTwo(false);
              }}
            />
          </div>
        </div>
        <div className="col-6">
          <div className="text-left mt-md-4 mt-3">
            <CustomButton
              label={BUTTON_LABELS.SAVE}
              className="btn btn-lg btn-primary text-capitalize"
              type="submit"
            />
          </div>
        </div>
      </div>
    </Form>
  );
};
