import React from "react";
import { Field } from "redux-form";
import { CustomButton, Form, Input } from "../../../components";
import { BUTTON_LABELS, INPUT_TYPE, STRINGS } from "../../../shared/constants";

export const RenderForm = ({ handleSubmit, submit, history }) => {
  return (
    <Form onSubmit={handleSubmit(submit)}>
      <h3 className="text-center mb-5"> Login </h3>
      <div className="row">
        <div className="col-12">
          <div className="form-group">
            <label>{STRINGS.EMAIL_LABEL}</label>
            <Field name={STRINGS.EMAIL_INPUT_NAME} component={Input} placeholder={STRINGS.EMAIL_PLACEHOLDER} />
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-12">
          <div className="form-group">
            <label>{STRINGS.PASSWORD_LABEL}</label>
            <Field
              name={STRINGS.PASSWORD_INPUT_NAME}
              component={Input}
              placeholder={STRINGS.PASSWORD_PLACEHOLDER}
              config={{ type: INPUT_TYPE.password }}
            />
          </div>
        </div>
      </div>

      <div className="row mb-3">
        <div className="col-sm-6">
          <div className="form-group text-left custom_checkbox curser_point">
            <input type="checkbox" id="rememberCheck" style={{ width: 22 }} />
            <label htmlFor="rememberCheck" className="mb-0">
              {STRINGS.REMEMBER_ME_LABEL}
            </label>
          </div>
        </div>
        <div className="col-sm-6">
          <div className="form-group text-right curser_point">
            <label className="d-inline-block mb-0" onClick={() => history.push("/forgot-password")}>
              {STRINGS.FORGOT_PASSWORD_LABEL}
            </label>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-12">
          <CustomButton label={BUTTON_LABELS.LOGIN} className="w-100 btn btn-lg text-capitalize btn-primary" />
        </div>
      </div>

      {/* <div className="row">
        <div className="col-12">
          <div className="text-center mt-3 gotos">
            <span onClick={() => history.push(ROUTE_CONSTANTS.SIGNUP_FIRST)}>{BUTTON_LABELS.SIGN_UP}</span>
          </div>
        </div>
      </div> */}
    </Form>
  );
};
