// For Firebase JS SDK v7.20.0 and later, measurementId is optional
export const firebaseConfig = {
  apiKey: "AIzaSyBDAQKy7MmrAt7G7JZfpGM_nTU6XcP5e5M",
  authDomain: "inquiry-tracker.firebaseapp.com",
  databaseURL: "https://inquiry-tracker.firebaseio.com",
  projectId: "inquiry-tracker",
  storageBucket: "inquiry-tracker.appspot.com",
  messagingSenderId: "761707590677",
  appId: "1:761707590677:web:4a59c227eb2a5a144a4a1c",
  measurementId: "G-LEVCGYQVHJ",
  usePublicVapidKey: "BA9-ECOgrL6t9LBnThJz9rbcAhtURp37UX6Rp-_K86owVgW6zVprjpV906KBU7zerEPa7jc_lHQgQnMjRP8s3_w"
};