import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import NotificationScreen from "./screen";

const { notificationCount, addNotificationReadCount } = require(`../../../redux/actions`);

const mapStateToProps = (state) => {
  return {
    status: state.CommonReducer.status,
    soundStatus: state.CommonReducer.sound,
    count: state.CommonReducer.count,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    notificationCount: (data, type) => dispatch(notificationCount(data, type)),
    addNotificationReadCount: (data) => dispatch(addNotificationReadCount(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(NotificationScreen);
