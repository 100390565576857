import { VALIDATION_MESSAGES,STRINGS } from "../../../shared/constants";

const validator = (values) => {
  const errors = {};

  if (!values[STRINGS.FLOORBOOK_NAME_INPUT]) {
    errors[STRINGS.FLOORBOOK_NAME_INPUT] = VALIDATION_MESSAGES.FLOORBOOK_NAME_REQUIRED;
  }
  if (!values[STRINGS.FLOORBOOK_SIGNIN_INPUT]) {
    errors[STRINGS.FLOORBOOK_SIGNIN_INPUT] = VALIDATION_MESSAGES.FLOORBOOK_SIGNIN_REQUIRED;
  }
  if (!values[STRINGS.FLOORBOOK_COVER_INPUT]) {
    errors[STRINGS.FLOORBOOK_COVER_INPUT] = VALIDATION_MESSAGES.FLOORBOOK_COVER_REQUIRED;
  }
  if (!values[STRINGS.FLOORBOOK_PRIVACY_STATUS_INPUT]) {
    errors[STRINGS.FLOORBOOK_PRIVACY_STATUS_INPUT] = VALIDATION_MESSAGES.FLOORBOOK_PRIVACY_STATUS_INPUT_REQUIRED;
  }
  if (!values[STRINGS.FLOORBOOK_DESCRIPTION_INPUT]) {
    errors[STRINGS.FLOORBOOK_DESCRIPTION_INPUT] = VALIDATION_MESSAGES.FLOORBOOK_DESCRIPTION_REQUIRED;
  } else if (!values[STRINGS.FLOORBOOK_DESCRIPTION_INPUT].trim()) {
    errors[STRINGS.FLOORBOOK_DESCRIPTION_INPUT] = VALIDATION_MESSAGES.FLOORBOOK_DESCRIPTION_REQUIRED;
  }
  return errors;
};

export default validator;
