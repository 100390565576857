import { connect } from "react-redux";
import Screen from "./screen";
import {
  getMyJournals,
  getFavouriteFloorbooks,
  getAllJournals,
  setFavourite,
  deleteJournal,
  setFavouriteJournal,
  getFavouriteJournals,
  getOtherFloorbooks,
  getAllFloorbooks,
  updateFloorbookJournal,
  setHideorUnHideFloorbook,
  floorbookDetailsPagePosition
} from "../../../../../redux/actions";

const mapStateToProps = (state) => {
  return {
    loading: state.CommonReducer.isLoading,
    myfloorbookData: state.FloorbookReducer.myfloorbookData,
    OthersFloorbookData: state.FloorbookReducer.OthersFloorbookData,
    favouriteFloorbooksData: state.FloorbookReducer.favouriteFloorbooksData,
    Floorbookpage_Position: state.ScrollPositionsReducer.Floorbookpage_Position,
    userRole: state.CommonReducer.userData.user_role,
    privacyStatusofFloorbook: state.FloorbookReducer.privacyStatusofFloorbook,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getAllJournals: (callback) => dispatch(getAllJournals(callback)),
    getMyJournals: (callback) => dispatch(getMyJournals(callback)),
    getFavouriteFloorbooks: (callback) =>
      dispatch(getFavouriteFloorbooks(callback)),
    setFavourite: (data, callback) => dispatch(setFavourite(data, callback)),
    deleteJournal: (data, callback) => dispatch(deleteJournal(data, callback)),
    setFavouriteJournal: (data, callback) =>
      dispatch(setFavouriteJournal(data, callback)),
    getFavouriteJournals: (callback) =>
      dispatch(getFavouriteJournals(callback)),
    getOtherFloorbooks: (callback) => dispatch(getOtherFloorbooks(callback)),
    getAllFloorbooks: (callback) => dispatch(getAllFloorbooks(callback)),
    getFavouriteFloorbooks: (callback) =>
      dispatch(getFavouriteFloorbooks(callback)),
    floorbookDetailsPagePosition: (data) =>
      dispatch(floorbookDetailsPagePosition(data)),
    updateFloorbookJournal: (data, callback) =>
      dispatch(updateFloorbookJournal(data, callback)),
    setHideorUnHideFloorbook: (data, callback) =>
      dispatch(setHideorUnHideFloorbook(data, callback)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Screen);
