import { withSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { DisplayFloorbooks, CustomButton, Loader } from "../../../../../components";
import {
  BUTTON_LABELS,
  ROUTE_CONSTANTS,
  STATUS,
  NO_DATA_FOUND_MESSAGES,
} from "../../../../../shared/constants";
import { parseToHtml } from "../../../../../shared/services/Utility";

const Screen = ({
  history,
  getUsefulResources,
  usefulResources = [],
  enqueueSnackbar,
  loading,
  isAdmin,
  deleteUsefulResource,
}) => {
  const [resources, setResources] = useState([]);
  const fetchData = () => {
    /*** api call to fetch all the resources ***/
    getUsefulResources((message, type) => {
      if (type === STATUS.error) {
        enqueueSnackbar(message, {
          variant: type,
          autoHideDuration: 3000,
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "left",
          },
        });
      }
    });
  };

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    setResources(usefulResources);
  }, [usefulResources]);

  const onDeleteResource = (id) => {
    deleteUsefulResource(id, (message, type) => {
      enqueueSnackbar(message, {
        variant: type,
        autoHideDuration: 3000,
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "left",
        },
      });
      if (type === STATUS.success) {
        let arr = resources.filter((item) => item.id !== id);
        setResources(arr);
      }
    });
  };
  return (
    <div>
      <div className="card-header d-flex justify-content-end">
        {/* <HeadTitle title={HEAD_TITLES.USEFUL_RESOURCES} /> */}
        <div className="hdr_btns ml-xl-auto">
          {isAdmin ? (
            <CustomButton
              label={BUTTON_LABELS.ADD_NEW_RESOURCE}
              className="btn text-capitalize btn-primary"
              onButtonClick={() => {
                history.push(ROUTE_CONSTANTS.ADD_USEFUL_RESOURCE);
              }}
            />
          ) : null}
        </div>
      </div>
      {loading ? (
        <Loader />
      ) : (
        <div className="card-body pt-0">
          <div className="floorbook_list">
            {resources.length ? (
              resources.map((item, idx) => (
                <DisplayFloorbooks
                  key={idx}
                  id={item.id}
                  description={parseToHtml(item.description)}
                  type={item.type}
                  title={item.title}
                  resourceFile={item.resourceFile}
                  dataType="resources"
                  onDeleteResource={(id) => onDeleteResource(id)}
                  isAdmin={isAdmin}
                  loading={loading}
                />
              ))
            ) : (
              <h6 className="text-center p-5">{NO_DATA_FOUND_MESSAGES.NO_RESOURCE_FOUND}</h6>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default withSnackbar(Screen);
