import React, { useState, useEffect } from "react";
import { withStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";
import CustomButton from "../../atoms/CustomButton";
import { ADD_FLOORBOOK_TYPE, STATUS, VALIDATION_MESSAGES, BUTTON_LABELS } from "../../../shared/constants";
import { reduxForm } from "redux-form";
import { connect } from "react-redux";
import validator from "./validator";
import "./style.scss";
import { withSnackbar } from "notistack";
import { getBase64Image } from "../../../shared/services/Utility";
import { NewJournalForm } from "./form";

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

let DETAILS = {};

const JournalModal = ({
  btnLabel,
  className,
  formType = 0, //0 for create new Journal, 1 for edit
  details,
  handleSubmit = () => {},
  addNewJournal,
  updateJournal,
  getAllJournals,
  addNewFloorbook,
  updateFloorbook,
  resetForm,
  onClose = () => {},
  getAllFloorbooks,
  loading,
  enqueueSnackbar,
}) => {
  /****** DETAILS for initial update ********* */
  DETAILS = details;
  /**************** */
  const [open, setOpen] = React.useState(false);
  const [loader, set_loader] = useState(false);
  const [journalCoverImage, set_journalCoverImage] = useState();
  const [journalSignInImage, set_journalSignInImage] = useState();
  const [errors, setErrors] = useState({
    coverImage: VALIDATION_MESSAGES.JOURNAL_COVER_REQUIRED,
    signInImage: VALIDATION_MESSAGES.JOURNAL_SIGNIN_REQUIRED,
  });
  useEffect(() => {
    set_loader(loading);
  }, [loading]);

  const [showError, updateShowError] = useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
    resetForm();
    onClose();
  };
  const onDeleteCoverImage = () => {
    set_journalCoverImage("");
  };
  const onDeleteSignInImage = () => {
    set_journalSignInImage("");
  };

  useEffect(() => {
    if (details && details.image1) {
      //convert imageUrl to binary image
      getBase64Image(details.image1, "coverImage.jpg", (value) => {
        set_journalCoverImage(value);
      });
    }
    if (details && details.image2) {
      getBase64Image(details.image2, "signInImage.jpg", (value) => {
        set_journalSignInImage(value);
      });
    }
  }, [details]);

  const submit = (values) => {
    journalCoverImage && setErrors({ ...errors, coverImage: "" });
    journalSignInImage && setErrors({ ...errors, signInImage: "" });
    if (journalCoverImage && journalSignInImage) {
      let data = {
        name: values.JournalName,
        cover_image: journalCoverImage,
        image: journalSignInImage,
        is_learning_journal: 1,
        description: values.JournalDescription,
        private: values.privacyStatus,
      };

      if (formType === ADD_FLOORBOOK_TYPE.create) {
        addNewFloorbook(data, (message, type) => {
          enqueueSnackbar(message, {
            variant: type,
            autoHideDuration: 3000,
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "left",
            },
          });
          if (type === STATUS.success) {
            handleClose();
            resetForm();
            getAllFloorbooks((message, type) => {});
          }
        });
      } else {
        updateFloorbook(details.id, data, (message, type) => {
          enqueueSnackbar(message, {
            variant: type,
            autoHideDuration: 3000,
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "left",
            },
          });
          if (type === STATUS.success) {
            handleClose();
            resetForm();
            getAllFloorbooks((message, type) => {});
            window.location.reload();
          }
        });
      }
      /** if form type is of create than call the api for create new Journal , else api for update Journal */
      //   addNewJournal(data, (message, type) => {
      //     enqueueSnackbar(message, {
      //       variant: type,
      //       autoHideDuration: 3000,
      //       anchorOrigin: {
      //         vertical: "bottom",
      //         horizontal: "left",
      //       },
      //     });
      //     if (type === STATUS.success) {
      //       handleClose();
      //       resetForm();
      //       getAllJournals((message, type) => {});
      //     }
      //   });
      // } else {
      //   updateJournal(details.id, data, (message, type) => {
      //     enqueueSnackbar(message, {
      //       variant: type,
      //       autoHideDuration: 3000,
      //       anchorOrigin: {
      //         vertical: "bottom",
      //         horizontal: "left",
      //       },
      //     });
      //     if (type === STATUS.success) {
      //       handleClose();
      //       resetForm();
      //       getAllJournals((message, type) => {});
      //       window.location.reload();
      //     }
      //   });
      // }
    } else {
      updateShowError(true);
    }
  };

  return (
    <>
      <CustomButton
        className={
          className ? className : "w-100 btn btn-lg text-capitalize btn-primary"
        }
        label={btnLabel}
        onButtonClick={handleClickOpen}
      />

      <Dialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <div className={loader ? "loader_enable" : ""}>
          <DialogTitle
            id="customized-dialog-title"
            onClose={handleClose}
            className="text-center"
          >
            {formType
              ? BUTTON_LABELS.EDIT_JOURNAL
              : BUTTON_LABELS.CREATE_NEW_JOURNAL}
          </DialogTitle>
          <NewJournalForm
            journalCoverImage={journalCoverImage}
            set_journalCoverImage={set_journalCoverImage}
            journalSignInImage={journalSignInImage}
            set_journalSignInImage={set_journalSignInImage}
            onDeleteCoverImage={onDeleteCoverImage}
            onDeleteSignInImage={onDeleteSignInImage}
            formType={formType}
            setErrors={setErrors}
            errors={errors}
            showError={showError}
            details={details}
            loader={loader}
            handleSubmit={handleSubmit}
            submit={submit}
          />
        </div>
      </Dialog>
    </>
  );
};

let AddJournalModal = reduxForm({
  form: "AddNewJournal",
  validate: validator,
  enableReinitialize: true,
})(JournalModal);

AddJournalModal = connect((state) => {
  return {
    initialValues: DETAILS
      ? {
          JournalName: DETAILS.name,
          JournalDescription: DETAILS.description,
          privacyStatus: DETAILS.privacyStatus,
        }
      : {},
  };
})(AddJournalModal);

export default withSnackbar(AddJournalModal);
