import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";
import "./style.scss";
import ProfileModal from "../ProfileModal";
import { BUTTON_LABELS } from "../../../shared/constants";
const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon style={{ color: "#0D532D" }} />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});
//use to dispaly user list who liked that page or floorbook
const LikesListingModal = ({ likes, Likes_Data }) => {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    if (likes !== 0) {
      setOpen(true);
    }
  };
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <span onClick={handleClickOpen}>
        {likes} {BUTTON_LABELS.LIKES}
      </span>
      <Dialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={open}>
        <div className="total_likes_modal">
          <DialogTitle id="customized-dialog-title" onClose={handleClose}>
            <i className="mr-2">
              <img src={require("../../../assets/images/icons/like_grey.svg")} alt="" />
            </i>
            {likes} {BUTTON_LABELS.LIKES}
          </DialogTitle>
          <MuiDialogContent className="post_likes_content">
            {Likes_Data.length &&
              Likes_Data.map((item, index) => {
                return (
                  <div key={index} className="like_users d-flex align-items-center justify-content-start">
                    <figure>
                      <ProfileModal user_image={item.user_image} user_id={item.user_id} />
                    </figure>

                    <h6>{item.name}</h6>
                  </div>
                );
              })}
          </MuiDialogContent>
        </div>
      </Dialog>
    </>
  );
};
export default LikesListingModal;
