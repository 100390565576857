import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import CustomButton from "../../atoms/CustomButton";
import { BUTTON_LABELS } from "../../../shared/constants";

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon style={{ color: "#0D532D" }} />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const AddModal = ({ handle_imageChange, removeProfilePic }) => {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <label htmlFor="files" onClick={() => handleClickOpen()}>
        <img
          style={{ cursor: "pointer" }}
          src={require("../../../assets/images/icons/camera_icon.svg")}
          width={25}
          alt=""
          draggable={false}
        />
      </label>

      <Dialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={open}>
        <DialogTitle id="customized-dialog-title" onClose={handleClose} />
        <MuiDialogContent className="add_floor_box d-flex align-items-center justify-content-center flex-column">
          <div>
            <label
              className="w-100 btn btn-lg btn-primary text-capitalize mb-4"
              htmlFor="filesd"
              style={{ alignItems: "center", display: "flex", justifyContent: "center" }}
            >
              {BUTTON_LABELS.SELECT_PHOTO}
            </label>
            <input
              id="filesd"
              multiple={false}
              style={{ display: "none" }}
              type="file"
              accept={".jpg,.png"}
              onChange={(e) => {
                if (e.target.files.length) {
                  handle_imageChange(e.target.files[0]);
                  handleClose();
                }
              }}
            />
          </div>
          <div>
            <CustomButton
              className="w-100 btn btn-lg btn-primary text-capitalize mb-4"
              label={BUTTON_LABELS.REMOVE_PHOTO}
              onButtonClick={() => {
                removeProfilePic("");
                handleClose();
              }}
            />
          </div>
          <label>Max Size: 10Mb</label>
        </MuiDialogContent>
      </Dialog>
    </>
  );
};

export default AddModal;
