import React from "react";
import { Field } from "redux-form";
import { CustomButton, Form, Input, CaptchaInput } from "../../../../components";
import { BUTTON_LABELS, INPUT_TYPE, ROUTE_CONSTANTS, STRINGS } from "../../../../shared/constants";

export const RenderForm = ({ history, handleSubmit, submit, saveCaptcha }) => {
  return (
    <Form onSubmit={handleSubmit(submit)}>
      <h3 className="text-center text-capitalize mb-4">{STRINGS.SIGN_UP_LABEL}</h3>
      <div className="row">
        <div className="col-12">
          <div className="form-group">
            <label>{STRINGS.FIRST_NAME_LABEL}</label>
            <Field name={STRINGS.FIRST_NAME_INPUT} component={Input} placeholder={STRINGS.FIRST_NAME_PLACEHOLDER} />
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-12">
          <div className="form-group">
            <label>{STRINGS.LAST_NAME_LABEL}</label>
            <Field name={STRINGS.LAST_NAME_INPUT} component={Input} placeholder={STRINGS.LAST_NAME_PLACEHOLDER} />
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-12">
          <div className="form-group">
            <label>{STRINGS.EMAIL_LABEL}</label>
            <Field name={STRINGS.EMAIL_INPUT_NAME} component={Input} placeholder={STRINGS.EMAIL_PLACEHOLDER} />
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-12">
          <div className="form-group">
            <label>{STRINGS.PASSWORD_LABEL}</label>
            <Field
              name={STRINGS.PASSWORD_INPUT_NAME}
              component={Input}
              placeholder={STRINGS.PASSWORD_PLACEHOLDER}
              config={{ type: INPUT_TYPE.password }}
            />
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-12">
          <div className="form-group">
            <label>{STRINGS.CAPTCHA_LABEL}</label>
            <Field
              name={STRINGS.CAPTCHA_INPUT}
              component={CaptchaInput}
              saveCaptcha={saveCaptcha}
              REGENERATE_CAPCTHA={require(`../../../../assets/images/regenerate.svg`)}
              text={"text"}
            />
          </div>
        </div>
      </div>
      <div class="mt-3">
        <CustomButton
          label={BUTTON_LABELS.NEXT}
          type="submit"
          className="w-100 btn btn-lg text-capitalize btn-primary mt-3"
          onButtonClick={(e) => {}}
        />
        <div className="text-center mt-3 gotos">
          <span onClick={() => history.push(ROUTE_CONSTANTS.LOGIN)}>{BUTTON_LABELS.LOGIN_IF_YOU_ALREADY_SIGNED}</span>
        </div>
      </div>
    </Form>
  );
};
