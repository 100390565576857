import { connect } from "react-redux";
import Screen from "./screen";
import {
  likeFloorbook,
  getFloorbookCoverDetails,
} from "../../../../../../redux/actions";

const mapStateToProps = (state) => {
  return {
    loading: state.CommonReducer.isLoading,
    profileImage: state.CommonReducer.userData.profile_pic,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getFloorbookCoverDetails: (data, callback) =>
      dispatch(getFloorbookCoverDetails(data, callback)),
    likeFloorbook: (data, status, created_at, callback) =>
      dispatch(likeFloorbook(data, status, created_at, callback)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Screen);
