import React, { useState } from "react";
import { Radio, RadioGroup, FormLabel, FormControlLabel } from "@material-ui/core";
import "./style.scss";
import { STRINGS } from "../../../shared/constants";

const RadioButtons = ({
  labelText = "",
  radioGroupItems = [],
  defaultValue,
  handleValueChange = () => {},
  input,
  meta: { touched, error },
  widthStyle,
}) => {
  const validationSpan = touched && error ? <span className="error_msg text-danger">{error}</span> : null;
  const style = widthStyle ? widthStyle : "col-sm-6";
  const [showSelectedValue, updateSelectedValue] = useState(defaultValue);
  return (
    <>
      <FormLabel component="legend">{labelText}</FormLabel>
      <RadioGroup
        aria-label="position"
        name={STRINGS.FLOORBOOK_PRIVACY_STATUS_INPUT}
        value={showSelectedValue}
        onChange={(event) => {
          input.onChange(event.target.value);
          updateSelectedValue(event.target.value);
          handleValueChange(event.target.value);
        }}
        row
      >
        {radioGroupItems.map((item, index) => {
          return (
            <FormControlLabel
              checked={item.value === showSelectedValue ? true : false}
              key={index + ""}
              value={item.value}
              control={<Radio color="primary" />}
              label={item.label ? item.label : ""}
              className="radio-group"
              labelPlacement="end"
            />
          );
        })}
        {validationSpan}
      </RadioGroup>
    </>
  );
};
export default RadioButtons;
