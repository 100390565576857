import { connect } from "react-redux";
import { getUsefulResources, deleteUsefulResource } from "../../../../../redux/actions";
import UsefulResource from "./screen";

const mapStateToProps = (state) => {
  return {
    usefulResources: state.UsefulResourcesReducer.resources,
    isAdmin: state.UsefulResourcesReducer.isAdmin,
    loading: state.CommonReducer.isLoading,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getUsefulResources: (callback) => dispatch(getUsefulResources(callback)),
    deleteUsefulResource: (data, callback) => dispatch(deleteUsefulResource(data, callback)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(UsefulResource);
