import { connect } from "react-redux";
import { getHelpInfo, setHelpInfo } from "../../../../redux/actions";

import Screen from "./screen";

const mapStateToProps = (state) => {
  return {
    loading: state.CommonReducer.isLoading,
    user_role: state.CommonReducer.userData.user_role,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getHelpInfo: (callback) => dispatch(getHelpInfo(callback)),
    setHelpInfo: (data, callback) => dispatch(setHelpInfo(data, callback)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Screen);
