const LIVE_URL = "https://api.inquiry-tracker.com/floorbook-php/index.php/api"; //live
const STAGING_URL = "https://staging-api.inquiry-tracker.com/floorbook-php/index.php/api"; // staging

export const BASE_URL = STAGING_URL;

//Authentication url's
export const Authentication_URL = BASE_URL + "/login";
export const Register_URL = BASE_URL + "/register";
export const LOGOUT = BASE_URL + "/logout";
// Url's for  get dropdown data at register page
export const Get_Dropdown = BASE_URL + "/get-dropdown-data";
// Forgot password url's
export const SEND_EMAIL_FOR_PASSWORD_RESET = BASE_URL + "/forgotPass";
export const MATCH_OTP = BASE_URL + "/matchOtp";
export const UPDATE_PASSWORD = BASE_URL + "/update-password";
// url for floorbook-add/update
export const ADD_FLOORBOOK = BASE_URL + "/add-floorbook";
export const UPDATE_FLOORBOOK = BASE_URL + "/update-floorbook";
// Add floorbook page url's
export const SAVE_FLOORBOOKS_PAGE = BASE_URL + "/add-floorbook-page";

// api's for dropdown menus
export const GET_FLOORBOOK = BASE_URL + "/get-floorbookpage";
export const GET_SUBJECT_AREA = BASE_URL + "/get-subject-area";
export const GET_INQUIRY_LINES = BASE_URL + "/get-inquiry-lines";
export const GET_SCOTTISH_LOCAL_AUTHORITY = BASE_URL + "/get-local-authority";
export const UPDATE_FLOORBOOK_IS_JOURNAL = BASE_URL + "/update-floorbook-is-journal";
export const GET_FLOORBOOK_PAGE_DETAIL = BASE_URL + "/get-floorbookpageby-page_id";
export const UPDATE_FLOORBOOK_PAGE = BASE_URL + "/update-floorbook-page";
export const GET_PAGE_DATA_BY_ID = BASE_URL + "/get-data-by-pageId";
// get my/other/saved floorbook url
export const GET_ALL_FLOORBOOKS = BASE_URL + "/get-all-floorbook";
export const GET_OTHERS_FLOORBOOKS = BASE_URL + "/get-other-floorbook";
export const GET_FAVOURITE_FLOORBOOK = BASE_URL + "/get-favourite-floorbooks";
export const HIDE_UNHIDE_FLOORBOOK = BASE_URL + "/update-floorbook-status";
// like floorbook/floorbook-page url
export const LIKE_FLOORBOOK_PAGE = BASE_URL + "/floorbookpage-like";
export const LIKE_FLOORBOOK = BASE_URL + "/update-floorbook-like";
// comment floorbook/floorbook-page url
export const SAVE_FLOORBOOK_PAGE_COMMENT = BASE_URL + "/add-floorbookpage-comment";
export const GET_ALL_FLOORBOOKS_PAGE = BASE_URL + "/get-all-floorbook-page";
export const SAVE_FLOORBOOK_COMMENT = BASE_URL + "/add-floorbook-comment";
export const UPDATE_FLOORBOOK_COMMENT = BASE_URL + "/update-floorbook-comment";
export const UPDATE_FLOORBOOK_PAGE_COMMENT = BASE_URL + "/update-floorbookpage-comment";
export const DO_COMMENT_LIKE = BASE_URL + "/add-comment-like";
export const DO_COMMENT_REPLY = BASE_URL + "/add-comment-reply";
//delete floorbook or floorbook page
export const DELETE_FLOORBOOK = BASE_URL + "/floorbook";
export const DELETE_FLOORBOOK_PAGE = BASE_URL + "/floorbook-page";
export const GET_COVER_DETAILS = BASE_URL + "/floorbook";
//resource api's
export const GET_USEFULRESOURCES = BASE_URL + "/get-resources";
export const ADD_USEFULRESOURCES = BASE_URL + "/add-resource";
export const EDIT_RESOURCE = BASE_URL + "/edit-resource";
export const DELETE_RESOURCE = BASE_URL + "/delete-resource";
export const GET_RESOURCE_BY_ID = BASE_URL + "/get-resourcebyId"; //to-do-> use api for detail resource and ask backend to delete another one
export const GET_USEFUL_RESOURCES_DETAIL = BASE_URL + "/get-resource-by-id";

//reports api
export const GET_REPORT = BASE_URL + "/get-floorbook-report";
export const GET_REPORT_FLOORBOOK = BASE_URL + "/get-user-floorbooks";
export const UPDATE_REPORTS_HEADING = BASE_URL + "/add-update-report-heading";
export const UPDATE_REPORTS_SUMMARY = BASE_URL + "/add-update-report-summary";

export const SET_FAVOURITE_FLOORBOOK = BASE_URL + "/update-favourite";
export const UPDATE_FLOORBOOK_COMPLETION_TIME = BASE_URL + "/modify-floorbookpost";

//journal's api
export const ADD_JOURNAL = BASE_URL + "/add-journal-entry";
export const GET_MY_JOURNALS = BASE_URL + "/get-journal-entry";
export const GET_OTHERS_JOURNAL = BASE_URL + "/get-others-journal-entry";
export const GET_JOURNAL_BY_ID = BASE_URL + "/journal-entry-details";
export const DELETE_JOURNAL = BASE_URL + "/delete-entry";
export const EDIT_JOURNAL_ENTRY = BASE_URL + "/edit-entry";
export const SET_FAVOURITE_JOURNAL = BASE_URL + "/entry-favourite";
export const GET_FAVOURITE_JOURNALS = BASE_URL + "/get-favourite-entries";
export const LIKE_JOURNAL = BASE_URL + "/journal-entry-like";

//History api's
export const GET_USER_HISTORY = BASE_URL + "/user-history";
export const LIKE_HISTORY = BASE_URL + "/user-likes";
export const COMMENTS_HISTORY = BASE_URL + "/user-Comments";
//profile
export const GET_USER_PROFILE_DATA = BASE_URL + "/user-profile";
export const CHANGE_PASSWORD = BASE_URL + "/change-password";
export const UPDATE_PROFILE = BASE_URL + "/user-edit";
export const GET_USER_PROFILE_DETAILS = BASE_URL + "/user-data";

//delete comments
export const DELETE_COMMENT_FLOORBOOK = BASE_URL + "/floorbook-comment";
export const DELETE_COMMENT_FLOORBOOK_PAGE = BASE_URL + "/floorbookpage-comment";

// notification
export const NOTIFICATION_SETTING = BASE_URL + "/update-notification-setting";
export const NOTIFICATION_LIST = BASE_URL + "/get-notification";
export const NOTIFICATION_CLEAR = BASE_URL + "/delete-notification";
export const UPDATE_NOTIFICATION_READ_STATUS = BASE_URL + "/update-notification-read-status";
export const GET_UNREAD_NOTIFICATION_COUNT = BASE_URL + "/get-unread-notification";

export const GET_NOTIFICATION_SETTING = BASE_URL + "/get-user-settings";
//Help page
export const SET_HELP_INFO = BASE_URL + "/edit-help-data";
export const GET_HELP_INFO = BASE_URL + "/help-data";

//line of inquiry

export const UPDATE_LINE_OF_INQUIRY = BASE_URL + "/update-delete-inquiry-lines";
//Api Method Type
export const POST_METHOD = "POST";
export const GET_METHOD = "GET";

var APIMethodEnum = { POST: 1, GET: 2 };
Object.freeze(APIMethodEnum);
