import { connect } from "react-redux";
import {
  getAllFloorbooksList,
  homePagePosition,
  fetchFloorboookOrder,
  savepageNoOfLatestPosts,
  saveTotalCountofLatestPosts,
  savePageNo,
  setEmptyDataForPosts,
  get_Notification,
  getUnreadNotificationCount,
  getUserProfileDetails
} from "../../../../redux/actions";
import Screen from "./screen";

const mapStateToProps = (state) => {
  return {
    allFloorbooksPagesList: state.FloorbookReducer.allFloorBooksPagesList,
    allFloorBooksPagesCount: state.FloorbookReducer.allFloorBooksPagesCount,
    loading: state.CommonReducer.isLoading,
    Page_Position: state.ScrollPositionsReducer.Page_Position,
    LatestPosts: state.HomePageReducer.LatestPosts,
    pageNoOfLatestPost: state.HomePageReducer.pageNoOfLatestPost,
    totalLatestPosts: state.HomePageReducer.totalLatestPosts,
    pageNoHome: state.HomePageReducer.pageNoHome,
    floorbookAllPages: state.FloorbookReducer.floorbookAllPages,
    notificationList: state.NotificationReducer.notificationList,
    notificationLoader: state.NotificationReducer.notificationLoader,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getAllFloorbooksList: (data, callback) => dispatch(getAllFloorbooksList(data, callback)),
    pagePosition: (data) => dispatch(homePagePosition(data)),
    fetchFloorboookOrder: (data) => dispatch(fetchFloorboookOrder(data)),
    savepageNoOfLatestPosts: (data) => dispatch(savepageNoOfLatestPosts(data)),
    saveTotalCountofLatestPosts: (data) => dispatch(saveTotalCountofLatestPosts(data)),
    savePageNo: (data) => dispatch(savePageNo(data)),
    getUserProfileDetails: (data, callback) =>
      dispatch(getUserProfileDetails(data, callback)),
    setEmptyDataForPosts: (data) => dispatch(setEmptyDataForPosts(data)),
    get_Notification: (callback) => dispatch(get_Notification(callback)),
    getUnreadNotificationCount: (callback) => dispatch(getUnreadNotificationCount(callback)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Screen);
