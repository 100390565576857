import React from "react";

//Component used to cover the image with a layer tp prevent clicks and drags
export default ({ children, handleClick = () => {}, cusror }) => {
  return (
    <div style={{ position: "relative", userSelect: "none" }} onClick={handleClick}>
      {children}
      <div style={{ position: "absolute", top: 0, width: "100%", height: "100%", cursor: cusror ? "pointer" : "" }} />
    </div>
  );
};
