import React from "react";
import { MAX_DESCRIPTION_LENGTH } from "../../../shared/constants";
const TextArea = ({
  input,
  label,
  meta: { touched, error },
  config,
  placeholder,
  widthStyle,
  style,
  maxLength,
  data,
  value,
  limit,
  defaultData,
  id,
  onChange = () => {},
  ...props
}) => {
  const [count, set_count] = React.useState("");
  const [showCount, set_showCount] = React.useState(false);
  widthStyle = widthStyle ? widthStyle : "";

  const validationSpan = touched && error ? <span className="error_msg text-danger">{error}</span> : null;

  React.useEffect(() => {
    if (defaultData) {
      set_count(MAX_DESCRIPTION_LENGTH - defaultData.length);
      set_showCount(true);
    }
  }, [defaultData]);

  React.useEffect(() => {
    if (showCount) {
      let data = document.getElementById(id).value;
      set_count(MAX_DESCRIPTION_LENGTH - data.length);
    }
  });

  return (
    <div className="form-group" style={style}>
      {label && <label>{label}</label>}
      <textarea
        id={id}
        className="form-control"
        rows={2}
        cols={2}
        placeholder={placeholder}
        {...input}
        {...config}
        onChange={(value) => {
          input.onChange(value);
          onChange(value);
          set_showCount(true);
        }}
        maxLength={MAX_DESCRIPTION_LENGTH}
        autoComplete="off"
      />
      {validationSpan}
      {showCount ? <strong>{count} characters left</strong> : <strong>Max characters: {MAX_DESCRIPTION_LENGTH}</strong>}
    </div>
  );
};
export default TextArea;
