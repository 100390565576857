import React from "react";
import { Field } from "redux-form";
import { CustomButton, Form, Input } from "../../../../components";
import { BUTTON_LABELS, INPUT_TYPE, STRINGS } from "../../../../shared/constants";

export const RenderForm = ({ handleSubmit, submitPassword }) => {
  return (
    <Form onSubmit={handleSubmit(submitPassword)}>
      <div className="row">
        <div className="col-12">
          <div className="form-group">
            <label>{STRINGS.OLD_PASSWORD}</label>
            <Field
              name={STRINGS.OLD_PASSWORD_INPUT}
              component={Input}
              placeholder={STRINGS.OLD_PASSWORD_PLACEHOLDER}
              config={{ type: INPUT_TYPE.password }}
            />
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-12">
          <div className="form-group">
            <label>{STRINGS.NEW_PASSWORD}</label>
            <Field
              name={STRINGS.NEW_PASSWORD_INPUT}
              component={Input}
              placeholder={STRINGS.NEW_PASSWORD_PLACEHOLDER}
              config={{ type: INPUT_TYPE.password }}
            />
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-12">
          <div className="form-group">
            <label>{STRINGS.CONFIRM_PASSWORD_LABEL}</label>
            <Field
              name={STRINGS.CONFIRM_PASSWORD_INPUT}
              component={Input}
              placeholder={STRINGS.CONFIRM_PASSWORD_PLACEHOLDER}
              config={{ type: INPUT_TYPE.password }}
            />
          </div>
        </div>
      </div>
      <div className="d-flex justify-content-center">
        <CustomButton
          label={BUTTON_LABELS.SAVE}
          type="submit"
          className=" btn btn-lg text-capitalize btn-primary mt-4"
        />
      </div>
    </Form>
  );
};
