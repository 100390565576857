import React, { useEffect, useState } from "react";
import { CustomButton, Loader } from "../../../../components";
import { withSnackbar } from "notistack";
import { BUTTON_LABELS,STATUS } from "../../../../shared/constants";
import { Editor, EditorState, RichUtils } from "draft-js";
import { stateToHTML } from "draft-js-export-html";
import { stateFromHTML } from "draft-js-import-html";
import "./style.scss";

const HelpPage = ({ user_role, loading, setHelpInfo, getHelpInfo, enqueueSnackbar }) => {
  const [edit, set_edit] = useState(false);
  const [HelpData, set_HelpData] = useState("");
  const [editorState, seteditorState] = React.useState(EditorState.createEmpty());
  const loadHelpData = () => {
    getHelpInfo((payload, type) => {
      if (type === STATUS.error) {
        enqueueSnackbar(payload, {
          variant: type,
          autoHideDuration: 3000,
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "left",
          },
        });
        return;
      }
      set_HelpData(payload.data.content);
    });
  };

  useEffect(() => {
    loadHelpData();
  }, []);

  const onChange = (newState) => {
    seteditorState(newState);
  };

  const handleKeyCommand = (command) => {
    const newState = RichUtils.handleKeyCommand(editorState, command);
    if (newState) {
      onChange(newState);
      return "handled";
    }
    return "not-handled";
  };
  //function to underline the text
  const onUnderlineClick = () => {
    onChange(RichUtils.toggleInlineStyle(editorState, "UNDERLINE"));
  };
  //function for bold
  const onBoldClick = () => {
    onChange(RichUtils.toggleInlineStyle(editorState, "BOLD"));
  };
  //function for italic
  const onItalicClick = () => {
    onChange(RichUtils.toggleInlineStyle(editorState, "ITALIC"));
  };

  const submit = () => {
    let Content = stateToHTML(editorState.getCurrentContent());
    const data = {
      content: Content,
    };
    setHelpInfo(data, (payload, type) => {
      if (type === STATUS.error) {
        enqueueSnackbar(payload, {
          variant: type,
          autoHideDuration: 3000,
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "left",
          },
        });
        return;
      }
      loadHelpData();
      set_edit(false);
    });
  };

  const setHelpData = () => {
    if (HelpData) {
      let data = stateFromHTML(HelpData);
      seteditorState(EditorState.createWithContent(data));
    }
  };
  useEffect(() => {
    setHelpData();
  }, [HelpData]);

  return (
    <>
      <div className="card-header d-flex justify-content-end">
        {/* <HeadTitle title={HEAD_TITLES.HELP} /> */}
        {user_role === 1 || user_role === 2
          ? !edit && (
              <div className="hdr_btns ml-xl-auto">
                <CustomButton
                  label={BUTTON_LABELS.EDIT}
                  className="btn btn-sm btn-primary text-capitalize"
                  onButtonClick={() => {
                    set_edit(true);
                  }}
                />
              </div>
            )
          : null}
      </div>
      <div className="card-body pt-0" style={{ pointerEvents: edit ? "initial" : "none" }}>
        <div className="row">
          <div className="col-12 text-justify">
            <div className="editorContainer">
              <div className="ediotr_header" style={{ visibility: edit ? "visible" : "hidden" }}>
                <button type="button" style={{ textDecoration: "underline" }} onClick={onUnderlineClick}>
                  {" "}
                  U
                </button>

                <button type="button" onClick={onBoldClick}>
                  <b>B</b>
                </button>
                <button type="button" onClick={onItalicClick}>
                  <em>I</em>
                </button>
              </div>
              <div className="editors">
                <Editor editorState={editorState} handleKeyCommand={handleKeyCommand} onChange={onChange} />
              </div>
            </div>

            {edit ? (
              <div className="form-row">
                <div className="col-6">
                  <div className="text-right mt-md-4 mt-3">
                    <CustomButton
                      label={BUTTON_LABELS.CANCEL}
                      className="btn btn-md btn-primary text-capitalize"
                      onButtonClick={() => {
                        set_edit(false);
                        setHelpData();
                      }}
                    />
                  </div>
                </div>
                <div className="col-6">
                  <div className="text-left mt-md-4 mt-3">
                    <CustomButton
                      label={BUTTON_LABELS.SAVE}
                      className="btn btn-md btn-primary text-capitalize"
                      onButtonClick={() => {
                        submit();
                      }}
                    />
                  </div>
                </div>
              </div>
            ) : null}
          </div>
        </div>
      </div>
      {loading && <Loader />}
    </>
  );
};

export default withSnackbar(HelpPage);
