import React, { useState, useEffect } from "react";
import ReactSelect, { components } from "react-select";
import "./style.scss";

const SearchSelect = ({
  config = {},
  input,
  meta: { touched, error },
  options,
  widthStyle,
  defaultValue,
  onValueChange = () => {},
  placeholder,
  value,
}) => {
  widthStyle = widthStyle ? widthStyle : "";
  const validationSpan = touched && error ? <span className="error_msg text-danger">{error}</span> : null;
  const [editInput, show_editInput] = useState(false);
  const [selectValue, setSelectvalue] = useState("");

  useEffect(() => {
    if (options) {
      let obj = options.filter((option) => option.value == defaultValue);
      setSelectvalue(obj);
    }
  }, [defaultValue && options]);

  const { Option } = components;
  const IconOption = (props) => {
    console.log("props", props);
    return (
      <Option {...props}>
        {editInput ? <input name="input" onChange={() => console.log("target")}></input> : props.label}{" "}
        {/* <img src={require("../../../assets/images/logo_green.png")} style={{ width: 36 }} alt={props.label} /> */}
        <label
          onClick={(e) => {
            e.preventDefault();
            console.log(e);
            show_editInput(true);
          }}
        >
          edit
        </label>
      </Option>
    );
  };

  return (
    <>
      <div className="form-group">
        <ReactSelect
          {...input}
          {...config}
          placeholder={placeholder}
          options={options}
          width={widthStyle}
          isSearchable={true}
          value={selectValue}
          onChange={(value) => {
            input.onChange(value);
            onValueChange(value);
            setSelectvalue(value);
          }}
          className={"form-select"}
          onBlur={(event) => event.preventDefault()}
          // components={{ Option: IconOption }}
        />

        {validationSpan}
      </div>
    </>
  );
};
export default SearchSelect;
