import React, { useEffect, useState } from "react";
import {
  AddNewFloorBookModal,
  CustomButton,
  DisplayPost,
  HeadTitle,
  ImageOverlay,
  AddNewJournalModal,
  LikesListingModal,
  Loader,
  DeleteModal,
} from "../../../../../components";
import "./style.scss";
import Carousel from "react-material-ui-carousel";
import { withSnackbar } from "notistack";
import { useLocation } from "react-router-dom";
import {
  BUTTON_LABELS,
  HEAD_TITLES,
  MAX_DESCRIPTION_SHOW_LENGTH,
  NO_DATA_FOUND_MESSAGES,
  STATUS,
  HEART_FILL,
  HEART_OUTLINE,
  LIKE_OUTLINE,
  LIKE_GREEN_ICON,
  LIKE_GRAY_ICON,
  COMMENTS_GRAY_ICON,
  COMMENTS_GREEN_ICON,
} from "../../../../../shared/constants";
import { Report } from "../../../../../Screens";
import { parseToHtml } from "../../../../../shared/services/Utility";
import { ROUTE_CONSTANTS, USER_ROLES } from "../../../../../shared/constants";
import { Switch, makeStyles } from "@material-ui/core";
import FormGroup from "@material-ui/core/FormGroup";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { tr } from "date-fns/locale";

const JournalDetails = ({
  history,
  getFloorbookPages,
  enqueueSnackbar,
  floorbookAllPages,
  saveFloorbookPages,
  loading,
  likeFloorbook,
  setFavourite,
  userData,
  deleteFloorbook,
  deleteFloorbookPage,
  FloorbookDetailPage_Position,
  floorbookDetailPagePosition,
  fetchFloorboookOrder = () => {},
  setHideorUnHideFloorbook = () => {},
  privacyStatusofFloorbook, //privacy status of floorbook stored in redux from saga
}) => {
  const useQuery = () => {
    return new URLSearchParams(useLocation().search);
  };

  const [pagesData, updatePageData] = useState([]); // state to store pages data , inside that floorbook , whose details are opened at that moment
  const [floorbookData, updatefloorbookData] = useState([]); // state to store floorbook data
  const [description, set_description] = useState(""); // state to store description of floorbook
  const [showFull, set_showFull] = useState(false); // state for show-less and more functionality of description
  const [descriptionIn_Html, upadte_descriptionIn_Html] = useState("");
  const [floorbookLikeStatus, set_likeStatus] = useState(0);
  const [floorbookLikes, set_totalCount] = useState(0);
  const [florrbookUserLikes, set_userLikes] = useState([]);
  const [floorbookFav, set_floorbookFav] = useState("");
  const [checked, setChecked] = React.useState(false); // state for Switch( hide and unhide functionality)
  let query = useQuery();
  let id_floorbook = query.get("journal_id");
  const [data, updateData] = useState([]);

  useEffect(() => {
    fetchFloorbookData();
  }, []);

  const fetchFloorbookData = () => {
    getFloorbookPages(id_floorbook, (message, type, status) => {
      if (type === STATUS.error) {
        enqueueSnackbar(message, {
          variant: type,
          autoHideDuration: 3000,
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "left",
          },
        });
        if (status === 404) {
          history.push(ROUTE_CONSTANTS.LEARNING_JOURNAL);
        }
      }
    });
  };

  useEffect(() => {
    updatePageData(floorbookAllPages);
    updatefloorbookData(floorbookAllPages.floorbook);
  }, [floorbookAllPages]);

  useEffect(() => {
    if (floorbookData) {
      //update the images data for carasol slider
      updateData([
        {
          cover_image: floorbookData.cover_image,
        },
        {
          cover_image: floorbookData.image,
        },
      ]);
      set_likeStatus(floorbookData.like_status);
      set_totalCount(floorbookData.total_likes);
      set_userLikes(floorbookData.user_likes);
      set_floorbookFav(floorbookData.user_favourite);
    }
  }, floorbookData);

  useEffect(() => {
    set_description(floorbookData && floorbookData.description);
  }, [floorbookData && floorbookData.description]);

  useEffect(() => {
    upadte_descriptionIn_Html(parseToHtml(description));
  }, [description]);

  useEffect(() => {
    setChecked(privacyStatusofFloorbook ? false : true); //update the switch(checked and unchecked) status
  }, [privacyStatusofFloorbook]);

  useEffect(() => {
    return function cleanup() {
      saveFloorbookPages([]);
    };
  }, []);

  //opens the cover page
  const showCover = () => {
    history.push({
      pathname: `${ROUTE_CONSTANTS.JOURNAL_COVER_DETAILS}`,
      search: `journal_page=${floorbookAllPages.floorbook.id}`,
    });
  };
  // delete floorbook page
  const onDeleteFloorbookPage = (id) => {
    deleteFloorbookPage(id, (message, type) => {
      enqueueSnackbar(message, {
        variant: type,
        autoHideDuration: 3000,
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "left",
        },
      });
      if (type === STATUS.success) {
        fetchFloorbookData();
      }
    });
  };
  // api request to hide or unhide the floorbook (privacy status of floorbook)
  const updateFloorbookPrivacyData = () => {
    let postdata = {
      id: id_floorbook,
      status: privacyStatusofFloorbook ? 0 : 1,
    };
    setHideorUnHideFloorbook(postdata, (message, type) => {
      enqueueSnackbar(message, {
        variant: type,
        autoHideDuration: 3000,
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "left",
        },
      });
      if (type === STATUS.success) {
        toggleChecked();
      }
    });
  };
  //api request to delete the floorbook
  const onDeleteFloorbook = () => {
    deleteFloorbook(id_floorbook, (message, type) => {
      enqueueSnackbar(message, {
        variant: type,
        autoHideDuration: 3000,
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "left",
        },
      });
      if (type === STATUS.success) {
        history.push(ROUTE_CONSTANTS.LEARNING_JOURNAL);
      }
    });
  };
  let prevPath =
    history &&
    history.location &&
    history.location.state &&
    history.location.state.from;

  const saveFloorbookdetailPagePosition = (data) => {
    //save the last post position
    floorbookDetailPagePosition(data);
  };

  var rowToScrollTo = document.getElementById(FloorbookDetailPage_Position);

  useEffect(() => {
    if (FloorbookDetailPage_Position && rowToScrollTo) {
      window.onload = init();
    }
  }, [rowToScrollTo]);

  const init = () => {
    var d = document.getElementById("content-wrapper");
    rowToScrollTo.scrollIntoView();
  };

  const toggleChecked = () => {
    setChecked((prev) => !prev);
  };
  const useStyles = makeStyles((theme) => ({
    toggle: {
      "& .Mui-checked": {
        color: "#1f532d",
        transform: "translateX(25px) !important",
      },
      "& .MuiSwitch-track": {
        backgroundColor: "#7e9481 !important",
      },
    },
  }));
  const classes = useStyles();

  return (
    <>
      <div className="card-header d-flex flex-column-reverse flex-xl-row justify-content-center justify-content-xl-between align-items-center">
        {/* <HeadTitle
          title={
            prevPath === ROUTE_CONSTANTS.HOME
              ? HEAD_TITLES.HOME
              : prevPath === ROUTE_CONSTANTS.HISTORY
              ? HEAD_TITLES.HISTORY
              : HEAD_TITLES.FLOORBOOK
          }
          titleCursor={true}
          titleOnClick={() => {
            // window.history.back();

            history.push(
              prevPath === ROUTE_CONSTANTS.HOME
                ? ROUTE_CONSTANTS.HOME
                : prevPath === ROUTE_CONSTANTS.HISTORY
                ? ROUTE_CONSTANTS.HISTORY
                : ROUTE_CONSTANTS.FLOORBOOKS
            );
          }}
          subTitle={[{ label: "Detail" }]}
        /> */}
        {(floorbookData && floorbookData.is_edit) ||
        (floorbookData &&
          !floorbookData.is_private &&
          USER_ROLES.ADMIN == userData.user_role) ? (
          <FormGroup>
            <Typography component="div">
              <Grid component="label" container alignItems="center">
                <Grid item>{BUTTON_LABELS.HIDE}</Grid>
                <Grid item>
                  <Switch
                    checked={checked}
                    onChange={updateFloorbookPrivacyData}
                    name="checked"
                    className={classes.toggle}
                  />
                </Grid>
                <Grid item>{BUTTON_LABELS.UNHIDE}</Grid>
              </Grid>
            </Typography>
          </FormGroup>
        ) : null}

        <div className="hdr_btns ml-xl-auto">
          <button
            className="btn-favourite"
            onClick={() => {
              //call api
              let id = floorbookData && floorbookData.id;
              setFavourite(
                {
                  id,
                  status: Number(floorbookFav),
                  marked_at: new Date().getTime(),
                },
                (payload, type) => {
                  if (type === STATUS.error) {
                    enqueueSnackbar(payload, {
                      variant: type,
                      autoHideDuration: 3000,
                      anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "left",
                      },
                    });
                  }
                  set_floorbookFav(payload.status);
                }
              );
            }}
          >
            {floorbookFav ? (
              <i>
                <img src={HEART_FILL} alt="" />
              </i>
            ) : (
              <i>
                <img src={HEART_OUTLINE} alt="" />
              </i>
            )}
          </button>
          <label className="post_type">
            {privacyStatusofFloorbook ? "Private" : "Public"}
          </label>
          <CustomButton
            label={BUTTON_LABELS.REPORT}
            className="btn btn-sm btn-primary text-capitalize"
            onButtonClick={() => {
              history.push({
                pathname: `${ROUTE_CONSTANTS.REPORT}`,
                state: {
                  id: floorbookData && floorbookData.id,
                  name: floorbookData && floorbookData.name,
                },
              });
            }}
          />

          {(floorbookData &&
            floorbookData.is_edit &&
            floorbookData.cover_image &&
            floorbookData.image) ||
          USER_ROLES.ADMIN == userData.user_role ? (
            // Edit floorboook Modal(Reused add floorboook Modal) , show this option if user is admin or owner of the floorbook
            <AddNewJournalModal
              btnLabel={BUTTON_LABELS.EDIT}
              className="btn btn-sm btn-primary text-capitalize"
              formType="edit"
              modalTitle={" Edit Journal "}
              details={{
                id: floorbookData && floorbookData.id,
                name: floorbookData && floorbookData.name,
                image1: floorbookData && floorbookData.cover_image,
                image2: floorbookData && floorbookData.image,
                description: floorbookData && floorbookData.description,
                privacyStatus:
                  floorbookData && String(floorbookData.is_private),
                showPrivacyChangeOptions:
                  floorbookData && floorbookData.is_edit
                    ? true
                    : floorbookData &&
                      !floorbookData.is_private &&
                      USER_ROLES.ADMIN == userData.user_role
                    ? true
                    : false,
              }}
            />
          ) : null}

          {(floorbookData && floorbookData.is_edit) ||
          USER_ROLES.ADMIN == userData.user_role ? (
            // Modal to delete the floorbook
            <DeleteModal
              btnLabel={BUTTON_LABELS.DELETE}
              type={1}
              onDelete={onDeleteFloorbook}
              loading={loading}
            />
          ) : null}
        </div>
      </div>

      <div className="cover_sec">
        <Carousel>
          {data.map((item, index) => (
            <figure key={index}>
              <ImageOverlay>
                <img
                  src={item.cover_image}
                  key={index}
                  height={200}
                  width={200}
                  className="img-fluid"
                />
              </ImageOverlay>
            </figure>
          ))}
        </Carousel>

        <div className="cover_summary">
          <h3>{floorbookData && floorbookData.name}</h3>
          <span>
            Created by: &nbsp;
            <h5> {floorbookData && floorbookData.user_name}</h5>{" "}
            <span style={{ fontSize: 20, fontWeight: 700 }}>|</span>{" "}
            {floorbookData && floorbookData.city},{" "}
            {floorbookData && floorbookData.country}
          </span>
          <p>
            {floorbookData && floorbookData.setting_name} (
            {floorbookData && floorbookData.setting_type})
          </p>
          {description && (
            <>
              <div
                className="w-100 text-center"
                dangerouslySetInnerHTML={
                  showFull
                    ? { __html: descriptionIn_Html && descriptionIn_Html }
                    : {
                        __html:
                          descriptionIn_Html &&
                          descriptionIn_Html.slice(
                            0,
                            MAX_DESCRIPTION_SHOW_LENGTH
                          ),
                      }
                }
              ></div>
              {showFull ? (
                <a
                  style={{
                    cursor: "pointer",
                    fontWeight: "500",
                    color: "#0D532D",
                  }}
                  onClick={() => set_showFull(false)}
                >
                  {BUTTON_LABELS.SHOW_LESS}
                </a>
              ) : description.length >= MAX_DESCRIPTION_SHOW_LENGTH ? (
                <a
                  style={{
                    cursor: "pointer",
                    fontWeight: "500",
                    color: "#0D532D",
                  }}
                  onClick={() => set_showFull(true)}
                >
                  {BUTTON_LABELS.SHOW_MORE}
                </a>
              ) : null}
            </>
          )}
        </div>
        <div className="post_status">
          <span>
            <i className="mr-2">
              <img src={LIKE_GRAY_ICON} alt="" />
            </i>{" "}
            <span>
              <LikesListingModal
                likes={floorbookLikes}
                Likes_Data={florrbookUserLikes || []}
              />
            </span>
          </span>
          <span onClick={() => showCover()}>
            <i className="mr-2">
              <img src={COMMENTS_GRAY_ICON} alt="" />
            </i>{" "}
            {(floorbookData && floorbookData.total_comments) || 0}{" "}
            <span>{BUTTON_LABELS.COMMENTS}</span>
          </span>
        </div>
        <div className="d-flex justify-content-around flex-row detial_like_smt">
          <CustomButton
            onButtonClick={() => {
              likeFloorbook(
                floorbookData && floorbookData.id,
                floorbookLikeStatus,
                new Date().getTime(),
                (data) => {
                  set_likeStatus(data.status);
                  set_totalCount(data.total_likes);
                  set_userLikes([...data.user_likes]);
                }
              );
            }}
            icon={floorbookLikeStatus ? LIKE_GREEN_ICON : LIKE_OUTLINE}
            className="btn btn-lg btn-primary"
            label={
              floorbookLikeStatus ? BUTTON_LABELS.LIKED : BUTTON_LABELS.LIKE
            }
          />
          <CustomButton
            icon={COMMENTS_GREEN_ICON}
            className="btn btn-lg btn-primary"
            label={BUTTON_LABELS.COMMENT}
            onButtonClick={() => showCover()}
          />
        </div>
      </div>

      <div className="card-body">
        {pagesData && pagesData.pages && pagesData.pages.length ? (
          pagesData.pages.map((item, index) => {
            return (
              <DisplayPost
                key={item.id}
                id={item.id}
                title={item.title}
                images={item.image}
                floorbook_name={
                  floorbookAllPages &&
                  floorbookAllPages.floorbook &&
                  floorbookAllPages.floorbook.name
                }
                username={
                  floorbookAllPages &&
                  floorbookAllPages.floorbook &&
                  floorbookAllPages.floorbook.user_name
                }
                location_activity={item.location_activity}
                description={item.description}
                subject={item.subject}
                improvement={item.improvement}
                is_learning_journal={item.is_learning_journal}
                inquiry_line={item.inquiry_line}
                activity_from={item.activity_from}
                activity_to={item.activity_to}
                floorbookPagePost={false}
                likes={item.total_likes}
                likeStatus={item.like_status}
                likedUsers={item.user_likes}
                is_edit={item.is_edit}
                pageType={1}
                isJournal={true}
                comments={item.total_comments}
                user_image={item.user_image}
                user_id={item.user_id}
                onDelete={onDeleteFloorbookPage} // api request to delete the floorbook page
                saveFloorbookdetailPagePosition={
                  saveFloorbookdetailPagePosition
                }
                fetchFloorboookOrder={fetchFloorboookOrder}
              />
            );
          })
        ) : (
          <p style={{ fontSize: "20px", textAlign: "center" }}>
            {NO_DATA_FOUND_MESSAGES.NO_FLOORBOOK_PAGES_FOUND}
          </p>
        )}
      </div>
      <Report
        currentReportData={{
          id: floorbookData && floorbookData.id,
          name: floorbookData && floorbookData.name,
        }}
      />
      {loading && <Loader />}
    </>
  );
};

export default withSnackbar(JournalDetails);
