import React, { useState, useEffect } from "react";
import { HEAD_TITLES, NO_DATA_MESSAGES, STATUS,USER_ROLES } from "../../../../../shared/constants";
import { DisplayPost, Loader,DisplayFloorbooks } from "../../../../../components";
import { withSnackbar } from "notistack";
import "./style.scss";
import { parseToHtml } from "../../../../../shared/services/Utility";

const Screen = ({
  getAllJournals,
  getMyJournals,
  loading,
  history,
 favouriteFloorbooksData,
getAllFloorbooks,
  OthersFloorbookData,
  enqueueSnackbar,
  userRole,
  setFavourite,
  myfloorbookData,
  setFavouriteJournal,
  getFavouriteFloorbooks,
  getOtherFloorbooks,
  setHideorUnHideFloorbook,
  updateFloorbookJournal,
  Floorbookpage_Position,
  floorbookDetailsPagePosition,
  deleteJournal = () => { },
  getFavouriteJournals,
}) => {
  const [myJournalsValue, set_myJournalsValue] = useState(true);
  const [otherJournalsValue, set_otherJournalsValue] = useState(false);

  const [showMyJournals, set_showMyJournals] = useState(false);
  const [showFavouriteJournals, set_showFavouriteJournals] = useState(false);
  const [showOthersJournals, set_showOthersJournals] = useState(true);
const [myfloorbooks, updateSetMyFloorbooks] = useState([]);
const [favouriteFloorbooks, update_favouriteFloorbooks] = useState([]);
const [othersFloorbooks, update_othersFloorbooks] = useState([]);
  const [myJournals, set_myJournals] = useState([]);
  const [favouriteJournals, update_favouriteJournals] = useState([]);
  const [otherJournals, set_otherJournals] = useState([]);
  const [checked, setChecked] = React.useState(false);
  const [searchValue, setSearchValue] = useState("");
  const toggleChecked = () => {
    setChecked((prev) => !prev);
  };
 useEffect(() => {
   getOtherFloorbooks((message, type) => {
     if (type === STATUS.error) {
       enqueueSnackbar(message, {
         variant: type,
         autoHideDuration: 3000,
         anchorOrigin: {
           vertical: "bottom",
           horizontal: "left",
         },
       });
     }
   });
 }, [showOthersJournals]);

 useEffect(() => {
   getAllFloorbooks((message, type) => {
     if (type === STATUS.error) {
       enqueueSnackbar(message, {
         variant: type,
         autoHideDuration: 3000,
         anchorOrigin: {
           vertical: "bottom",
           horizontal: "left",
         },
       });
     }
   });
 }, [showMyJournals]);

 useEffect(() => {
   showFavouriteJournals &&
     getFavouriteFloorbooks((message, type) => {
       if (type === STATUS.error) {
         enqueueSnackbar(message, {
           variant: type,
           autoHideDuration: 3000,
           anchorOrigin: {
             vertical: "bottom",
             horizontal: "left",
           },
         });
       }
     });
 }, [showFavouriteJournals]);
 const updateFloorbookIsJournal = (
   floorbookId,
   isJournal,
   idx,
   floorbookType
 ) => {
   let postdata = {
     id: floorbookId,
     is_learning_journal: isJournal,
   };
   updateFloorbookJournal(postdata, (message, type) => {
     enqueueSnackbar(message, {
       variant: type,
       autoHideDuration: 3000,
       anchorOrigin: {
         vertical: "bottom",
         horizontal: "left",
       },
     });
     if (type === "success") {
       // check floorbook Type 1: is for favourite floorbooks , 2: for my floorbooks , and 0 is for other floorbooks
       // change the is_private key acc to the index and type of floorbook
       console.log("is journal updated successfully");
       if (floorbookType == 1) {
         favouriteFloorbooks.splice(idx, 1, {
           ...favouriteFloorbooks[idx],
           is_learning_journal: isJournal,
         });
         update_favouriteFloorbooks(favouriteFloorbooks);
       }
       if (floorbookType == 2) {
         myfloorbooks.splice(idx, 1, {
           ...myfloorbooks[idx],
           is_learning_journal: isJournal,
         });
         updateSetMyFloorbooks(myfloorbooks);
       } else {
         othersFloorbooks.splice(idx, 1, {
           ...othersFloorbooks[idx],
           is_learning_journal: isJournal,
         });
         update_othersFloorbooks(othersFloorbooks);
       }
     }
   });
 };
 const updateFloorbookPrivacyData = (
   floorbookId,
   status,
   idx,
   floorbookType,
   is_learning_journal
 ) => {
   console.log("status", floorbookId, status, idx);
   let postdata = {
     id: floorbookId,
     status: status,
     is_learning_journal:is_learning_journal
   };
   setHideorUnHideFloorbook(postdata, (message, type) => {
     enqueueSnackbar(message, {
       variant: type,
       autoHideDuration: 3000,
       anchorOrigin: {
         vertical: "bottom",
         horizontal: "left",
       },
     });
     if (type === "success") {
       // check floorbook Type 1: is for favourite floorbooks , 2: for my floorbooks , and 0 is for other floorbooks
       // change the is_private key acc to the index and type of floorbook
       if (floorbookType == 1) {
         favouriteFloorbooks.splice(idx, 1, {
           ...favouriteFloorbooks[idx],
           is_private: status,
         });
         update_favouriteFloorbooks(favouriteFloorbooks);
       }
       if (floorbookType == 2) {
         myfloorbooks.splice(idx, 1, {
           ...myfloorbooks[idx],
           is_private: status,
         });
         updateSetMyFloorbooks(myfloorbooks);
       } else {
         othersFloorbooks.splice(idx, 1, {
           ...othersFloorbooks[idx],
           is_private: status,
         });
         update_othersFloorbooks(othersFloorbooks);
       }

       toggleChecked();
     }
   });
 };

 useEffect(() => {
  // updateSetMyFloorbooks(myfloorbookData);
   let data =
     myfloorbookData &&
     myfloorbookData.filter((item) => item.is_learning_journal == 1);
     updateSetMyFloorbooks(data);
 }, [myfloorbookData]);
 console.log("my floorbooks", OthersFloorbookData,myfloorbookData,favouriteFloorbooksData);
 useEffect(() => {
   //update_favouriteFloorbooks(favouriteFloorbooksData);
   let data = favouriteFloorbooksData.filter(
     (item) => item.is_learning_journal == 1
   );
     update_favouriteFloorbooks(data);
 }, [favouriteFloorbooksData]);

 useEffect(() => {
   //update_othersFloorbooks(OthersFloorbookData);
   let data = OthersFloorbookData.filter(
     (item) => item.is_learning_journal == 1
   );

     update_othersFloorbooks(data);

 }, [OthersFloorbookData]);

 const saveFloorbookDetailsPagePosition = (data) => {
   //save the last post position
   floorbookDetailsPagePosition(data);
 };

 var rowToScrollTo = document.getElementById(Floorbookpage_Position);
 useEffect(() => {
   if (Floorbookpage_Position && rowToScrollTo) {
     window.onload = init();
   }
 }, [rowToScrollTo]);

 const init = () => {
   var d = document.getElementById("content-wrapper");
   rowToScrollTo.scrollIntoView();
 };
  // const fetchData = () => {
  //   getAllJournals((data, type) => {
  //     if (type === STATUS.success) {
  //       console.log("sucesss=>>>>>>>>>>>>>>>>", data);
  //       set_otherJournals(data);
  //     }
  //     if (type === STATUS.error) {
  //       enqueueSnackbar(data, {
  //         variant: type,
  //         autoHideDuration: 3000,
  //         anchorOrigin: {
  //           vertical: "bottom",
  //           horizontal: "left",
  //         },
  //       });
  //     }
  //   });
  //   getMyJournals((data, type) => {
  //     if (type === STATUS.success) {
  //       console.log("sucesss=>>>>>>>>>>>>>>>>", data);
  //       set_myJournals(data);
  //     }
  //   });
  // };

  // useEffect(() => {
  //   fetchData();
  // }, []);

  // useEffect(() => {
  //   showFavouriteJournals &&
  //     getFavouriteJournals((data, type) => {
  //       if (type === STATUS.success) {
  //         console.log("sucesss=>>>>>>>>>>>>>>>>", data);
  //         update_favouriteJournals(data);
  //       }
  //       if (type === STATUS.error) {
  //         enqueueSnackbar(data, {
  //           variant: type,
  //           autoHideDuration: 3000,
  //           anchorOrigin: {
  //             vertical: "bottom",
  //             horizontal: "left",
  //           },
  //         });
  //       }
  //     });
  // }, [showFavouriteJournals]);
  // const onDeleteJournalEntry = (id) => {
  //   let data = {
  //     id: id,
  //   };
  //   deleteJournal(data, (message, type) => {
  //     enqueueSnackbar(message, {
  //       variant: type,
  //       autoHideDuration: 3000,
  //       anchorOrigin: {
  //         vertical: "bottom",
  //         horizontal: "left",
  //       },
  //     });
  //     if (type === STATUS.success) {
  //       fetchData();
  //     }
  //   });
  // };
  const renderData = () => {
    const displayData =
      showMyJournals && myfloorbooks.length
        ? myfloorbooks
        : showFavouriteJournals && favouriteFloorbooks.length
        ? favouriteFloorbooks
        : showOthersJournals && othersFloorbooks.length
        ? othersFloorbooks
        : [];

    // function to update favourite status of floorbooks on UI and Api
    const updateFav = (id, status, bookType, idx,is_learning_journal) => {
      setFavourite(
        { id, status, marked_at: new Date().getTime(),is_learning_journal:is_learning_journal },
        (payload, type) => {
          if (type === STATUS.error) {
            enqueueSnackbar(payload, {
              variant: type,
              autoHideDuration: 3000,
              anchorOrigin: {
                vertical: "bottom",
                horizontal: "left",
              },
            });
            return;
          }
          // check if showFavouriteFloorbook or other floorbook
          if (bookType) {
            favouriteFloorbooks.splice(idx, 1);
            update_favouriteFloorbooks(favouriteFloorbooks);
            let otherIdx = othersFloorbooks.findIndex((item) => item.id === id);
            if (otherIdx !== -1) {
              othersFloorbooks[otherIdx] = {
                ...othersFloorbooks[otherIdx],
                user_favourite: payload.status,
              };
              update_othersFloorbooks(othersFloorbooks);
            }
          } else {
            othersFloorbooks.splice(idx, 1, {
              ...othersFloorbooks[idx],
              user_favourite: payload.status,
            });
            update_othersFloorbooks(othersFloorbooks);
          }
        }
      );
    };
    // const displayData =
    //   showMyJournals && myJournals && myJournals.length
    //     ? myJournals
    //     : showOthersJournals && otherJournals && otherJournals.length
    //       ? otherJournals
    //       : showFavouriteJournals && favouriteJournals && favouriteJournals.length
    //         ? favouriteJournals
    //         : [];
    // // function to update favourite status of floorbooks on UI and Api
    // const updateFav = (id, status, journalType, idx) => {
    //   setFavouriteJournal({ id, status }, (payload, type) => {
    //     console.log("response=>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>", payload);
    //     if (type === STATUS.error) {
    //       enqueueSnackbar(payload, {
    //         variant: type,
    //         autoHideDuration: 3000,
    //         anchorOrigin: {
    //           vertical: "bottom",
    //           horizontal: "left",
    //         },
    //       });
    //       return;
    //     }
    //     //check if showFavouriteFloorbook or other floorbook
    //     if (journalType) {
    //       favouriteJournals.splice(idx, 1);
    //       update_favouriteJournals(favouriteJournals);
    //       let otherIdx = otherJournals.findIndex((item) => item.id === id);
    //       if (otherIdx !== -1) {
    //         otherJournals[otherIdx] = { ...otherJournals[otherIdx], user_favourite: payload.status };
    //         set_otherJournals(otherJournals);
    //       }
    //     } else {
    //       // let idx = othersFloorbooks.findIndex((item) => item.id === id);
    //       // othersFloorbooks.splice(idx, 1, { ...othersFloorbooks[idx], user_favourite: payload.status });
    //       otherJournals.splice(idx, 1, { ...otherJournals[idx], user_favourite: payload.status });
    //       set_otherJournals(otherJournals);
    //     }
    //   });
    // };
    return (
      <div className="card-body">
        <div className="floorbook_list">
          {displayData.length ? (
            displayData.map((item, index) =>
              !searchValue ||
              item.name.toLowerCase().includes(searchValue.toLowerCase()) ? (
                <DisplayFloorbooks
                  key={index}
                  idx={index}
                  favourite={
                    showOthersJournals || showFavouriteJournals ? true : false
                  }
                  id={item.id}
                  cover_image={item.cover_image}
                  name={item.name}
                  is_learning_journal={item.is_learning_journal}
                  description={parseToHtml(item.description)}
                  image={item.image}
                  is_fav={item.user_favourite}
                  type={item.type}
                  is_private={item.is_private}
                  settingName={item.setting_name}
                  country={item.country}
                  city={item.city}
                  checked={checked}
                  setChecked={setChecked}
                  firstname={item.first_name}
                    lastname={item.last_name}
                    isAdmin={userRole == USER_ROLES.ADMIN ? true : false}
                    updateFloorbookIsJournal={updateFloorbookIsJournal}
                  updateFloorbookPrivacyData={updateFloorbookPrivacyData} // api to hide/unhide(change privacy status) of floorbook
                  showChangePrivacyOptions={
                    (USER_ROLES.ADMIN === userRole && !item.is_private) ||
                    showMyJournals
                      ? true
                      : false
                  }
                  // show hide/unhide switch options or not
                  showFloorbookPrivacyStatus={
                    (showOthersJournals || showFavouriteJournals) &&
                    userRole == USER_ROLES.ADMIN
                      ? true
                      : showMyJournals
                      ? true
                      : false
                  } // to show the (public / private) status label in the floorbook listing(all)
                  updateFav={updateFav} // function to call update favourite api
                  floorbookType={
                    showFavouriteJournals ? 1 : showMyJournals ? 2 : 0
                  } // 1 : FavouriteFloorbook 0 : otherfloorbooks //2 : my floorbook
                  saveFloorbookDetailsPagePosition={
                    saveFloorbookDetailsPagePosition
                  }
                />
            )  : null)
          ) : (
            <div className="d-flex align-items-center no-data-found">
              <div>
                {showFavouriteJournals
                  ? NO_DATA_MESSAGES.NO_SAVED_JOURNAL
                  : showOthersJournals
                    ? NO_DATA_MESSAGES.NO_JOURNAL
                    : NO_DATA_MESSAGES.NO_MY_JOURNALS}
                <br />{" "}
                <p>
                  There is no Journal added yet, <br />
                  <i>
                    <img src={require("../../../../../assets/images/icons/heart_fill.svg")} alt="" width="30px" />
                  </i>{" "}
                  You can add one to view here.
                </p>
              </div>
            </div>
          )}
        </div>
      </div>
    );
  };

  return (
    <>
      <div className="card-header d-flex flex-column-reverse flex-xl-row justify-content-center justify-content-xl-between align-items-center"></div>

      <ul className="list-unstyled d-flex align-items-center flex-wrap card_tab">
        <li
          style={{
            ...(showOthersJournals ? { backgroundColor: "#0D532D", color: "white" } : null),
          }}
          onClick={() => {
            set_showMyJournals(false);
            set_showOthersJournals(true);
            set_showFavouriteJournals(false);
          }}
        >
          {HEAD_TITLES.ALL_JOURNALS}
        </li>
        <li
          style={{
            ...(showFavouriteJournals ? { backgroundColor: "#0D532D", color: "white" } : null),
          }}
          onClick={() => {
            set_showOthersJournals(false);
            set_showMyJournals(false);
            set_showFavouriteJournals(true);
          }}
        >
          {HEAD_TITLES.SAVED_JOURNALS}
        </li>
        {myfloorbooks && myfloorbooks.length ? (
          <li
            style={{
              ...(showMyJournals ? { backgroundColor: "#0D532D", color: "white" } : null),
            }}
            onClick={() => {
              set_showOthersJournals(false);
              set_showMyJournals(true);
              set_showFavouriteJournals(false);
            }}
          >
            {HEAD_TITLES.MY_JOURNALS}
          </li>
        ) : null}
      </ul>

      {renderData()}

      {loading && <Loader />}
    </>
  );
};
export default withSnackbar(Screen);
