import React, { useState, useEffect } from "react";
import { Loader, ImageOverlay } from "../../../../../components";
import { withSnackbar } from "notistack";
import { useLocation } from "react-router-dom";
import { parseToHtml } from "../../../../../shared/services/Utility";
import { STATUS } from "../../../../../shared/constants";

const Screen = ({ enqueueSnackbar, loading, getUsefulResourceDetail, history }) => {
  const [resourceData, set_resourceData] = useState({});

  const useQuery = () => {
    return new URLSearchParams(useLocation().search);
  };
  let query = useQuery();

  useEffect(() => {
    let resource_id = query.get("resource_id");

    if (resource_id) {
      getUsefulResourceDetail(resource_id, (message, type, data) => {
        if (type === STATUS.error) {
          enqueueSnackbar(message, {
            variant: type,
            autoHideDuration: 3000,
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "left",
            },
          });
        }
        set_resourceData(data);
      });
    }
  }, []);

  return loading ? (
    <Loader />
  ) : (
    <div>
      <div class="post_detail_com">
          <div className="card-header d-flex flex-column-reverse flex-xl-row justify-content-center justify-content-xl-between align-items-center">
          {/* <HeadTitle
            title={HEAD_TITLES.USEFUL_RESOURCES}
            titleCursor={true}
            titleOnClick={() => history.push(ROUTE_CONSTANTS.USEFUL_RESOURCES)}
            subTitle={[{ label: "Detail" }]}
          /> */}
        </div>
        {resourceData && (
          <div className="cover_sec">
            <div className="Carousel-root-1">
              {resourceData.type === "image" ? (
                <ImageOverlay>
                  <img width="100%" src={resourceData.resourceFile} alt="resource"></img>
                </ImageOverlay>
              ) : resourceData.type === "video" ? (
                <video width="100%" controls controlsList="nodownload">
                  <source src={resourceData.resourceFile}></source>
                </video>
              ) : null}
            </div>
            <div className="cover_summary">
              <h3>{resourceData.title}</h3>
              <div
                className="w-100 text-center"
                dangerouslySetInnerHTML={{ __html: parseToHtml(resourceData.description) }}
              ></div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default withSnackbar(Screen);
