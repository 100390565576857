import { STRINGS } from "../../../shared/constants";
import { VALIDATION_MESSAGES, EMAIL_REGX } from "../../../shared/constants";

const validator = (values) => {
  const errors = {};

  //  else if (
  if (!values[STRINGS.EMAIL_INPUT_NAME]) {
    errors[STRINGS.EMAIL_INPUT_NAME] = VALIDATION_MESSAGES.EMAIL_REQUIRED;
  }
  if (values[STRINGS.EMAIL_INPUT_NAME] && !EMAIL_REGX.test(values[STRINGS.EMAIL_INPUT_NAME].trim())) {
    errors[STRINGS.EMAIL_INPUT_NAME] = VALIDATION_MESSAGES.EMAIL_INVALID;
  }
  if (!values[STRINGS.PASSWORD_INPUT_NAME]) {
    errors[STRINGS.PASSWORD_INPUT_NAME] = VALIDATION_MESSAGES.PASSWORD_REQUIRED;
  }

  return errors;
};

export default validator;
