import { withSnackbar } from "notistack";
import React, { useEffect } from "react";
import { useState } from "react";
import { useHistory } from "react-router-dom";
import { CustomButton, HeadTitle, ImageOverlay, Loader } from "../../../../components";
import { BUTTON_LABELS, HEAD_TITLES, ROUTE_CONSTANTS, STATUS, STRINGS } from "../../../../shared/constants";
import { parseToHtml } from "../../../../shared/services/Utility";
import "./style.scss";

const Screen = ({ loading, getProfileData, enqueueSnackbar, setUserData = () => {} }) => {
  const history = useHistory();
  const [profileData, set_profileData] = useState({});
  useEffect(() => {
    getProfileData((payload, type) => {
      if (type === STATUS.success) {
        set_profileData(payload.data);

        let { data } = payload;
        setUserData({
          first_name: data.first_name,
          last_name: data.last_name,
          email: data.email,
          profile_pic: data.profile_pic,
          user_role: data.user_role,
        });
      } else {
        enqueueSnackbar(payload, {
          variant: type,
          autoHideDuration: 3000,
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "left",
          },
        });
      }
    });
  }, []);
  return (
    <>
      <div className="card-header d-flex flex-column-reverse flex-xl-row justify-content-center justify-content-xl-between align-items-center">
        {/* <HeadTitle title={HEAD_TITLES.PROFILE} /> */}
        <div className="hdr_btns ml-md-auto">
          <CustomButton
            label="Change Password"
            className="btn text-capitalize btn-primary"
            onButtonClick={() => {
              history.push(ROUTE_CONSTANTS.CHNAGE_PASSOWRD);
            }}
          />
          <CustomButton
            label={BUTTON_LABELS.EDIT}
            className="btn  text-capitalize btn-primary"
            onButtonClick={() => {
              history.push(ROUTE_CONSTANTS.EDIT_PROFILE);
            }}
          />
        </div>
      </div>
      {loading ? (
        <Loader />
      ) : (
        <div className="card-body pt-0">
          <div className="row align-items-center">
            <div className="col-md-3">
              <div className="userProfile_pic">
                <ImageOverlay>
                  <img
                    src={profileData.profile_pic || require("../../../../assets/images/no-user.png")}
                    width={"100%"}
                  />
                </ImageOverlay>
              </div>
            </div>
            <div className="col-md-9">
              <div className="profile-data">
                <h2>{profileData.user_name}</h2>
                <span className="user_email">{profileData.email}</span>
              </div>
            </div>
          </div>

          <div className="profile_summary">
            <div className="table_grid text-center">
              <div className="row">
                <div className="col-sm-6 col-12">
                  <div className="bio_label">
                    <label>{STRINGS.SETTING_TYPE_LABEL}</label>
                    <span>{profileData.setting_type}</span>
                  </div>
                </div>
                <div className="col-sm-6 col-12">
                  <div className="bio_label">
                    <label>{STRINGS.SETTING_NAME_LABEL}</label>
                    <span>{profileData.setting_name}</span>
                  </div>
                </div>
                <div className="col-sm-6 col-12">
                  <div className="bio_label">
                    <label>{STRINGS.COUNTRY_LABEL}</label>
                    <span>
                      {profileData.country}
                      {profileData.scottish_authority ? `/${profileData.scottish_authority}` : null}
                    </span>
                  </div>
                </div>
                <div className="col-sm-6 col-12">
                  <div className="bio_label">
                    <label>{STRINGS.CITY_LABEL}</label>
                    <span>{profileData.city}</span>
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-12 text-left">
                <label>{STRINGS.DESCRIPTION_OF_CLASS_AND_AGE_LABEL}</label>
                <p dangerouslySetInnerHTML={{ __html: parseToHtml(profileData.description) }}></p>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default withSnackbar(Screen);
