import React, { useState, useEffect } from "react";
import { withStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";
import CustomButton from "../../atoms/CustomButton";
import { ADD_FLOORBOOK_TYPE, STATUS,ADD_FLOORBOOK_MESSAGES, BUTTON_LABELS } from "../../../shared/constants";
import { reduxForm } from "redux-form";
import { connect } from "react-redux";
import validator from "./validator";
import "./style.scss";
import { withSnackbar } from "notistack";
import { getBase64Image } from "../../../shared/services/Utility";
import { NewFloorbookForm } from "./form";

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

let DETAILS = {};

const FloorBookModal = ({
  btnLabel,
  className,
  formType = 0, //0 for create new floorbook, 1 for edit
  details,
  handleSubmit = () => {},
  addNewFloorbook,
  updateFloorbook,
  getAllFloorbooks,
  resetForm,
  loading,
  enqueueSnackbar,
  onClose = () => {},
}) => {
  /****** DETAILS for initial update ********* */
  DETAILS = details;
  /**************** */
  const [open, setOpen] = React.useState(false);
  const [loader, set_loader] = useState(false);
  const [floorbookCoverImage, setFloorbookCoverImage] = useState();
  const [floorbookSignInImage, setFloorbookSignInImage] = useState();
  const [errors, setErrors] = useState({
    coverImage: ADD_FLOORBOOK_MESSAGES.COVER_IMAGE,
    signInImage: ADD_FLOORBOOK_MESSAGES.SIGN_IN_IMAGE,
  });
  useEffect(() => {
    set_loader(loading);
  }, [loading]);

  const [showError, updateShowError] = useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
    resetForm();
    onClose();
  };
  const onDeleteCoverImage = () => {
    setFloorbookCoverImage("");
  };
  const onDeleteSignInImage = () => {
    setFloorbookSignInImage("");
  };

  useEffect(() => {
    if (details && details.image1) {
      //convert imageUrl to binary image
      getBase64Image(details.image1, "coverImage.jpg", (value) => {
        setFloorbookCoverImage(value);
      });
    }
    if (details && details.image2) {
      getBase64Image(details.image2, "signInImage.jpg", (value) => {
        setFloorbookSignInImage(value);
      });
    }
  }, [details]);

  const submit = (values) => {
    floorbookCoverImage && setErrors({ ...errors, coverImage: "" });
    floorbookSignInImage && setErrors({ ...errors, signInImage: "" });
    if (floorbookCoverImage && floorbookSignInImage) {
      let data = {
        name: values.floorbookName,
        cover_image: floorbookCoverImage && floorbookCoverImage,
        image: floorbookSignInImage && floorbookSignInImage,
        description: values.floorbookDescription,
        is_learning_journal: 0,
        private: values.privacyStatus,
      };

      if (formType === ADD_FLOORBOOK_TYPE.create) {
        /** if form type is of create than call the api for create new floorbook , else api for update floorbook */
        addNewFloorbook(data, (message, type) => {
          enqueueSnackbar(message, {
            variant: type,
            autoHideDuration: 3000,
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "left",
            },
          });
          if (type === STATUS.success) {
            handleClose();
            resetForm();
            getAllFloorbooks((message, type) => {});
          }
        });
      } else {
        updateFloorbook(details.id, data, (message, type) => {
          enqueueSnackbar(message, {
            variant: type,
            autoHideDuration: 3000,
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "left",
            },
          });
          if (type === STATUS.success) {
            handleClose();
            resetForm();
            getAllFloorbooks((message, type) => {});
            window.location.reload();
          }
        });
      }
    } else {
      updateShowError(true);
    }
  };

  return (
    <>
      <CustomButton
        className={className ? className : "w-100 btn btn-lg text-capitalize btn-primary"}
        label={btnLabel}
        onButtonClick={handleClickOpen}
      />

      <Dialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={open}>
        <div className={loader ? "loader_enable" : ""}>
          <DialogTitle id="customized-dialog-title" onClose={handleClose} className="text-center">
            {formType ? BUTTON_LABELS.EDIT_FLOORBOOK : BUTTON_LABELS.CREATE_NEW_FLOORBOOK}
          </DialogTitle>
          <NewFloorbookForm
            floorbookCoverImage={floorbookCoverImage}
            setFloorbookCoverImage={setFloorbookCoverImage}
            floorbookSignInImage={floorbookSignInImage}
            setFloorbookSignInImage={setFloorbookSignInImage}
            onDeleteCoverImage={onDeleteCoverImage}
            onDeleteSignInImage={onDeleteSignInImage}
            formType={formType}
            setErrors={setErrors}
            errors={errors}
            showError={showError}
            details={details}
            loader={loader}
            handleSubmit={handleSubmit}
            submit={submit}
          />
        </div>
      </Dialog>
    </>
  );
};

let AddFloorbookModal = reduxForm({
  form: "AddNewFloorbook",
  validate: validator,
  enableReinitialize: true,
})(FloorBookModal);

AddFloorbookModal = connect((state) => {
  return {
    initialValues: DETAILS
      ? {
          floorbookName: DETAILS.name,
          floorbookDescription: DETAILS.description,
          privacyStatus: DETAILS.privacyStatus,
        }
      : {},
  };
})(AddFloorbookModal);

export default withSnackbar(AddFloorbookModal);
