import { Row, Col, Form } from "react-bootstrap";
import React, { Component } from "react";
import Grid from "@material-ui/core/Grid";
import Modal from "./Modal";
import PropTypes from "prop-types";
import "./style.scss";
import { VIDEO_PLAY_ICON } from "../../../../shared/constants";

class Images extends Component {
  static defaultProps = {
    images: [],
    hideOverlay: false,
    renderOverlay: () => "Preview Image",
    overlayBackgroundColor: "#222222",
    onClickEach: null,
    countFrom: 5,
  };

  constructor(props) {
    super(props);

    this.state = {
      ImagesData: [],
      modal: false,
      countFrom: props.countFrom > 0 && props.countFrom < 5 ? props.countFrom : 5,
      conditionalRender: false,
      firstPartRenderTwo: false,
      secondPartRenderTwo: false,
    };

    this.openModal = this.openModal.bind(this);
    this.onClose = this.onClose.bind(this);

    if (props.countFrom <= 0 || props.countFrom > 5) {
      console.warn("countFrom is limited to 5!");
    }
  }

  componentDidMount() {
    console.log("component did mount");
    let array = [];
    let imageTypes = ["jpg", "jpeg", "png"];
    let videoTypes = ["webm", "MP2", "MPEG", "MPE", "MPV", "mp4"];
    this.props.images.length &&
      this.props.images.map((item) => {
        let result = item.split("/");
        // console.log("result", result);
        let result2 = result[result.length - 1];
        // console.log("result", result2);
        let type = result2.split(".");
        let extension = type[type.length - 1];

        let a = {
          url: item,
          type: imageTypes.includes(extension) ? "photo" : videoTypes.includes(extension) ? "video" : "",
          altTag: "Image and Video",
        };
        array.push({ ...a });
      });
    this.setState({ ImagesData: array });
  }
  openModal(index) {
    const { onClickEach, images } = this.props;

    if (onClickEach) {
      return onClickEach({ src: images[index], index });
    }

    this.setState({ modal: true, url: images[index], index: index });
  }

  onClose() {
    this.setState({ modal: false });
  }

  renderOne() {
    const { images } = this.props;
    const { countFrom, ImagesData } = this.state;
    const overlay = images.length > countFrom && countFrom == 1 ? this.renderCountOverlay(true) : this.renderOverlay();

    return (
      <Grid>
        <Row>
          <Col
            xs={12}
            md={12}
            className={
              images.length > 2 ? " border videothumb height-one background " : "border videothumb img-full background"
            } // if images are more than two than decrease the padding to make the size constant
            onClick={this.openModal.bind(this, 0)}
            style={{
              background:
                ImagesData && ImagesData[0] && ImagesData[0].type == "photo" && ImagesData[0].url
                  ? `url(${images[0]})`
                  : null,
            }}
          >
            {overlay}
            {ImagesData && ImagesData[0] && ImagesData[0].type == "video" ? (
              <>
                <video>
                  <source src={images[0]}></source>
                </video>
                <a className="play_btn" href="javascript:void(0);">
                  <img src={VIDEO_PLAY_ICON} width="50" alt="" />
                </a>
              </>
            ) : null}
          </Col>
        </Row>
      </Grid>
    );
  }

  renderTwo() {
    const { images } = this.props;

    const { countFrom, ImagesData } = this.state;

    const overlay =
      images.length > countFrom && [2, 3].includes(+countFrom) ? this.renderCountOverlay(true) : this.renderOverlay();
    const conditionalRender =
      [3, 4].includes(images.length) || (images.length > +countFrom && [3, 4].includes(+countFrom));
    let ext1 = ImagesData && ImagesData[0] && ImagesData[0].type;
    let ext2 = ImagesData && ImagesData[1] && ImagesData[1].type;
    let ext3 = ImagesData && ImagesData[2] && ImagesData[2].type;

    return (
      <Grid>
        <Row>
          <Col
            xs={6}
            md={6}
            // className={
            //   images.length > 2
            //     ? ext2 == "jpeg"
            //       ? "border videothumb height-two background "
            //       : "border videothumb img-full background"
            //     : null
            // }
            className={images.length > 2 ? "border videothumb height-two background" : "border background videothumb"}
            onClick={this.openModal.bind(this, conditionalRender ? 1 : 0)}
            style={{
              background:
                ext1 == "photo" && (!ext3 || images.length > 3)
                  ? `url(${images[0]})`
                  : ext2 == "photo" && conditionalRender
                  ? `url(${images[1]})`
                  : null,
            }}
          >
            {this.renderOverlay()}
            {/* {ext1 == "video" || (ext2 == "video" && (ext1 !== "photo" || !ext2)) */}
            {(ext1 == "video" && !ext3) || (ext2 == "video" && (ext3 || images.length > 3)) ? (
              <>
                <video className="border">
                  <source
                    src={ext1 == "video" && !ext3 ? images[0] : ext2 == "video" && conditionalRender ? images[1] : null}
                  ></source>
                </video>
                <a className="play_btn" href="javascript:void(0);">
                  <img src={VIDEO_PLAY_ICON} width="50" alt="" />
                </a>
              </>
            ) : null}
          </Col>
          <Col
            xs={6}
            md={6}
            className={
              images.length > 2 ? "border videothumb height-two background " : "border videothumb img-full background"
            }
            onClick={this.openModal.bind(this, conditionalRender ? 2 : 1)}
            style={{
              background:
                ext2 == "photo" && (!ext3 || images.length > 2)
                  ? `url(${images[1]})`
                  : ext3 == "photo" && conditionalRender
                  ? `url(${images[2]})`
                  : null,
            }}
          >
            {this.renderOverlay()}
            {(ext2 == "video" && (ext3 != "photo" || !ext3)) || (ext3 == "video" && conditionalRender) ? (
              <>
                <video className="border">
                  <source
                    src={ext2 == "video" ? images[1] : ext3 == "video" && conditionalRender ? images[2] : null}
                    // src={images[1]}
                  ></source>
                </video>
                <a className="play_btn" href="javascript:void(0);">
                  <img src={VIDEO_PLAY_ICON} width="50" alt="" />
                </a>
              </>
            ) : null}
          </Col>
        </Row>
      </Grid>
    );
  }

  renderThree(more) {
    const { images } = this.props;
    const { countFrom, ImagesData } = this.state;
    const conditionalRender = images.length == 4 || (images.length > +countFrom && +countFrom == 4);
    const overlay =
      !countFrom || countFrom > 5 || (images.length > countFrom && [4, 5].includes(+countFrom))
        ? this.renderCountOverlay(true)
        : this.renderOverlay(conditionalRender ? 3 : 4);
    let ext1 = ImagesData && ImagesData[1] && ImagesData[1].type;
    let ext2 = ImagesData && ImagesData[2] && ImagesData[2].type;
    let ext3 = ImagesData && ImagesData[3] && ImagesData[3].type;
    let ext4 = ImagesData && ImagesData[4] && ImagesData[4].type;

    return (
      <Grid>
        <Row>
          <Col
            xs={4}
            md={4}
            className="border videothumb height-three background "
            onClick={this.openModal.bind(this, conditionalRender ? 1 : 2)}
            //style={{ background: `url(${conditionalRender ? images[1] : images[2]})` }}
            style={{
              background:
                ext1 == "photo" && !ext4
                  ? `url(${images[1]})`
                  : ext2 == "photo" && images.length > 3
                  ? `url(${images[2]})`
                  : null,
            }}
          >
            {this.renderOverlay(conditionalRender ? 1 : 2)}
            {(ext2 == "video" && ext4) || (ext1 == "video" && !ext4) ? (
              <>
                <video className="border">
                  <source
                    src={ext2 == "video" ? images[1] : ext1 == "video" && conditionalRender ? images[1] : null}
                  ></source>
                </video>
                <a className="play_btn" href="javascript:void(0);">
                  <img src={VIDEO_PLAY_ICON} width="50" alt="" />
                </a>
              </>
            ) : null}
          </Col>
          <Col
            xs={4}
            md={4}
            className="border videothumb height-three background "
            onClick={this.openModal.bind(this, conditionalRender ? 2 : 3)}
            //style={{ background: `url(${conditionalRender ? images[2] : images[3]})` }}
            style={{
              background:
                ext2 == "photo" && !ext4 ? `url(${images[2]})` : ext3 == "photo" && ext4 ? `url(${images[3]})` : null,
            }}
          >
            {this.renderOverlay(conditionalRender ? 2 : 3)}
            {(ext2 == "video" && !ext4) || ext3 == "video" ? (
              <>
                <video className="border">
                  <source
                    src={ext2 == "video" && !ext4 ? images[2] : ext3 == "video" && conditionalRender ? images[3] : null}
                  ></source>
                </video>
                <a className="play_btn" href="javascript:void(0);">
                  <img src={VIDEO_PLAY_ICON} width="50" alt="" />
                </a>
              </>
            ) : null}
          </Col>
          <Col
            xs={4}
            md={4}
            className="border videothumb height-three background "
            onClick={this.openModal.bind(this, conditionalRender ? 3 : 4)}
            //style={{ background: `url(${conditionalRender ? images[3] : images[4]})` }}
            style={{
              background: ext3 == "photo" && !ext4 ? `url(${images[3]})` : ext4 == "photo" ? `url(${images[4]})` : null,
            }}
          >
            {(ext3 == "video" && !ext4) || ext4 == "video" ? (
              <>
                <video className="border">
                  <source
                    src={ext3 == "video" && !ext4 ? images[3] : ext4 == "video" && images.length > 4 ? images[4] : null}
                  ></source>
                </video>
                {ext4 == "video" && images.length > 4 ? null : (
                  <a className="play_btn" href="javascript:void(0);">
                    <img src={VIDEO_PLAY_ICON} width="50" alt="" />
                  </a>
                )}
              </>
            ) : null}
            {overlay}
          </Col>
        </Row>
      </Grid>
    );
  }

  renderOverlay(id) {
    const { hideOverlay, renderOverlay, overlayBackgroundColor } = this.props;

    if (hideOverlay) {
      return false;
    }

    return [
      <div key={`cover-${id}`} className="cover slide" style={{ backgroundColor: overlayBackgroundColor }}></div>,
      <div key={`cover-text-${id}`} className="cover-text slide animate-text" style={{ color: "white" }}>
        {renderOverlay()}
      </div>,
    ];
  }

  renderCountOverlay(more) {
    const { images } = this.props;
    const { countFrom } = this.state;
    const extra = images.length - (countFrom && countFrom > 5 ? 5 : countFrom);

    return [
      more && <div key="count" className="cover"></div>,
      more && (
        <div key="count-sub" className="cover-text">
          <p>+{extra}</p>
        </div>
      ),
    ];
  }

  render() {
    const { modal, index, countFrom } = this.state;

    const { images } = this.props;
    const imagesToShow = [...images];

    if (countFrom && images.length > countFrom) {
      imagesToShow.length = countFrom;
    }

    return (
      <div className="grid-container">
        {[1, 3, 4].includes(imagesToShow.length) && this.renderOne()}
        {imagesToShow.length >= 2 && imagesToShow.length != 4 && this.renderTwo()}
        {imagesToShow.length >= 4 && this.renderThree()}

        {modal && (
          <Modal
            onClose={this.onClose}
            lightbox={this.props.lightbox}
            index={this.state.index}
            images={images}
            ImagesData={this.state.ImagesData}
          />
        )}
      </div>
    );
  }
}

Images.propTypes = {
  images: PropTypes.array.isRequired,
  hideOverlay: PropTypes.bool,
  renderOverlay: PropTypes.func,
  overlayBackgroundColor: PropTypes.string,
  onClickEach: PropTypes.func,
  countFrom: PropTypes.number,
};

export default Images;
