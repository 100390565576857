import React, { useEffect, useState } from "react";
import "./styles.scss";
import { Loader, ImageOverlay } from "../../../components";
import { STATUS } from "../../../shared/constants";
import { reduxForm } from "redux-form";
import { ROUTE_CONSTANTS } from "../../../shared/constants";
import { withSnackbar } from "notistack";
import validator from "./validator";
import { LOGO } from "../../../shared/constants";
import { RenderForm } from "./form";
import { messaging } from "../../../config/init-fcm";
import firebase from "firebase/app";

const LoginScreen = ({
  enqueueSnackbar,
  history,
  loading,
  handleSubmit = () => {},
  dispatchLoginRequest = () => {},
  goBackToSignUPFirst,
}) => {
  const [loader, set_loader] = useState(false);
  const [FcmToken, setFcmToken] = useState("");

  useEffect(() => {
    console.log("to notify.");
    if (firebase.messaging.isSupported()) {
      messaging
        .requestPermission()
        .then(async function () {
          const token = await messaging.getToken();
          console.log("tokne,", token);
          setFcmToken(token);
        })
        .catch(function (err) {
          console.log("Unable to get permission to notify.", err);
          alert("If, you want to recieve notifications ,Go to Browser Settings");
          setFcmToken("");
        });
    }
  }, []);

  useEffect(() => {
    set_loader(loading);
    goBackToSignUPFirst(false);
  }, [loading, goBackToSignUPFirst]);

  const submit = (values) => {
    values && onPressLoginBtn(values);
  };

  const onPressLoginBtn = (values) => {
    const data = {
      email: values.email.toLowerCase().trim(),
      password: values.password,
      device_token: FcmToken,
    };

    dispatchLoginRequest(data, (message, type) => {
      enqueueSnackbar(message, {
        variant: type,
        autoHideDuration: 3000,
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "left",
        },
      });
      if (type === STATUS.success) {
        history.push(ROUTE_CONSTANTS.FLOORBOOKS);
      }
    });
  };

  return (
    <div className="front-forms">
      <div className="container-fluid">
        <div className="login_box">
          <div className="front-logo text-center mb-4">
            <ImageOverlay>
              <img src={LOGO} alt="Inquiry Tracker" />
            </ImageOverlay>
          </div>
          <div className="form-box">
            <RenderForm history={history} submit={submit} handleSubmit={handleSubmit} />
          </div>
        </div>
      </div>
      {loader && <Loader />}
    </div>
  );
};

const Screen = reduxForm({
  form: "loginform",
  validate: validator,
})(LoginScreen);

export default withSnackbar(Screen);
