import { reset } from "redux-form";
import { connect } from "react-redux";
import { addNewFloorbook, getAllFloorbooks, updateFloorbook } from "../../../redux/actions";
import AddFloorbookModal from "./Modal";

const mapStateToProps = (state) => {
  return {
    loading: state.CommonReducer.isLoading,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    addNewFloorbook: (data, callback) => dispatch(addNewFloorbook(data, callback)),
    updateFloorbook: (id, data, callback) => dispatch(updateFloorbook(id, data, callback)),
    getAllFloorbooks: (callback) => dispatch(getAllFloorbooks(callback)),
    resetForm: () => dispatch(reset("AddNewFloorbook")),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AddFloorbookModal);
