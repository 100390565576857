import { reset } from "redux-form";
import { connect } from "react-redux";
import { updateJournal, getAllJournals,getAllFloorbooks,updateFloorbook,addNewFloorbook } from "../../../redux/actions";
import AddJournalModal from "./Modal";

const mapStateToProps = (state) => {
  return {
    loading: state.CommonReducer.isLoading,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    //addNewJournal: (data, callback) => dispatch(addNewJournal(data, callback)),
    addNewFloorbook: (data, callback) =>
      dispatch(addNewFloorbook(data, callback)),
    updateFloorbook: (id, data, callback) =>
      dispatch(updateFloorbook(id, data, callback)),
    getAllFloorbooks: (callback) => dispatch(getAllFloorbooks(callback)),
    updateJournal: (id, data, callback) =>
      dispatch(updateJournal(id, data, callback)),
    getAllJournals: (callback) => dispatch(getAllJournals(callback)),
    resetForm: () => dispatch(reset("AddNewJournal")),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AddJournalModal);
