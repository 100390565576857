import * as actionTypes from "../actions/ActionTypes";

const initialCommonState = {
  Form1: {},
  Form2: {},
  allCountries: [],
  allCities: [],
  allSettingTypes: [],
  scottishLocalAuthority: [],
};

const SignUpReducer = (state = { canShow: false, message: "" }, action) => {
  switch (action.type) {
    case actionTypes.SAVE_SIGNUP_FORM_ONE:
      return {
        ...state,
        Form1: action.data,
      };
    case actionTypes.SAVE_COUNTRY_DATA:
      return {
        ...state,
        allCountries: action.data,
      };
    case actionTypes.SAVE_CITY_DATA:
      return {
        ...state,
        allCities: action.data,
      };
    case actionTypes.SAVE_SETTING_TYPE_DATA:
      return {
        ...state,
        allSettingTypes: action.data,
      };
    case actionTypes.SAVE_SIGNUP_FORM_TWO:
      return {
        ...state,
        Form2: action.data,
      };

    case actionTypes.SAVE_SCOTTISH_LOCAL_AUTHORITY_DROPDOWN:
      return {
        ...state,
        scottishLocalAuthority: action.data,
      };

    /********************************/
    case actionTypes.LOGOUT:
      return {
        ...state,
        Form1: {},
        Form2: {},
        allCountries: [],
        allCities: [],
        allSettingTypes: [],
      };
    /********************************/
    default:
      return state;
  }
};

export default SignUpReducer;
