import React, { useState, useEffect, useCallback } from "react";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import update from "immutability-helper";
import { UploadImageName } from "../../atoms";
import ImageCarousel from "../ImageCarousel";
import { createCanvas, loadImage } from "canvas";
import CustomButton from "../../atoms/CustomButton";
import Loader from "../../atoms/loader";
import { withSnackbar } from "notistack";
import Resizer from "react-image-file-resizer";
import "./style.scss";
const Upload = ({
  carasol,
  accept,
  onFileChange = () => {},
  multilple,
  onDelete = () => {},
  callback,
  imageData,
  enqueueSnackbar,
  defaultImages,
  DataForEdit,
  onlyImages = false,
}) => {
  const [arr, updateArr] = useState([]);
  const [newImage, set_newImage] = useState(false);
  const [showCarasol, setCarasol] = useState();
  const [loader, set_loader] = useState(false);
  const [errorMessage, updateErrorMessgae] = useState();
  let canvasRef = [];

  useEffect(() => {
    if (defaultImages && defaultImages.length) {
      updateArr([...defaultImages]);
    }
  }, [defaultImages]);
  useEffect(() => {
    if (DataForEdit && DataForEdit.length) {
      updateArr([...DataForEdit]);
    }
  }, [DataForEdit]);

  let smooth = true;

  useEffect(() => {
    setCarasol(carasol);
  }, [carasol]);

  const handleChange = (imageP, width, height, file, videoUrl) => {
    console.log("handleChange for fileSize check", imageP, file, videoUrl);
    // if (file.size > 20000000) {
    //   enqueueSnackbar(`${file.name} file size too large. Max size allowed : 20MB`, {
    //     variant: "error",
    //     autoHideDuration: 6000,
    //     anchorOrigin: {
    //       vertical: "bottom",
    //       horizontal: "left",
    //     },
    //   });
    //   return;
    // }
    let dataArray = {
      data: {
        video: videoUrl ? file : "",
        imageP: imageP,
        width: width,
        height: height,
        size: file.size,
      },

      name: file.name,
      localUrl: "",
      faces: [],
      blurs: [],
      canvasDim: {},
      newURl: "",
      videoUrl: videoUrl ? videoUrl : "",
    };
    multilple ? updateArr((state) => [...state, dataArray]) : updateArr([dataArray]);
  };

  const onFacesChange = (faces, index) => {
    let facesArray = [];
    facesArray = [...facesArray, ...faces];
    arr.map((item, i) => (i === index ? (item.faces = [...facesArray]) : ""));
  };

  const onFaceBlurDelete = (index, blurIndex) => {
    arr.map((item, i) => (i === index ? item.faces.splice(blurIndex, 1) : ""));
  };

  const onBlurDelete = (index, blurIndex) => {
    arr.map((item, i) => (i === index ? item.blurs.splice(blurIndex, 1) : ""));
  };
  const BlurFaces = (blurs, index) => {
    blurs && blurs.length && arr.map((item, i) => (i === index ? (item.blurs = [...blurs]) : ""));
  };

  const handleFileChange = async (event, file) => {
    updateErrorMessgae("");
    if (event.target.files.length + arr.length > 10) {
      enqueueSnackbar(`Only 10 ${onlyImages ? "Files" : "Images"} can be uploaded`, {
        variant: "error",
        autoHideDuration: 4000,
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "left",
        },
      });
      return;
    }
    for (let i = 0; i < file.length; i++) {
      // let imageP = event && event.target.files[i] && URL.createObjectURL && URL.createObjectURL(event.target.files[i]);
      let fileType = file[i].type.split("/");
      if (fileType[0] == "image") {
        const img = new Image();
        const imageP = await resizeFile(file[i]);
        img.src = imageP;
        let width;
        let height;
        img.onload = () => {
          width = img.width;
          height = img.height;
          handleChange(imageP, width, height, file[i]);
        };
      } else if (fileType[0] == "video") {
        let videoUrl = URL.createObjectURL && URL.createObjectURL(file[i]);
        //let video = document.getElementById("fileInput");
        handleChange("", "", "", file[i], videoUrl);
      }
    }
  };
  /****resize image if image dimensions are greater than screen size ****/
  const resizeFile = (file) =>
    new Promise((resolve) => {
      Resizer.imageFileResizer(
        file,
        1050,
        720,
        "JPEG",
        100,
        0,
        (uri) => {
          resolve(uri);
        },
        "base64"
      );
    });
  const [defaultData, set_defaultData] = useState(imageData);

  /** remove image from array on cancel click**/
  const onCancelClick = (index) => {
    if (defaultData) {
      set_defaultData("");
      onDelete();
    } else {
      let array = [...arr];
      array.splice(index, 1);
      updateArr((state) => array);
      onDelete();
    }
    var name = document.getElementById("fileInput");
    name.value = "";
  };
  const detectFace = (data) => {
    return data.map((face) => ({
      x: face._x,
      y: face._y,
      w: face.width + 5,
      h: face.height,
    }));
  };
  const onSubmitClick = () => {
    callback(arr);
  };

  const loadProcessedImage = (detections, data, canvasRefs, index) => {
    if (!data.imageP) {
      console.log("return from load");
      return true;
    }
    set_newImage(true);
    let threshold = 30;
    let dataUrl;
    let imageP = data.imageP;
    let image = {
      height: data.height,
      width: data.width,
    };
    // Load Image
    loadImage(imageP)
      .then((newImage) => {
        const outputCanvas = canvasRefs;
        const outputCtx = outputCanvas.getContext("2d");
        const hiddenCanvas = createCanvas(image.width, image.height);
        const hiddenCtx = hiddenCanvas.getContext("2d");
        if (smooth) {
          // New canvases for applying blurring and feathering (canvases for inverted mask of blurred images)
          const imaskCanvas = createCanvas(image.width, image.height);
          const imaskCtx = imaskCanvas.getContext("2d");
          const imaskCanvas2 = createCanvas(image.width, image.height);
          const imaskCtx2 = imaskCanvas2.getContext("2d");
          // Set global composite operation to destination in
          imaskCtx.globalCompositeOperation = "destination-in";
          detectFace(detections).forEach((face, i) => {
            // Determine the blur amount by width of face
            let blurAmount = threshold;
            if (face.w >= 300) blurAmount = threshold * 2.5;
            else if (face.w <= 30) blurAmount = threshold * 1.25;
            else if (face.w <= 10) blurAmount = threshold;
            // Add blur filter
            hiddenCtx.filter = `blur(${blurAmount}px)`;
            // Draw original image to hidden canvas
            hiddenCtx.drawImage(newImage, 0, 0, image.width, image.height);
            // Add blurred faces to blank canvas
            if (face.w <= 30) {
              imaskCtx.putImageData(
                hiddenCtx.getImageData(face.x - 5, face.y - 5, face.w + 10, face.h + 10),
                face.x - 5,
                face.y - 5
              );
            } else {
              imaskCtx.putImageData(
                hiddenCtx.getImageData(face.x - 10, face.y - 10, face.w + 20, face.h + 20),
                face.x - 10,
                face.y - 10
              );
            }
          });
          // Draw blurred faces onto 2nd inverted mask canvas
          imaskCtx2.drawImage(imaskCanvas, 0, 0);
          imaskCtx2.shadowColor = "black"; // Required for feathering
          imaskCtx2.shadowBlur = 30;
          imaskCtx2.globalCompositeOperation = "destination-in";
          // Feathering
          imaskCtx2.shadowBlur = 10;
          imaskCtx2.drawImage(imaskCanvas, 0, 0);
          imaskCtx2.shadowBlur = 10;
          imaskCtx2.drawImage(imaskCanvas, 0, 0);
          // Clear visible canvas then draw original image to it and then add the blurred images
          outputCtx.clearRect(0, 0, image.width, image.height);
          outputCtx.drawImage(newImage, 0, 0);
          outputCtx.drawImage(imaskCanvas2, 0, 0);
          dataUrl = document.getElementById(`canvasX${index}`).toDataURL("image/jpeg", 0.6);
          arr[index].newURl = dataUrl;
        }
        return dataUrl;
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const moveCard = useCallback(
    (dragIndex, hoverIndex) => {
      const dragCard = arr[dragIndex];
      updateArr(
        update(arr, {
          $splice: [
            [dragIndex, 1],
            [hoverIndex, 0, dragCard],
          ],
        })
      );
    },
    [arr]
  );
  const checkFilesSizeInTotal = (arr) => {
    let totalSize = 0;
    for (let i = 0; i < arr.length; i++) {
      if (arr[i].data.size) totalSize = totalSize + arr[i].data.size;
    }

    if (totalSize > 40000000) {
      enqueueSnackbar(`File size's are too large. Max Total file size allowed : 40MB`, {
        variant: "error",
        autoHideDuration: 6000,
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "left",
        },
      });
      return false;
    }
    return true;
  };
  return (
    <>
      <div className="file_upload">
        <DndProvider backend={HTML5Backend}>
          <label style={{ cursor: "pointer" }}>
            <input
              type="file"
              id="fileInput"
              name="files"
              style={{ visibility: "hidden" }}
              accept={accept}
              multiple={multilple}
              onChange={(e) => {
                console.log("upload image on change", e.target.files);
                handleFileChange(e, e.target.files);
                onFileChange(e.target.files[0], e.target.value);
                set_loader(true);
                set_defaultData("");
                setTimeout(() => {
                  set_loader(false);
                }, 1000);
              }}
            />
            Upload {onlyImages ? "Files" : "Image"}
          </label>
          <div className="file_scroll">
            {defaultData ? (
              <UploadImageName
                moveCard={moveCard}
                id={defaultData.name}
                image={defaultData.name}
                onClick={onCancelClick}
              />
            ) : (
              arr &&
              arr.map((item, index) => {
                return (
                  <UploadImageName
                    key={index}
                    moveCard={moveCard}
                    id={index}
                    image={item.name}
                    index={index}
                    onClick={onCancelClick}
                  />
                );
              })
            )}
            {}
          </div>
        </DndProvider>
      </div>
      <label>Max Size: 20MB</label>
      <div className="pixelate_slider">
        {showCarasol && arr.length ? (
          <ImageCarousel
            images={arr}
            tool={true}
            onFaceBlurDelete={onFaceBlurDelete}
            BlurFaces={BlurFaces}
            onFacesChange={onFacesChange}
            onBlurDelete={onBlurDelete}
          />
        ) : (
          ""
        )}

        <>
          {arr.map((item, index) => {
            if (item && item.data && item.data.imageP) {
              return (
                <canvas
                  key={index}
                  id={`canvasX${index}`}
                  className="hidden"
                  ref={(ref) => (canvasRef[index] = ref)}
                  width={item.data.width}
                  height={item.data.height}
                  style={{ maxWidth: "100%", maxHeight: "auto" }}
                />
              );
            }
          })}
        </>

        {callback && arr.length ? (
          <div className="pixelate_menu">
            Pixelate Tool{" "}
            <i>
              <img src={require("../../../assets/images/icons/blur_icon.svg")} alt="" />
            </i>
          </div>
        ) : null}
      </div>

      {errorMessage && (
        <span>
          <p className="error_msg text-danger">{errorMessage}</p>
        </span>
      )}
      {callback && (
        <div className="card-footer">
          <div className="text-center">
            <CustomButton
              label="Save & Continue"
              className="btn btn-lg text-capitalize btn-primary"
              onButtonClick={() => {
                let checkFileSize = checkFilesSizeInTotal(arr);
                if (checkFileSize) {
                  arr.map((item, index) => {
                    updateErrorMessgae("");

                    loadProcessedImage([...item.faces, ...item.blurs], item.data, canvasRef[index], index);

                    set_loader(true);
                  });
                  setTimeout(() => {
                    arr.length ? onSubmitClick() : updateErrorMessgae(`Please upload ${onlyImages ? "File" : "Image"}`);
                    set_loader(false);
                  }, arr.length * 1000);
                }
              }}
            />
          </div>
        </div>
      )}
      {loader && <Loader />}
    </>
  );
};
export default withSnackbar(Upload);
