import { connect } from "react-redux";
import { changePassword } from "../../../../redux/actions";
import Screen from "./screen";

const mapStateToProps = (state) => {
  return {
    loading: state.CommonReducer.isLoading,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    changePassword: (data, callback) => dispatch(changePassword(data, callback)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Screen);
