import React from "react";

const Button = ({ type = "", label, bgColor, onButtonClick, icon, className }) => {
  return (
    <>
      <button style={{ background: bgColor }} icon={icon} type={type} className={className} onClick={onButtonClick}>
        {icon ? (
          <i className="mr-3">
            <img src={icon} height={20} alt="" />
          </i>
        ) : (
          ""
        )}
        {label}
      </button>
    </>
  );
};

export default Button;
