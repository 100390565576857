const initialCommonState = {
  report: [],
};

const ReportReducer = (state = { ...initialCommonState }, action) => {
  switch (action.type) {
    /********************************/

    default:
      return state;
  }
};

export default ReportReducer;
