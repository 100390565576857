import { LOGIN_REQUEST, LOGIN, LOGOUT, GO_BACK } from "./ActionTypes";

export const LoginRequest = (data, callback) => {
  return {
    type: LOGIN_REQUEST,
    data,
    callback,
  };
};
export const Login = (userId, emailId) => {
  return {
    type: LOGIN,
    userId: userId,
    emailId: emailId,
  };
};

export const Logout = () => {
  return {
    type: LOGOUT,
    userId: 0,
    emailId: "",
    sessionToken: "",
  };
};

export const goBackToSignUPFirst = (data) => {
  return {
    type: GO_BACK,
    data,
  };
};
