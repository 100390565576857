import { connect } from "react-redux";
import Screens from "./screen";
import {
  reportGet,
  reportFloorbook,
  getFavouriteFloorbooks,
  saveCompletionData,
  setReportsHeading,
  setReportsSummary,
} from "../../../../redux/actions";
import { call } from "redux-saga/effects";

const mapStateToProps = (state) => {
  return {
    state,
    loading: state.CommonReducer.isLoading,
    favouriteFloorbooksData: state.FloorbookReducer.favouriteFloorbooksData,
    user_role: state.CommonReducer.userData.user_role,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    reportGet: (data, callback) => dispatch(reportGet(data, callback)),
    reportFloorbook: (callback) => dispatch(reportFloorbook(callback)),
    getFavouriteFloorbooks: (callback) => dispatch(getFavouriteFloorbooks(callback)),
    saveCompletionData: (data, callback) => dispatch(saveCompletionData(data, callback)),
    setReportsHeading: (data, callback) => dispatch(setReportsHeading(data, callback)),
    setReportsSummary: (data, callback) => dispatch(setReportsSummary(data, callback)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Screens);
