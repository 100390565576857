import * as actionTypes from "../actions/ActionTypes";
import { Login, startLoading, stopLoading, onLoginSuccess, setUserData, logout_user } from "../actions";
import { put, takeEvery, all, takeLatest } from "redux-saga/effects";
import { checkOffline } from "../../shared/services/Utility";
const { updateAuthToken, postRequest, getRequest } = require(`../../helpers/axios`);
const { STATUS_CODE, MESSAGES, STATUS } = require(`../../shared/constants`);

const api = require(`../../shared/Apis/api`);

//request to authenticate email and password
function* loginAuthentication({ data, callback }) {
  try {
    yield put(startLoading());
    const response = yield postRequest({ API: `${api.Authentication_URL}`, DATA: data });

    if (response.status === STATUS_CODE.unAuthorized) {
      yield put(stopLoading());
      callback(response.data.message, STATUS.error);
      yield put(logout_user());
    } else if (response.status !== STATUS_CODE.successful) {
      yield put(stopLoading());
      callback(response.data.error.message, STATUS.error);
    } else {
      callback("Login Successfull", STATUS.success);
      yield put(onLoginSuccess(response.data.data.token));
      yield put(setUserData(response.data.data.details));
      updateAuthToken(response.data.data.token);
      yield put(stopLoading());
    }
  } catch (error) {
    yield put(stopLoading());
    if (checkOffline()) {
      callback(MESSAGES.OFFLINE, STATUS.error);
    } else {
      callback(MESSAGES.ERROR_IN_CATCH, STATUS.error);
    }
  }
}
//request to logout (logged user)
function* logoutUser({ callback }) {
  try {
    yield put(startLoading());
    const response = yield getRequest({ API: `${api.LOGOUT}` });
    if (response.status === STATUS_CODE.unAuthorized) {
      yield put(stopLoading());
      callback(response.data.message, STATUS.error);
      yield put(logout_user());
    } else if (response.status !== STATUS_CODE.successful) {
      yield put(stopLoading());
      callback(response.data.error.message, STATUS.error);
    } else {
      callback("You are successfully logged out", STATUS.success);
      yield put(stopLoading());
      yield put(logout_user());
    }
  } catch (error) {
    yield put(stopLoading());
    if (checkOffline()) {
      callback(MESSAGES.OFFLINE, STATUS.error);
    } else {
      callback(MESSAGES.ERROR_IN_CATCH, STATUS.error);
    }
  }
}

function* loginSaga() {
  yield all([
    takeEvery(actionTypes.LOGIN_REQUEST, loginAuthentication),
    takeLatest(actionTypes.LOGOUT_SERVER, logoutUser),
  ]);
}

export default loginSaga;
