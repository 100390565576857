import { withSnackbar } from "notistack";
import React from "react";
import { useHistory } from "react-router-dom";
import { reduxForm } from "redux-form";
import { Loader } from "../../../../components";
import {
  ROUTE_CONSTANTS,
  STRINGS,
  VALIDATION_MESSAGES,
  STATUS,
} from "../../../../shared/constants";
import { RenderForm } from "./form";

const ChangePasswordForm = ({ handleSubmit = () => {}, enqueueSnackbar, changePassword, loading }) => {
  const history = useHistory();
  const submitPassword = (values) => {
    let data = {
      oldPassword: values.oldPassword,
      newPassword: values.newPassword,
      confirmPassword: values.confirmPassword,
    };
    changePassword(data, (message, type) => {
      enqueueSnackbar(message, {
        variant: type,
        autoHideDuration: 3000,
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "left",
        },
      });
      if (type === STATUS.success) {
        history.push(ROUTE_CONSTANTS.PROFILE);
      }
    });
  };
  return (
    <>
      <div className="card-header d-flex flex-column-reverse flex-xl-row justify-content-center justify-content-md-end align-items-center">
        {/* <HeadTitle
          title={HEAD_TITLES.PROFILE}
          titleCursor={true}
          titleOnClick={() => history.push(`${ROUTE_CONSTANTS.PROFILE}`)}
          subTitle={[{ label: "Change Password" }]}
        /> */}
      </div>
      <div className="card-body">
        <RenderForm handleSubmit={handleSubmit} submitPassword={submitPassword} />
      </div>
      {loading && <Loader />}
    </>
  );
};

const validator = (values) => {
  const errors = {};
  if (!values[STRINGS.OLD_PASSWORD_INPUT]) {
    errors[STRINGS.OLD_PASSWORD_INPUT] = VALIDATION_MESSAGES.PASSWORD_REQUIRED;
  } else if (values) {
    if (!values[STRINGS.OLD_PASSWORD_INPUT].trim())
      errors[STRINGS.OLD_PASSWORD_INPUT] = VALIDATION_MESSAGES.PASSWORD_REQUIRED;
  }
  if (!values[STRINGS.NEW_PASSWORD_INPUT]) {
    errors[STRINGS.NEW_PASSWORD_INPUT] = VALIDATION_MESSAGES.PASSWORD_REQUIRED;
  } else if (values) {
    if (!values[STRINGS.NEW_PASSWORD_INPUT].trim())
      errors[STRINGS.NEW_PASSWORD_INPUT] = VALIDATION_MESSAGES.PASSWORD_REQUIRED;

    if (
      values[STRINGS.NEW_PASSWORD_INPUT].trim() &&
      (values[STRINGS.NEW_PASSWORD_INPUT].length == 3 || values[STRINGS.NEW_PASSWORD_INPUT].length < 3)
    ) {
      errors[STRINGS.NEW_PASSWORD_INPUT] = VALIDATION_MESSAGES.PASSWORD_VALIDATE;
    }
  }
  if (!values[STRINGS.CONFIRM_PASSWORD_INPUT]) {
    errors[STRINGS.CONFIRM_PASSWORD_INPUT] = VALIDATION_MESSAGES.PASSWORD_REQUIRED;
  }
  if (values[STRINGS.CONFIRM_PASSWORD_INPUT] != values[STRINGS.NEW_PASSWORD_INPUT])
    errors[STRINGS.CONFIRM_PASSWORD_INPUT] = VALIDATION_MESSAGES.CONFIRM_PASSWORD_VALIDATE;

  return errors;
};

const Screen = reduxForm({
  form: "changePasswordForm",
  validate: validator,
})(ChangePasswordForm);

export default withSnackbar(Screen);
