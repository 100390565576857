import React, { useEffect, useState, useRef } from "react";
import {
  ROUTE_CONSTANTS,
  USER_ROLES,
  BUTTON_LABELS,
  LOGO_GREEN,
  NOTIFICATION_ICON,
  HAMBURGER_ICON,
  STATUS,
} from "../../../shared/constants";
import { useLocation, useHistory } from "react-router-dom";
import AddNewModal from "../../molecules/AddNewModal";
import "./style.scss";
import ImageOverlay from "../ImageOverlay";
import NotificationPost from "../NotificationPost";
import { withSnackbar } from "notistack";

const TopBar = ({
  onToggle,
  toggle,
  count,
  clearNotifications,
  get_Notification = () => {},
  enqueueSnackbar,
  notificationList,
  userData,
  updateNotificationReadStatus = () => {},
  getUnreadNotificationCount = () => {},
}) => {
  const [showDropDown, set_showDropDown] = useState(false);
  const node = useRef();
  const [searchText, set_searchText] = useState("");

  useEffect(() => {
    if (showDropDown) {
      get_Notification((message, type) => {
        if (type === STATUS.error) {
          enqueueSnackbar(message, {
            variant: type,
            autoHideDuration: 3000,
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "left",
            },
          });
        }

        if (type === STATUS.success) {
          updateNotificationReadStatus((message, type) => {
            if (type === STATUS.success) {
              getUnreadNotificationCount((message, type) => {
                console.log("measasase in app.js", message);
              });
            }
          });
        }
      });
    }
  }, [showDropDown]);

  // useEffect(() => {
  //   if (notificationList && notificationList.data) {
  //     updateCount(notificationList.data.length);
  //   }
  // }, [notificationList]);

  let { pathname } = useLocation();
  let history = useHistory();

  useEffect(() => {
    // add when mounted
    document.addEventListener("mousedown", handleClick);
    // return function to be called when unmounted
    return () => {
      document.removeEventListener("mousedown", handleClick);
    };
  });

  const handleClick = (e) => {
    if (node.current.contains(e.target)) {
      // inside click
      return;
    } else {
      // outside click
      set_showDropDown(false);
      return;
    }
  };

  return (
    <div className="fixed-top dashboard-header">
      <nav className="navbar navbar-expand-lg justify-content-between">
        <span className="navbar-brand" style={{ cursor: "pointer" }} onClick={() => history.push(ROUTE_CONSTANTS.HOME)}>
          <ImageOverlay>
            <img src={LOGO_GREEN} alt="Inquiry Tracker" className="img-fluid" />
          </ImageOverlay>
        </span>

        <div className="d-flex align-items-center">
          <ul className="navbar-nav ml-auto navbar-right-top">
            <div className="desktop_btn">
              {USER_ROLES.ADMIN === userData.user_role || USER_ROLES.EDITOR === userData.user_role ? (
                <AddNewModal btnLabel={BUTTON_LABELS.ADD_NEW} />
              ) : null}
            </div>
            <li></li>
            <li className="d-none">
              <form className="search_form mx-4">
                <input
                  type="text"
                  className="form-control"
                  style={{ visibility: "hidden" }}
                  placeholder="Search with keywords(floorbook name, journal name)"
                  onChange={(e) => {
                    set_searchText(e.target.value);
                  }}
                />
                {/* <button onClick={(e) => e.preventDefault()}>
                  <ImageOverlay>
                    <img src={SEARCH_ICON} alt="" />
                  </ImageOverlay>
                </button> */}
              </form>
            </li>

            <li className="dropdown notification_drop" ref={node}>
              {count > 0 ? <span className="count">{count}</span> : null}
              <a
                className="notification_icon"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
                onClick={() => set_showDropDown(!showDropDown)}
              >
                <ImageOverlay>
                  <img src={NOTIFICATION_ICON} alt="Floorbooks" />
                </ImageOverlay>
              </a>

              <ul
                id="drop_notification"
                className={
                  showDropDown
                    ? "dropdown-menu dropdown-menu-right notification-dropdown show"
                    : "dropdown-menu dropdown-menu-right notification-dropdown"
                }
              >
                <li>
                  <div className="notification-title text-center">
                    {BUTTON_LABELS.NOTIFICATION}
                    <span
                      className="clear_all"
                      onClick={() => clearNotifications((val) => console.log("clear notifications : ", val))}
                    >
                      {BUTTON_LABELS.CLEAR_ALL}
                    </span>
                  </div>

                  <div className="notification-list">
                    <NotificationPost onClose={() => set_showDropDown(false)} />
                  </div>
                </li>
              </ul>
            </li>
          </ul>

          {!toggle && (
            <button
              className={toggle ? "hamburger-btn d-lg-none toggle-active" : "hamburger-btn d-lg-none"}
              onClick={() => onToggle()}
            >
              <i>
                <img src={HAMBURGER_ICON} alt="" />
              </i>
              {/* <svg xmlns="http://www.w3.org/2000/svg" width="27.001" height="27" viewBox="0 0 27.001 27">
              <path
                id="close"
                d="M15.974,13.636,26.487,3.122A1.749,1.749,0,0,0,24.014.649L13.5,11.162,2.987.649A1.749,1.749,0,0,0,.513,3.122L11.027,13.636.513,24.15a1.749,1.749,0,1,0,2.473,2.473L13.5,16.109,24.014,26.623a1.749,1.749,0,0,0,2.473-2.473Zm0,0"
                transform="translate(0 -0.136)"
                fill="#0D532D"
              />
            </svg> */}
            </button>
          )}
        </div>
      </nav>
    </div>
  );
};

export default withSnackbar(TopBar);
