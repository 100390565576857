import { ca } from "date-fns/locale";
import {
  NOTICATION_SOUND,
  NOTICATION_STATUS,
  SET_NOTIFICATION_STATUS,
  SET_NOTIFICATION_SOUND,
  CLEAR_NOTIFICATIONS,
  SET_NOTIFICATION_COUNT,
  SET_NOTIFICATION_LIST,
  GET_NOTIFICATION_LIST,
  STOP_NOTIFICATION_LOADER,
  START_NOTIFICATION_LOADER,
  GET_UNREAD_NOTIFICATION_COUNT,
  UPDATE_NOTIFICATION_READ_STATUS,
  SET_NOTIFICATION_UNREAD_COUNT,
  GET_NOTIFICATION_SETTING,
} from "./ActionTypes";

export const start_Notification_Loader = () => {
  return {
    type: START_NOTIFICATION_LOADER,
  };
};

export const stop_Notification_Loader = () => {
  return {
    type: STOP_NOTIFICATION_LOADER,
  };
};

export const set_Notification_List = (data) => {
  return {
    type: SET_NOTIFICATION_LIST,
    data,
  };
};

export const get_Notification = (callback) => {
  return {
    type: GET_NOTIFICATION_LIST,
    callback,
  };
};

export const notificationCount = (data) => {
  return {
    type: SET_NOTIFICATION_COUNT,
    data,
  };
};

export const notificationStatus = (data, callback) => {
  return {
    type: NOTICATION_STATUS,
    data,
    callback,
  };
};
export const notificationSound = (data, callback) => {
  return {
    type: NOTICATION_SOUND,
    data,
    callback,
  };
};

export const set_Notification_Status = (data) => {
  return {
    type: SET_NOTIFICATION_STATUS,
    data,
  };
};
export const set_Notification_Sound = (data) => {
  return {
    type: SET_NOTIFICATION_SOUND,
    data,
  };
};

export const clearNotifications = (callback) => {
  return {
    type: CLEAR_NOTIFICATIONS,
    callback,
  };
};

export const getUnreadNotificationCount = (callback) => {
  return {
    type: GET_UNREAD_NOTIFICATION_COUNT,
    callback,
  };
};

export const updateNotificationReadStatus = (callback) => {
  return {
    type: UPDATE_NOTIFICATION_READ_STATUS,
    callback,
  };
};

export const addNotificationReadCount = (data) => {
  return {
    type: SET_NOTIFICATION_UNREAD_COUNT,
    data,
  };
};

export const getNotificationSetting = (callback) => {
  return {
    type: GET_NOTIFICATION_SETTING,
    callback,
  };
};
