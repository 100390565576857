import { all, fork } from "redux-saga/effects";
import loginSaga from "./LoginSaga";
import SignUpSaga from "./SignUpSaga";
import ForgetPasswordSaga from "./ForgetPasswordSaga";
import FloorbookSaga from "./FloorbookSaga";
import FloorbookPagesSaga from "./FloorbookPagesSaga";
import UsefulResourcesSaga from "./UsefulResourcesSaga";
import ReportSaga from "./ReportSaga";
import HistorySaga from "./HistorySaga";
import ProfileSaga from "./ProfileSaga";
import notificationSaga from "./NotificationSaga";
import HelpPageSaga from "./HelpPageSaga";
import JournalSaga from "./JournalSaga";
function* dataSaga() {
  yield all([
    fork(loginSaga),
    fork(SignUpSaga),
    fork(ForgetPasswordSaga),
    fork(FloorbookSaga),
    fork(FloorbookPagesSaga),
    fork(UsefulResourcesSaga),
    fork(ReportSaga),
    fork(HistorySaga),
    fork(ProfileSaga),
    fork(notificationSaga),
    fork(HelpPageSaga),
    fork(JournalSaga),
  ]);
}

export default dataSaga;
