import { NAME_REGX, EMAIL_REGX, CITY_REGEX, STRINGS, VALIDATION_MESSAGES } from "../../../../shared/constants";

export const validator = (values) => {
  const errors = {};
  if (!values[STRINGS.FIRST_NAME_INPUT]) {
    errors[STRINGS.FIRST_NAME_INPUT] = VALIDATION_MESSAGES.FIRST_NAME_REQUIRED;
  } else if (values) {
    if (!values[STRINGS.FIRST_NAME_INPUT].trim())
      errors[STRINGS.FIRST_NAME_INPUT] = VALIDATION_MESSAGES.FIRST_NAME_REQUIRED;

    if (values[STRINGS.FIRST_NAME_INPUT].trim() && !NAME_REGX.test(values[STRINGS.FIRST_NAME_INPUT])) {
      errors[STRINGS.FIRST_NAME_INPUT] = VALIDATION_MESSAGES.FIRST_NAME_VALIDATE;
    }
  }
  if (!values[STRINGS.LAST_NAME_INPUT]) {
    errors[STRINGS.LAST_NAME_INPUT] = VALIDATION_MESSAGES.LAST_NAME_REQUIRED;
  } else if (values) {
    if (!values[STRINGS.LAST_NAME_INPUT].trim())
      errors[STRINGS.LAST_NAME_INPUT] = VALIDATION_MESSAGES.LAST_NAME_REQUIRED;

    if (values[STRINGS.LAST_NAME_INPUT].trim() && !NAME_REGX.test(values[STRINGS.LAST_NAME_INPUT])) {
      errors[STRINGS.LAST_NAME_INPUT] = VALIDATION_MESSAGES.LAST_NAME_VALIDATE;
    }
  }
  if (!values[STRINGS.EMAIL_INPUT_NAME]) {
    errors[STRINGS.EMAIL_INPUT_NAME] = VALIDATION_MESSAGES.EMAIL_REQUIRED;
  } else if (values) {
    if (!values[STRINGS.EMAIL_INPUT_NAME].trim()) errors[STRINGS.EMAIL_INPUT_NAME] = VALIDATION_MESSAGES.EMAIL_REQUIRED;

    if (values[STRINGS.EMAIL_INPUT_NAME].trim() && !EMAIL_REGX.test(values[STRINGS.EMAIL_INPUT_NAME])) {
      errors[STRINGS.EMAIL_INPUT_NAME] = VALIDATION_MESSAGES.EMAIL_INVALID;
    }
  }
  if (!values[STRINGS.SETTING_TYPE_INPUT]) {
    errors[STRINGS.SETTING_TYPE_INPUT] = VALIDATION_MESSAGES.SETTING_TYPE_REQUIRED;
  }

  if (!values[STRINGS.SETTING_OTHER_TYPE_INPUT]) {
    errors[STRINGS.SETTING_OTHER_TYPE_INPUT] = VALIDATION_MESSAGES.SETTING_OTHER_TYPE_REQUIRED;
  }

  if (!values[STRINGS.SETTING_NAME_INPUT]) {
    errors[STRINGS.SETTING_NAME_INPUT] = VALIDATION_MESSAGES.SETTING_NAME_REQUIRED;
  } else if (values[STRINGS.SETTING_NAME_INPUT]) {
    if (!values[STRINGS.SETTING_NAME_INPUT].trim())
      errors[STRINGS.SETTING_NAME_INPUT] = VALIDATION_MESSAGES.SETTING_TYPE_REQUIRED;

    // if (values[STRINGS.SETTING_NAME_INPUT].trim() && !NAME_REGX.test(values[STRINGS.SETTING_NAME_INPUT])) {
    //   errors[STRINGS.SETTING_NAME_INPUT] = VALIDATION_MESSAGES.SETTING_TYPE_VALIDATE;
    // }
  }

  if (!values[STRINGS.COUNTRY_INPUT]) {
    errors[STRINGS.COUNTRY_INPUT] = VALIDATION_MESSAGES.COUNTRY_REQUIRED;
  }

  if (!values[STRINGS.CITY_INPUT]) {
    errors[STRINGS.CITY_INPUT] = VALIDATION_MESSAGES.CITY_REQUIRED;
  } else if (values[STRINGS.CITY_INPUT]) {
    if (!values[STRINGS.CITY_INPUT].trim()) errors[STRINGS.CITY_INPUT] = VALIDATION_MESSAGES.CITY_REQUIRED;

    if (values[STRINGS.CITY_INPUT].trim() && !CITY_REGEX.test(values[STRINGS.CITY_INPUT])) {
      errors[STRINGS.CITY_INPUT] = VALIDATION_MESSAGES.CITY_VALIDATE;
    }
  }

  if (!values[STRINGS.DESCRIPTION_OF_CLASS_AND_AGE_INPUT]) {
    errors[STRINGS.DESCRIPTION_OF_CLASS_AND_AGE_INPUT] = VALIDATION_MESSAGES.DESCRIPTION_OF_CLASS_AND_AGE_REQUIRED;
  } else if (values[STRINGS.DESCRIPTION_OF_CLASS_AND_AGE_INPUT]) {
    if (!values[STRINGS.DESCRIPTION_OF_CLASS_AND_AGE_INPUT].trim())
      errors[STRINGS.DESCRIPTION_OF_CLASS_AND_AGE_INPUT] = VALIDATION_MESSAGES.DESCRIPTION_OF_CLASS_AND_AGE_REQUIRED;
  }
  return errors;
};
