import * as actionTypes from "../actions/ActionTypes";

const initialCommonState = {
  LatestPosts: [],
  pageNoOfLatestPost: "",
  totalLatestPosts: 1,
  pageNoHome: 1,
};

const HomePageReducer = (state = { ...initialCommonState }, action) => {
  switch (action.type) {
    case actionTypes.SAVE_HOME_LATEST_POSTS:
      return {
        ...state,
        LatestPosts: [...state.LatestPosts, ...action.data],
      };
    case actionTypes.EMPTY_POSTS:
      return {
        ...state,
        LatestPosts: [],
      };
    case actionTypes.PAGENO_OF_LATEST_POSTS:
      return {
        ...state,
        pageNoOfLatestPost: action.data,
      };
    case actionTypes.TOTAL_LATEST_POSTS_COUNT:
      return {
        ...state,
        totalLatestPosts: action.data,
      };
    case actionTypes.SAVE_PAGENO:
      return {
        ...state,
        pageNoHome: action.data,
      };
    /********************************/

    default:
      return state;
  }
};

export default HomePageReducer;
