import * as actionTypes from "../actions/ActionTypes";
import { startLoading, stopLoading, logout_user } from "../actions";
import { put, all, takeLatest } from "redux-saga/effects";
import { checkOffline } from "../../shared/services/Utility";
const { postRequest, getRequest } = require(`../../helpers/axios`);
const { STATUS_CODE, MESSAGES, STATUS } = require(`../../shared/constants`);

const api = require(`../../shared/Apis/api`);

//request to get user Profile data
function* getUseProfile({ callback }) {
  try {
    yield put(startLoading());
    const response = yield getRequest({ API: `${api.GET_USER_PROFILE_DATA}` });

    if (response.status === STATUS_CODE.unAuthorized) {
      yield put(stopLoading());
      callback(response.data.message, STATUS.error);
      yield put(logout_user());
    } else if (response.status !== STATUS_CODE.successful) {
      yield put(stopLoading());
      callback(response.data.error.message, STATUS.error);
    } else {
      callback(response.data.data, STATUS.success);
      yield put(stopLoading());
    }
  } catch (error) {
    yield put(stopLoading());
    if (checkOffline()) {
      callback(MESSAGES.OFFLINE, STATUS.error);
    } else {
      callback(MESSAGES.ERROR_IN_CATCH, STATUS.error);
    }
  }
}

//api call to update user profile
function* updateProfile({ data, callback }) {
  try {
    yield put(startLoading());
    const formdata = new FormData();
    formdata.append("profile_pic", data.profile_pic);
    formdata.append("first_name", data.first_name);
    formdata.append("last_name", data.last_name);
    formdata.append("email", data.email);
    formdata.append("setting_type", data.setting_type);
    formdata.append("setting_name", data.setting_name);
    formdata.append("country_code", data.country_code);
    formdata.append("city", data.city);
    formdata.append("description", data.description);
    if (!!data.scottish_authority) {
      formdata.append("scottish_authority", data.scottish_authority);
    }
    console.log("update profilelelelelelel", formdata);
    const response = yield postRequest({ API: `${api.UPDATE_PROFILE}`, DATA: formdata });
    if (response.status === STATUS_CODE.unAuthorized) {
      yield put(stopLoading());
      callback(response.data.message, STATUS.error);
    } else if (response.status !== STATUS_CODE.successful) {
      yield put(stopLoading());
      callback(response.data.error.message, STATUS.error);
    } else {
      callback(response.data.data.message, STATUS.success, response.data.data.data);
      yield put(stopLoading());
    }
  } catch (error) {
    yield put(stopLoading());
    if (checkOffline()) {
      callback(MESSAGES.OFFLINE, STATUS.error);
    } else {
      callback(MESSAGES.ERROR_IN_CATCH, STATUS.error);
    }
  }
}

//request to update change password
function* changePassword({ data, callback }) {
  try {
    yield put(startLoading());
    const formdata = new FormData();
    formdata.append("current_password", data.oldPassword);
    formdata.append("new_password", data.newPassword);
    formdata.append("new_confirm_password", data.confirmPassword);
    const response = yield postRequest({ API: `${api.CHANGE_PASSWORD}`, DATA: formdata });
    if (response.status === STATUS_CODE.unAuthorized) {
      yield put(stopLoading());
      callback(response.data.message, STATUS.error);
      yield put(logout_user());
    } else if (response.status !== STATUS_CODE.successful) {
      yield put(stopLoading());
      callback(response.data.error.message, STATUS.error);
    } else {
      callback(response.data.data.message, STATUS.success);
      yield put(stopLoading());
    }
  } catch (error) {
    yield put(stopLoading());
    if (checkOffline()) {
      callback(MESSAGES.OFFLINE, STATUS.error);
    } else {
      callback(MESSAGES.ERROR_IN_CATCH, STATUS.error);
    }
  }
}
function* getUserProfileDetailsById({ data, callback }) {
  try {
    yield put(startLoading());
    const response = yield getRequest({ API: `${api.GET_USER_PROFILE_DETAILS}?id=${data}` });

    if (response.status === STATUS_CODE.unAuthorized) {
      yield put(stopLoading());
      callback(response.data.message, STATUS.error);
      yield put(logout_user());
    } else if (response.status !== STATUS_CODE.successful) {
      yield put(stopLoading());
      callback(response.data.error.message, STATUS.error);
    } else {
      callback(response.data.data.data, STATUS.success);
      yield put(stopLoading());
    }
  } catch (error) {
    yield put(stopLoading());
    if (checkOffline()) {
      callback(MESSAGES.OFFLINE, STATUS.error);
    } else {
      callback(MESSAGES.ERROR_IN_CATCH, STATUS.error);
    }
  }
}

function* profileSaga() {
  yield all([
    takeLatest(actionTypes.GET_USER_PROFILE, getUseProfile),
    takeLatest(actionTypes.CHANGE_PASSWORD, changePassword),
    takeLatest(actionTypes.UPDATE_USER_PROFILE, updateProfile),
    takeLatest(actionTypes.GET_USER_PROFILE_DETAILS, getUserProfileDetailsById),
  ]);
}

export default profileSaga;
