import { connect } from "react-redux";
import {
  clearNotifications,
  get_Notification,
  updateNotificationReadStatus,
  getUnreadNotificationCount,
} from "../../../redux/actions";
import TopBar from "./screen";

const mapStateToProps = (state) => {
  return {
    state,
    count: state.CommonReducer.count,
    notificationList: state.NotificationReducer.notificationList,
    userData: state.CommonReducer.userData,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    clearNotifications: (callback) => dispatch(clearNotifications(callback)),
    get_Notification: (callback) => dispatch(get_Notification(callback)),
    updateNotificationReadStatus: (callback) => dispatch(updateNotificationReadStatus(callback)),
    getUnreadNotificationCount: (callback) => dispatch(getUnreadNotificationCount(callback)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(TopBar);
