import * as faceapi from "face-api.js";

// Load models and weights
export async function loadModels() {
  const MODEL_URL = process.env.PUBLIC_URL + "/models";
  await faceapi.loadSsdMobilenetv1Model(MODEL_URL);
}

export async function getFacePosition(blob, inputSize = 512) {
  //Tiny face model options
  // let scoreThreshold = 0.5;
  // const OPTION = new faceapi.TinyFaceDetectorOptions({
  //   inputSize,
  //   scoreThreshold,
  // });

  // fetch image to api
  let img = await faceapi.fetchImage(blob);

  let minConfidence = 0.5;
  // let maxResults= 100

  const options = new faceapi.SsdMobilenetv1Options({ minConfidence });
  // detect all faces and generate full description from image
  let fullDesc;
  await faceapi
    .detectAllFaces(img, options)
    .then((data) => {
      fullDesc = data;
    })
    .catch((err) => console.log(err));
  return fullDesc;
}
