import React, { useState, useEffect } from "react";
import PixelateTool from "../../../helpers/PixelateTool";
import { CustomButton, ImageOverlay } from "../../atoms";

import "./style.scss";

const ImageCarousel = ({
  images,
  tool,
  onFacesChange,
  onFaceBlurDelete,
  BlurFaces,
  onBlurDelete,
  onImageClick = () => {},
}) => {
  const [showIndex, updateIndex] = useState(0);
  const [arr, updatearr] = useState([]);

  useEffect(() => {
    images && updatearr([...images]);
    if (images && images.length - 1 < showIndex && showIndex !== 0) {
      updateIndex((state) => state - 1);
    }
  }, [images]);

  return (
    <>
      <div className="post_slider">
        {showIndex !== 0 ? (
          /** left button **/
          <CustomButton
            icon={require("../../../assets/images/icons/back_icon.svg")}
            onButtonClick={() => {
              if (showIndex !== 0) {
                updateIndex(showIndex - 1);
              }
            }}
            className={"btn-arrows btn-prev"}
          />
        ) : null}

        {arr &&
          arr.map((item, index) => {
            if (index === showIndex) {
              if (tool === true && !item.videoUrl) {
                return (
                  <PixelateTool
                    key={index}
                    index={index}
                    imageData={item}
                    onFacesChange={onFacesChange}
                    onFaceBlurDelete={onFaceBlurDelete}
                    BlurFaces={BlurFaces}
                    onBlurDelete={onBlurDelete}
                  />
                );
              } else
                return (
                  <div className="slider_wrap" key={index}>
                    <figure onClick={() => onImageClick()} style={{ cursor: "pointer" }}>
                      {item.videoUrl ? (
                        <video controls>
                          <source src={item.videoUrl} width={400} type="video/mp4" />
                        </video>
                      ) : (
                        <ImageOverlay>
                          <img src={item.imageP || item} alt="" height={250} width={550} className="img-fluid" />
                        </ImageOverlay>
                      )}
                    </figure>
                  </div>
                );
            }
          })}
        {arr.length > 1 ? (
          showIndex === arr.length - 1 ? null : (
            /** right button **/
            <CustomButton
              icon={require("../../../assets/images/icons/next_icon.svg")}
              className={"btn-arrows btn-next"}
              onButtonClick={() => {
                if (showIndex <= arr.length - 2) {
                  updateIndex(showIndex + 1);
                }
              }}
            />
          )
        ) : null}
      </div>
    </>
  );
};
export default ImageCarousel;
