import { connect } from "react-redux";
import {
  getAllHistory,
  getLikesHistory,
  getCommentsHistory,
  fetchFloorboookOrder,
  setLastOpenedTab,
} from "../../../../redux/actions";
import History from "./screen";

const mapStateToProps = (state) => {
  return {
    loading: state.CommonReducer.isLoading,
    allLikeCommentsHistory: state.HistoryReducer.allLikesComments,
    allLikesHistory: state.HistoryReducer.allLikes,
    allCommentsHistory: state.HistoryReducer.allComments,
    lastOpenedTab: state.HistoryReducer.lastOpenedTab,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getAllHistory: (data, callback) => dispatch(getAllHistory(data, callback)),
    getLikesHistory: (data, callback) => dispatch(getLikesHistory(data, callback)),
    getCommentsHistory: (data, callback) => dispatch(getCommentsHistory(data, callback)),
    fetchFloorboookOrder: (data) => dispatch(fetchFloorboookOrder(data)),
    setLastOpenedTab: (data) => dispatch(setLastOpenedTab(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(History);
