import * as actionTypes from "../actions/ActionTypes";

const initialCommonState = {
    notificationList: [],
    notificationLoader: false
};

const NotificationReducer = (state = { ...initialCommonState }, action) => {
    switch (action.type) {

        case actionTypes.SET_NOTIFICATION_LIST:
            console.log("called",action.data)
            return {
                ...state,
                notificationList: action.data,
            };


        case actionTypes.START_NOTIFICATION_LOADER:
            return {
                ...state,
                notificationLoader: true,
            };


        case actionTypes.STOP_NOTIFICATION_LOADER:
            return {
                ...state,
                notificationLoader: false,
            };

        

        /********************************/

        default:
            return state;
    }
};

export default NotificationReducer;
