import React, { useState, useEffect } from "react";
import { loadModels, getFacePosition } from "./face_api";
import "./style.scss";
export default ({ index, imageData, onFacesChange, onFaceBlurDelete, BlurFaces, onBlurDelete }) => {
  try {
    const [testdata, set_testdata] = useState({
      imageURL: "",
      fullDesc: null,
    });
    const [loading, set_loading] = useState(false);
    const [canvasDim, set_canvasDim] = useState({ width: 0, height: 0 });
    const [selector, set_selector] = useState({ width: 20, height: 20 });

    useEffect(() => {
      if (imageData) {
        let { data } = imageData;
        set_testdata({
          imageURL: data.imageP,
        });
        let width = data.width;
        let height = data.height;
        //load face Api data models before images
        fetchData().then(() => handleImage(data.imageP, { width, height }, imageData.blurs));
      }
    }, [imageData]);
    const handleImage = async (imageP = testdata.imageURL, image, blurData) => {
      set_canvasDim({
        height: image.height,
        width: image.width,
      });
      await getFacePosition(imageP, 608) // fetch face points from the face api
        .then((fullDesc) => {
          set_testdata({
            imageURL: imageP,
            fullDesc,
            detections: fullDesc.map((fd) => fd.box),
            image: image,
            faces: fullDesc.map((fd) => fd.box),
            blurs: [...blurData],
          });
        })
        .then(() => set_loading(false)) //to prevent pixelate onClick before loading complete
        .catch((err) => {
          set_loading(false);
          console.log(err);
        });
    };

    const fetchData = async () => {
      await loadModels();
    };

    const { detections, faces, blurs } = testdata;

    const removeBlur = (value) => {
      faces.splice(value, 1);
      set_testdata({ ...testdata, faces });
      onFaceBlurDelete(index, value);
    };

    const addBlur = (value) => {
      faces.splice(value, 0, detections[value]);
      set_testdata({ ...testdata, faces });
    };

    let drawBox = null; //face box visible
    if (!!detections) {
      drawBox = detections.map((detection, i) => {
        let height = (detection.height / canvasDim.height) * 100;
        let width = (detection.width / canvasDim.width) * 100;
        let left = (detection._x / canvasDim.width) * 100;
        let top = (detection._y / canvasDim.height) * 100;
        return (
          <div
            key={i}
            style={{
              position: "absolute",
              height: `${height}%`,
              width: `${width}%`,
              top: `${top}%`,
              left: `${left}%`,
              cursor: "pointer",
            }}
            onClick={() => {
              addBlur(i);
            }}
          />
        );
      });
    }
    let drawBox2 = null; //face box invisible
    if (!!faces) {
      onFacesChange(faces, index);
      drawBox2 = faces.map((face, i) => {
        let height = (face.height / canvasDim.height) * 100;
        let width = (face.width / canvasDim.width) * 100;
        let left = (face._x / canvasDim.width) * 100;
        let top = (face._y / canvasDim.height) * 100;
        return (
          <div
            key={i}
            style={{
              position: "absolute",
              border: "2px solid #0D532D",
              height: `${height}%`,
              width: `${width}%`,
              top: `${top}%`,
              left: `${left}%`,
              cursor: "pointer",
              zIndex: 2,
            }}
            onClick={() => {
              removeBlur(i);
            }}
          />
        );
      });
    }

    const removeBlurs = (i) => {
      blurs.splice(i, 1);
      set_testdata({ ...testdata, blurs });
      onBlurDelete(index, i);
    };

    let drawBox3 = null; //blur box
    if (blurs && blurs.length) {
      drawBox3 = blurs.map((face, i) => {
        let height = (face.height / canvasDim.height) * 100;
        let width = (face.width / canvasDim.width) * 100;
        let left = (face._x / canvasDim.width) * 100;
        let top = (face._y / canvasDim.height) * 100;
        return (
          <div
            key={i}
            style={{
              position: "absolute",
              border: "2px solid black",
              height: `${height}%`,
              width: `${width}%`,
              top: `${top}%`,
              left: `${left}%`,
              cursor: "pointer",
              zIndex: 2,
              backgroundColor: "#000",
              filter: "blur(4px)",
            }}
            onClick={() => {
              removeBlurs(i);
            }}
          />
        );
      });
    }

    const onMouseClick = (e) => {
      //to detect user clicks for pixelation
      blurs &&
        blurs.splice(1, 0, {
          height: selector.height,
          width: selector.width,
          _x: e.nativeEvent.offsetX - selector.width / 2,
          _y: e.nativeEvent.offsetY - selector.height / 2,
        });
      set_testdata({ ...testdata, blurs });
      BlurFaces(blurs, index);
    };
    return loading ? (
      <div style={{}}>
        <h1>Loading........</h1>
      </div>
    ) : (
      <div>
        {testdata.imageURL && (
          <>
            {/* image outer div */}
            <div
              className="slider_item"
              style={{
                width: imageData.data.width, // original height and original width of image (important)
                height: imageData.data.height,
                position: "relative",
                margin: "20px auto",
                display: "flex",
                overflow: "hidden",
              }}
            >
              <img
                src={testdata.imageURL}
                alt="imageURL"
                draggable={false}
                width="100%"
                onClick={(e) => onMouseClick(e)}
                style={{ overflow: "hidden" }}
              />
              {!!drawBox ? drawBox : null}
              {!!drawBox2 ? drawBox2 : null}
              {!!drawBox3 ? drawBox3 : null}
            </div>
          </>
        )}
      </div>
    );
  } catch (err) {
    console.log(err);
  }
};
