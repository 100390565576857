import React, { useEffect, useState } from "react";
import { SideBar, CustomTopBar } from "../../index";
import { useLocation, useHistory } from "react-router-dom";
import { CustomButton } from "../../atoms";
import AddNewModal from "../../molecules/AddNewModal";
import AddNewFloorBookModal from "../../molecules/AddNewFloorBookModal";
import AddNewJournalModal from "../../molecules/AddNewJournalModal";
import {
  homePagePosition,
  floorbookDetailsPagePosition,
  floorbookDetailPagePosition,
  saveHomeLatestPosts,
  saveTotalCountofLatestPosts,
  savepageNoOfLatestPosts,
  setLastOpenedTab,
} from "../../../redux/actions";
import {
  ADD_FLOORBOOK_TYPE,
  ROUTE_CONSTANTS,
  BUTTON_LABELS,
  ADD_JORNAL_TYPE,
  USER_ROLES,
} from "../../../shared/constants";
import "./style.scss";
import { connect } from "react-redux";

const ScreenHOC = ({
  children,
  userRole,
  floorbookDetailsPagePosition,
  floorbookDetailPagePosition,
  homePagePosition,
  setLastOpenedTab = () => {},
}) => {
  let history = useHistory();
  const [toggle, update_toggle] = useState(false);
  const [btnLabel, updateBtnLabel] = useState("");
  const onToggle = () => {
    //toggle to show side-bar onClick on hamburger icon
    update_toggle(!toggle);
  };

  let { pathname } = useLocation();
  let Home = ROUTE_CONSTANTS.HOME;
  let Floorbooks = ROUTE_CONSTANTS.FLOORBOOKS;
  let FloorbookDetails = ROUTE_CONSTANTS.FLOORBOOK_DETAILS;
  let LearningJournals = ROUTE_CONSTANTS.LEARNING_JOURNAL;
  let Reports = ROUTE_CONSTANTS.REPORT;

  useEffect(() => {
    if (pathname === Home || pathname === Reports) {
      return updateBtnLabel(BUTTON_LABELS.ADD_NEW);
    }
    if (pathname === Floorbooks) {
      return updateBtnLabel(BUTTON_LABELS.ADD_NEW_FLOORBOOK);
    }
    if (pathname === FloorbookDetails) {
      return updateBtnLabel(BUTTON_LABELS.ADD_NEW_PAGE);
    }
    if (pathname === LearningJournals) {
      return updateBtnLabel(BUTTON_LABELS.ADD_NEW_LEARNING_JOURNAL_ENTRY);
    }
    updateBtnLabel("");
  }, [pathname]);

  const onTabClick = () => {
    saveHomeLatestPosts([]);
    saveTotalCountofLatestPosts(1);
    savepageNoOfLatestPosts(1);
    setLastOpenedTab("");
  };
  return (
    <>
      <CustomTopBar onToggle={onToggle} toggle={toggle} />

      <nav className={toggle ? "side-navbar open-sidebar" : "side-navbar"}>
        <a className="navbar-brand text-center w-100 d-lg-none" href="#">
          <img
            src={require("../../../assets/images/logo_green.png")}
            alt="Inquiry Tracker"
            className="img-fluid"
          />
        </a>
        <SideBar
          onClick={(path) => {
            floorbookDetailsPagePosition(" ");
            homePagePosition(" ");
            floorbookDetailPagePosition(" ");
            onTabClick();
            history.push(path);
          }}
          toggle={toggle}
          onToggle={onToggle}
        />
      </nav>

      <div id="content-wrapper">
        <div className="container-fluid" id="inside-wrapper">
          <div className="page_box">
            <div className="mobile_add_btn">
              {USER_ROLES.ADMIN === userRole ||
              USER_ROLES.EDITOR === userRole ? (
                btnLabel === BUTTON_LABELS.ADD_NEW ? (
                  <AddNewModal btnLabel={btnLabel} />
                ) : btnLabel === BUTTON_LABELS.ADD_NEW_FLOORBOOK ? (
                  <AddNewFloorBookModal
                    btnLabel={btnLabel}
                    formType={ADD_FLOORBOOK_TYPE.create}
                  />
                ) : btnLabel === BUTTON_LABELS.ADD_NEW_JOURNAL ? (
                  <AddNewJournalModal
                    btnLabel={btnLabel}
                    formType={ADD_JORNAL_TYPE.create}
                  />
                ) : btnLabel ===
                  BUTTON_LABELS.ADD_NEW_LEARNING_JOURNAL_ENTRY ? (
                  <CustomButton
                    className="btn btn-lg btn-primary text-capitalize"
                    onButtonClick={() =>
                      history.push(ROUTE_CONSTANTS.ADD_NEW_JOURNAL)
                    }
                    label={btnLabel}
                  />
                ) : btnLabel === BUTTON_LABELS.ADD_NEW_PAGE ? (
                  <CustomButton
                    className="btn btn-lg btn-primary text-capitalize"
                    onButtonClick={() =>
                      history.push(ROUTE_CONSTANTS.ADD_NEW_FLOORBOOK_PAGE)
                    }
                    label={btnLabel}
                  />
                ) : null
              ) : null}
            </div>
            <div className="card default_block box_vh">{children}</div>
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    userRole: state.CommonReducer.userData.user_role,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    pagePosition: (data) => dispatch(homePagePosition(data)),
    floorbookDetailsPagePosition: (data) => dispatch(floorbookDetailsPagePosition(data)),
    homePagePosition: (data) => dispatch(homePagePosition(data)),
    floorbookDetailPagePosition: (data) => dispatch(floorbookDetailPagePosition(data)),
    savepageNoOfLatestPosts: (data) => dispatch(savepageNoOfLatestPosts(data)),
    saveTotalCountofLatestPosts: (data) => dispatch(saveTotalCountofLatestPosts(data)),
    saveHomeLatestPosts: (data) => dispatch(saveHomeLatestPosts(data)),
    setLastOpenedTab: (data) => dispatch(setLastOpenedTab(data)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(ScreenHOC);
