import { connect } from "react-redux";
import JournalDetails from "./screen";
import {
  getFloorbookPages,
  likeFloorbook,
  saveFloorbookPages,
  setFavourite,
  deleteFloorbook,
  deleteFloorbookPage,
  floorbookDetailPagePosition,
  fetchFloorboookOrder,
  updateFloorbook,
  setHideorUnHideFloorbook,
} from "../../../../../redux/actions";

const mapStateToProps = (state) => {
  return {
    floorbookAllPages: state.FloorbookReducer.floorbookAllPages,
    loading: state.CommonReducer.isLoading,
    userData: state.CommonReducer.userData,
    FloorbookDetailPage_Position:
      state.ScrollPositionsReducer.FloorbookDetailPage_Position,
    privacyStatusofFloorbook: state.FloorbookReducer.privacyStatusofFloorbook,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getFloorbookPages: (id, callback) =>
      dispatch(getFloorbookPages(id, callback)),
    saveFloorbookPages: (data) => dispatch(saveFloorbookPages(data)),
    likeFloorbook: (data, status, created_at, callback) =>
      dispatch(likeFloorbook(data, status, created_at, callback)),
    setFavourite: (data, callback) => dispatch(setFavourite(data, callback)),
    deleteFloorbook: (data, callback) =>
      dispatch(deleteFloorbook(data, callback)),
    deleteFloorbookPage: (data, callback) =>
      dispatch(deleteFloorbookPage(data, callback)),
    floorbookDetailPagePosition: (data) =>
      dispatch(floorbookDetailPagePosition(data)),
    fetchFloorboookOrder: (data) => dispatch(fetchFloorboookOrder(data)),
    updateFloorbook: (id, data, callback) =>
      dispatch(updateFloorbook(id, data, callback)),
    setHideorUnHideFloorbook: (data, callback) =>
      dispatch(setHideorUnHideFloorbook(data, callback)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(JournalDetails);
