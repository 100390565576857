import { STRINGS } from "../constants";
import { ADD_FLOORBOOK_PAGE, ADD_NEW_JOURNAL_ENTRY_STRINGS } from "./strings";

export const EMAIL_REGX = /^(([^<>()\\.,;:\s@"]+(\.[^<>()\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
export const NAME_REGX = /^[a-zA-Z-,]+(\s{0,1}[a-zA-Z-, ])*$/;
export const PASSWORD_REGEX = /^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{7,15}$/;
export const NAME_NUMBER_REGEX = /^[a-zA-Z0-9 ]+$/;
export const CITY_REGEX = /^[a-zA-Z\s]+$/;
export const STATUS_CODE = {
  successful: 200,
  unAuthorized: 401,
};

export const STATUS = {
  success: "success",
  error: "error",
};
export const MESSAGES = {
  OFFLINE: "You are offline.",
  ERROR_IN_CATCH: "Something went wrong.",
};
/************** validation messages in form fields **************/
export const VALIDATION_MESSAGES = {
  EMAIL_REQUIRED: STRINGS.EMAIL_REQUIRED,
  EMAIL_INVALID: STRINGS.EMAIL_INVALID,
  PASSWORD_REQUIRED: STRINGS.PASSWORD_REQUIRED,
  PASSWORD_VALIDATE: STRINGS.PASSWORD_VALIDATE,
  FIRST_NAME_REQUIRED: STRINGS.FIRST_NAME_REQUIRED,
  LAST_NAME_REQUIRED: STRINGS.LAST_NAME_REQUIRED,
  FIRST_NAME_VALIDATE: STRINGS.FIRST_NAME_VALIDATE,
  LAST_NAME_VALIDATE: STRINGS.LAST_NAME_VALIDATE,
  CONFIRM_PASSWORD_VALIDATE: STRINGS.CONFIRM_PASSWORD_VALIDATE,
  CITY_REQUIRED: STRINGS.CITY_REQUIRED,
  CITY_VALIDATE: STRINGS.CITY_VALIDATE,
  COUNTRY_REQUIRED: STRINGS.COUNTRY_REQUIRED,
  SETTING_NAME_REQUIRED: STRINGS.SETTING_NAME_REQUIRED,
  SETTING_TYPE_REQUIRED: STRINGS.SETTING_TYPE_REQUIRED,
  SETTING_OTHER_TYPE_REQUIRED: STRINGS.SETTING_OTHER_TYPE_REQUIRED,
  FLOORBOOK_NAME_REQUIRED: STRINGS.FLOORBOOK_NAME_REQUIRED,
  FLOORBOOK_COVER_REQUIRED: STRINGS.FLOORBOOK_COVER_REQUIRED,
  FLOORBOOK_SIGNIN_REQUIRED: STRINGS.FLOORBOOK_SIGNIN_REQUIRED,
  FLOORBOOK_DESCRIPTION_REQUIRED: STRINGS.FLOORBOOK_DESCRIPTION_REQUIRED,
  FLOORBOOK_NAME_VALIDATE: STRINGS.FLOORBOOK_NAME_VALIDATE,
  CAPTCHA_VALIDATE: STRINGS.CAPTCHA_VALIDATE,
  CAPTCHA_REQUIRED: STRINGS.CAPTCHA_REQUIRED,
  DESCRIPTION_OF_CLASS_AND_AGE_REQUIRED: STRINGS.DESCRIPTION_OF_CLASS_AND_AGE_REQUIRED,
  FLOORBOOK_REQUIRED: ADD_FLOORBOOK_PAGE.FLOORBOOK_REQUIRED,
  FLOORBOOK_TITLE_REQUIRED: ADD_FLOORBOOK_PAGE.FLOORBOOK_TITLE_REQUIRED,
  PAGE_TITLE_REQUIRED: STRINGS.PAGE_TITLE_REQUIRED,
  LINE_OF_INQUIRY_REQUIRED: ADD_FLOORBOOK_PAGE.LINE_OF_INQUIRY_REQUIRED,
  OTHER_LINE_OF_INQUIRY_REQUIRED: ADD_FLOORBOOK_PAGE.OTHER_LINE_OF_INQUIRY_REQUIRED,
  FROM_DATE_REQUIRED: ADD_FLOORBOOK_PAGE.FROM_DATE_REQUIRED,
  END_DATE_REQUIRED: ADD_FLOORBOOK_PAGE.END_DATE_REQUIRED,
  DESCRIPTION_REQUIRED: ADD_FLOORBOOK_PAGE.DESCRIPTION_REQUIRED,
  LOCATION_REQUIRED: ADD_FLOORBOOK_PAGE.LOCATION_REQUIRED,
  SUBJECT_REQUIRED: ADD_FLOORBOOK_PAGE.SUBJECT_REQUIRED,
  REFLECTIONS_REQUIRED: ADD_FLOORBOOK_PAGE.REFLECTIONS_REQUIRED,
  FROM_DATE_VALIDATE: ADD_FLOORBOOK_PAGE.FROM_DATE_VALIDATE,
  END_DATE_VALIDATE: ADD_FLOORBOOK_PAGE.END_DATE_VALIDATE,
  TIME_SHOUDLD_NOT_BE_IN_PAST: ADD_FLOORBOOK_PAGE.TIME_SHOUDLD_NOT_BE_IN_PAST,
  SELECT_START_TIME_FIRST: ADD_FLOORBOOK_PAGE.SELECT_START_TIME_FIRST,
  START_TIME_SHOULD_BE_AHEAD: ADD_FLOORBOOK_PAGE.START_TIME_SHOULD_BE_AHEAD,
  SAME_TIME_VALIDATION: ADD_FLOORBOOK_PAGE.SAME_TIME_VALIDATION,
  SETTING_TYPE_VALIDATE: STRINGS.SETTING_TYPE_VALIDATE,
  FLOORBOOK_PRIVACY_STATUS_INPUT_REQUIRED: STRINGS.FLOORBOOK_PRIVACY_STATUS_INPUT_REQUIRED,
  JOURNAL_NAME_REQUIRED: STRINGS.JOURNAL_NAME_REQUIRED,
  JOURNAL_NAME_VALIDATE: STRINGS.JOURNAL_NAME_VALIDATE,
  JOURNAL_COVER_REQUIRED: STRINGS.JOURNAL_COVER_REQUIRED,
  JOURNAL_SIGNIN_REQUIRED: STRINGS.JOURNAL_SIGNIN_REQUIRED,
  JOURNAL_DESCRIPTION_REQUIRED: STRINGS.JOURNAL_DESCRIPTION_REQUIRED,
  JOURNAL_PRIVACY_STATUS_REQUIRED: STRINGS.JOURNAL_PRIVACY_STATUS_REQUIRED,

  JOURNAL_TITLE_REQUIRED: ADD_NEW_JOURNAL_ENTRY_STRINGS.JOURNAL_TITLE_REQUIRED,
};

export const ADD_FLOORBOOK_TYPE = {
  create: 0,
  edit: 1,
};
export const ADD_JORNAL_TYPE = {
  create: 0,
  edit: 1,
};
// maximum length for descriptions
export const MAX_DESCRIPTION_LENGTH = 6000;
// maximum length to show less and show more
export const MAX_DESCRIPTION_SHOW_LENGTH = 256;
export const IS_FLOORBOOK = {
  page: 0,
  floorbook: 1,
};
//report types for dropdown
export const Graph_Types = [
  {
    value: 1,
    label: "Location report ",
    type: "graph",
  },
  {
    value: 2,
    label: "Lines of Inquiry report",
    type: "mind map",
  },
  {
    value: 3,
    label: "Subject area report",
    type: "mind map",
  },
  {
    value: 4,
    label: "Reflective practice report ",
    type: "list",
  },
];
export const Floorbook_Category_Types = [
  {
    label: "Favourite Floorbooks",
    value: 1,
  },
  {
    label: "Other Floorbooks",
    value: 2,
  },
];
export const File_Required_Error = "File Required";
// User roles
export const USER_ROLES = {
  ADMIN: 1,
  EDITOR: 3,
  VIEWER: 4,
};

export const FLOORBOOK_CATEGORY_FOR_REPORTS = [
  {
    label: "My Floorbooks",
    value: "1",
  },
  {
    label: "Saved Floorbooks",
    value: "2",
  },
  {
    label: "Other Floorbooks",
    value: "3",
  },
];
