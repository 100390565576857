import { withSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { reduxForm } from "redux-form";
import { HeadTitle, Loader } from "../../../../../components";
import {
  NAME_NUMBER_REGEX,
  USEFUL_RESOURCES,
  VALIDATION_MESSAGES,
  ROUTE_CONSTANTS,
  HEAD_TITLES,
  File_Required_Error,
  STATUS,
} from "../../../../../shared/constants";
import { RenderForm } from "./form";
import "./style.scss";
import { getBase64Image } from "../../../../../shared/services/Utility";

const Screen = ({
  handleSubmit = () => { },
  enqueueSnackbar,
  addUsefulResource,
  loading,
  history,
  getResourceById,
  initialize,
  editUsefulResource,
}) => {
  const [file, set_file] = useState([]);
  const [editMode, updateEditMode] = useState(false);

  const handleFileChange = (value) => {
    set_file(value);
  };

  const [errors, setErrors] = useState({
    file: File_Required_Error,
  });
  const [showError, set_showError] = useState(false);
  const [defaultdata, set_defaultdata] = useState("");
  const useQuery = () => {
    return new URLSearchParams(useLocation().search);
  };
  let query = useQuery();
  let path = history.location.pathname;
  let id = query.get("id");

  useEffect(() => {
    if (path == ROUTE_CONSTANTS.EDIT_RESOURCE) {
      if (id) {
        getResourceById(id, (message, type, data) => {
          getBase64Image(data.resourceFile, `image${1}`, async (value, url) => {
            if (value) {
              value = {
                file: value,
                url: data.resourceFile,
              };
              initialize({
                name: data.title,
                description: data.description,
              });
              set_defaultdata(data.description);
              set_file([value]);
              updateEditMode(true);
            }
          });
        });
      } else {
        history.push(ROUTE_CONSTANTS.HOME);
      }
    }
  }, [path]);

  const submit = (values) => {
    file.length && setErrors("");
    if (!file.length) {
      set_showError(true);
      return;
    }

    /***** api call to add resource ******/
    if (editMode) {
      let data = {
        id: id,
        name: values.name,
        description: values.description,
        resourceFile: file[0].file,
      };

      editUsefulResource(data, (message, type) => {
        enqueueSnackbar(message, {
          variant: type,
          autoHideDuration: 3000,
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "left",
          },
        });
        if (type === STATUS.success) {
          history.push(ROUTE_CONSTANTS.USEFUL_RESOURCES);
        }
      });
    } else {
      let data = {
        name: values.name,
        description: values.description,
        resourceFile: file[0].file,
      };
      addUsefulResource(data, (message, type) => {
        if (type === STATUS.error) {
          enqueueSnackbar(message, {
            variant: type,
            autoHideDuration: 3000,
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "left",
            },
          });
        } else if (type === STATUS.success) {
          history.push(ROUTE_CONSTANTS.USEFUL_RESOURCES);
        }
      });
    }
  };

  return (
    <div>
      <div className="card-header d-flex flex-column-reverse flex-xl-row justify-content-center justify-content-xl-between align-items-center">
        {/* <HeadTitle
          title={HEAD_TITLES.USEFUL_RESOURCES}
          titleCursor={true}
          titleOnClick={() => history.push(ROUTE_CONSTANTS.USEFUL_RESOURCES)}
          subTitle={[{ label: editMode ? "Edit Resource" : "Add New" }]}
        /> */}
      </div>
      <div className="card-body pt-0">
        <div className="px-2">
          <RenderForm
            handleSubmit={handleSubmit}
            handleFileChange={handleFileChange}
            file={file}
            submit={submit}
            showError={showError}
            errors={errors}
            editMode={editMode}
            defaultdata={defaultdata}
          />
        </div>
      </div>
      {loading && <Loader />}
    </div>
  );
};

const validator = (values) => {
  const errors = {};
  if (!values[USEFUL_RESOURCES.NAME_INPUT]) {
    errors[USEFUL_RESOURCES.NAME_INPUT] = VALIDATION_MESSAGES.FLOORBOOK_NAME_REQUIRED;
  }
  if (values[USEFUL_RESOURCES.NAME_INPUT] && !NAME_NUMBER_REGEX.test(values[USEFUL_RESOURCES.NAME_INPUT].trim())) {
    errors[USEFUL_RESOURCES.NAME_INPUT] = VALIDATION_MESSAGES.FLOORBOOK_NAME_VALIDATE;
  }
  if (!values[USEFUL_RESOURCES.DESCRIPTION_INPUT]) {
    errors[USEFUL_RESOURCES.DESCRIPTION_INPUT] = VALIDATION_MESSAGES.FLOORBOOK_DESCRIPTION_REQUIRED;
  } else if (!values[USEFUL_RESOURCES.DESCRIPTION_INPUT].trim()) {
    errors[USEFUL_RESOURCES.DESCRIPTION_INPUT] = VALIDATION_MESSAGES.FLOORBOOK_DESCRIPTION_REQUIRED;
  }
  return errors;
};

let showForm = reduxForm({
  form: "AddUsefulResource",
  validate: validator,
})(Screen);

export default withSnackbar(showForm);
