import { connect } from "react-redux";
import {
  getComments,
  saveFloorbookComment,
  deleteFloorbookComment,
  deleteFloorbook_PageComment,
  editFloorbookComment,
  editFloorbookPageComment,
  addCommentLike,
  addCommentReply
} from "../../../redux/actions";
import CommentBox from "./comments";

const mapStateToProps = (state) => {
  return {
    state,
    loading: state.CommonReducer.isLoading,
    userRole: state.CommonReducer.userData.user_role,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getComments: (data, callback) => dispatch(getComments(data, callback)),
    deleteFloorbook_PageComment: (data, callback) => dispatch(deleteFloorbook_PageComment(data, callback)),
    saveFloorbookComment: (data, callback) => dispatch(saveFloorbookComment(data, callback)),
    deleteFloorbookComment: (data, callback) => dispatch(deleteFloorbookComment(data, callback)),
    editFloorbookComment: (data,callback) => dispatch(editFloorbookComment(data,callback)),
    editFloorbookPageComment: (data, callback) => dispatch(editFloorbookPageComment(data, callback)),
    addCommentLike:(data,callback) => dispatch(addCommentLike(data,callback)),
    addCommentReply:(data,callback)=> dispatch(addCommentReply(data,callback))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CommentBox);
