import { VALIDATION_MESSAGES,STRINGS } from "../../../shared/constants";

const validator = (values) => {
  const errors = {};

  if (!values[STRINGS.JOURNAL_NAME_INPUT]) {
    errors[STRINGS.JOURNAL_NAME_INPUT] = VALIDATION_MESSAGES.JOURNAL_NAME_REQUIRED;
  }
  // if (values[STRINGS.JOURNAL_NAME_INPUT] && !NAME_NUMBER_REGEX.test(values[STRINGS.FLOORBOOK_NAME_INPUT].trim())) {
  //   errors[STRINGS.JOURNAL_NAME_INPUT] = VALIDATION_MESSAGES.FLOORBOOK_NAME_VALIDATE;
  // }
  if (!values[STRINGS.JOURNAL_SIGNIN_INPUT]) {
    errors[STRINGS.FLOORBOOK_SIGNIN_INPUT] = VALIDATION_MESSAGES.JOURNAL_SIGNIN_REQUIRED;
  }
  if (!values[STRINGS.JOURNAL_COVER_INPUT]) {
    errors[STRINGS.JOURNAL_COVER_INPUT] = VALIDATION_MESSAGES.JOURNAL_COVER_REQUIRED;
  }
  if (!values[STRINGS.JOURNAL_PRIVACY_STATUS_INPUT]) {
    errors[STRINGS.JOURNAL_PRIVACY_STATUS_INPUT] = VALIDATION_MESSAGES.JOURNAL_PRIVACY_STATUS_REQUIRED;
  }
  if (!values[STRINGS.JOURNAL_DESCRIPTION_INPUT]) {
    errors[STRINGS.JOURNAL_DESCRIPTION_INPUT] = VALIDATION_MESSAGES.JOURNAL_DESCRIPTION_REQUIRED;
  } else if (!values[STRINGS.JOURNAL_DESCRIPTION_INPUT].trim()) {
    errors[STRINGS.JOURNAL_DESCRIPTION_INPUT] = VALIDATION_MESSAGES.JOURNAL_DESCRIPTION_REQUIRED;
  }
  return errors;
};

export default validator;
