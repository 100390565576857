import {
  ADD_USEFUL_RESOURCE,
  GET_USEFUL_RESOURCES_DATA,
  SET_USEFUL_RESOURCES_DATA,
  IS_ADMIN,
  GET_USEFUL_RESOURCE_DETAIL,
  SET_USEFUL_RESOURCE_DETAIL,
  DELETE_USEFUL_RESOURCE,
  EDIT_USEFUL_RESOURCE,
  GET_RESOURCE_BY_ID,
} from "./ActionTypes";

export const getResourceById = (data, callback) => {
  return {
    type: GET_RESOURCE_BY_ID,
    data,
    callback,
  };
};
export const deleteUsefulResource = (data, callback) => {
  return {
    type: DELETE_USEFUL_RESOURCE,
    data,
    callback,
  };
};
export const editUsefulResource = (data, callback) => {
  return {
    type: EDIT_USEFUL_RESOURCE,
    data,
    callback,
  };
};
export const getUsefulResources = (callback) => {
  return {
    type: GET_USEFUL_RESOURCES_DATA,
    callback,
  };
};

export const setUsefulResources = (data) => {
  return {
    type: SET_USEFUL_RESOURCES_DATA,
    data,
  };
};

export const addUsefulResource = (data, callback) => {
  return {
    type: ADD_USEFUL_RESOURCE,
    data,
    callback,
  };
};
export const isAdmin = (data) => {
  return {
    type: IS_ADMIN,
    data,
  };
};
export const getUsefulResourceDetail = (data, callback) => {
  return {
    type: GET_USEFUL_RESOURCE_DETAIL,
    data,
    callback,
  };
};
export const setUsefulResourceDetail = (data) => {
  return {
    type: SET_USEFUL_RESOURCE_DETAIL,
    data,
  };
};
