import React, { useEffect, useState } from "react";
import moment from "moment";
import ChartDataLabels from "chartjs-plugin-datalabels";
import Chart from "chart.js";
import { useHistory } from "react-router-dom";
import { ROUTE_CONSTANTS, REPORTS_STRINGS, STATUS, Graph_Types } from "../../../../shared/constants";
import { ImageOverlay, KeyboardDateTimePickerr } from "../../../../components";
import { Field, reduxForm } from "redux-form";
import html2pdf from "html2pdf.js";

const Screen = ({
  allPages,
  floorbookDetails,
  saveCompletionData = () => { },
  floorbookType,
  redirectToFloorbookDetailPage = () => { },
}) => {
  const [completionDate, set_completionDate] = useState("");
  const [pageId, set_pageId] = useState("");
  const pageGap = allPages && allPages[0] && allPages[0].is_owner ? 3 : 4; //page break after 3 for my floobooks and 4 for favourite and other floorbooks
  useEffect(() => {
    if (completionDate && pageId) {
      let check = document.getElementById(pageId).checked;
      let time = new Date(completionDate).getTime();
      if (time && pageId && check) {
        let data = {
          page_id: pageId,
          is_checked: check,
          completion_date: time,
        };
        saveCompletionData(data, (response, status) => {
          if (status === STATUS.success) {
            let idx = allPages.findIndex((page) => page.id === pageId);
            allPages.splice(idx, 1, { ...allPages[idx], completion_date: time });
          }
        });
      }
    }
  }, [completionDate]);

  const setData = (value, id) => {
    set_completionDate(value);
    set_pageId(id);
  };
  //reflective practice report
  const history = useHistory();
  return (
    <>
      <div className="row" id="divToPDF" style={{ padding: 15 }}>
        <div className="col-12">
          <h4 className="text-center p-2">{REPORTS_STRINGS.LABEL_REFLECTIVE_PRACTICE}</h4>
          <p className="text-center">
            Created By:-
            <strong> {floorbookDetails.user_name}</strong> Floorbook{" "}
            <strong style={{ cursor: "pointer" }} onClick={() => redirectToFloorbookDetailPage()}>
              {floorbookDetails.floorbook_name}
            </strong>
          </p>
        </div>
        <div className="col-12">
          <div className="floorbook_list">
            {allPages.length
              ? allPages.map((page, idx) => (
                <div
                  className={
                    (idx + 1) % pageGap === 0 ? "floorbook_block html2pdf__page-break afterClass" : "floorbook_block"
                  }
                  key={idx}
                >
                  {page.is_owner ? (
                    <CheckListBox page={page} setData={setData} />
                  ) : page.completion_date ? (
                    <p className="reports_status">
                      Completed on <strong>{moment(page.completion_date).format("DD-MMM-YY")}</strong>
                    </p>
                  ) : (
                    <p className="reports_status">Not yet Completed</p>
                  )}
                  <div className="row">
                    <div className="col-xl-3 col-md-4">
                      {page.images && (
                        <figure
                          className="post_thumb"
                          onClick={() =>
                            history.push({
                              pathname: ROUTE_CONSTANTS.FLOORBOOK_PAGE_DETAILS,
                              search: `page_id=${page.id}`,
                              state: {
                                from: history.location.pathname,
                                floorbookType: floorbookType,
                                reportType: 4,
                              },
                            })
                          }
                          style={{ cursor: "pointer" }}
                        >
                          <ImageOverlay>
                            <img src={page.images[0]} alt="" width="100%" height={250} />
                          </ImageOverlay>
                        </figure>
                      )}
                    </div>{" "}
                    <div className="col-xl-9 col-md-8 summary_txt">
                      <span>
                        <i className="mr-2">
                          <img src={require("../../../../assets/images/icons/calendar_icon.svg")} width="18" alt="" />
                        </i>
                        <strong>{moment(page.activity_from).format("DD MMM YYYY")}</strong>
                      </span>
                      <p className="mt-2">
                        Name:
                        <strong
                          onClick={() =>
                            history.push({
                              pathname: ROUTE_CONSTANTS.FLOORBOOK_PAGE_DETAILS,
                              search: `page_id=${page.id}`,
                              state: {
                                from: history.location.pathname,
                                floorbookType: floorbookType,
                                reportType: 4,
                              },
                            })
                          }
                          style={{ cursor: "pointer" }}
                        >
                          {" "}
                          {page.name}
                        </strong>
                      </p>
                      <p>{page.improvement}</p>
                    </div>
                  </div>
                </div>
              ))
              : null}
          </div>
        </div>
      </div>
    </>
  );
};
export const ReflectiveReport = reduxForm({
  form: "reflective",
})(Screen);

//location report
export const ActivityLocationReport = ({ redirectToFloorbookDetailPage, Pages ,name}) => {
  // returns a canvas component on which graph will be painted
  return (
    <div className="row" style={{ overflow: "scroll" }}>
      <div id="divToPDF" style={{ padding: "0px 15px", minWidth: 1248, minHeight: 270 }}>
        <p className="text-center">{Graph_Types[0].label}  , {name}</p>
        <canvas id="LocationGraph" width={"100%"} height={40} />
      </div>
    </div>
  );
};

export const drawGraph = (ctx, Pages, redirectToFloorbookDetailPage) => {
  // drawGrap with reference to the canvas and pages data
  var grade = ctx.getContext("2d").createLinearGradient(0, 0, 0, 400);
  grade.addColorStop(0, "rgba(6, 100, 32, 0.5)");
  grade.addColorStop(1, "rgba(6, 100, 32, 0.9)");
  if (window.chart != undefined) window.chart.destroy();
  let gData = Pages.map((page) => {
    return { x: moment(page.activity_from).format("DD MMM"), y: page.location_activity * 10 - 5, label: "hello" };
  });
  let xLabels = [];
  Pages.forEach((page) => {
    if (!xLabels.includes(moment(page.activity_from).format("DD MMM"))) {
      xLabels.push(moment(page.activity_from).format("DD MMM"));
    }
  });

  /************** tipLabels formatting **************/
  let tipLabels = [];
  let j = 0;
  let flag = 0;
  for (let i = 0; i < Pages.length; i++) {
    if (i === 0) {
      tipLabels[j] = Pages[0].name.length > 20 ? `${Pages[0].name.slice(0, 20)}...` : Pages[0].name;
      j++;
    } else if (
      Pages[i].activity_from === Pages[i - 1].activity_from &&
      Pages[i].location_activity === Pages[i - 1].location_activity
    ) {
      tipLabels[j - 1] = ` ${tipLabels[j - 1]}, \n ${Pages[i].name.length > 20 ? `${Pages[i].name.slice(0, 20)}...` : Pages[i].name
        }`;
      tipLabels[j + flag] = "";
      flag++;
    } else {
      j += flag;
      flag = 0;
      tipLabels[j] = Pages[i].name.length > 20 ? `${Pages[i].name.slice(0, 20)}...` : Pages[i].name;
      j++;
    }
  }
  /****************************************************/
  window.chart = new Chart(ctx, {
    type: "line",
    plugins: [ChartDataLabels],
    data: {
      labels: [...xLabels], //[...Pages.map((page) => moment(page.activity_from).format("DD MMM"))], //["5 oct", "11 oct", "12 oct", "13oct", "14 Oct", "16 oct"],
      datasets: [
        {
          labels: [...tipLabels],
          lineTension: 0,
          label: "Location of Activity",
          // data: [...Pages.map((page) => page.location_activity * 10 - 5)],
          data: [...gData],
          fill: true,
          pointBackgroundColor: "rgba(0, 113, 0, 1)",
          pointRadius: 5,
          backgroundColor: grade,
          borderColor: ["rgba(55, 113, 81, 0.7)"],
          borderWidth: 1,
          minBarLength: 30,
        },
      ],
    },
    options: {
      tooltips: {
        enabled: false,
      },
      onClick: () => {
        //redirect from  location report on that Floorbook
        redirectToFloorbookDetailPage();
      },
      plugins: {
        datalabels: {
          rotation: 320,
          anchor: Pages.length > 1 ? "right" : "top",
          align: Pages.length > 1 ? "right" : "top",
          offset: Pages.length > 1 ? -45 : 1,
          formatter: function (value, context) {
            return context.dataset.labels[context.dataIndex];
          },
          color: "black",
          labels: {
            title: {
              font: {
                size: 14,
                // weight: "bold",
              },
            },
          },
        },
      },
      title: {
        display: true,
        text:
          "Inside\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0Outside\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0Beyond",
        position: "left",
        fontSize: 16,
        padding: 0,
      },
      scales: {
        display: false,
        xAxes: [
          {
            gridLines: {
              lineWidth: 2,
              drawOnChartArea: false,
              drawBorder: true,
              color: "rgba(55, 113, 81, 1)",
              drawTicks: true,
            },
            ticks: {
              stepSize: 200,
              beginAtZero: true,
            },
          },
        ],
        yAxes: [
          {
            gridLines: {
              drawOnChartArea: false,
              lineWidth: 2,
              color: "rgba(55, 113, 81, 1)",
            },
            ticks: {
              stepSize: 10,
              display: false,
              labelOffset: 0,
              beginAtZero: true,
              max: 30,
            },
          },
        ],
      },
      legend: {
        display: false,
      },
      layout: {
        padding: {
          top: 50,
          right: 100,
          left: 40,
        },
      },
      animation: {
        onComplete: chartToUrl,
      },
    },
  });
};

const chartToUrl = () => {
  console.log(window.chart.toBase64Image());
};

const CheckListBox = ({ idx, page, setData }) => {
  return (
    <div className="row mb-3">
      <div className="col-sm-9">
        <div className="form-group text-left">
          <input
            type="checkbox"
            id={page.id}
            defaultChecked={Boolean(page.completion_date)}
            style={{ width: "20px", height: "25px", cursor: "pointer" }}
          />
          <label className="mb-0" htmlFor={page.id}>
            {page.completion_date
              ? // document.getElementById(page.id) && document.getElementById(page.id).checked
              REPORTS_STRINGS.LABEL_COMPLETED
              : REPORTS_STRINGS.LABEL_UNCOMPLETE}
          </label>
        </div>
      </div>
      <div className="col-sm-3">
        <div className="form-group date_picker">
          <label>{REPORTS_STRINGS.SELECT_COMPLETION_LABEL}</label>
          <Field
            name={REPORTS_STRINGS.COMPLETION_DATE_INPUT}
            component={KeyboardDateTimePickerr}
            defaultValue={page ? page.completion_date : ""}
            onValueChange={(value) => {
              setData(value, page.id);
            }}
          />
        </div>
      </div>
    </div>
  );
};

export const printDocument = (reportLabel, floorbookLabel) => {
  const input = document.getElementById("divToPDF");

  var opt = {
    margin: 6,
    filename: "report.pdf",
    image: { type: "jpeg", quality: 0.98 },
    html2canvas: { letterRendering: true, useCORS: true, scrollX: 0, scrollY: 0 },
    jsPDF: {
      unit: "mm",
      format: "ledger",
      orientation: reportLabel.localeCompare(Graph_Types[3].label) === 0 ? "portrait" : "landscape",
    },
    pagebreak: { mode: ["css", "legacy"], after: ".afterClass" },
  };
  html2pdf().from(input).set(opt).save();
};
