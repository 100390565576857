import {
  SAVE_SIGNUP_FORM_ONE,
  GET_DROPDOWN_DATA,
  SAVE_COUNTRY_DATA,
  SAVE_SETTING_TYPE_DATA,
  SAVE_CITY_DATA,
  SIGN_UP_FOR_NEW_USER,
  SAVE_SIGNUP_FORM_TWO,
  SAVE_SCOTTISH_LOCAL_AUTHORITY_DROPDOWN,
  GET_SCOTTISH_LOCAL_AUTHORITY_DROPDOWN,
} from "./ActionTypes";

export const getDropDownData = (callback) => {
  return {
    type: GET_DROPDOWN_DATA,
    callback,
  };
};
export const saveSignUPFormOne = (data) => {
  return {
    type: SAVE_SIGNUP_FORM_ONE,
    data,
  };
};
export const saveCountryData = (data) => {
  return {
    type: SAVE_COUNTRY_DATA,
    data,
  };
};
export const saveCityData = (data) => {
  return {
    type: SAVE_CITY_DATA,
    data,
  };
};
export const saveSettingTypeData = (data) => {
  return {
    type: SAVE_SETTING_TYPE_DATA,
    data,
  };
};
export const signUpForNewUser = (data, callback) => {
  return {
    type: SIGN_UP_FOR_NEW_USER,
    data,
    callback,
  };
};
export const saveSignUpFormTwo = (data) => {
  return {
    type: SAVE_SIGNUP_FORM_TWO,
    data,
  };
};
export const getScottishLocalAuthorities = (callback) => {
  return {
    type: GET_SCOTTISH_LOCAL_AUTHORITY_DROPDOWN,
    callback,
  };
};

export const saveScottishLocalAuthorities = (data) => {
  return {
    type: SAVE_SCOTTISH_LOCAL_AUTHORITY_DROPDOWN,
    data,
  };
};
