export const STRINGS = {
  SIGN_UP_LABEL: "Sign Up",
  REMEMBER_ME_LABEL: "Remember me",
  FORGOT_PASSWORD_LABEL: "Forgot Password?",
  EMAIL_INPUT_NAME: "email",
  FIRST_NAME_INPUT: "firstName",
  LAST_NAME_INPUT: "lastName",
  SETTING_TYPE_INPUT: "settingType",
  SETTING_OTHER_TYPE_INPUT: "otherType",
  SETTING_NAME_INPUT: "settingName",
  COUNTRY_INPUT: "country",
  SCOTTISH_LOCAL_AUTHORITY_INPUT: "scottishLocal",
  CITY_INPUT: "city",
  DESCRIPTION_OF_CLASS_AND_AGE_INPUT: "description",
  EMAIL_REQUIRED: "Email is required.",
  USER_ALREADY_EXIST: "User already exist",
  EMAIL_PLACEHOLDER: "Email",
  PASSWORD_PLACEHOLDER: "Password",
  OLD_PASSWORD_PLACEHOLDER: "Old Password",
  NEW_PASSWORD_PLACEHOLDER: "New Password",
  FIRST_NAME_PLACEHOLDER: "First Name",
  LAST_NAME_PLACEHOLDER: "Last Name",
  CONFIRM_PASSWORD_PLACEHOLDER: "Confirm Password",
  PASSWORD_INPUT_NAME: "password",
  OLD_PASSWORD_INPUT: "oldPassword",
  NEW_PASSWORD_INPUT: "newPassword",
  CONFIRM_PASSWORD_INPUT: "confirmPassword",
  PASSWORD_REQUIRED: "Password is required.",
  EMAIL_LABEL: "Email",
  PASSWORD_LABEL: "Password",
  FIRST_NAME_LABEL: "First Name",
  LAST_NAME_LABEL: "Last Name",
  CAPTCHA_LABEL: "Enter Capture",
  CAPTCHA_INPUT: "captcha",
  CAPTURE_PLACEHOLDER: "Enter Capture",
  SETTING_TYPE_PLACEHOLDER: "Setting Type",
  SETTING_NAME_LABEL: "Name of Setting",
  SETTING_TYPE_LABEL: "Type of Setting",
  SETTING_OTHER_TYPE_LABEL: "Other type of setting",
  SETTING_NAME_PLACEHOLDER: "Name of Setting",
  OLD_PASSWORD: "Old Password",
  NEW_PASSWORD: "New Password",
  CONFIRM_PASSWORD_LABEL: "Confirm Password",
  COUNTRY_LABEL: "Country",
  SCOTTISH_LOCAL_AUTHORITY_LABEL: "Scottish Local Authority",
  CITY_LABEL: "City or Town or Village",
  DESCRIPTION_OF_CLASS_AND_AGE_LABEL: "Your bio",
  DESCRIPTION_OF_CLASS_AND_AGE_LABEL_PLACEHOLDER: "For example, describe your class and ages of children",
  EMAIL_INVALID: "Email is invalid.",
  FIRST_NAME_REQUIRED: "First Name Required",
  LAST_NAME_REQUIRED: "Last Name Required",
  PASSWORD_VALIDATE: "Password must have a minimum of 4 characters.",
  PASSWORD_SUCCESSFUL: "Password has been changed successfully.",
  FIRST_NAME_VALIDATE: "No special characters can be used here, please try again.",
  LAST_NAME_VALIDATE: "No special characters can be used here, please try again.",
  CONFIRM_PASSWORD_VALIDATE: "Passwords don’t match. Please try again.",
  COUNTRY_REQUIRED: "Country Name Required",
  CITY_REQUIRED: "City Name Required",
  CITY_VALIDATE: "No special characters can be used here, please try again.",
  SETTING_NAME_REQUIRED: "Setting Name Required",
  SETTING_TYPE_REQUIRED: "Setting Type Required",
  SETTING_OTHER_TYPE_REQUIRED: "Enter other type of setting",
  SETTING_TYPE_VALIDATE: "No special characters can be used here, please try again.",
  DESCRIPTION_OF_CLASS_AND_AGE_REQUIRED: "Description Required",
  FLOORBOOK_NAME_LABEL: "Floorbook Name",
  FLOORBOOK_COVER: "Floorbook Cover",
  FLOORBOOK_SIGN_IN_IMAGE: "Floorbook Sign in Page",
  FLOORBOOK_NAME_PLACEHOLDER: "Floorbook Name",
  FLOORBOOK_NAME_INPUT: "floorbookName",
  FLOORBOOK_COVER_INPUT: "floorbookCoverImage",
  FLOORBOOK_COVER_LABEL: "Floorbook cover",
  FLOORBOOK_SIGNIN_INPUT: "floorbookSignInImage",
  FLOORBOOK_SIGNIN_LABEL: "Floorbook sign in page",
  FLOORBOOK_DESCRIPTION_LABEL: "Floorbook description",
  FLOORBOOK_DESCRIPTION_INPUT: "floorbookDescription",
  FLOORBOOK_DESCRIPTION_PLACEHOLDER: "Description",
  FLOORBOOK_NAME_REQUIRED: "Floorbook Name Required",
  FLOORBOOK_NAME_VALIDATE: "No special characters can be used here, please try again.",
  FLOORBOOK_COVER_REQUIRED: "Cover Image Required",
  FLOORBOOK_SIGNIN_REQUIRED: "Image Required",
  FLOORBOOK_DESCRIPTION_REQUIRED: "Description Required",
  FLOORBOOK_PRIVACY_STATUS_LABEL: "Who would you like to view this Floorbook?",
  FLOORBOOK_PRIVACY_STATUS_INPUT: "privacyStatus",
  FLOORBOOK_PRIVACY_STATUS_INPUT_REQUIRED: "Required",
  SUBJECT_AREA_GROUP_LABEL: "Choose a group for Subject ",
  SUBJECT_AREA_GROUP_FIELD: "subjectAreaGroupField",
  PAGE_TITLE_REQUIRED: "Page Title Required",
  JOURNAL_NAME_LABEL: "Group Learning Journal Name",
  JOURNAL_COVER: "Group Learning Journal Cover",
  JOURNAL_SIGN_IN_IMAGE: "Group Learning Journal Sign in Page",
  JOURNAL_NAME_PLACEHOLDER: "Group Learning Journal Name",
  JOURNAL_NAME_INPUT: "JournalName",
  JOURNAL_COVER_INPUT: "JournalCoverImage",
  JOURNAL_COVER_LABEL: "Group Learning Journal cover",
  JOURNAL_SIGNIN_INPUT: "JournalSignInImage",
  JOURNAL_SIGNIN_LABEL: "Group Learning Journal sign in page",
  JOURNAL_DESCRIPTION_LABEL: "Group Learning Journal description",
  JOURNAL_DESCRIPTION_INPUT: "JournalDescription",
  JOURNAL_DESCRIPTION_PLACEHOLDER: "Description",
  JOURNAL_NAME_REQUIRED: "Group Learning Journal Name Required",
  JOURNAL_NAME_VALIDATE: "No special characters can be used here, please try again.",
  JOURNAL_COVER_REQUIRED: "Cover Image Required",
  JOURNAL_SIGNIN_REQUIRED: "Image Required",
  JOURNAL_DESCRIPTION_REQUIRED: "Description Required",
  JOURNAL_PRIVACY_STATUS_LABEL: "Who would you like to view this Journal?",
  JOURNAL_PRIVACY_STATUS_INPUT: "privacyStatus",
  JOURNAL_PRIVACY_STATUS_INPUT_REQUIRED: "Privacy Status Required",
  CAPTCHA_REQUIRED: "Required",
  CAPTCHA_VALIDATE: "Incorrect, please try again",
  SELECT_PLACEHOLDER: "Select",
  RESET_PASSWORD_LABEL: "Reset Password",
  VERIFICATION_LABEL: "Verification",
  OTP_REQIURED: "Enter One Time Password",
  OTHER_TYPE_PLACEHOLDER: "Type here",
  COUNTRY_PLACEHOLDER: "Country",
  SCOTTISH_LOCAL_AUTHORITY_PLACEHOLDER: "Scottish Local Authority",
  CITY_PLACEHOLDER: "City",
};

export const INPUT_TYPE = {
  password: "password",
};

export const NO_DATA_MESSAGES = {
  NO_MY_FLOORBOOK: `No my Floorbooks`,
  YOU_CAN_ADD_TO_VIEW_HERE: "Click on the Add Floorbook, to see them here.",
  NO_SAVED_FLOORBOOK: `No saved Floorbooks,`,
  NO_FLOORBOOK: "No All Floorbooks.",
  NO_SAVED_JOURNAL: `No saved Group Learning Journals,`,
  NO_JOURNAL: "No All Group Learning Journals",
  NO_MY_JOURNALS: "No my Group Learning Journals",
};
// head titles for breadcrumbs
export const HEAD_TITLES = {
  PROFILE: "Profile",
  HOME: "Home",
  FLOORBOOK: `Floorbooks`,
  HELP: "Help",
  USEFUL_RESOURCES: "Useful Resources",
  SETTINGS: "Settings",
  REPORT: "Reports",
  HISTORY: "History",
  LEARNING_JOURNALS: "Group Learning Journals",
  LATEST_COMMENTS: "Latest Comments",
  SAVED_JOURNALS: "Saved Group Learning Journals",
  BACK: "Back",
  LATEST_POSTS: "Latest Posts",
  LIKES: "Likes",
  COMMENTS: "Comments",
  MY_FLOORBOOKS: "My Floorbooks",
  SAVED_FLOORBOOKS: "Saved Floorbooks",
  ALL_FLOORBOOKS: "All Floorbooks",
  LOGOUT: "Log out",
  MY_JOURNALS: "My Group Learning Journals",
  ALL_JOURNALS: "All Group Learning Journals",
  ALL: "All",
  ADD_NEW_LEARNING_JOURNAL_ENTRY: "Add New Learning Journals Entry",
  EDIT_NEW_LEARNING_JOURNAL_ENTRY: "Edit New Learning Journals Entry",
  ADD_NEW_FLOORBOOK_PAGE: "Add new floorbook entry",
  EDIT_FLOORBOOK_PAGE: "Edit Floorbook entry",
};

export const ADD_FLOORBOOK_PAGE = {
  FROM_PLACEHOLDER: "From",
  TO_PLACEHOLDER: "To",
  FLOORBOOK_LABEL: "Select Floorbook",
  FROM_DATE_LABEL: "From",
  TO_DATE_LABEL: "To",
  TITLE_LABEL: "Page Title",
  LINE_OF_INQUIRY_LABEL: "Add line of inquiry",
  OTHER_TYPE_LABEL: " Other Line of inquiry",
  DATE_LABEL: "Date of Activity",
  DESCRIPTION_LABEL: "Description",
  LOCATION_LABEL: "Location of Activity",
  SUBJECT_LABEL: "Subject Area",
  REFLECTIONS_LABEL: "Areas for improvement/reflections",
  REFLECTIONS_PLACEHOLDER: "Add Reflections",
  SUBJECT_PLACEHOLDER: "Add New Subject",
  DESCRIPTION_PLACEHOLDER: "Add description",
  TITLE_PLACEHOLDER: "Add Title",
  FLOORBOOK_INPUT: "floorbookName",
  TITLE_INPUT: "title",
  LINE_OF_INQUIRY_INPUT: "lineOfInquiry",
  OTHER_TYPE_INPUT: "other_lineOfInquiry",
  START_DATE_INPUT: "startDate",
  END_DATE_INPUT: "endDate",
  DESCRIPTION_INPUT: "description",
  LOCATION_INPUT: "location",
  SUBJECT_INPUT: "subject",
  REFLECTIONS_INPUT: "reflections",
  FLOORBOOK_TITLE_REQUIRED: "Floorbook title required",
  FLOORBOOK_REQUIRED: "Floorbook Required",
  LINE_OF_INQUIRY_REQUIRED: "Required",
  OTHER_LINE_OF_INQUIRY_REQUIRED: "Required",
  FROM_DATE_REQUIRED: "Required",
  END_DATE_REQUIRED: "Required",
  DESCRIPTION_REQUIRED: "Description Required",
  LOCATION_REQUIRED: "Location Required",
  SUBJECT_REQUIRED: "Subject Required",
  REFLECTIONS_REQUIRED: "Required",
  FROM_DATE_VALIDATE: "",
  END_DATE_VALIDATE: "",
  TIME_SHOUDLD_NOT_BE_IN_PAST: "Time should not be  in past",
  SELECT_START_TIME_FIRST: "Select Start Time First",
  START_TIME_SHOULD_BE_AHEAD: "Start Date should be before end Date",
  SAME_TIME_VALIDATION: "Start Date and end Date cannot be same.",
  DISCARDED_FORM_WARNING: "Your changes will not be saved, do you want to continue?",
};

export const ADD_NEW_JOURNAL_ENTRY_STRINGS = {
  //LABELS
  TITLE_LABEL: "Title",
  LINE_OF_INQUIRY_LABEL: "Add line of inquiry",
  OTHER_TYPE_LABEL: " Other Line of inquiry",
  DATE_LABEL: "Date of Activity",
  DESCRIPTION_LABEL: "Description",
  LOCATION_LABEL: "Location of Activity",
  SUBJECT_LABEL: "Subject Area",
  REFLECTIONS_LABEL: "Areas for improvement/reflections",
  FROM_DATE_LABEL: "From",
  TO_DATE_LABEL: "To",
  //PLACEHOLDERS
  TITLE_PLACEHOLDER: "Add Title",
  DESCRIPTION_PLACEHOLDER: "Add description",
  SUBJECT_PLACEHOLDER: "Add New Subject",
  FROM_PLACEHOLDER: "From",
  TO_PLACEHOLDER: "To",
  REFLECTIONS_PLACEHOLDER: "Add Reflections",
  //INPUTS
  TITLE_INPUT: "title",
  JOURNAL_INPUT:'journalName',
  LINE_OF_INQUIRY_INPUT: "lineOfInquiry",
  OTHER_TYPE_INPUT: "other_lineOfInquiry",
  START_DATE_INPUT: "startDate",
  END_DATE_INPUT: "endDate",
  DESCRIPTION_INPUT: "description",
  LOCATION_INPUT: "location",
  SUBJECT_INPUT: "subject",
  REFLECTIONS_INPUT: "reflections",
  //VALIDATIONS
  JOURNAL_TITLE_REQUIRED: " Title required",
  LINE_OF_INQUIRY_REQUIRED: "Required",
  OTHER_LINE_OF_INQUIRY_REQUIRED: "Required",
  FROM_DATE_REQUIRED: "Required",
  END_DATE_REQUIRED: "Required",
  DESCRIPTION_REQUIRED: "Description Required",
  LOCATION_REQUIRED: "Location Required",
  SUBJECT_REQUIRED: "Subject Required",
  REFLECTIONS_REQUIRED: "Required",
  FROM_DATE_VALIDATE: "",
  END_DATE_VALIDATE: "",
  TIME_SHOUDLD_NOT_BE_IN_PAST: "Time should not be  in past",
  SELECT_START_TIME_FIRST: "Select Start Time First",
  START_TIME_SHOULD_BE_AHEAD: "Start Date should be before end Date",
  SAME_TIME_VALIDATION: "Start Date and end Date cannot be same.",
  DISCARDED_FORM_WARNING: "Your changes will not be saved, do you want to continue?",
};
export const NOTIFICATION = {
  NOTIFICATION_LABEL: "Notification",
  NOTIFICATION_SOUND_LABEL: "Notification Sound",
};
// labels and validation  for resources
export const USEFUL_RESOURCES = {
  UPLOAD_LABEL: "Upload File",
  NAME_LABEL: "Name",
  DESCRIPTIONS_LABEL: "Description",
  NAME_PLACEHOLDER: "Name",
  DESCRIPTIONS_PLACEHOLDER: "Description",
  NAME_REQUIRED: "Required",
  DESCRIPTION_REQUIRED: "REQUIRED",
  NAME_INPUT: "name",
  DESCRIPTION_INPUT: "description",
};
// labels for reports
export const REPORT = {
  FLOORBOOK_LABEL: "Select Floorbook",
  FLOORBOOK_N_JOURNAL_LABEL: "Select Floorbook/Group Learning Journal",
  CATEGORY_LABEL: "Select Report",
  FLOORBOOK_INPUT: "floorbook",
  CATEGORY_INPUT: "category",
  FLOORBOOK_CATEGORY: "Floorbook Category",
  FLOORBOOK_CATEGORY_INPUT: "floorbookCategory",
};
export const ACTIVITY_LOCATIONS = { 1: "Inside", 2: "Outside", 3: "Beyond" };

export const File_Required_Error = "File Required";
export const ADD_FLOORBOOK_MESSAGES = {
  COVER_IMAGE: "Cover Image Required",
  SIGN_IN_IMAGE: "Image Required",
};

export const ADD_NEW_JOURNAL_MESSAGES = {
  COVER_IMAGE: "Cover Image Required",
  SIGN_IN_IMAGE: "Image Required",
};
//labels for radio btns in  add floorbook modal to choose whether the floorbook is public or private
export const PRIVACY_STATUS_LABELS = [
  { label: "Public - Other  practitioners using the Inquiry Tracker", value: "0" },
  { label: "Private - Only me and the Inquiry Tracker admins/Instructors", value: "1" },
];
/*label for radio btns in add floorbook modal to choose subject area group [only that group options will be shown in the 
select subject area field*/
export const SUBJECT_AREA_GROUP_LABELS = [
  {
    label: "Curriculum for excellence",
    value: "0",
  },
  {
    label: "Schema (0 - 2 year olds)",
    value: "1",
  },
];

export const SNACKBAR_FILECHECKS = {
  Video_10Mb: "Video size not to exceed 10 Mb.",
  Image_5Mb: "`Images size not to exceed 5 Mb.`",
};
export const BUTTON_LABELS = {
  ADD_NEW: "Add New",
  ADD_NEW_RESOURCE: "Add New Resource",
  ADD_NEW_FLOORBOOK: "Add New Floorbook",
  ADD_NEW_JOURNAL: "Add New Group Learning Journal ",
  ADD_NEW_PAGE: "Add New Page",
  NOTIFICATION: "Notification",
  CLEAR_ALL: "Clear All",
  VIEW_ALL_NOTIFICATION: "View all notifications",
  SAVE: "Save",
  CREATE: "Create",
  ADD_NEW_FLOORBOOK_PAGE: "Add new Floorbook Entry",
  ADD_NEW_LEARNING_JOURNAL_ENTRY: "Add New Journal Entry",
  LOGOUT: "Log out",
  LIKE: "Like",
  LIKES: "Likes",
  LIKED: "Liked",
  GO_BACK: "Go Back",
  COMMENT: "Comment",
  COMMENTS: "Comments",
  REPORT: "View Reports & Analysis",
  EDIT: "Edit",
  CANCEL: "Cancel",
  SUBMIT: "Submit",
  SEND: "Send",
  LOGIN: "Login",
  CHANGE_EMAIL: "Change Email?",
  SIGN_UP: "Sign Up",
  NEXT: "Next",
  LOGIN_IF_YOU_ALREADY_SIGNED: "Login if you already signed up before",
  EDIT_FLOORBOOK: "Edit Floorbook",
  EDIT_JOURNAL: "Edit Group Learning Journal",
  CREATE_NEW_FLOORBOOK: "Create New Floorbook",
  CREATE_NEW_JOURNAL: "Create New Group Learning Journal",
  RESEND_OTP: "Resend OTP",
  DELETE: "Delete",
  REMOVE_PHOTO: "Remove Photo",
  SELECT_PHOTO: "Select Photo",
  YES: "Yes",
  SHOW_LESS: "show less",
  SHOW_MORE: "show more",
  HIDE: "Hide",
  UNHIDE: "Unhide",
  PRIVATE: "Private",
  PUBLIC: "Public",
};
export const NO_DATA_FOUND_MESSAGES = {
  NO_FLOORBOOK_PAGES_FOUND: "No Floorbook Enrties Found",
  NO_HISTORY_FOUND: "No History found.",
  SOMETHING_WENT_WRONG: "Oops... Something went wrong, please try again",
  NO_PAGE_FOUND_IN_FLOORBOOK: "No entries found in selected Floorbook",
  NO_RESOURCE_FOUND: "No Useful Resources found.",
};

export const REPORTS_STRINGS = {
  LABEL_REFLECTIVE_PRACTICE: "Reflective Practice Report",
  LABEL_COMPLETED: "Completed",
  LABEL_UNCOMPLETE: "Not Complete",
  SELECT_COMPLETION_LABEL: "Select Completion Date",
  COMPLETION_DATE_INPUT: "completionDate",
};
